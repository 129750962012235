export const modalOpen = (state, viewMode) => {
  if (!viewMode) {
    return false
  }

  return state.modals.viewMode === viewMode
}

export const alerts = state => {
  return [...state.modals.alerts]
}
