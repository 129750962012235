// import React from "react";
// import PropTypes from "prop-types";
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const SecondaryButton = styled.div`
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  text-align: center;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  font-size: 0.9em;
  cursor: pointer;

  text-decoration: none;
  color: black;

  &:hover {
    background-color: #f0f0f0;
  }

  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.85;
  letter-spacing: normal;
  color: #494d52;
`

export const StrippedLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

export const InvisibleButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 3px;
  :focus {
    outline: none;
  }
`
export const InvisibleHoverButton = styled(InvisibleButton)`
  transition: all 100ms;
  opacity: 0.6;
  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }

  &.fullWidthHeight {
    width: 100%;
    height: 100%;
  }
  &.active {
    background-color: #4d5d8026;
    opacity: 1;
  }
`
