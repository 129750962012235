import React from 'react'

import { Input as FeComponentsInput } from 'frontend-components'
import styled from 'styled-components'

import useCallbackRef from '../../hooks/useCallbackRef'

const StyledInput = styled(FeComponentsInput)`
  font-size: 12px;
  padding: 0 8px;
  width: 100%;
`

const StyledLabel = styled(StyledInput).attrs({
  as: 'span'
})``

const Pencil = styled.span`
  display: inline-block;
  transform: rotateZ(90deg);
  margin-left: 8px;
  line-height: 18px;
`
const EditIcon = () => <Pencil>&#9998;</Pencil>

export const Input = ({ customRefFn, onChange, onKeyDownCapture, value }) => {
  if (!customRefFn) {
    customRefFn = () => {}
  }

  useCallbackRef({
    initialValue: null,
    callback: customRefFn
  })

  return (
    <StyledInput
      autoFocus
      onChange={onChange}
      onKeyDownCapture={onKeyDownCapture}
      value={value}
    />
  )
}

export const Label = ({
  editVisible,
  onMouseEnter,
  onMouseLeave,
  toggleEditMode,
  value
}) => (
  <div
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={toggleEditMode}
  >
    <StyledLabel>{value}</StyledLabel>
    {editVisible && <EditIcon />}
  </div>
)
