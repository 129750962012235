import * as actionTypes from './actionTypes'

export function changeStreamAxis ({ uuid, value }) {
  return {
    type: actionTypes.CHANGE_STREAM_AXIS,
    uuid,
    value
  }
}

export function createNewAxis () {
  return {
    type: actionTypes.CREATE_NEW_AXIS
  }
}

export function createNewAxisAndAddStream ({ uuid }) {
  return {
    type: actionTypes.CREATE_NEW_AXIS_AND_ADD_STREAM,
    uuid
  }
}

export function removeAxis ({ name }) {
  return {
    type: actionTypes.REMOVE_AXIS,
    name
  }
}

export function setIsPermalink ({ setting }) {
  return {
    type: actionTypes.SET_IS_PERMALINK,
    setting
  }
}

export function setLegendName ({ name, uuid }) {
  return {
    type: actionTypes.SET_LEGEND_NAME,
    name,
    uuid
  }
}

export function setStreamColor ({ color, uuid }) {
  return {
    type: actionTypes.SET_STREAM_COLOR,
    color,
    uuid
  }
}

export function setStreamsEmphasis ({ updates }) {
  return {
    type: actionTypes.SET_STREAMS_EMPHASIS,
    updates
  }
}

export function toggleAxisStreamVisibility ({ uuid }) {
  return {
    type: actionTypes.TOGGLE_AXIS_STREAM_VISIBILITY,
    uuid
  }
}

export function toggleLegend () {
  return {
    type: actionTypes.TOGGLE_LEGEND
  }
}

export function toggleStream ({ metadata, uuid }) {
  return {
    type: actionTypes.TOGGLE_STREAM,
    metadata,
    uuid
  }
}

export function toggleStreamVisibility ({ uuid }) {
  return {
    type: actionTypes.TOGGLE_STREAM_VISIBILITY,
    uuid
  }
}

export function updateAxis ({ name, values }) {
  return {
    type: actionTypes.UPDATE_AXIS,
    name,
    values
  }
}

export function updateAxisName ({ name, prevName }) {
  return {
    type: actionTypes.UPDATE_AXIS_NAME,
    name,
    prevName
  }
}

export function updateChart ({ data }) {
  return {
    type: actionTypes.UPDATE_CHART,
    data
  }
}

export function updateInstance ({ data }) {
  return {
    type: actionTypes.UPDATE_INSTANCE,
    data
  }
}

export function updateSetting ({ setting, value }) {
  return {
    type: actionTypes.UPDATE_SETTING,
    setting,
    value
  }
}
