import * as actionTypes from './actionTypes'

export function clearStreamTableFilters () {
  return {
    type: actionTypes.CLEAR_STREAM_TABLE_FILTERS
  }
}

export function debounceGetStreams (data) {
  return {
    type: actionTypes.DEBOUNCE_GET,
    ...data
  }
}

export function getMetadataUsage ({ prefix = '' }) {
  return {
    type: actionTypes.GET_METADATA_USAGE,
    prefix
  }
}

export function getStream ({ useCache, uuid }) {
  return {
    type: actionTypes.GET_STREAM,
    useCache,
    uuid
  }
}

export function getStreams ({
  columns,
  filters,
  page,
  pageSize,
  sort,
  type = actionTypes.GET,
  useCache
}) {
  return {
    type,
    columns,
    filters,
    page,
    pageSize,
    sort,
    useCache
  }
}

export function setStreamTableColumns ({ selectedColumns }) {
  return {
    type: actionTypes.SET_STREAM_TABLE_COLUMNS,
    selectedColumns
  }
}

export function setStreamTableFilters ({ id, value }) {
  return {
    type: actionTypes.SET_STREAM_TABLE_FILTERS,
    id,
    value
  }
}

export function setStreamTableHoveredRow ({ hoveredRow }) {
  return {
    type: actionTypes.SET_STREAM_TABLE_HOVERED_ROW,
    hoveredRow
  }
}
