export const customMapStyles = [
  { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#263c3f' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6b9a76' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#38414e' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#212a37' }]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9ca5b3' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#746855' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#1f2835' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#f3d19c' }]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#2f3948' }]
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#17263c' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#515c6d' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#17263c' }]
  }
]

export const theme = {
  dark: {
    clusterMarker: {
      background: 'magenta3'
    },
    icon: {
      plusCircle: 'cyan2',
      timesCircle: 'magenta2'
    },
    styledDrawer: {
      antDrawerBody: {
        backgroundColor: 'black',
        borderColor: 'neutral4',
        color: 'neutral2',
        svg: {
          fill: 'neutral2'
        }
      },
      antDrawerHeader: {
        backgroundColor: 'neutral7',
        color: 'neutral2',
        svg: {
          fill: 'neutral2'
        }
      }
    },
    styledPopover: {
      antPopoverInner: {
        backgroundColor: 'neutral7'
      },
      antPopoverInnerContent: {
        pre: {
          backgroundColor: 'neutral6',
          color: 'cyan2'
        }
      }
    }
  },
  light: {
    clusterMarker: {
      background: 'red2'
    },
    icon: {
      plusCircle: 'blue2',
      timesCircle: 'red2'
    },
    styledDrawer: {
      antDrawerBody: {
        backgroundColor: 'white',
        borderColor: 'neutral7',
        color: 'black',
        svg: {
          fill: 'neutral7'
        }
      },
      antDrawerHeader: {
        backgroundColor: 'white',
        color: 'black',
        svg: {
          fill: 'neutral7'
        }
      }
    },
    styledPopover: {
      antPopoverInner: {
        backgroundColor: 'neutral2'
      },
      antPopoverInnerContent: {
        pre: {
          backgroundColor: 'neutral3',
          color: 'blue2'
        }
      }
    }
  }
}
