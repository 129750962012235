import React, { useState } from 'react'

import { createGlobalStyle, css } from '@xstyled/styled-components'
import {
  bindTheme,
  darkTheme,
  lightTheme,
  ThemeProvider
} from 'frontend-components'

import { redesignEnabled } from '../../utils/featureFlags'
import { getThemeFromLocalStorage } from '../../services/localStorage'

const globalColors = {
  dark: {
    body: {
      backgroundColor: 'black'
    }
  },
  light: {
    body: {
      backgroundColor: 'white'
    }
  }
}

const colors = bindTheme(globalColors)

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors('body.backgroundColor')};
  }

  ${() => {
    if (!redesignEnabled()) {
      return css`
        html body,
        html input,
        html textarea,
        html button {
          font-family: 'Roboto', sans-serif;
        }
        [class^='ant'] {
          font-family: 'Roboto', sans-serif !important;
        }

        html .ant-notification {
          width: unset;
          z-index: 0;
        }
        html label {
          /* TODO: This is overriding a bootstrap style that's causing issues. Remove this once react-strap is gone from the repo */
          margin-bottom: 0;
        }
      `
    } else {
      return css`
        html,
        body,
        #root {
          height: unset;
        }
      `
    }
  }}
`

export default function Theme (props) {
  const [themePref] = useState(getThemeFromLocalStorage())

  const themeName = themePref

  const theme =
    {
      light: lightTheme,
      dark: darkTheme
    }[themeName] || darkTheme

  return (
    <ThemeProvider theme={redesignEnabled() ? theme : lightTheme}>
      <GlobalStyle />
      {props.children}
    </ThemeProvider>
  )
}
