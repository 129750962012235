import React from 'react'

import { connect } from 'react-redux'

import { redesignEnabled } from '../../utils/featureFlags'
import { SecondaryButton } from '../Buttons/Buttons'
import plotter from '../../ducks/plotter'

const generateHTMLDoc = jupyterText =>
  `
<!DOCTYPE html>
<html>
<head>
    <link href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.15.0/themes/prism.min.css" rel="stylesheet" />
    <title>PingThings - Plotter</title>
</head>
<body>
    <pre style="font-size: 12px;"><code class="language-python">${jupyterText}</code></pre>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/prism/1.15.0/prism.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/prism/1.15.0/components/prism-python.min.js"></script>
</body>
</html>
`

const generateJupyterText = vars => {
  return `
"""
Copy this snippet of code to pull data from the Plotter into your Python environment.

Read our Python API docs to learn more.
https://btrdb.readthedocs.io/en/latest/
"""

import btrdb

# Connect to the database
db = btrdb.connect()



# Define variables
stream_uuids = ${JSON.stringify(vars.uuids, 4, 4)}

start_time = "${vars.start_time}"
end_time   = "${vars.end_time}"
resolution = ${vars.resolution.toString()}


# Define utility function for querying streams
def get_streams(stream_uuids, start_time, end_time, resolution, visible_only=True):
    """
    This function returns a StreamSet object filtered using the stream uuids 
    and time interval selected in the plotter.

    Parameters
    -----------
    stream_uuids: list[str]
      list of stream uuids to query

    start_time: val : int or datetime like object
      Earliest time stamp queried

    end_time: val : int or datetime like object
      Latest time stamp queried

    resolution: int
      Time resolution of aggregates returned, where each aggregation window spans
      2^resolution nanoseconds (see https://btrdb.readthedocs.io/en/latest/working/stream-view-data.html 
      for more information)

    visible_only: bool, default: True
      Indicates whether to query data for all streams selected, or only the
      selected streams which are set as visible in the plotter

    Returns
    -----------
    """
    if visible_only:
        streams = db.streams(*stream_uuids['visible'])
    else:
        streams = db.streams(*stream_uuids['all'])


    # convert start and end time to nanoseconds
    start_ns = btrdb.utils.timez.to_nanoseconds(start_time)
    end_ns   = btrdb.utils.timez.to_nanoseconds(end_time)

    # filter to specified time window
    streams = streams.filter(start=start_ns, end=end_ns)
    
    # specify time resolution and return the result
    return streams.aligned_windows(resolution)


# Query the data and return results as a DataFrame
streams = get_streams(stream_uuids, start_time, end_time, resolution)
df = streams.to_dataframe(agg='mean')
df.head()
`
}

const getJupyterVars = ({ chart, selectedRows }) => {
  const start = (chart.selection || [])[0] || chart.start
  const end = (chart.selection || [])[1] || chart.end
  return Object.keys(selectedRows).reduce(
    (results, hexUuid) => {
      results.uuids.all.push(hexUuid)
      const stream = selectedRows[hexUuid]
      if (stream.plot && stream.plot.visibility === false) {
        return results
      } else {
        results.uuids.visible.push(hexUuid)
        return results
      }
    },
    {
      uuids: {
        visible: [],
        all: []
      },
      start_time: nanoEpochToISO(start),
      end_time: nanoEpochToISO(end),
      start_epochnano: start,
      end_epochnano: end,
      resolution: chart.resolution
    }
  )
}

const nanoEpochToISO = epochnano => {
  const en = epochnano.toString()
  const epochMillisecond = Number(en.slice(0, 13))
  const nano = en.slice(13, 16)
  const isoString = new Date(epochMillisecond).toISOString()
  const nanoISOString = isoString.slice(0, -1) + nano
  return nanoISOString
}

const printJupyterExportData = ({ chart, selectedRows }) => {
  const jupyterVars = getJupyterVars({ chart, selectedRows })
  const resultText = generateJupyterText(jupyterVars)
  const htmldoc = generateHTMLDoc(resultText)

  var tab = window.open('about:blank', '_blank')
  tab.document.write(htmldoc) // where 'html' is a variable containing your HTML
  tab.document.close() // to finish loading the page
}

export const ExportToJupyter = ({ chart, selectedRows, streamsSelected }) => {
  function open () {
    printJupyterExportData({ chart, selectedRows })
  }

  const disabled = !streamsSelected
  const label = 'Export to Jupyter'

  if (redesignEnabled()) {
    return (
      <li>
        <button
          className='no-icon'
          disabled={disabled}
          id='export-to-jupyter'
          onClick={open}
          type='button'
        >
          <span className='item-text'>{label}</span>
        </button>
      </li>
    )
  } else {
    return (
      <SecondaryButton className='export-to-jupyter' onClick={open}>
        {label}
      </SecondaryButton>
    )
  }
}

const mapStateToProps = state => {
  const chart = plotter.selectors.chart(state)
  const selectedRows = plotter.selectors.selectedRows(state)
  const streamsSelected = plotter.selectors.streamsSelected(state)

  return {
    chart,
    selectedRows,
    streamsSelected
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ExportToJupyter)
