export function convertArrayToObject (array, key) {
  return array.map(item => {
    return {
      [key]: item
    }
  })
}

export function generateCollection (array) {
  const collection = []
  for (var z = 0; z < array.length; z++) {
    collection.push(array[z][1])
  }
  return collection
}

export function isInnerNode (array, innerNode) {
  return array.map(el => {
    const o = Object.assign({}, el)
    o.isInnerNode = innerNode
    return o
  })
}
