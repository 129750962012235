import React from 'react'

export const ChartIcon = () => (
  <svg viewBox='0 0 445 445' xmlns='http://www.w3.org/2000/svg'>
    <g id='lineplot' fill='none'>
      <circle strokeWidth='27' cx='125' cy='292' r='27.5' />
      <circle strokeWidth='27' cx='208' cy='180' r='27.5' />
      <circle strokeWidth='27' cx='320' cy='264' r='27.5' />
      <circle strokeWidth='27' cx='403' cy='97' r='27.5' />
      <path d='M180.96 195l22.362 16.85-52.96 70.28L128 265.28z' />
      <path d='M307.037 237.232l-16.85 22.362-70.28-52.96 16.85-22.362z' />
      <path d='M380.708 112.756l24.949 12.712-62.498 122.658-24.948-12.712z' />
    </g>
    <g id='axes'>
      <path d='M0 0h28v445H0z' />
      <path d='M445 417v28H0v-28z' />
    </g>
    <g id='ticks'>
      <path d='M56 251v28H0v-28z' />
      <path d='M56 334v28H0v-28z' />
      <path d='M56 167v28H0v-28z' />
      <path d='M56 83v28H0V83z' />
    </g>
  </svg>
)

export default ChartIcon
