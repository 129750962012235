import gql from 'graphql-tag'

const CREATE_PERMALINK = gql`
  mutation CreatePermalink($data: Json!, $bookmark: Json, $public: Json) {
    CreatePermalink(
      body: { data: $data, bookmark: $bookmark, public: $public }
    ) {
      error {
        code
        msg
      }
      permalink {
        uuid
      }
      __typename
    }
  }
`

const GET_PERMALINK = gql`
  query getPermalink($uuid: ID!) {
    GetPermalink(body: { uuid: $uuid }) {
      error {
        code
        msg
      }
      permalink {
        uuid
        data
        thumbnail
      }
    }
  }
`

export async function createPermalink ({ client, state }) {
  try {
    const data = { ...state }
    const result = await client.mutate({
      mutation: CREATE_PERMALINK,
      variables: {
        data: JSON.stringify(data),
        bookmark: { value: false },
        public: { value: true }
      }
    })

    return { data: result.data.CreatePermalink.permalink }
  } catch (e) {
    console.error({ error: e.message })
    return { error: e.message }
  }
}

export async function getPermalink ({ client, uuid }) {
  try {
    const data = await client.query({
      query: GET_PERMALINK,
      variables: { uuid }
    })

    return {
      data: {
        ...data.data.Permalink.permalink,
        data: JSON.parse(data.data.Permalink.permalink.data)
      }
    }
  } catch (e) {
    console.error({ error: e.message })
    return { error: e.message }
  }
}
