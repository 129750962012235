import React, { useState, useEffect, useCallback } from 'react'

import styled from 'styled-components'

import { ChartStreamDataProvider as RedesignedChartStream } from '../ChartStream/ChartStream.redesign'
import { redesignEnabled } from '../../utils/featureFlags'
import { TOKEN_KEY } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import AppProviders from '../../providers/AppProviders'
import ChartStream from '../ChartStream/ChartStream'
import Loader from '../Loader/Loader'
import permalinks from '../../ducks/permalinks'
import plotter from '../../ducks/plotter'
import useAuthorization from '../../hooks/useAuthorization'

/**
 * This component loads a permalink with the app state for a specific chart.
 * It then parses that and uses it to render a ChartStream object.
 *
 * This can be used in conjunction with an endpoint on the bffplotter server that loads a page in a headless
 * Chrome browser and takes a snapshot and returns the resulting image. This allows us to mix
 * svg, dom elements, and canvas together without worrying about how to convert to an image.
 *
 * However, at the time of this comment, we rerender all the DOM elements
 * using canvas so another server isn't necessary. The user just needs to
 * right click the chart and choose save as image as long as a pure canvas
 * implementation is maintained.
 */

function ScreenshotPage (props) {
  const { permalinkId } = props.match.params

  useAuthorization()

  const dispatch = useDispatch()
  const axesArray = useSelector(plotter.selectors.axesArray)
  const chartState = useSelector(permalinks.selectors.contents(permalinkId))
  const fetchLoading = useSelector(permalinks.selectors.fetchLoading)
  const plotsArray = useSelector(plotter.selectors.plotsArray)

  const [chartDimensions, setChartNode] = useDimensions()

  useEffect(() => {
    if (!chartState && !fetchLoading) {
      dispatch(permalinks.actions.fetch({ id: permalinkId }))
    }
  }, [dispatch, permalinkId, chartState, fetchLoading])

  if (!chartState) {
    return <Loader />
  } else {
    return render()
  }

  function render () {
    const {
      streams,
      axes,
      legend,
      chart: { start, end, selection }
    } = chartState

    if (!streams || !axes || !legend) {
      return <div>State was incorrectly shaped</div>
    }

    const Component = redesignEnabled() ? RedesignedChartStream : ChartStream

    return (
      <div ref={setChartNode}>
        {chartDimensions && (
          <Component
            axes={axesArray}
            className='chart-0'
            dimensions={chartDimensions}
            hideHints
            initialEndTime={end}
            initialSelectedTimeRange={selection}
            initialStartTime={start}
            plots={plotsArray}
            showLegend={legend.visible}
          />
        )}
      </div>
    )
  }
}

function useDimensions () {
  const [dimensions, setDimensions] = useState(null)
  const [currentNode, setCurrentNode] = useState(null)

  const ref = useCallback(n => {
    if (n !== null) recalc(n)
    if (n !== currentNode) setCurrentNode(n)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => recalc(currentNode))
    return () => window.removeEventListener('resize', () => recalc(currentNode))
  })

  function recalc (node) {
    node = node || currentNode
    if (!node) return
    const dims = node.getBoundingClientRect()
    setDimensions({
      width: dims.width,
      height: dims.width / 2
    })
  }

  return [dimensions, ref]
}

export default props => (
  <AppProviders>
    <ScreenshotPage {...props} />
  </AppProviders>
)

const RegenerateControls = styled.div`
  font-size: 18px;
  margin: 50px 0 10px 50px;

  & div:first-child {
    margin-bottom: 10px;
  }
  input,
  button {
    font-size: inherit;
    margin-right: 10px;
  }
  input {
    width: 100px;
  }
`
const ErrorMsg = styled.div`
  color: red;
  font-size: 14px;
`
/**
 * This calls the backend and displays the resulting image.
 * It also gives the user the opportunity to regenerate the
 * image using a new width.
 */
export class ScreenshotImage extends React.Component {
  constructor (props) {
    super(props)
    let propsWidth
    if (Number.isNaN(Number(this.props.match.params.width))) propsWidth = 1024
    else propsWidth = this.props.match.params.width
    this.state = {
      loading: true,
      inputWidth: propsWidth,
      newWidth: propsWidth,
      inputError: false
    }
  }

  componentDidMount () {
    const { inputWidth } = this.state
    const fetchResult = this.fetchImage(inputWidth)
    fetchResult.then(v => {
      const url = window.URL.createObjectURL(v)
      this.setState({
        imageBlobUrl: url
      })
      return v
    })
  }

  fetchImage = async width => {
    const url = this.makeUrl(width)
    const opts = {
      headers: {},
      mode: 'cors'
    }
    const token = localStorage.getItem(TOKEN_KEY)
    if (token && token !== 'null') opts.headers.Authorization = token
    const fetchVar = fetch(url, opts)
    const result = await fetchVar.then(v => v.blob())
    return result
  }

  makeUrl = width => {
    if (!width) return
    const base64ChartState = this.props.match.params.state
    const url = `/api/image/${width}/${base64ChartState}`
    return url
  }

  render () {
    // if (this.state.imagedata === undefined)
    // return "Preparing imaging for download";
    return (
      <>
        {this.state.loading === false && (
          <RegenerateControls>
            <div>You may change the pixel width using the following input:</div>
            <input
              type='text'
              value={this.state.inputWidth}
              onChange={e =>
                console.log(e.target) ||
                this.setState({
                  inputWidth: e.target.value
                })
              }
            />
            <button
              onClick={() => {
                const inputWidth = Number(this.state.inputWidth)
                const newWidth = Number(this.state.newWidth)
                console.log('widths:', inputWidth, this.state.newWidth)
                // In JS NaN is the only value not equal to itself
                // eslint-disable-next-line no-self-compare
                if (inputWidth !== inputWidth) {
                  return this.setState({ inputError: 'NaN' })
                }
                if (inputWidth === newWidth) {
                  return this.setState({ inputError: 'Same' })
                }
                if (inputWidth < 600) {
                  return this.setState({
                    inputError: 'outofrange',
                    inputWidth: 600
                  })
                }
                if (inputWidth > 2500) {
                  return this.setState({
                    inputError: 'outofrange',
                    inputWidth: 2500
                  })
                }
                const fetchResult = this.fetchImage(inputWidth)
                this.setState({
                  loading: true,
                  inputError: false,
                  newWidth: inputWidth,
                  imageBlobUrl: undefined
                })
                fetchResult.then(v => {
                  const url = window.URL.createObjectURL(v)
                  this.setState({
                    imageBlobUrl: url
                  })
                  return v
                })
              }}
            >
              Regenerate Image
            </button>
            {this.state.inputError === 'NaN' && (
              <ErrorMsg>Must enter an integer</ErrorMsg>
            )}
            {this.state.inputError === 'outofrange' && (
              <ErrorMsg>Width must be between 600 and 2500</ErrorMsg>
            )}
            {this.state.inputError === 'Same' && (
              <ErrorMsg>
                {`The current image is already ${this.state.newWidth}px wide`}
              </ErrorMsg>
            )}
          </RegenerateControls>
        )}
        {this.state.loading && (
          <div style={{ fontSize: '24px', margin: '50px' }}>
            Preparing image...
          </div>
        )}
        {this.state.imageBlobUrl && (
          <img
            key={this.state.newWidth}
            alt='Screenshot of chart'
            width={this.state.newWidth}
            src={this.state.imageBlobUrl}
            onLoad={() => this.setState({ loading: false })}
          />
        )}
      </>
    )
  }
}
