import React from 'react'

import { connect } from 'react-redux'
import * as d3array from 'd3-array'
import * as d3color from 'd3-color'
import * as d3scale from 'd3-scale'
import PropTypes from 'prop-types'

import { getTreePath, treePathTime } from '../../lib/treePath'
import {
  getScaleResolution,
  isInRange,
  roundTimeRange
} from '../../lib/btrdbMath'
import { computeAxisLayers, drawAxisLayers } from '../../lib/timeAxis'
import plotter from '../../ducks/plotter'

const {
  selectors: { isUTC: isUTCSelector }
} = plotter

function fadeColor (color, opacity) {
  const c = d3color.color(color)
  c.opacity = opacity
  return c + ''
}

export class TreeViz extends React.Component {
  constructor () {
    super()
    this.state = {}
  }

  static propTypes = {
    highlightedPoint: PropTypes.objectOf({
      time: PropTypes.number,
      pointRes: PropTypes.number
    }),
    selectedTimeRange: PropTypes.arrayOf(PropTypes.number),
    plotScale: PropTypes.func,
    cellW: PropTypes.number,
    cellH: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    labelRowH: PropTypes.number,
    margin: PropTypes.number,
    mouseX: PropTypes.number,
    highlightColor: PropTypes.string,
    isUTC: PropTypes.bool
  }

  static defaultProps = {
    cellW: 5,
    cellH: 8,
    labelRowH: 20,
    margin: 16
  }

  static getDerivedStateFromProps (props, state) {
    const canvasRatio = window.devicePixelRatio
    const { highlightedPoint, plotScale, labelRowH } = props
    const mouseX = props.mouseX || state.mouseX
    const path = highlightedPoint
      ? getTreePath(highlightedPoint.time, highlightedPoint.pointRes)
      : getTreePath(plotScale.invert(mouseX), getScaleResolution(plotScale))
    const { midRes } = path
    const cones = midRes ? path.slice() : path.slice(0, -1)
    const n = cones.length
    const cellCounts = d3array
      .range(n + 1)
      .map(i => (i === n && midRes ? 2 ** midRes.level : 64))
    const rowPad = labelRowH * 1.6
    return { canvasRatio, path, cones, cellCounts, mouseX, rowPad }
  }

  draw (canvas) {
    const ctx = canvas.getContext('2d')
    const {
      width,
      height,
      highlightedPoint,
      highlightColor,
      selectedTimeRange,
      margin,
      cellW,
      cellH,
      isUTC,
      labelRowH,
      plotScale
    } = this.props
    const { canvasRatio, path, cellCounts, cones, rowPad } = this.state
    ctx.save()
    ctx.scale(canvasRatio, canvasRatio)
    ctx.clearRect(0, 0, width, height)

    const w = 64 * cellW
    const left = width / 2 - w / 2

    ctx.translate(left, margin)
    const levelY = i => i * (rowPad + cellH)

    // draw levels
    for (const [i, numCells] of cellCounts.entries()) {
      // 64 ticks
      const y = levelY(i)
      ctx.beginPath()
      for (const j of d3array.range(64)) {
        const x = j * cellW
        ctx.moveTo(x, y)
        ctx.lineTo(x, y + cellH)
      }
      ctx.strokeStyle = 'rgba(0,0,0,0.1)'
      ctx.stroke()

      // lower-res ticks
      const myCellW = (cellW * 64) / numCells
      if (numCells < 64) {
        ctx.beginPath()
        for (const j of d3array.range(numCells)) {
          const x = j * myCellW
          ctx.moveTo(x, y)
          ctx.lineTo(x, y + cellH)
        }
        ctx.strokeStyle = 'rgba(0,0,0,0.25)'
        ctx.stroke()
      }

      // date ticks and labels
      const t0 = treePathTime(path.slice(0, i))
      const t1 = t0 + 2 ** (62 - 6 * i)
      const scaleMs = d3scale
        .scaleLinear()
        .domain([t0, t1].map(t => t / 1e6))
        .range([0, w])
      const timeAxisLayers = computeAxisLayers(scaleMs, {
        isUTC
      }).slice(0, i === cellCounts.length - 1 ? 2 : 1)
      ctx.save()
      ctx.translate(0, y + cellH)
      ctx.fillStyle = 'rgba(90,110,100, 0.7)'
      ctx.strokeStyle = 'rgba(90,110,100, 0.7)'
      ctx.beginPath()
      ctx.rect(0, 0, w, rowPad * 2)
      ctx.clip()
      drawAxisLayers(ctx, scaleMs, timeAxisLayers, { rowH: labelRowH })
      ctx.restore()

      // draw visible and selected regions
      {
        const res = 56 - 6 * i
        const getInsideCellRange = timeRange => {
          const paths = d3array
            .range(64)
            .map(j => [...path.slice(0, i), j])
            .filter(p =>
              isInRange(treePathTime(p), roundTimeRange(timeRange, res))
            )
          if (paths.length === 0) return
          const start = paths[0].slice(-1)[0]
          const end = paths.slice(-1)[0].slice(-1)[0] + 1
          return [start, end].map(j => j * cellW)
        }
        const vis = getInsideCellRange(plotScale.domain())
        if (vis) {
          const [x0, x1] = vis
          ctx.beginPath()
          const y0 = y + cellH - 1
          ctx.moveTo(x0, y0)
          ctx.lineTo(x1, y0)
          ctx.strokeStyle = '#333'
          ctx.stroke()
        }
        if (selectedTimeRange) {
          const select = getInsideCellRange(selectedTimeRange)
          if (select) {
            const [x0, x1] = select
            ctx.beginPath()
            ctx.rect(x0, y, x1 - x0, cellH)
            ctx.fillStyle = fadeColor(highlightColor, 0.3)
            ctx.fill()
          }
        }
      }

      // whole border
      ctx.beginPath()
      ctx.rect(0, y, w, cellH)
      ctx.strokeStyle = '#333'
      ctx.stroke()
    }

    // draw cones
    for (const [i, node] of cones.entries()) {
      const y0 = levelY(i)
      const y1 = levelY(i + 1)
      const x = node * cellW
      ctx.beginPath()
      ctx.moveTo(x, y0 + cellH)
      ctx.lineTo(x + cellW, y0 + cellH)
      ctx.lineTo(w, y1)
      ctx.lineTo(0, y1)
      ctx.fillStyle = 'rgba(80,100,120, 0.15)'
      ctx.fill()
      ctx.beginPath()
      ctx.rect(x, y0, cellW, cellH)
      ctx.fillStyle = 'rgba(255,255,255,0.5)'
      ctx.fill()
      ctx.stroke()
    }

    // draw highlight
    if (highlightedPoint) {
      const y = levelY(cellCounts.length - 1)
      const myCellW = (cellW * 64) / cellCounts.slice(-1)[0]
      const x = (path.midRes ? path.midRes.cell : path.slice(-1)[0]) * myCellW
      ctx.beginPath()
      ctx.rect(x, y, myCellW, cellH)
      ctx.fillStyle = fadeColor(highlightColor, 0.5)
      ctx.fill()
      ctx.strokeStyle = '#333'
      ctx.stroke()
    }

    // title
    {
      ctx.fillStyle = '#888'
      ctx.textBaseline = 'bottom'
      ctx.textAlign = 'left'
      const pad = 6
      ctx.fillText('BTrDB Tree', 0, levelY(0) - pad)
    }

    ctx.restore()
  }

  render () {
    const { canvasRatio } = this.state
    const { width, height } = this.props
    return (
      <canvas
        ref={node => {
          if (!node) return
          this.draw(node)
        }}
        width={width * canvasRatio}
        height={height * canvasRatio}
        style={{
          width: `${width}px`,
          height: `${height}px`
        }}
      />
    )
  }
}

const mapStateToProps = state => {
  const isUTC = isUTCSelector(state)

  return {
    isUTC
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TreeViz)
