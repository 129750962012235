export const getVersionInfo = (__env__, NODE_ENV) => {
  const versionInfo = (__env__ && __env__.VERSION_INFO) || {
    NODE_ENV
  }
  if (
    NODE_ENV === 'production' &&
    (versionInfo === undefined || versionInfo.version === undefined)
  ) {
    console.warn(
      '[VERSION MISSING] The env var VERSION_INFO used to show the UI version is missing'
    )
  }
  return versionInfo
}
