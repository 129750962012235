import { all, setContext } from 'redux-saga/effects'

import auth from './auth'
import errors from './error'
import grouper from './grouper'
import heatmap from './heatmap'
import modals from './modals'
import permalinks from './permalinks'
import pmus from './pmus'
import sql from './sql'
import streams from './streams'

export default function * root ({ client }) {
  yield setContext({ client })
  yield all([
    ...auth.sagas.watchers,
    ...errors.sagas.watchers,
    ...grouper.sagas.watchers,
    ...heatmap.sagas.watchers,
    ...modals.sagas.watchers,
    ...permalinks.sagas.watchers,
    ...pmus.sagas.watchers,
    ...sql.sagas.watchers,
    ...streams.sagas.watchers
  ])
}
