import * as actionTypes from './actionTypes'

export const close = () => {
  return {
    type: actionTypes.CLOSE
  }
}

export const open = ({ viewMode }) => {
  return {
    type: actionTypes.OPEN,
    viewMode
  }
}

export const openAlert = ({ kind, message }) => {
  const id = Math.floor(Math.random() * 1000000)

  return {
    id,
    kind,
    message,
    type: actionTypes.OPEN_ALERT
  }
}

export const closeAlert = arg => {
  const id = typeof arg === 'object' ? arg.id : arg
  return {
    type: actionTypes.CLOSE_ALERT,
    id
  }
}
