import * as d3array from 'd3-array'
import { timeMin, timeMax, maxRes, stepRes } from './btrdbMath'

export function getTreePath (targetTime, targetRes) {
  const cellW = res => 2 ** res
  const resCell = (t, res) => Math.floor(t / cellW(res))

  let t = targetTime - timeMin
  const path = []
  for (let res = maxRes; res >= targetRes; res -= stepRes) {
    path.push(resCell(t, res))
    t %= cellW(res)
  }

  const midResLevel = (maxRes - targetRes) % 6
  if (midResLevel > 0) {
    path.midRes = {
      level: midResLevel,
      cell: resCell(t, targetRes)
    }
  }
  return path
}

export function treePathTime (path) {
  let t = timeMin
  let res = maxRes
  for (const cell of path) {
    t += cell * 2 ** res
    res -= stepRes
  }
  if (path.midRes) {
    const { level, cell } = path.midRes
    t += cell * 2 ** (res - level)
  }
  return t
}

export function getTreePathBounds (startTime, endTime, resolution) {
  startTime = Math.max(timeMin, startTime)
  endTime = Math.min(timeMax, endTime + 2 ** resolution * 2)
  const startPath = getTreePath(startTime, resolution)
  let endPath = getTreePath(endTime, resolution)
  if (endPath[0] === 64) {
    endPath = nextTreePath(endPath, -1)
  }
  return [startPath, endPath]
}

function nextTreePath (curr, delta = 1) {
  if (Math.abs(delta) > 1) {
    throw new Error('nextTreePath currently only works for deltas -1,0,1')
  }

  const next = curr.slice()
  if (delta === 0) return next

  const step = (cell, len) => {
    cell += delta
    cell %= len
    if (cell < 0) cell += len
    const wrapped = delta > 0 ? cell === 0 : cell === len - 1
    return { cell, wrapped }
  }

  if (curr.midRes) {
    const { level } = curr.midRes
    const len = 2 ** level
    const { cell, wrapped } = step(curr.midRes.cell, len)
    next.midRes = { level, cell }
    if (!wrapped) return next
  }

  const len = 64
  for (const i of d3array.range(next.length).reverse()) {
    const { cell, wrapped } = step(next[i], len)
    next[i] = cell
    if (!wrapped) break
  }
  return next
}

function treePathsEqual (a, b) {
  return (
    JSON.stringify(a) === JSON.stringify(b) &&
    JSON.stringify(a.midRes) === JSON.stringify(b.midRes)
  )
}

export function iterateTreePathBounds ([start, stop]) {
  const paths = []
  for (let i = start; true; i = nextTreePath(i)) {
    paths.push(i)
    if (treePathsEqual(i, stop)) break
  }
  return paths
}
