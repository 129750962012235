import * as actionTypes from './actionTypes'

const DEFAULT_STATE = { viewMode: null, alerts: [] }

export default function modals (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionTypes.CLOSE: {
      return { ...DEFAULT_STATE }
    }
    case actionTypes.OPEN: {
      const { viewMode } = action
      return {
        ...state,
        viewMode
      }
    }
    case actionTypes.OPEN_ALERT: {
      const { id, kind, message } = action

      return {
        ...state,
        alerts: state.alerts.concat([{ id, kind, message }])
      }
    }
    case actionTypes.CLOSE_ALERT: {
      const { id } = action

      const alerts = [...state.alerts].filter(alert => alert.id !== id)

      return {
        ...state,
        alerts
      }
    }
    default: {
      return state
    }
  }
}
