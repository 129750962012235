import clone from 'lodash/cloneDeep'

import * as actionTypes from './actionTypes'
import { updateNode } from './updateNode'

const initialState = {
  data: [],
  error: false,
  loading: false,
  success: false
}

export default function grouper (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_COLLECTION: {
      return {
        ...state,
        error: false,
        loading: true,
        success: false
      }
    }
    case actionTypes.GET_COLLECTION_ERROR: {
      return {
        ...initialState,
        error: true,
        loading: false,
        success: false
      }
    }
    case actionTypes.GET_COLLECTION_SUCCESS: {
      const { data, id } = action

      state = clone(state)
      let grouperData = state.data

      grouperData = updateNode({ data, id, grouperData })

      return {
        ...state,
        data: grouperData,
        error: false,
        id,
        loading: false,
        success: true
      }
    }
    default:
      return state
  }
}
