import { ensureHexUuid } from '../../utils/utils'
import * as serializers from './serializers'
import plotter from '../plotter'

// just exported for testing purposes. If you find you need to reuse this somewhere,
// it's worth thinking about a better location for this func.
export const _getAccessor = ({ fieldName, name }) => {
  const getMetadata = d => {
    if (d.metadata) {
      return d.metadata
    } else {
      return d
    }
  }

  const split = name.split('.')
  const rootKey = name.split('.').length > 1 ? split[0] : null
  let accessor
  if (rootKey) {
    accessor = d => {
      if (Object.keys(d).length === 0) return
      return getMetadata(d)[rootKey][fieldName]
    }
  } else accessor = d => getMetadata(d)[fieldName]

  if (fieldName === 'uuid') {
    accessor = d => ensureHexUuid(getMetadata(d)[fieldName])
  }

  return accessor
}

export const availableColumns = state => {
  const topLevel = serializers.serializeAvailableColumns({
    streamsData: streamsData(state)
  })
  const annotations = metadataUsage(state)

  return {
    annotations,
    topLevel: topLevel.topLevel
  }
}
export const error = state => state.streams.error
export const loading = state => state.streams.loading
export const metadataUsage = state => state.streams.streamTable.metadataUsage
export const metadataUsageStatus = state =>
  state.streams.streamTable.metadataUsageStatus
export const stream = (state, uuid) => state.streams[uuid]
export const streamError = state => state.streams.streamError
export const streamLoading = state => state.streams.streamLoading
export const streamsData = state => state.streams.data
export const streamsDataWithHightlightedRows = state => {
  return state.streams.data.map(datum => {
    const isHighlighted = Object.keys(
      plotter.selectors.selectedRows(state)
    ).includes(datum.uuid)
    return {
      ...datum,
      isHighlighted
    }
  })
}
export const streamTableSelectedColumns = state => {
  const selectedColumns = state.streams.streamTable.selectedColumns
  for (let i = 0; i < selectedColumns.length; i++) {
    const { Header, id } = selectedColumns[i]
    selectedColumns[i].accessor = _getAccessor({ fieldName: Header, name: id })
  }
  return selectedColumns
}
export const streamTableFilters = state =>
  state.streams.streamTable.filters || {}
export const streamTableHoveredRow = state => {
  const hoveredRow = state.streams.streamTable.hoveredRow
  if (hoveredRow.uuid) {
    return hoveredRow
  } else {
    return {}
  }
}
export const total = state => state.streams.total
