import React from 'react'

import 'antd/lib/icon/style/css'
import 'antd/lib/tooltip/style/css'
import { bindTheme, Icon as FeComponentsIcon } from 'frontend-components'
import { useApolloClient } from 'react-apollo'
import Icon from 'antd/lib/icon'
import styled from 'styled-components'
import Tooltip from 'antd/lib/tooltip'

import { InvisibleHoverButton } from '../../Buttons/Buttons'
import { QUERY_NEAREST } from '../../ChartStream/ChartStream'
import { redesignEnabled } from '../../../utils/featureFlags'
import { resetXScaleTheme } from './resetXScaleTheme'
import { SET_X_SCALE } from '../../Chart/Chart'

const colors = bindTheme(resetXScaleTheme)

const StyledIcon = styled(FeComponentsIcon)``

export const IconButton = styled.button`
  background: transparent;
  border: none !important;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  padding: 8px 0;

  path {
    fill: ${colors('icon.color')};
  }

  &:focus {
    outline: none;
  }

  &:hover {
    path {
      fill: ${props =>
        props.color ? props.color : colors('icon.hover.color')(props)};
    }
  }

  &.active {
    background-color: ${colors('icon.active.backgroundColor')};
  }
`

function useQueryStreamStartEnd () {
  const client = useApolloClient()
  return async uuid => {
    const promise = client.query({
      query: QUERY_NEAREST,
      variables: {
        uuid,
        start: '0',
        // Send a query for the end of the day instead of now so the timestamp stays consistent and our gql client can more easily cache the request
        end: new Date().setUTCHours(23, 59, 59, 999) + '000000'
      }
    })
    const result = await promise
    let returnValue = {}
    try {
      returnValue = {
        ...result,
        data: [result.data.start.value.time, result.data.end.value.time]
      }
    } catch (error) {
      returnValue = result
    }
    return returnValue
  }
}

/**
 *
 * Find the uuid and then query for the range, and then when a
 * use clicks then we dispatch the query and await it
 */
export function ResetXScale (row) {
  const queryStreamStartEnd = useQueryStreamStartEnd()

  const { plot } = row.original || {}
  const { uuid } = plot || {}

  const clickHandler = async () => {
    // make a call to nearest to get the full range, then reset
    // the chart to those numbers

    const promise = queryStreamStartEnd(uuid)
    const result = await promise

    document.dispatchEvent(
      new CustomEvent(SET_X_SCALE, { detail: result && result.data })
    )
  }

  if (redesignEnabled) {
    return (
      <IconButton color={plot ? plot.color : null} onClick={clickHandler}>
        <StyledIcon height={8} name='zoom' viewBox='0 0 18 8' width={18} />
      </IconButton>
    )
  } else {
    return (
      <Tooltip
        mouseEnterDelay={0.5}
        mouseLeaveDelay={0}
        placement='left'
        title="Recenter chart on this stream's data"
      >
        <InvisibleHoverButton
          style={{ width: '100%', height: '100%' }}
          onClick={clickHandler}
        >
          <Icon style={{ fontSize: '20px' }} type='column-width' />
        </InvisibleHoverButton>
      </Tooltip>
    )
  }
}
