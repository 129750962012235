import React from 'react'

import 'antd/lib/notification/style/css'
import 'antd/lib/tooltip/style/css'
import 'react-table-6/react-table.css'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import matchSorter from 'match-sorter'
import notification from 'antd/lib/notification'
import ReactTable from 'react-table-6'
import styled from 'styled-components'
import ToolTip from 'antd/lib/tooltip'

import { filterAllMethod } from '../../utils/utils'
import { SecondaryButton } from '../Buttons/Buttons'
import { SideSelector } from './ChartControls'
import {
  TableControlBarBorderless,
  CustomReactTableStyles
} from '../styled/styled'
import YScaleControlContainer from './AxesTableComponents/YScaleControlContainer'
import EditableLabelContainer from '../EditableLabel/EditableLabelContainer'
import plotter from '../../ducks/plotter'
import StreamListTableContainer from './StreamListTableContainer'

const HighlightHint = styled.span`
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
  font-size: 16px;

  .icons {
    font-size: 14px;
  }

  &.noStreams:hover {
    display: flex;
    color: black;
    cursor: pointer;
  }
  &.noStreams {
    color: #bbb;
  }
  display: flex;
  color: #eee;
`

const AddAxis = ({ createNewAxis }) => {
  const handleAddAxis = () => {
    createNewAxis()
  }

  return (
    <SecondaryButton onClick={handleAddAxis} style={{ paddingLeft: '13px' }}>
      <FontAwesomeIcon style={{ marginRight: '5px' }} icon='plus' />
      <span>Create new Axis</span>
    </SecondaryButton>
  )
}

export const AxisSideSelector = ({ side, axis, updateAxis }) => {
  return (
    <SideSelector style={{ width: '92px' }}>
      <div
        className={side.toLowerCase() === 'left' ? 'active' : ''}
        onClick={() => updateAxis(axis, { side: 'left' })}
      >
        <FontAwesomeIcon icon='angle-left' />
      </div>
      <div
        className={side.toLowerCase() === 'hide' ? 'active' : ''}
        onClick={() => updateAxis(axis, { side: 'hide' })}
      >
        <FontAwesomeIcon
          style={{ fontSize: '12px' }}
          icon='times'
          aria-hidden='true'
        />
      </div>
      <div
        className={side.toLowerCase() === 'right' ? 'active' : ''}
        onClick={() => updateAxis(axis, { side: 'right' })}
      >
        <FontAwesomeIcon icon='angle-right' />
      </div>
    </SideSelector>
  )
}

const AxisShowHide = ({ toggleAxisStreamViz, axis, axisVisibility }) => {
  const toggleAxis = () => toggleAxisStreamViz(axis)
  return (
    <>
      <label>
        Axis:
        <input
          style={{ marginLeft: '3px' }}
          type='checkbox'
          checked={axisVisibility}
          onChange={toggleAxis}
        />
      </label>
    </>
  )
}

const SubTable = styled.div`
  padding: 0 0 20px 5%;
  width: 100%;
  background-color: #eee;
  & > * {
    background-color: #fff;
  }
`

class AxesTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  updateAxis = (name, values) => {
    const { updateAxis } = this.props
    updateAxis({ name, values })
  }

  removeAxis = name => {
    const { axes, removeAxis } = this.props

    const a = axes[name]
    if (a && a.streams && a.streams.length !== 0) {
      console.error('Tried deleting an axis that still had streams', a)
      // Axes control table not currently used in redesigned, so we won't see this.
      notification.warn({
        message: "Can't remove an axis with streams",
        description: 'Make sure to reassign streams to another axis first.',
        duration: 5
      })
      return
    }

    removeAxis({ name })
  }

  changeAxisName = (prevName, name) => {
    const { updateAxisName } = this.props
    updateAxisName({ name, prevName })
  }

  toggleAxisStreamViz = axisName => {
    const { axes, toggleStreamVisibility } = this.props
    const axis = axes[axisName]
    axis.streams.forEach(uuid => toggleStreamVisibility({ uuid }))
  }

  render () {
    const { axes, createNewAxis, selectedRows } = this.props

    const axisNames = Object.keys(axes)
    const axisArray = axisNames
      .sort((a, b) => {
        const la = a.toLowerCase()
        const lb = b.toLowerCase()
        if (la < lb) return -1
        if (la > lb) return 1
        return 0
      })
      .map(key => axes[key])

    return (
      <div>
        <TableControlBarBorderless>
          <div className='tableTitle'>Axes</div>
          <AddAxis createNewAxis={createNewAxis} />
        </TableControlBarBorderless>
        <CustomReactTableStyles data-test-name='axes-table'>
          <ReactTable
            collapseOnDataChange={false}
            data={axisArray}
            defaultFilterMethod={filterAllMethod}
            defaultSorted={[
              {
                id: 'unit',
                desc: false
              }
            ]}
            SubComponent={row => {
              if (row.original.streams.length === 0) {
                return (
                  <SubTable>
                    <div
                      style={{
                        padding: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      Once you assign a stream to this axis, it will appear
                      here.
                    </div>
                  </SubTable>
                )
              }

              const { unit } = row.original
              const filteredStreamsObj = Object.keys(selectedRows)
                .filter(uuid => selectedRows[uuid].plot.unit === unit)
                .reduce((pv, uuid) => {
                  return { ...pv, [uuid]: selectedRows[uuid] }
                }, {})

              return (
                <SubTable>
                  <StreamListTableContainer selectedRows={filteredStreamsObj} />
                </SubTable>
              )
            }}
            columns={[
              {
                Header: 'UNITS',
                accessor: 'unit',
                maxWidth: 100,
                filterAll: true,
                Cell: row => {
                  const unit = row.value
                  return (
                    <EditableLabelContainer
                      value={unit}
                      commitEdit={this.changeAxisName}
                      entity={axes[unit]}
                    />
                  )
                }
              },
              {
                Header: 'SIDE OF GRAPH',
                accessor: 'side',
                maxWidth: 125,
                filterable: false,
                Cell: row => {
                  if (row.original.streams.length === 0) return null
                  const side = row.value
                  const axis = row.original.unit
                  return (
                    <AxisSideSelector
                      side={side}
                      axis={axis}
                      updateAxis={this.updateAxis}
                    />
                  )
                }
              },
              {
                Header: 'Y SCALE',
                width: 175,
                filterable: false,
                Cell: YScaleControlContainer
              },
              {
                Header: 'SHOW/HIDE',
                id: 'axis-showhide',
                accessor: d => {
                  const hasVisibleStreams = !!d.streams.find(
                    uuid => selectedRows[uuid].plot.visibility === true
                  )
                  return hasVisibleStreams
                },
                maxWidth: 100,
                filterable: false,
                Cell: row => {
                  if (row.original.streams.length === 0) return null
                  const axisVisibility = row.value
                  const axis = row.original.unit
                  return (
                    <AxisShowHide
                      axis={axis}
                      axisVisibility={axisVisibility}
                      toggleAxisStreamViz={this.toggleAxisStreamViz}
                    />
                  )
                }
              },
              {
                Header: 'COLLECTIONS',
                id: 'axis-streams',
                accessor: d => {
                  const collections = d.streams.reduce((colls, uuid) => {
                    const { metadata } = selectedRows[uuid]
                    const collxnName =
                      (metadata && metadata.collection) || 'Unknown Collection'
                    if (colls[collxnName]) colls[collxnName] += 1
                    else colls[collxnName] = 1
                    return colls
                  }, {})
                  const array = Object.keys(collections).map(
                    k => `${k}(${collections[k]})`
                  )

                  return array
                },
                Cell: row => {
                  if (row.original.streams.length === 0) {
                    return (
                      <span style={{ fontStyle: 'italic' }}>
                        Assign at least one stream to this axis to start using
                        it
                      </span>
                    )
                  }

                  return <div>{row.value.join(', ')}</div>
                },
                filterMethod: (filter, rows) => {
                  return matchSorter(rows, filter.value, {
                    keys: ['axis-streams']
                  })
                },
                filterAll: true
              },
              {
                Header: 'ACTION',
                headerClassName: 'rt-custom-header',
                accessor: 'none',
                id: 'action-hint',
                minWidth: 80,
                maxWidth: 80,
                resizable: false,
                Cell: row => {
                  const hasStreams = row.original.streams.length > 0
                  const axisName = row.original.unit
                  if (hasStreams) {
                    return (
                      <ToolTip
                        placement='right'
                        title='You may only delete an axis if it has no streams. Reassign streams to another axis first.'
                      >
                        <HighlightHint>
                          <FontAwesomeIcon icon='times-circle' />
                        </HighlightHint>
                      </ToolTip>
                    )
                  }
                  return (
                    <HighlightHint
                      className='noStreams'
                      onClick={e => this.removeAxis(axisName)}
                    >
                      <FontAwesomeIcon icon='times-circle' />
                    </HighlightHint>
                  )
                }
              }
            ]}
            pageSize={axisArray.length <= 5 ? axisArray.length : undefined}
            showPagination={axisArray.length > 5}
            filterable
          />
        </CustomReactTableStyles>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const axes = plotter.selectors.axes(state)
  const selectedRows = plotter.selectors.selectedRows(state)

  return {
    axes,
    selectedRows
  }
}

const mapDispatchToProps = {
  createNewAxis: plotter.actions.createNewAxis,
  removeAxis: plotter.actions.removeAxis,
  toggleStreamVisibility: plotter.actions.toggleStreamVisibility,
  updateAxis: plotter.actions.updateAxis,
  updateAxisName: plotter.actions.updateAxisName
}

export default connect(mapStateToProps, mapDispatchToProps)(AxesTable)
