export function ctxTextExtractor () {
  let state = { translate: { x: 0, y: 0 }, font: '13px sans-serif' }
  const texts = []
  const stack = []
  const ctx = {
    save () {
      stack.push({ ...state })
    },
    restore () {
      state = stack.pop()
    },
    translate (dx, dy) {
      const { x, y } = state.translate
      state.translate = { x: x + dx, y: y + dy }
    },
    fillText (text, x, y) {
      texts.push({ text, x, y, ...state })
    }
  }
  const dummyFunc = () => ({})
  const proxyCtx = new Proxy(ctx, {
    get (target, name) {
      if (name in target) return target[name]
      return dummyFunc
    },
    set (target, name, value) {
      state[name] = value
      return true
    }
  })
  return { ctx: proxyCtx, texts }
}

export function canvasTextCss ({
  x,
  y,
  textAlign,
  textBaseline,
  translate,
  fillStyle,
  globalAlpha,
  font,
  textCss
}) {
  const yCorrection = -1 // empirically measured
  x += translate.x
  y += translate.y + yCorrection

  return {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    transform: `translate(${x}px,${y}px)`,
    display: 'flex',
    justifyContent:
      {
        left: 'flex-start',
        right: 'flex-end',
        center: 'center'
      }[textAlign] || 'flex-start',
    alignItems:
      {
        top: 'flex-start',
        bottom: 'flex-end',
        middle: 'center'
      }[textBaseline] || 'flex-end',
    whiteSpace: 'nowrap',
    color: fillStyle,
    opacity: globalAlpha,
    font,
    ...textCss
  }
}

export function ctxWithoutText (ctx) {
  const dummyFunc = () => ({})
  const proxyCtx = new Proxy(ctx, {
    get (target, name) {
      if (name === 'fillText') return dummyFunc
      if (typeof target[name] === 'function') {
        return target[name].bind(target)
      }
      return target[name]
    },
    set (target, name, value) {
      target[name] = value
      return true
    }
  })
  return proxyCtx
}

// Fast estimation of text width assuming 13px sans-serif
const textWidthCache = {}
function textWidthKey (text) {
  const widestUpper = 'W'
  const widestLower = 'm'
  const widestNum = '8'
  return String(text)
    .replace(/[A-Z]/g, widestUpper)
    .replace(/[a-z]/g, widestLower)
    .replace(/\d/g, widestNum)
}
let measureCtx
export function fastTextWidth (text, isExact = false) {
  measureCtx = measureCtx || document.createElement('canvas').getContext('2d')
  const cache = textWidthCache
  const key = isExact ? text : textWidthKey(text)
  if (cache[key]) return cache[key]
  measureCtx.font = '13px sans-serif'
  const w = measureCtx.measureText(key).width
  cache[key] = w
  return w
}
