import React from 'react'

import 'antd/lib/spin/style/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import lodashGet from 'lodash.get'
import PropTypes from 'prop-types'
import ReactTable from 'react-table-6'
import Spin from 'antd/lib/spin'
import styled from 'styled-components'

import { constrainPageSizeOptions } from '../../utils/utils'
import {
  CustomReactTableStyles,
  HighlightHint,
  NoStreamsSelected
} from '../styled/styled'
import { STREAM_TABLE_DEFAULTS } from '../../constants'

const getRowInfoFromEvent = e => {
  try {
    const rowinfoNode = e.currentTarget.attributes.rowinfo
    if (!rowinfoNode) return
    let rowinfo = rowinfoNode && rowinfoNode.value
    rowinfo = JSON.parse(rowinfo)
    if (!rowinfo) return
    return rowinfo
  } catch (error) {
    console.log('error in TRMouseOverHandler')
  }
}

const getTrProps = ({ hoveredRow, rowInfo, state }) => {
  const result = {}
  if (rowInfo && rowInfo.original) {
    const isHovered = lodashGet(hoveredRow, 'uuid') === rowInfo.original.uuid
    const isSelected = lodashGet(state, `selectedRows.${rowInfo.original.uuid}`)

    if (isHovered && isSelected) {
      result.className = 'rowSelected isHovered'
    } else if (!isHovered && isSelected) {
      result.className = 'rowSelected'
    } else if (isHovered && !isSelected) {
      result.className = 'isHovered'
    }
    result.rowinfo = JSON.stringify(rowInfo.original)
  }
  return result
}

export const ClearFilters = styled.a`
  font-size: 12px;

  &:hover {
    color: hsl(209, 100%, 35%);
  }
`

const CustomTRComponent = ({ children, className, ...rest }) => {
  return (
    <div className={'rt-tr ' + className} role='row' {...rest}>
      {children}
    </div>
  )
}

export class FilterInput extends React.Component {
  static propTypes = {
    updateFilterValue: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }
  }

  updateValue = e => {
    const value = e.target.value
    this.props.updateFilterValue({ id: this.props.id, value })
  }

  render () {
    return (
      <input
        data-testid={`${this.props.id}-filter-input`}
        key={this.props.id}
        type='text'
        value={this.props.value}
        onChange={this.updateValue}
      />
    )
  }
}

const LoadingComponent = ({ isLoading, isLoggedIn }) => {
  if (isLoading && isLoggedIn) {
    return (
      <NoStreamsSelected>
        <span style={{ fontSize: '16px' }}>
          <Spin size='large' style={{ margin: '0 20px' }} />
          Currently loading streams and metadata...
        </span>
      </NoStreamsSelected>
    )
  } else {
    return null
  }
}

const NoDataComponent = ({ data, isLoading, isLoggedIn }) => {
  if (data && data.length === 0 && !isLoading && isLoggedIn) {
    return (
      <NoStreamsSelected>
        <span style={{ fontSize: '16px' }}>
          No data was loaded. Does the current user have permissions to view
          streams?
        </span>
      </NoStreamsSelected>
    )
  } else if (!isLoggedIn && !isLoading) {
    return (
      <NoStreamsSelected>
        <span
          style={{ fontSize: '16px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          Please login to see streams ...
        </span>
      </NoStreamsSelected>
    )
  } else {
    return null
  }
}

export const PlusCircle = ({ onClick }) => {
  return (
    <HighlightHint className='plus-circle' onClick={onClick}>
      <FontAwesomeIcon icon={faPlusCircle} />
    </HighlightHint>
  )
}

class StreamTable extends React.Component {
  static propTypes = {
    clearFilters: PropTypes.func,
    data: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    isLoading: PropTypes.bool
  }

  /** Click handler for selecting a stream */
  TRClickHandler = e => {
    const { setStreamTableHoveredRow, streamTableHoveredRow } = this.props

    const metadata = getRowInfoFromEvent(e)
    if (!metadata) return

    if (streamTableHoveredRow.uuid !== metadata.uuid) {
      setStreamTableHoveredRow({ hoveredRow: metadata })
    }
  }

  renderTRComponent = ({ children, className, ...rest }) => {
    return (
      <CustomTRComponent
        children={children}
        className={className}
        onClick={this.TRClickHandler}
        {...rest}
      />
    )
  }

  render () {
    const {
      columns,
      currentPage,
      data,
      isLoading,
      isLoggedIn,
      numPages,
      pageChangeHandler,
      pageSize,
      pageSizeChangeHandler,
      selectedRows,
      sortedChangeHandler,
      streamTableHoveredRow
    } = this.props

    return (
      <CustomReactTableStyles data-test-name='streams-table'>
        <ReactTable
          className='-striped overflow'
          columns={columns}
          data={data}
          defaultFilterMethod={() => data}
          defaultPageSize={STREAM_TABLE_DEFAULTS.pageSize}
          filterable
          getTrProps={(state, rowInfo) =>
            getTrProps({ hoveredRow: streamTableHoveredRow, rowInfo, state })
          }
          hoveredRow={streamTableHoveredRow}
          LoadingComponent={() => (
            <LoadingComponent isLoading={isLoading} isLoggedIn={isLoggedIn} />
          )}
          manual
          NoDataComponent={() => (
            <NoDataComponent
              data={data}
              isLoading={isLoading}
              isLoggedIn={isLoggedIn}
            />
          )}
          onPageChange={pageChangeHandler}
          onPageSizeChange={pageSizeChangeHandler}
          onSortedChange={sortedChangeHandler}
          page={currentPage}
          pages={numPages}
          pageSizeOptions={constrainPageSizeOptions(numPages * pageSize)}
          selectedRows={selectedRows}
          TrComponent={this.renderTRComponent}
        />
      </CustomReactTableStyles>
    )
  }
}

export default StreamTable
