import React from 'react'

import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import merge from 'deepmerge'
import PropTypes from 'prop-types'

import { client } from '../Apollo/ApolloClient'
import { getStateFromLocalStorage } from '../../services/localStorage'
import { persistState } from './utils'
import reducers from '../../ducks/reducers'
import rootSaga from '../../ducks/sagas'

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

// SAGAS
const sagaMiddleware = createSagaMiddleware()

// REDUX
const enhancer = compose(composeWithDevTools(applyMiddleware(sagaMiddleware)))

const store = createStore(
  reducers,
  merge(reducers(undefined, {}), getStateFromLocalStorage(), {
    arrayMerge: overwriteMerge
  }),
  enhancer
)

store.subscribe(persistState({ store }))

// MORE SAGAS
sagaMiddleware.run(() => rootSaga({ client }))

const ReduxProvider = ({ children }) => (
  <Provider store={store}>{children}</Provider>
)

ReduxProvider.propTypes = {
  children: PropTypes.object
}

export default ReduxProvider
