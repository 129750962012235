import plotter from '../plotter'
import { streamFinder } from './updateNode'

export const error = state => state.grouper.error
export const loading = state => state.grouper.loading
export const success = state => state.grouper.success
export const data = state => (state.grouper.data ? state.grouper.data : [])
export const dataWithHighlightedRows = state => {
  const grouperData = state.grouper.data
  const id = state.grouper.id
  if (!id) {
    return grouperData
  } else {
    const stream = streamFinder({ id, grouperData })
    const uuid = stream.uuid
    const isHighlighted = Object.keys(
      plotter.selectors.selectedRows(state)
    ).includes(uuid)

    stream.isHighlighted = isHighlighted
    return grouperData
  }
}
