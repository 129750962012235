import React, { useState, useEffect } from 'react'

import { QuickLink } from 'frontend-components'

const Card = ({
  color,
  counter,
  iconSize,
  iconViewbox,
  image,
  subtitle,
  title,
  total,
  url
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const start = counter * 150
  const stop = 800 * (total / 4) + counter * 150

  useEffect(() => {
    if (typeof counter === 'number') {
      setTimeout(() => setIsHovered(true), start)
      setTimeout(() => setIsHovered(false), stop)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <QuickLink
      color={color}
      forceHover={isHovered}
      iconSize={iconSize}
      iconViewbox={iconViewbox}
      image={image}
      subtitle={subtitle}
      title={title}
      url={url}
    />
  )
}

export default Card
