import React from 'react'
import PropTypes from 'prop-types'
import * as d3scale from 'd3-scale'
import * as d3array from 'd3-array'
import * as d3color from 'd3-color'
import {
  getScaleResolution,
  getExactScaleResolution,
  maxRes,
  // bottomRes,
  timeRangeToIndex
} from '../../lib/btrdbMath'
import { streamCaches, intersectCache } from '../../lib/cache'
import { getDefaultChangedRange } from '../ChartStream/ChartStream'

const pointsType = PropTypes.arrayOf(
  PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    mean: PropTypes.number,
    time: PropTypes.number,
    count: PropTypes.number
  })
)
const bottomRes = 9 // Because of time stamp precision in browsers we don't actually go below this
export class DebugCache extends React.Component {
  constructor () {
    super()
    this.state = {}
  }

  static propTypes = {
    plotScale: PropTypes.func,
    plot: PropTypes.shape({
      uuid: PropTypes.string,
      unit: PropTypes.string,
      color: PropTypes.string,
      tiles: PropTypes.arrayOf(
        PropTypes.shape({
          timeRange: PropTypes.arrayOf(PropTypes.number),
          pointRes: PropTypes.number,
          points: pointsType,
          allPoints: pointsType
        })
      )
    }),
    width: PropTypes.number,
    height: PropTypes.number,
    plotW: PropTypes.number,
    plotX: PropTypes.number,
    cacheH: PropTypes.number
  }

  static defaultProps = {
    cacheH: 10
  }

  static getDerivedStateFromProps (props, state) {
    const { plot, plotScale, width, height, plotW, plotX } = props
    const exactRes = getExactScaleResolution(plotScale)
    const snapRes = getScaleResolution(plotScale)
    const plotY = height * 0.5
    const regionScale = d3scale
      .scaleLinear()
      .domain(plotScale.domain())
      .range([plotX, plotX + plotW])
    const scale = d3scale
      .scaleLinear()
      .domain([0, width].map(regionScale.invert))
      .range([0, width])
    const canvasRatio = window.devicePixelRatio
    return { scale, snapRes, exactRes, plot, canvasRatio, plotX, plotY }
  }

  /**
   * Draw a vertical line. Everything to the right of this line
   * represents the range we invalidate to allow for new live data
   * to overwrite what was previously in the cache.
   */
  drawLiveRefreshLine = ctx => {
    const changedRange = getDefaultChangedRange()
    const start = changedRange[0]
    this.drawVerticalLineAtTime(ctx, start, { setLineDash: [5, 3] })
  }

  drawNowLine = ctx => {
    const now = new Date().getTime() + '000000'
    this.drawVerticalLineAtTime(ctx, now, {})
  }

  drawVerticalLineAtTime = (
    ctx,
    time,
    { strokeStyle = '#AAA', setLineDash = [1, 1] }
  ) => {
    const { height } = this.props
    const { scale } = this.state

    const x = scale(time)
    ctx.beginPath()
    ctx.moveTo(x, 0)
    ctx.lineTo(x, height)
    ctx.strokeStyle = strokeStyle
    ctx.setLineDash(setLineDash)
    ctx.stroke()
  }

  drawCacheRow = (ctx, { y, h, cache, opacity }) => {
    const { width } = this.props
    const { scale } = this.state
    const { res } = cache
    const entries = intersectCache(
      cache,
      timeRangeToIndex(scale.domain(), res)
    ).filter(e => !e.isGap)
    for (const entry of entries) {
      let [x, x1] = entry.range.map(i => scale(i * 2 ** res))

      // clip
      const pad = 10
      x = Math.max(-pad, x)
      x1 = Math.min(width + pad, x1)

      const color = d3color.color(
        entry.isRemoved ? '#f00' : entry.points ? '#0f0' : '#ff0'
      )
      color.opacity = opacity

      const w = x1 - x
      ctx.beginPath()
      ctx.rect(x, y, w, h)
      ctx.fillStyle = color
      ctx.fill()
      ctx.strokeStyle = '#AAA'
      ctx.stroke()
    }
  }

  drawCacheRows = ctx => {
    const { width, height, cacheH } = this.props
    const { exactRes, plotY } = this.state

    const resY = d3scale
      .scaleLinear()
      .domain([exactRes, exactRes + 1])
      .range([plotY - cacheH, plotY])

    const visRes = [
      Math.max(bottomRes, Math.floor(resY.invert(0))),
      Math.min(maxRes, Math.floor(resY.invert(height)))
    ]

    ctx.save()
    ctx.textAlign = 'left'
    ctx.textBaseline = 'middle'
    ctx.lineWidth = 1
    const h = cacheH
    const caches = streamCaches[this.state.plot.uuid]
    for (const res of d3array.range(visRes[0], visRes[1] + 1)) {
      const y = resY(res)
      const cache = caches[res]

      // cache row border
      ctx.beginPath()
      ctx.moveTo(0, y)
      ctx.lineTo(width, y)
      ctx.strokeStyle = 'rgba(0,0,0,0.1)'
      ctx.stroke()

      // draw cache entries
      if (cache) {
        const { stale } = cache
        this.drawCacheRow(ctx, { y, h, cache, opacity: 0.3 })
        this.drawCacheRow(ctx, { y, h, cache: stale, opacity: 0.15 })
      }
    }

    // bottom border after resolution 56
    const y = resY(maxRes + 1)
    ctx.beginPath()
    ctx.moveTo(0, y)
    ctx.lineTo(width, y)
    ctx.stroke()
    ctx.restore()
  }

  drawCacheViz = ctx => {
    this.drawCacheRows(ctx)
    this.drawLiveRefreshLine(ctx)
    this.drawNowLine(ctx)
    const { plotX, plotY } = this.state
    const { width, height, plotW } = this.props

    // draw region
    const timeY = plotY
    {
      ctx.save()
      ctx.fillStyle = 'rgba(0,0,0,0.05)'
      ctx.fillRect(0, 0, plotX, height)
      const x = plotX + plotW
      ctx.fillRect(x, 0, width - x, height)
      // ctx.fillRect(plotX, plotY - regionH / 2, plotW, regionH);
      ctx.restore()
    }

    ctx.fillStyle = '#333'
    ctx.textBaseline = 'top'
    ctx.fillText(
      'BTrDB Cache | Current Res: ' + Math.ceil(this.state.exactRes),
      plotX + 10,
      10
    )

    ctx.beginPath()
    ctx.rect(plotX, 0.5, plotW, height - 1)
    ctx.stroke()

    // draw time axis
    ctx.save()
    ctx.translate(0, timeY)
    ctx.textBaseline = 'top'
    ctx.textAlign = 'center'
    ctx.strokeStyle = '#f00'
    ctx.beginPath()
    ctx.moveTo(0, 0)
    ctx.lineTo(width, 0)
    ctx.stroke()
    ctx.restore()
  }

  draw (canvas) {
    const ctx = canvas.getContext('2d')
    const { width, height } = this.props
    const { canvasRatio } = this.state
    ctx.save()
    ctx.scale(canvasRatio, canvasRatio)
    ctx.clearRect(0, 0, width, height)
    this.drawCacheViz(ctx)
    ctx.restore()
  }

  render () {
    const { canvasRatio } = this.state
    const { width, height } = this.props
    return (
      <div
        onClick={e => {
          const { height, cacheH } = this.props
          const { exactRes, plotY } = this.state
          const resY = d3scale
            .scaleLinear()
            .domain([exactRes, exactRes + 1])
            .range([plotY - cacheH, plotY])
          const visRes = [
            Math.max(bottomRes, Math.floor(resY.invert(0))),
            Math.min(maxRes, Math.floor(resY.invert(height)))
          ]
          const clickCoords = {
            x: e.clientX - e.target.getBoundingClientRect().x,
            y: e.clientY - e.target.getBoundingClientRect().y
          }
          const res = Math.floor(resY.invert(clickCoords.y))
          const clickedTime = this.state.scale.invert(clickCoords.x)
          if (res < visRes[0]) return
          if (res > visRes[1]) return

          const {
            [res]: resCache,
            stales: { [res]: resCacheStale }
          } = streamCaches[this.state.plot.uuid]
          const entry =
            resCache &&
            intersectCache(
              resCache,
              timeRangeToIndex([clickedTime, clickedTime + 1000], res)
            )
          const entryStale =
            resCacheStale &&
            intersectCache(
              resCacheStale,
              timeRangeToIndex([clickedTime, clickedTime + 1000], res)
            )

          console.group('Cache Info')
          console.log('Resolution:', res, 'Uuid:', this.state.plot.uuid)
          console.log(
            'Clicked Time',
            clickedTime,
            'Total Time range',
            this.state.scale.domain()
          )
          console.log('Cache:', streamCaches[this.state.plot.uuid])
          resCache &&
            console.log(
              'Cache for res ' + res + ':',
              streamCaches[this.state.plot.uuid][res]
            )
          entry && !entry[0].isGap && console.log('Clicked entry:', entry)
          entryStale &&
            !entryStale[0].isGap &&
            console.log('Clicked entry (stale):', entry)
          console.groupEnd()
        }}
      >
        <canvas
          ref={node => {
            if (!node) return
            this.draw(node)
          }}
          width={width * canvasRatio}
          height={height * canvasRatio}
          style={{
            width: `${width}px`,
            height: `${height}px`
          }}
        />
      </div>
    )
  }
}
