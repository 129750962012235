import React from 'react'

import 'antd/lib/notification/style/css'
import { redesignEnabled } from '../../utils/featureFlags'
import { report as reportSentry } from '../../utils/sentry'
import { useDispatch } from 'react-redux'
import { WebsocketTest } from 'frontend-components'
import notification from 'antd/lib/notification'

import { openAlert } from '../../ducks/modals/actions'

const Component = () => {
  const dispatch = useDispatch()

  const handleError = state => {
    const message = 'Network error.'
    const description =
      "We've detected your browser or local network may be blocking websocket connections. This application will not work normally. Please try again or contact support@pingthings.io"

    if (redesignEnabled()) {
      dispatch(
        openAlert({
          kind: 'error',
          message: `${message} ${description}`
        })
      )
    } else {
      notification.warn({
        message,
        description,
        duration: 5,
        key: 'websocketError'
      })
    }

    reportSentry(new Error('unable to connect to websocket'), {
      level: 'error',
      tags: {
        alertName: 'websocket connect failure'
      },
      extraScope: { state }
    })
  }

  return <WebsocketTest handleError={handleError} />
}

export default Component
