import React from 'react'

import 'antd/lib/notification/style/css'
import lodashGet from 'lodash.get'
import notification from 'antd/lib/notification'

import { redesignEnabled } from '../../../utils/featureFlags'
import { TOKEN_KEY, USERNAME_KEY } from '../../../constants'
import { isLoggedInSso } from '../../../lib/sso'
import { Button } from 'antd'

const ERROR_KEY = 'TokenExpiredError'

export function isError ({ networkError }) {
  const jwtErrorCodes = ['TokenExpiredError', 'JsonWebTokenError']

  const tokenError = lodashGet(networkError, 'result.errors', []).some(error =>
    jwtErrorCodes.includes(lodashGet(error, 'extensions.code'))
  )

  if (tokenError === true) return true

  // Detect expired token for a subscription call over websockets
  const message = (networkError && networkError.message) || ''
  const tokenExpired = message && message.includes('jwt expired')

  if (tokenExpired === true) return true
  else return false
}

function handleErrorDefault () {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(USERNAME_KEY)
  localStorage.setItem(ERROR_KEY, 'JsonWebTokenError')
  if (!isLoggedInSso()) {
    setTimeout(() => (window.location.href = '/login'), 1000)
  }
}

export class ErrorListener extends React.Component {
  componentDidMount () {
    const error = localStorage.getItem(ERROR_KEY)
    if (error) {
      if (!redesignEnabled()) {
        // There's no guarantee that the alert component will be on the dom when this error is hit.
        // because of that, we don't render an error for redesigns. We have a card to follow up
        // to fix this: https://app.clubhouse.io/pingthings-ws/story/8537/handle-network-errors-via-something-that-s-not-apollo-client
        notification.warn({
          message: 'Login Expired',
          description: 'Please login again. Your previous credentials expired.',
          btn: <Button onClick={() => window.location.reload()}>Reload</Button>
        })
      }

      localStorage.removeItem(ERROR_KEY)
    }
  }

  render () {
    return null
  }
}

export function getErrorPlugin (overrides = {}) {
  const { handleError = handleErrorDefault } = overrides
  return {
    name: ERROR_KEY,
    isError,
    handleError,
    ErrorListener
  }
}
