import React, { Component } from 'react'

import ApolloProviderWithClient from './Apollo/ApolloClient'
import KeyboardShortcuts from './KeyboardShortcuts'
import ReduxProvider from './Redux'
import Theme from './Theme'

class AppProviders extends Component {
  render () {
    return (
      <Theme>
        <ApolloProviderWithClient>
          <ReduxProvider>
            <KeyboardShortcuts toggleDisplayBinding='?'>
              {this.props.children}
            </KeyboardShortcuts>
          </ReduxProvider>
        </ApolloProviderWithClient>
      </Theme>
    )
  }
}

export default AppProviders
