import React from 'react'
import IdleTimer from 'react-idle-timer'

class IdleTimerWrapper extends React.Component {
  constructor () {
    super()
    const oneHour = 1000 * 60 * 60
    this.state = {
      isTimedOut: false,
      timeOut: oneHour
    }

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }

  _onAction () {
    this.setState({ isTimedOut: false })
  }

  _onActive () {
    this.setState({ isTimedOut: false })
  }

  _onIdle () {
    const elapsedTime = this.idleTimer.getElapsedTime()

    if (elapsedTime > this.state.timeOut) {
      this.setState({ isTimedOut: true }, () => {
        window.location.reload()
      })
    }
  }

  render () {
    return (
      <IdleTimer
        ref={ref => {
          this.idleTimer = ref
        }}
        element={document}
        onActive={this.onActive}
        onIdle={this.onIdle}
        onAction={this.onAction}
        debounce={250}
        timeout={this.state.timeOut}
      />
    )
  }
}

export default IdleTimerWrapper
