export const warnMissingEnvVars = (expectedVars, envObject = process.env) => {
  const missingVars = []
  for (const v of expectedVars) {
    if (envObject[v] === undefined) missingVars.push(v)
  }
  if (missingVars.length > 0) {
    throw new Error(
      'The following required environment variables were not defined:\n' +
        missingVars.join(', ')
    )
  }
}
