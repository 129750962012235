import * as actionTypes from './actionTypes'
import * as api from './api'
import reducer from './reducer'
import * as sagas from './sagas'

export default {
  actionTypes,
  api,
  reducer,
  sagas
}
