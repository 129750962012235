import React from 'react'

import 'antd/lib/notification/style/css'
import 'react-table-6/react-table.css'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { withApollo } from 'react-apollo'
import notification from 'antd/lib/notification'

import { MODALS, STREAM_TABLE_DEFAULTS } from '../../constants'
import { redesignEnabled } from '../../utils/featureFlags'
import auth from '../../ducks/auth'
import modals from '../../ducks/modals'
import plotter from '../../ducks/plotter'
import streams from '../../ducks/streams'
import StreamTable, {
  ClearFilters,
  FilterInput,
  PlusCircle
} from './StreamTable'
import StreamTableRedesign, { TableFilter } from './StreamTable.redesign'
import ui from '../../ducks/ui'
import { isLoggedInSso } from '../../lib/sso'

const {
  selectors: { isLoggedIn: isLoggedInSelector }
} = auth

const {
  actions: { close: closeModal, open: openModal, openAlert }
} = modals

const {
  actions: { toggleStream },
  selectors: { selectedRows: selectedRowsSelector }
} = plotter

const {
  actions: {
    clearStreamTableFilters,
    debounceGetStreams,
    getStreams,
    setStreamTableFilters,
    setStreamTableHoveredRow
  },
  selectors: {
    error: errorSelector,
    loading: loadingSelector,
    streamsDataWithHightlightedRows: streamsDataWithHightlightedRowsSelector,
    streamTableSelectedColumns,
    streamTableFilters: streamTableFiltersSelector,
    streamTableHoveredRow: streamTableHoveredRowSelector,
    total: totalSelector
  }
} = streams

const {
  actions: { lockMiniplotter, setMiniplotterPosition, unlockMiniplotter },
  selectors: { miniplotterLocked: miniplotterLockedSelector }
} = ui

const FilterInputContainer = ({
  c,
  filterAllMethod,
  streamTableFilters,
  updateFilterValue
}) => {
  return (
    <FilterInput
      filterAllMethod={filterAllMethod}
      id={c._pathToAttr}
      updateFilterValue={updateFilterValue}
      value={streamTableFilters[c._pathToAttr] || ''}
    />
  )
}

class StreamTableContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      columns: this.getVisibleColumns(),
      page: STREAM_TABLE_DEFAULTS.page,
      pageSize: STREAM_TABLE_DEFAULTS.pageSize,
      selectedColumns: props.selectedColumns,
      sorted: [[], []]
    }
  }

  componentDidMount () {
    this.serverSideSearch({})
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { error, isLoggedIn, loading, streamTableFilters } = this.props

    const columnsChanged = !isEqual(
      prevProps.selectedColumns,
      this.props.selectedColumns
    )
    const didAuthorize = !prevProps.isLoggedIn && isLoggedIn && !loading
    const filterChanged = !isEqual(
      prevProps.streamTableFilters,
      streamTableFilters
    )
    const pageChanged =
      prevState.page !== undefined && prevState.page !== this.state.page
    const pageSizeChanged = prevState.pageSize !== this.state.pageSize
    const sortChanged =
      // @TODO: Only supporting the first sort column for now
      prevState.sorted[0][0] !== this.state.sorted[0][0] ||
      prevState.sorted[1][0] !== this.state.sorted[1][0]

    if (error && !prevProps.error) {
      this.handleError()
    } else if (didAuthorize) {
      this.serverSideSearch({})
    } else if (
      filterChanged ||
      columnsChanged ||
      pageChanged ||
      pageSizeChanged ||
      sortChanged
    ) {
      const shouldDebounce = filterChanged
      const shouldResetPage = filterChanged || sortChanged
      this.serverSideSearch({ shouldDebounce, shouldResetPage })
    }

    if (columnsChanged || filterChanged) {
      this.resetVisibleColumns()
    }
  }

  clearFilters = () => {
    const { clearStreamTableFilters } = this.props
    clearStreamTableFilters()
  }

  getColumns = () => {
    const { selectedColumns } = this.props
    const defaultColumns = ['collection']
    const visibleColumns = selectedColumns.map(
      chartColumn => chartColumn._pathToAttr
    )
    return [...defaultColumns, ...visibleColumns]
  }

  getVisibleColumns = () => {
    const createDynamicColumns = selectedColumns => {
      if (redesignEnabled()) {
        return selectedColumns.map(c => ({
          label: c.Header,
          id: c.id,
          _pathToAttr: c.id,
          columns: [
            {
              id: c.id,
              Filter: TableFilter,
              value: this.props.streamTableFilters[c._pathToAttr] || '',
              updateFilterValue: this.updateFilterValue,
              _pathToAttr: c.id,
              renderItem: cell => {
                const { row, value } = cell

                return <div key={row.id}>{value}</div>
              }
            }
          ]
        }))
      }

      return selectedColumns.map(c => ({
        ...c,
        filterAll: true,
        Filter: (
          <FilterInputContainer
            c={c}
            filterAllMethod={this.filterAllMethod}
            streamTableFilters={this.props.streamTableFilters}
            updateFilterValue={this.updateFilterValue}
          />
        )
      }))
    }

    const actionColumn = {
      Header: 'ACTION',
      headerClassName: 'rt-custom-header',
      accessor: 'none',
      id: 'action-hint',
      minWidth: 80,
      maxWidth: 80,
      resizable: false,
      filterable: true,
      className: 'rt-td-pt-button',
      Cell: cellProps => {
        /** "Plus" button for when you add a stream from the "All Streams" list */
        const TRClickHandler = e => {
          const metadata = cellProps.row?._original
          if (!metadata) return

          const { toggleStream } = this.props
          toggleStream({
            metadata,
            uuid: metadata.uuid
          })
        }
        return (
          <div onClick={TRClickHandler} className='rt-td-pt-button-inner'>
            <PlusCircle />
          </div>
        )
      },
      filterMethod: () => {},
      Filter: () => {
        return Object.keys(this.props.streamTableFilters).length ===
          0 ? null : (
          <ClearFilters
            href='#'
            onClick={e => {
              e.preventDefault()
              this.clearFilters()
            }}
          >
            Clear Filters
          </ClearFilters>
        )
      }
    }

    if (redesignEnabled()) {
      return [...createDynamicColumns(this.props.selectedColumns || [])]
    } else {
      return [
        ...createDynamicColumns(this.props.selectedColumns || []),
        actionColumn
      ]
    }
  }

  handleError = () => {
    const { openAlert } = this.props

    const message = 'Error fetching streams.'
    const description = 'Please try again or contact support@pingthings.io'

    if (redesignEnabled()) {
      openAlert({
        kind: 'error',
        message: `${message} ${description}`
      })
    } else {
      notification.warn({
        message,
        description,
        duration: 5,
        key: 'streamsError'
      })
    }
  }

  numPages = ({ pageSize = this.state.pageSize }) => {
    const { total } = this.props
    return Math.ceil(total / pageSize)
  }

  pageChangeHandler = pageIndex => {
    this.setState({ page: pageIndex })
  }

  pageSizeChangeHandler = pageSize => {
    const newNumPages = this.numPages({ pageSize })

    if (this.state.page >= newNumPages) {
      const pageIndex = newNumPages - 1
      this.setState({ page: pageIndex, pageSize })
    } else {
      this.setState({ pageSize })
    }
  }

  resetVisibleColumns = () => {
    this.setState({
      columns: this.getVisibleColumns()
    })
  }

  serverSideSearch = ({ shouldDebounce = false, shouldResetPage = false }) => {
    const { debounceGetStreams, getStreams, streamTableFilters } = this.props
    const { pageSize, sorted } = this.state

    const action = shouldDebounce ? debounceGetStreams : getStreams
    const page = shouldResetPage ? 0 : this.state.page

    action({
      columns: this.getColumns(),
      filters: streamTableFilters,
      page,
      pageSize,
      sort: sorted,
      useCache: true
    })

    this.setState({ page })
  }

  sortedChangeHandler = newSorted => {
    if (!Array.isArray(newSorted)) {
      newSorted = [newSorted]
    }

    const terms = []
    const order = []
    for (const col of newSorted) {
      const columnId = col.originalId || col.id
      terms.push(columnId)
      order.push(col.desc ? 'desc' : 'asc')
    }
    this.setState({ sorted: [terms, order] })
  }

  updateFilterValue = ({ id, value }) => {
    const { setStreamTableFilters } = this.props

    setStreamTableFilters({ id, value })
  }

  handleRowMouseEnter = ({ current, data, e }) => {
    const {
      miniplotterLocked,
      openModal,
      setMiniplotterPosition,
      setStreamTableHoveredRow
    } = this.props

    if (!miniplotterLocked) {
      const top = window.pageYOffset + current.getBoundingClientRect().top

      setMiniplotterPosition({ top })
      setStreamTableHoveredRow({ hoveredRow: data })
      openModal({ viewMode: MODALS.PLOTTER.MINI_PLOTTER })
    }
  }

  handleTableMouseLeave = () => {
    const { closeModal, miniplotterLocked } = this.props
    if (!miniplotterLocked) {
      closeModal()
    }
  }

  render () {
    const {
      filters,
      isLoggedIn,
      loading,
      selectedColumns,
      streamsData,
      streamTableFilters,
      ...props
    } = this.props

    const { columns, page, pageSize, sorted } = this.state

    const Component = redesignEnabled() ? StreamTableRedesign : StreamTable
    const filterMethod = () => this.serverSideSearch({ shouldDebounce: true })

    return (
      <Component
        {...props}
        clearFilters={this.clearFilters}
        columns={columns}
        currentPage={page}
        data={streamsData}
        filterAllMethod={filterMethod}
        filters={streamTableFilters}
        handleRowMouseEnter={this.handleRowMouseEnter}
        handleTableMouseLeave={this.handleTableMouseLeave}
        isLoading={loading}
        isLoggedIn={isLoggedIn}
        numPages={this.numPages({})}
        pageChangeHandler={this.pageChangeHandler}
        pageSize={pageSize}
        pageSizeChangeHandler={this.pageSizeChangeHandler}
        sorted={sorted}
        sortedChangeHandler={this.sortedChangeHandler}
        updateFilterValue={this.updateFilterValue}
      />
    )
  }
}

const mapStateToProps = state => {
  const error = errorSelector(state)
  const isLoggedIn = isLoggedInSelector(state) || isLoggedInSso()
  const loading = loadingSelector(state)
  const miniplotterLocked = miniplotterLockedSelector(state)
  const selectedColumns = streamTableSelectedColumns(state)
  const selectedRows = selectedRowsSelector(state)
  const streamsData = streamsDataWithHightlightedRowsSelector(state)
  const streamTableFilters = streamTableFiltersSelector(state)
  const streamTableHoveredRow = streamTableHoveredRowSelector(state)
  const total = totalSelector(state)

  return {
    error,
    isLoggedIn,
    loading,
    miniplotterLocked,
    selectedColumns,
    selectedRows,
    streamsData,
    streamTableFilters,
    streamTableHoveredRow,
    total
  }
}

const mapDispatchToProps = {
  clearStreamTableFilters,
  closeModal,
  debounceGetStreams,
  getStreams,
  lockMiniplotter,
  openAlert,
  openModal,
  setStreamTableFilters,
  setStreamTableHoveredRow,
  setMiniplotterPosition,
  toggleStream,
  unlockMiniplotter
}

const enhance = compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(StreamTableContainer)
