import React from 'react'

import { Route, Redirect, Switch } from 'react-router-dom'
import lodashGet from 'lodash.get'
import styled from 'styled-components'

import { getVersionInfo } from '../../utils/versionInfo'
import { LoginPage } from '../Login/LoginForms'
import { mapEnabled } from '../../utils/featureFlags'
import Alerts from '../Alerts/Alerts'
import BootstrapStyles from '../BootstrapStyles'
import HeaderNav from '../HeaderNav/HeaderNav'
import IdleTimerWrapper from '../IdleTimer'
import MapContainer from '../Map'
import NotFoundPage from '../NotFoundPage/NotFoundPage'
import PermalinkHydrater from '../PermalinkHydrater/PermalinkHydrater'
import StreamSearchPageContainer from '../StreamSearch/index.js'
import useAuthorization from '../../hooks/useAuthorization'
import VisualizePage from '../VisualizePage/VisualizePage'
import WebsocketTest from '../WebsocketTest'

const Footer = styled.div`
  height: 15px;
  opacity: 0.7;
  background: white;
  position: relative;

  .version {
    display: inline-block;
    position: absolute;
    right: 0px;
    font-family: monospace;
  }
`

const Main = () => {
  useAuthorization()

  const versionInfo = getVersionInfo(window.__env__, process.env.NODE_ENV) || {}
  const shouldDisplayVersionInfo =
    lodashGet(versionInfo, 'version') && lodashGet(versionInfo, 'sha')

  return (
    <>
      <BootstrapStyles />
      <WebsocketTest />
      <Alerts />
      <IdleTimerWrapper />
      <HeaderNav />
      <Switch>
        <Route exact path='/' render={() => <Redirect to='/select' />} />
        <Route exact path='/select' component={StreamSearchPageContainer} />
        <Route exact path='/visualize' component={VisualizePage} />
        {mapEnabled() && <Route exact path='/map' component={MapContainer} />}
        <Route
          exact
          path='/stream/:uuid'
          component={StreamSearchPageContainer}
        />
        <Route path='/permalink/:uuid' component={PermalinkHydrater} />
        <Route path='/login/:msg?' component={LoginPage} />
        <Route component={NotFoundPage} />
      </Switch>
      <Footer>
        {shouldDisplayVersionInfo ? (
          <div className='version'>
            {`${versionInfo.version} (${versionInfo.sha})`}
          </div>
        ) : (
          <div className='version'>Unknown version</div>
        )}
      </Footer>
    </>
  )
}

export default Main
