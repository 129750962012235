import React from 'react'

import styled from '@xstyled/styled-components'

import { nineDotsEnabled } from '../../utils/featureFlags'
import { portalTheme } from './theme'
import logo from '../../assets/images/logo.svg'
import { bindTheme, NineDots } from 'frontend-components'
import nineDotsCards from '../../services/nineDotsConfig'

const colors = bindTheme(portalTheme)

const Logo = styled.img`
  height: 32px;
`

const NavContainer = styled.div`
  align-items: center;
  background-color: ${colors('headerContainer.backgroundColor')};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0 64px;
  position: fixed;
  width: 100%;
  z-index: 999;

  @media (max-width: 758px) {
    justify-content: center;
  }
`

const Spacer = styled.div`
  height: 64px;
`

const Nav = () => (
  <>
    <NavContainer>
      <Logo src={logo} alt='PingThings' />
      {nineDotsEnabled() && <NineDots cards={nineDotsCards} name='Portal' />}
    </NavContainer>
    <Spacer />
  </>
)

export default Nav
