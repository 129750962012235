import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import stringify from 'json-stable-stringify'

const MetadataPreviewStyles = styled.div`
  pre {
    background-color: #eee;
    border-radius: 5px;
    padding: 20px;
    white-space: pre-wrap;
    max-height: calc(100vh - 30vw);
    @media screen and (min-width: 2560px) {
      height: 620px;
    }
  }
`
const MetadataPreview = ({ metadata }) => {
  return (
    <MetadataPreviewStyles>
      {metadata ? (
        <pre>{stringify(metadata, { space: 4 })}</pre>
      ) : (
        <pre>Click a row to show its metadata</pre>
      )}
    </MetadataPreviewStyles>
  )
}
MetadataPreview.propTypes = {
  metadata: PropTypes.object
}

export default MetadataPreview
