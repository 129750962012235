import isUUID from 'validator/lib/isUUID'
import matchSorter from 'match-sorter'

function hexToBase64 (hexstring) {
  return btoa(
    hexstring
      .match(/\w{2}/g)
      .map(function (a) {
        return String.fromCharCode(parseInt(a, 16))
      })
      .join('')
  )
}

export const parseHexUuidToBase64Bin = hexUuid => {
  // strip hyphens
  const hexStr = hexUuid.split('-').join('')
  // to base 64
  const result = hexToBase64(hexStr)
  return result
}

export const base64HexToUUID = base64uuid => {
  const binstring = atob(base64uuid) // base64 to binary string
  const hexString = [...binstring]
    .map(v => {
      const hex = v.charCodeAt(0).toString(16)
      return hex.length === 1 ? '0' + hex : hex
    })
    .join('') // binary string to hex string
  function adduuiddash (hexString) {
    return [...hexString].reduce((s, char, i) => {
      if ([8, 12, 16, 20].includes(i)) {
        return `${s}-${char}`
      }
      return `${s}${char}`
    }, '')
  }
  const rawUuid = adduuiddash(hexString)
  return rawUuid
}

export const ensureHexUuid = unknownUuid => {
  if (unknownUuid !== undefined) {
    // if it's a standard uuid already, then return
    if (isUUID(unknownUuid)) return unknownUuid
    // otherwise encode to a standard hex uuid
    return base64HexToUUID(unknownUuid)
  }
}

export const ensureBase64Uuid = unknownUuid => {
  // if it's a standard uuid, then encode to base64 binary string
  if (isUUID(unknownUuid)) return parseHexUuidToBase64Bin(unknownUuid)
  return unknownUuid
}

/**
 *
 * This creates a deferred object that returns a promise object with .resolve and.reject methods
 */
export const createDeferred = () => {
  let resolve, reject
  // eslint-disable-next-line
  const promise = new Promise((res, rej) => {
    resolve = res
    reject = rej
  })
  promise.resolve = resolve
  promise.reject = reject
  return promise
}

/**
 * React Table utilities
 */

export const constrainPageSizeOptions = (
  numRows,
  options = [5, 10, 20, 25, 50, 100]
) => {
  const index = options.findIndex(o => o > numRows)
  const result = index === -1 ? options : options.slice(0, 1 + index)
  return result
}

// eslint-disable-next-line
const getFilterTerms = filterVal => {
  const terms = filterVal.split(' ').filter(t => {
    return t.length !== 0 && t !== '-' && t !== '-"' && t !== '-""'
  })
  const negTerms = []
  const posTerms = []
  for (const t of terms) {
    const match = /-"(.*?)"/.exec(t)
    if (match) {
      match.length > 0 && negTerms.push(match[1])
    } else {
      posTerms.push(t)
    }
  }
  return { positive: posTerms, negative: negTerms }
}

export const filterAllMethod = (filter, rows, column) => {
  const key = filter.pivotId || filter.id
  return matchSorter(rows, filter.value, {
    keys: [row => row[key]]
  })
}
