import * as actionTypes from './actionTypes'

const DEFAULT_STATE = {
  didReset: false,
  globalZoom: []
}

export default function chart (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionTypes.SET_GLOBAL_ZOOM: {
      const { domain } = action
      return {
        ...state,
        didReset: false,
        globalZoom: domain
      }
    }
    case actionTypes.RESET_GLOBAL_ZOOM: {
      return {
        ...state,
        didReset: true,
        globalZoom: DEFAULT_STATE.globalZoom
      }
    }
    default: {
      return state
    }
  }
}
