import React, { useContext, useEffect, useState } from 'react'
import Mousetrap from 'mousetrap'
import styled from 'styled-components'

// heavily inspired by greena13/react-hotshortcuts which is unmaintained

const KeyboardShortcutsContext = React.createContext(null)

export default function KeyboardShortcuts ({
  children,
  toggleDisplayBinding = null
}) {
  const [bindings, setBindings] = useState(new Map())

  function register (shortcut, onTrigger, description = null) {
    if (bindings.has(shortcut)) {
      console.error(`not binding ${shortcut} because it's already bound`)
      return
    }

    bindings.set(shortcut, description)
    setBindings(bindings)

    Mousetrap.bind(shortcut, onTrigger)

    return function unregister () {
      bindings.delete(shortcut)
      setBindings(bindings)
      Mousetrap.unbind(shortcut)
    }
  }

  return (
    <KeyboardShortcutsContext.Provider value={{ register, bindings }}>
      {toggleDisplayBinding ? (
        <KeyboardShortcutDisplay binding={toggleDisplayBinding} />
      ) : null}
      {children}
    </KeyboardShortcutsContext.Provider>
  )
}

export function useKeyboardShortcut (shortcut, onTrigger, description) {
  const { register } = useContext(KeyboardShortcutsContext)
  // register returns unregister, which is returned to useEffect for cleanup
  useEffect(() => register(shortcut, onTrigger, description), [
    shortcut,
    onTrigger,
    description,
    register
  ])
}

export function KeyboardShortcut ({ shortcut, onTrigger, description }) {
  useKeyboardShortcut(shortcut, onTrigger, description)
  return null
}

const KeyboardShortcutDisplayContainer = styled.div`
  width: 80vw;
  max-width: 400px;

  background-color: #ffffff;
  position: fixed;
  top: 25px;
  left: 25px;
  z-index: 9999999;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  padding: 16px;
`

const KeyDisplay = styled.span`
  display: inline-block;
  padding: 5px;
  border: 1px solid #aaa;
  border-radius: 3px;
  line-height: 1em;
  min-width: 1.8em;
  text-align: center;
  margin: 5px;
  margin-right: 10px;
`

export function KeyboardShortcutDisplay ({ binding }) {
  const [visible, setVisible] = useState(false)

  function toggleVisible () {
    setVisible(!visible)
  }

  // TODO why does this not update automatically?
  let { bindings } = useContext(KeyboardShortcutsContext)
  bindings = [...bindings.entries()]

  return (
    <>
      <KeyboardShortcut
        shortcut={binding}
        onTrigger={toggleVisible}
        description='Toggle this help menu'
      />
      {visible ? (
        <KeyboardShortcutDisplayContainer>
          <h3>Keyboard Shortcuts</h3>
          <ul>
            {bindings.map(([binding, description]) => (
              <li key={binding} style={{ listStyle: 'none' }}>
                <KeyDisplay>{binding}</KeyDisplay> {description}
              </li>
            ))}
          </ul>
        </KeyboardShortcutDisplayContainer>
      ) : null}
    </>
  )
}
