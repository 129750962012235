import { CHART_COLORS } from '../ColorPicker/Palettes'

const nameToAxis = {
  C1ANG: 'Degrees',
  C1MAG: 'Amps',
  C2ANG: 'Degrees',
  C2MAG: 'Amps',
  C3ANG: 'Degrees',
  C3MAG: 'Amps',
  FREQ_L1_1S: 'Hertz',
  FREQ_L1_C37: 'Hertz',
  FUND_DPF: 'DPF',
  FUND_VA: 'VA',
  FUND_VAR: 'VAR',
  FUND_W: 'Watts',
  L1ANG: 'Degrees',
  L1MAG: 'Volts',
  L2ANG: 'Degrees',
  L2MAG: 'Volts',
  L3ANG: 'Degrees',
  L3MAG: 'Volts',
  LSTATE: 'bitmap'
}

export const inferAxisName = metadata => {
  const { tags, annotations } = metadata
  const name = (tags.name || annotations.name).toUpperCase()
  const axis = nameToAxis[name]
  if (axis) return axis

  return (
    (metadata.tags && metadata.tags.unit) ||
    (metadata.annotations && metadata.annotations.unit) ||
    'Unknown'
  )
}

export const inferPlotsAxesFromStreams = ({
  axes,
  chart,
  selectedRows,
  streamsSelected,
  theme,
  updateInstance
}) => {
  if (!streamsSelected) {
    return
  }

  let updatedAxes = { ...axes }
  let updatedChart = { ...chart }

  // All streams previously assigned to an axis.
  // The list of streams associated with an axis is derived state.
  // It becomes out of sync when streams are added or removed.
  // We need to keep what we can from axesStreams to ensure we preserve choices the user has already made
  const axesStreams = Object.keys(updatedAxes)
    .map(aname => updatedAxes[aname].streams)
    .reduce((pv, cv) => {
      return pv.concat(cv)
    }, [])

  // A removed stream is anything that was assigned to an axis, but no longer exists in 'streams'
  const removedStreams = axesStreams.filter(
    s => Object.keys(selectedRows).includes(s) === false
  )
  const removedAndAddedStreams = []

  // Assigned streams are axesStreams after eliminatng removed streams
  // Must also eliminate streams without plot data (occurs when a user removes a stream, but adds it back)
  const assignedStreams = axesStreams.filter(s => {
    const stillPresent = removedStreams.includes(s) === false
    const removedAndAdded =
      selectedRows[s] && selectedRows[s].plot === undefined
    if (removedAndAdded) removedAndAddedStreams.push(selectedRows[s])
    return stillPresent && !removedAndAdded
  })

  // UnassignedStreams are all streams after eliminating assigned streams
  const unassignedStreams = Object.keys(selectedRows).filter(
    s => assignedStreams.includes(s) === false
  )

  if (unassignedStreams.length === 0 && removedStreams.length === 0) {
    // If there are no new streams to process as well as no streams that need to be removed, then just return what already exists in state
    return
  }

  // If there are no assignedstreams but there are removedStreams, that tells us
  // that every stream on the chart has changed
  // (unless someone removed and then added back the same stream).
  // That means we need to clear out additional state like start/end times, etc.
  if (
    assignedStreams.length === 0 &&
    removedStreams.length >= 1 &&
    removedAndAddedStreams.length === 0
  ) {
    console.log(
      'All streams turned over. Remove previous chart state and set axes to autoscale'
    )
    updatedChart = {}
    updatedAxes = {}
  }

  // Calculate which colors from our palette we should use next.
  const assignedColors = assignedStreams.map(uuid => {
    const stream = selectedRows[uuid]
    return stream.plot && stream.plot.color
  })

  let unassignedColors = CHART_COLORS({ theme }).filter(
    cc => assignedColors.includes(cc) === false
  )

  const getUnassignedColor = () => {
    // this line ensures that if we've used all the colors we start over
    if (unassignedColors.length === 0)
      unassignedColors = [...CHART_COLORS({ theme })]
    const nextColor = unassignedColors.shift()
    assignedColors.push(nextColor)
    return nextColor
  }

  // If we've made it here, then either a stream has been removed or added
  // Can only recalculate the unassigned streams otherwise we may blow away a user's settings
  // After this process is done, we can delete keys for any removed streams.
  updatedAxes = unassignedStreams.reduce((axes, uuid) => {
    const { metadata } = selectedRows[uuid]
    const unit = inferAxisName(metadata)
    if (!axes[unit]) {
      axes[unit] = { unit, streams: [metadata.uuid] }
    } else {
      axes[unit].streams.push(metadata.uuid)
    }
    return axes
  }, updatedAxes || {})

  const streamsWithPlotData = unassignedStreams.reduce(
    (streams, uuid, index) => {
      const stream = streams[uuid]
      const { metadata } = stream
      const unit = inferAxisName(metadata)
      const streamName =
        (metadata.tags && metadata.tags.name) ||
        (metadata.annotations && metadata.annotations.name) ||
        metadata.path ||
        metadata.collection
      const plotData = {
        uuid: metadata.uuid,
        unit: unit,
        color: getUnassignedColor(),
        visibility: true,
        legendName: streamName
      }
      streams[uuid] = {
        metadata,
        plot: plotData
      }
      return streams
    },
    streamsSelected ? { ...selectedRows } || {} : {}
  )

  // delete out any removed streams
  removedStreams.forEach(rs => {
    // find the axis associated a removed stream came from
    const axis = Object.keys(axes).find(aname => {
      return (
        updatedAxes[aname] &&
        updatedAxes[aname].streams.find(uuid => rs === uuid)
      )
    })
    if (updatedAxes[axis]) {
      updatedAxes[axis].streams = updatedAxes[axis].streams.filter(
        s => s !== rs
      )
      if (updatedAxes[axis].streams.length === 0) delete updatedAxes[axis]
    }
    delete streamsWithPlotData[rs]
  })

  // write the answers to state so that on next run they'll be present
  const data = {
    streams: streamsWithPlotData,
    axes: updatedAxes,
    chart: updatedChart
  }
  updateInstance({ data })

  return true
}
