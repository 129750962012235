export const theme = {
  dark: {
    button: {
      color: 'cyan2'
    }
  },
  light: {
    button: {
      color: 'blue2'
    }
  }
}
