import { call, getContext, put, takeEvery } from 'redux-saga/effects'

import * as actionTypes from './actionTypes'
import * as api from './api'

export function * createPermalink ({ state }) {
  const client = yield getContext('client')

  const { data, error } = yield call(api.createPermalink, { client, state })

  if (!error) {
    yield put({ type: actionTypes.CREATE_SUCCESS, data })
  } else {
    yield put({ type: actionTypes.CREATE_ERROR, error })
  }
}

export function * getPermalink ({ uuid }) {
  const client = yield getContext('client')

  const { data, error } = yield call(api.getPermalink, { client, uuid })

  if (!error) {
    yield put({ type: actionTypes.FETCH_SUCCESS, data })
  } else {
    yield put({ type: actionTypes.FETCH_ERROR, error })
  }
}

export const watchers = [
  takeEvery(actionTypes.CREATE, createPermalink),
  takeEvery(actionTypes.FETCH, getPermalink)
]
