import * as actionTypes from './actionTypes'
import * as serializers from './serializers'

const initialSelectedColumns = [
  serializers.serializeColumn({ name: 'collection' }),
  serializers.serializeColumn({ name: 'tags.name' }),
  serializers.serializeColumn({ name: 'tags.unit' })
]

const initialState = {
  data: [],
  error: false,
  streamError: false,
  loading: false,
  streamLoading: false,
  streamTable: {
    filters: {},
    hoveredRow: {
      uuid: null,
      metadata: null
    },
    metadataUsage: {},
    selectedColumns: initialSelectedColumns
  },
  total: 0
}

export default function streams (state = initialState, action) {
  switch (action.type) {
    case actionTypes.DEBOUNCE_GET:
    case actionTypes.GET: {
      return {
        ...state,
        error: false,
        loading: true
      }
    }
    case actionTypes.GET_ERROR: {
      return {
        ...initialState,
        error: true,
        loading: false
      }
    }
    case actionTypes.GET_SUCCESS: {
      const { data, total } = action
      return {
        ...state,
        data,
        error: false,
        loading: false,
        total
      }
    }
    case actionTypes.GET_STREAM: {
      return {
        ...state,
        streamError: false,
        streamLoading: true
      }
    }
    case actionTypes.GET_STREAM_ERROR: {
      return {
        ...initialState,
        streamError: true,
        streamLoading: false
      }
    }
    case actionTypes.GET_STREAM_SUCCESS: {
      const { uuid, metadata } = action
      return {
        ...state,
        streamError: false,
        streamLoading: false,
        [uuid]: metadata
      }
    }
    default:
      return { ...state, streamTable: streamTable(state.streamTable, action) }
  }
}

function streamTable (state, action) {
  switch (action.type) {
    case actionTypes.CLEAR_STREAM_TABLE_FILTERS: {
      return {
        ...state,
        filters: {}
      }
    }
    case actionTypes.GET_METADATA_USAGE: {
      return {
        ...state,
        metadataUsageStatus: 'loading'
      }
    }

    case actionTypes.GET_METADATA_USAGE_ERROR: {
      return {
        ...state,
        metadataUsageStatus: 'error'
      }
    }

    case actionTypes.GET_METADATA_USAGE_SUCCESS: {
      return {
        ...state,
        metadataUsage: action.data,
        metadataUsageStatus: 'success'
      }
    }
    case actionTypes.SET_STREAM_TABLE_COLUMNS: {
      const sortedSelectedColumns = action.selectedColumns.reduce(
        (acc, column) => {
          if (column.id === 'collection') {
            return [column, ...acc]
          }
          return [...acc, column]
        },
        []
      )
      return {
        ...state,
        selectedColumns: sortedSelectedColumns
      }
    }
    case actionTypes.SET_STREAM_TABLE_FILTERS: {
      const filters = { ...state.filters }

      if (!action.value) {
        delete filters[action.id]
        return {
          ...state,
          filters: { ...filters }
        }
      } else {
        return {
          ...state,
          filters: {
            ...filters,
            [action.id]: action.value
          }
        }
      }
    }
    case actionTypes.SET_STREAM_TABLE_HOVERED_ROW: {
      const { hoveredRow } = action

      return {
        ...state,
        hoveredRow
      }
    }
    default:
      return state
  }
}
