import * as actionTypes from './actionTypes'

const DEFAULT_STATE = {
  createLoading: false,
  createError: false,
  fetchLoading: false,
  fetchError: false,
  newestId: null,
  cache: {}
}

export default function permalinks (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionTypes.CREATE: {
      return {
        ...state,
        createLoading: true,
        createError: false,
        newestId: null
      }
    }
    case actionTypes.FETCH: {
      return {
        ...state,
        fetchLoading: true,
        fetchError: false
      }
    }
    case actionTypes.CREATE_ERROR: {
      return {
        ...state,
        createLoading: false,
        createError: true
      }
    }
    case actionTypes.FETCH_ERROR: {
      return {
        ...state,
        fetchLoading: false,
        fetchError: true
      }
    }
    case actionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.data.id]: action.data.state
        },
        createLoading: false,
        createError: false,
        newestId: action.data.uuid
      }
    }
    case actionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.data.id]: action.data.data
        },
        fetchLoading: false,
        fetchError: false
      }
    }
    default: {
      return state
    }
  }
}
