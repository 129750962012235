import lodashGet from 'lodash.get'
import lodashIsArray from 'lodash.isarray'

export const apiKey = state => state.auth.apikey
export const authenticateError = state => state.auth.authenticateError
export const authorizeLoading = state => state.auth.authorizeLoading
export const error = state => state.auth.error
export const isLoggedIn = state => !!(state.auth.username && state.auth.token)
export const loginLoading = state => state.auth.loginLoading
export const token = state => state.auth.token
export const username = state => state.auth.username
export const userRoles = state => {
  const groups = lodashGet(state.auth, 'groups')
  if (groups && lodashIsArray(groups)) {
    const roles = []
    for (const group of groups) {
      const groupRoles = lodashIsArray(group.capabilities)
        ? [...group.capabilities]
        : []
      roles.push(...groupRoles)
    }

    return [...new Set(roles)]
  }

  return []
}
export const changePasswordError = state =>
  state.auth.changePasswordStatus === 'error'
export const changePasswordSuccess = state =>
  state.auth.changePasswordStatus === 'success'
export const changePasswordLoading = state =>
  state.auth.changePasswordStatus === 'loading'
export const resetApiKeyError = state =>
  state.auth.resetApiKeyStatus === 'error'
export const resetApiKeySuccess = state =>
  state.auth.resetApiKeyStatus === 'success'
export const resetApiKeyLoading = state =>
  state.auth.resetApiKeyStatus === 'loading'
