export const portalTheme = {
  dark: {
    headerContainer: {
      backgroundColor: 'neutral6'
    },
    title: {
      h1: {
        color: 'neutral2'
      }
    }
  },
  light: {
    headerContainer: {
      backgroundColor: 'white'
    },
    title: {
      h1: {
        color: 'black'
      }
    }
  }
}
