const ERROR_KEY = 'IncorrectCredentialsError'

export function isError ({ graphQLErrors }) {
  let incorrectCredentials = false
  try {
    const error = (graphQLErrors && graphQLErrors[0]) || {}
    const message = error.message
    const match = ['invalid credential', '"code": 16'].find(msg =>
      message.includes(msg)
    )
    if (match) incorrectCredentials = true
  } catch (error) {}

  return incorrectCredentials
}

function handleError (args) {
  window.dispatchEvent(new CustomEvent(ERROR_KEY, { detail: args }))
}

export function getErrorPlugin () {
  return {
    name: ERROR_KEY,
    isError,
    handleError
  }
}
