import debounce from 'lodash.debounce'

let badClassNames = null

function unique (value, index, self) {
  return self.indexOf(value) === index
}

function findBootstrapClasses () {
  // ban bootstrap
  const bootstrap = [...document.styleSheets].find(s =>
    /bootstrap/.test(s.ownerNode.href)
  )

  if (!bootstrap) {
    console.log('Because redesignEnabled, bootstrap.min.css not loaded.')
    return []
  } else {
    window.bootstrap = bootstrap
    const rules = [...bootstrap.cssRules]
      .map(r => r.selectorText)
      .filter(Boolean)
    const classes = rules
      .map(r => r.match(/\.([A-Za-z0-9_-]+)/g))
      .flat()
      .filter(Boolean)
      .filter(unique)

    return classes
  }
}

function checkNode (node) {
  let classNames = []
  try {
    classNames = (node.className || '').split(' ')
  } catch (e) {}

  for (const className of classNames) {
    if (badClassNames.includes(node) || /ant-/.test(className)) {
      const error = new Error(`found bad class name ${className}`)
      error.node = node
      throw error
    }
  }

  for (const child of node.children || []) {
    checkNode(child)
  }
}

function designLeakCheck (...args) {
  if (!badClassNames) badClassNames = findBootstrapClasses()

  try {
    checkNode(document.body)
    console.log('Design leak check passed :)')
  } catch (e) {
    console.error('Design leak check failed!', e, e.node)
  }
}

export default window.designLeakCheck = debounce(designLeakCheck, 1000)
