import React from 'react'

import 'antd/lib/notification/style/css'
import { connect } from 'react-redux'
import notification from 'antd/lib/notification'
import stringify from 'json-stable-stringify'

import { redesignEnabled } from '../../utils/featureFlags'
import { report as reportSentry } from '../../utils/sentry'
import modals from '../../ducks/modals'

export const ErrorDescription = (error, errorInfo) => {
  console.log('Caught error:', error)
  console.log('ErrorInfo:', errorInfo)
  return (
    <div>
      <span style={{ fontSize: '16px' }}>
        The application encountered an error:
      </span>
      <p style={{ color: 'hsl(357, 100%, 45%)' }} className='errorString'>
        {error.toString()}
      </p>
      <span style={{ fontSize: '16px' }}>The error occurred in:</span>
      <div style={{ color: 'hsl(357, 100%, 45%)' }} className='errorString'>
        {stringify(errorInfo.componentStack)
          .split('\\n')
          .slice(1, 7)
          .map((frag, i) => (
            <div key={i}>{frag}</div>
          ))}
      </div>
    </div>
  )
}
export class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null
    }
  }

  componentDidCatch (error, errorInfo) {
    console.error(
      'This application error was caught by an error boundary:',
      error,
      errorInfo
    )

    this.displayError()

    reportSentry(error, {
      tags: { alertName: 'react-error' },
      extraScope: { errorInfo }
    })
  }

  displayError () {
    const { description, message, openAlert } = this.props

    if (redesignEnabled()) {
      openAlert({
        kind: 'error',
        message: `There was an error. Please try again or contact support@pingthings.io`
      })
    } else {
      notification.error({
        message: message || 'Application Error',
        description: description || '',
        duration: 0
      })
    }
  }

  render () {
    return this.props.children
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {
  openAlert: modals.actions.openAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
