export const axes = state => state.plotter.instance.axes
export const axesArray = state => {
  const _axes = { ...axes(state) }

  const axesArray = Object.keys(_axes)
    .map((axisName, idx) => {
      // add a 'side' property to the original object if it's not there
      if (!_axes[axisName].side) {
        _axes[axisName].side = idx % 2 === 0 ? 'left' : 'right' // alternate left and right
      }
      return {
        unit: axisName,
        side: _axes[axisName].side,
        streams: _axes[axisName].streams,
        scale: _axes[axisName].scale || undefined
      }
    })
    .filter(a => _axes[a.unit].streams.length !== 0) // remove empty any empty axes
    .sort() // try to add some stability to the order of results

  return axesArray
}
export const chart = state => state.plotter.instance.chart
export const emphasizedStream = state => {
  const streams = selectedRows(state)
  let emphasizedStream

  for (const stream of Object.values(streams)) {
    if (stream && stream.plot && stream.plot.emphasis) {
      emphasizedStream = stream.plot.uuid
    }
  }
  return emphasizedStream
}
export const instance = state => state.plotter.instance
export const isHydrated = state =>
  Object.keys(state.plotter.instance.streams).length > 0
export const isPermalink = state => state.plotter.instance.isPermalink
export const isUTC = state => state.plotter.settings.isUTC
export const legend = state => state.plotter.instance.legend
export const plotsArray = state => {
  const _selectedRows = { ...selectedRows(state) }
  return Object.keys(_selectedRows).map(uuid => {
    const { plot } = _selectedRows[uuid]
    return plot
  })
}
export const selectedRows = state => state.plotter.instance.streams
export const selectedUuids = state =>
  Object.keys(state.plotter.instance.streams)
export const streamsSelected = state =>
  Object.keys(state.plotter.instance.streams).length > 0
