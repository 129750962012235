import { call, put, race, take, takeLatest } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import * as pmusActionTypes from './actionTypes'
import * as serializers from '../streams/serializers'
import sql from '../sql'
const { actionTypes: sqlActionTypes } = sql

function * getPmus ({ useCache }) {
  const actionId = yield call(uuidv4)

  yield put({
    type: sqlActionTypes.QUERY,
    id: actionId,
    params: [],
    query: `SELECT uuid, collection, name, unit, ingress, distiller, annotations::json as annotations, property_version FROM streams WHERE annotations->'latitude' IS NOT null`,
    useCache
  })
  const { querySuccess, queryError } = yield race({
    querySuccess: take(
      action =>
        action.type === sqlActionTypes.QUERY_SUCCESS && action.id === actionId
    ),
    queryError: take(
      action =>
        action.type === sqlActionTypes.QUERY_ERROR && action.id === actionId
    )
  })

  if (queryError) {
    yield put({ type: pmusActionTypes.GET_PMUS_ERROR, error: queryError.error })
  } else {
    let data

    try {
      data = yield call(serializers.deserializeGeoStreamsList, {
        listData: querySuccess.data
      })
      yield put({ type: pmusActionTypes.GET_PMUS_SUCCESS, data })
    } catch (e) {
      console.log(e.message)
      yield put({ type: pmusActionTypes.GET_PMUS_ERROR, error: e.message })
    }
  }
}

const watchers = [takeLatest(pmusActionTypes.GET_PMUS, getPmus)]

export { getPmus, watchers }
