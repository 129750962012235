import lodashGet from 'lodash.get'

export const checkApiResponse = ({ res }) => {
  const jsonifiedErrorMessage = lodashGet(res, 'errors.0.message', '{}')
  const errorMessage = JSON.parse(jsonifiedErrorMessage).details

  if (errorMessage) {
    throw new Error(errorMessage)
  }
}
