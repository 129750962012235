import * as actionTypes from './actionTypes'

const initialState = {
  data: [],
  error: false,
  loading: false,
  success: false
}

export default function pmus (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PMUS: {
      return {
        ...state,
        error: false,
        loading: true,
        success: false
      }
    }
    case actionTypes.GET_PMUS_ERROR: {
      return {
        ...initialState,
        error: true,
        loading: false,
        success: false
      }
    }
    case actionTypes.GET_PMUS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        data,
        error: false,
        loading: false,
        success: true
      }
    }
    default:
      return state
  }
}
