import {
  call,
  debounce,
  getContext,
  put,
  race,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import { redesignEnabled } from '../../utils/featureFlags'
import * as serializers from './serializers'
import * as streamsActionTypes from './actionTypes'
import * as streamsApi from './api'
import modals from '../modals'
import sql from '../sql'

const { actionTypes: sqlActionTypes } = sql

const debounceInterval = process.env.REACT_APP_TYPE_AHEAD_DEBOUNCE
  ? Number(process.env.REACT_APP_TYPE_AHEAD_DEBOUNCE)
  : 300

export function * getMetadataUsage ({ prefix }) {
  const client = yield getContext('client')

  const { data, error } = yield call(streamsApi.getMetadataUsage, {
    client,
    prefix
  })

  if (!error) {
    const deserializedData = serializers.deserializeMetadataUsage({
      metadataUsage: data
    })
    yield put({
      type: streamsActionTypes.GET_METADATA_USAGE_SUCCESS,
      data: deserializedData
    })
  } else {
    yield put({ type: streamsActionTypes.GET_METADATA_USAGE_ERROR, error })
  }
}

function * getStream ({ uuid, useCache }) {
  const actionId = yield call(uuidv4)

  yield put({
    type: sqlActionTypes.QUERY,
    id: actionId,
    params: [`${uuid}`],
    query: 'SELECT * FROM streams WHERE uuid = $1',
    useCache
  })
  const { querySuccess, queryError } = yield race({
    querySuccess: take(
      action =>
        action.type === sqlActionTypes.QUERY_SUCCESS && action.id === actionId
    ),
    queryError: take(
      action =>
        action.type === sqlActionTypes.QUERY_ERROR && action.id === actionId
    )
  })

  if (queryError) {
    yield put({
      type: streamsActionTypes.GET_STREAM_ERROR,
      error: queryError.error
    })

    const isRedesignEnabled = yield call(redesignEnabled)

    if (isRedesignEnabled) {
      yield put(
        modals.actions.openAlert({
          kind: 'error',
          message:
            'There was an error. Please try again or contact support@pingthings.io'
        })
      )
    }
  } else {
    let data, metadata
    try {
      data = yield call(serializers.deserializeList, {
        listData: querySuccess.data
      })
      metadata = data[0]

      if (!metadata) {
        const isRedesignEnabled = yield call(redesignEnabled)

        if (isRedesignEnabled) {
          yield put(
            modals.actions.openAlert({
              kind: 'error',
              message:
                'There was an error. Please try again or contact support@pingthings.io'
            })
          )
        }
      }

      yield put({ type: streamsActionTypes.GET_STREAM_SUCCESS, metadata, uuid })
    } catch (e) {
      // TODO sentry
      console.log(e)
      yield put({ type: streamsActionTypes.GET_STREAM_ERROR, error: e.message })
    }
  }
}

function * getStreams ({
  columns,
  filters,
  page,
  pageSize,
  sort,
  useCache = false
}) {
  let countText
  let countValues
  let listText
  let listValues

  try {
    ;({
      countQuery: { text: countText, values: countValues },
      listQuery: { text: listText, values: listValues }
    } = yield call(serializers.serializeQuery, {
      columns,
      filters,
      page,
      pageSize,
      sort
    }))
  } catch (e) {
    // TODO sentry
    console.log(e.message)
    yield put({ type: streamsActionTypes.GET_ERROR, error: e.message })
    return
  }

  const countActionId = yield call(uuidv4)

  yield put({
    type: sqlActionTypes.QUERY,
    id: countActionId,
    params: countValues,
    query: countText,
    useCache
  })
  const { countQuerySuccess, countQueryError } = yield race({
    countQuerySuccess: take(
      action =>
        action.type === sqlActionTypes.QUERY_SUCCESS &&
        action.id === countActionId
    ),
    countQueryError: take(
      action =>
        action.type === sqlActionTypes.QUERY_ERROR &&
        action.id === countActionId
    )
  })

  if (countQueryError) {
    yield put({
      type: streamsActionTypes.GET_ERROR,
      error: countQueryError.error
    })
  } else {
    const listActionId = yield call(uuidv4)

    yield put({
      type: sqlActionTypes.QUERY,
      id: listActionId,
      params: listValues,
      query: listText,
      useCache
    })
    const { listQuerySuccess, listQueryError } = yield race({
      listQuerySuccess: take(
        action =>
          action.type === sqlActionTypes.QUERY_SUCCESS &&
          action.id === listActionId
      ),
      listQueryError: take(
        action =>
          action.type === sqlActionTypes.QUERY_ERROR &&
          action.id === listActionId
      )
    })

    if (listQueryError) {
      yield put({
        type: streamsActionTypes.GET_ERROR,
        error: listQueryError.error
      })
    } else {
      let data
      let total

      try {
        data = yield call(serializers.deserializeList, {
          listData: listQuerySuccess.data
        })
        total = yield call(serializers.deserializeCount, {
          countData: countQuerySuccess.data
        })
        yield put({ type: streamsActionTypes.GET_SUCCESS, data, total })
      } catch (e) {
        // TODO sentry
        console.log(e.message)
        yield put({ type: streamsActionTypes.GET_ERROR, error: e.message })
      }
    }
  }
}

const watchers = [
  debounce(debounceInterval, streamsActionTypes.DEBOUNCE_GET, getStreams),
  takeEvery(streamsActionTypes.GET, getStreams),
  takeLatest(streamsActionTypes.GET_METADATA_USAGE, getMetadataUsage),
  takeLatest(streamsActionTypes.GET_STREAM, getStream)
]

export { getStream, getStreams, watchers }
