import React, { useEffect } from 'react'

import { Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'

import { redesignEnabled } from '../../utils/featureFlags'
import plotter from '../../ducks/plotter'

const GET_PERMALINK = gql`
  query getPermalink($uuid: ID!) {
    GetPermalink(body: { uuid: $uuid }) {
      error {
        code
        msg
      }
      permalink {
        uuid
        data
      }
    }
  }
`

const HydrateRedirect = ({ data }) => {
  const dispatch = useDispatch()
  const isHydrated = useSelector(plotter.selectors.isHydrated)

  useEffect(() => {
    dispatch(
      plotter.actions.updateInstance({
        data: { ...data, isPermalink: true }
      })
    )
    // eslint-disable-next-line
  }, [])

  if (isHydrated === false) {
    return 'Loading State...'
  }

  const to = redesignEnabled() ? '/app' : '/visualize'

  return <Redirect to={to} />
}

/**
 * Right now we assume we're storing all the metadata for a stream in etcd
 * and our mock backend.
 * Could expand this to store only the UUID and then query for metadata
 * from the backend for each one.
 *
 * TODO: There is currently no handling of errors in cases where we retrieve a
 * permalink with associated UUIDS that either do not exist in the DB or for
 * which the user does not have permission
 */
class PermalinkHydrater extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        uuid: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }

  render () {
    const { uuid } = this.props.match.params
    return (
      <Query
        query={GET_PERMALINK}
        variables={{ uuid }}
        notifyOnNetworkStatusChange
      >
        {({ loading, error, data, refetch, networkStatus, variables }) => {
          if (networkStatus === 4) return 'Refetching!'
          if (loading) return <div>Loading permalink data</div>
          if (error) return `Permalink query failed: ${error}`
          const { GetPermalink } = data
          console.log('Loaded Permalink for:', variables)
          console.log('Found:', data)

          try {
            const decodedPermalink = JSON.parse(GetPermalink.permalink.data)
            return <HydrateRedirect data={decodedPermalink} />
          } catch (error) {
            console.error('Error while parsing permalink from string', error)
            return 'Permalink parsing failed'
          }
        }}
      </Query>
    )
  }
}

export default PermalinkHydrater
