import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import lodashIsEmpty from 'lodash.isempty'

import streams from '../ducks/streams'

export default function useMetadataUsage () {
  const dispatch = useDispatch()
  const metadataUsage = useSelector(state =>
    streams.selectors.metadataUsage(state)
  )
  const metadataUsageStatus = useSelector(state =>
    streams.selectors.metadataUsageStatus(state)
  )

  useEffect(() => {
    if (lodashIsEmpty(metadataUsage) && !metadataUsageStatus) {
      dispatch(streams.actions.getMetadataUsage({}))
    }
  }, [metadataUsage, metadataUsageStatus, dispatch])

  return { metadataUsage, metadataUsageStatus }
}
