import React from 'react'

import { Icon } from 'frontend-components'
import { IconButton } from './StreamListTableComponents/ResetXScale'
import styled from 'styled-components'

const SideSelector = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
`

const StyledIconButton = styled(IconButton)`
  padding: 4px 2px;

  &.last {
    margin-left: 2px;
  }
`

export const AxisSideSelector = ({ side, axis, updateAxis }) => {
  return (
    <SideSelector>
      <StyledIconButton
        className={side.toLowerCase() === 'left' ? 'active' : ''}
        onClick={() => updateAxis(axis, { side: 'left' })}
      >
        <Icon name='angleLeft' height={24} viewBox='0 0 12 24' width={12} />
      </StyledIconButton>
      <StyledIconButton
        className={`last ${side.toLowerCase() === 'right' ? 'active' : ''}`}
        onClick={() => updateAxis(axis, { side: 'right' })}
      >
        <Icon name='angleRight' height={24} viewBox='0 0 12 24' width={12} />
      </StyledIconButton>
    </SideSelector>
  )
}
