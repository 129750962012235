import React, { useState } from 'react'

import 'antd/lib/button/style/css'
import 'antd/lib/notification/style/css'
import 'antd/lib/typography/style/css'
import Button from 'antd/lib/button'
import lodashGet from 'lodash.get'
import notification from 'antd/lib/notification'
import styled from 'styled-components'
import Typography from 'antd/lib/typography'

import { getTokenAndUser } from '../../../services/authStorage'
import { redesignEnabled } from '../../../utils/featureFlags'

const ERROR_KEY = 'LackPermissionError'
const { Paragraph } = Typography
function isError ({ graphQLErrors }) {
  /**
   * Insufficient permissions are reported with a graphQLError:
   [
     {
       "extensions": {
         "code": "INTERNAL_SERVER_ERROR",
         "exception": {
           "errors": [
             {
               "locations": [],
               "message": "7 PERMISSION_DENIED: user does not have permission \"api\" on \"comtrade/Shawboro_2064\"",
               "path": [
                 "AlignedWindows"
               ]
             }
           ]
         }
       },
       "message": "7 PERMISSION_DENIED: user does not have permission \"api\" on \"comtrade/Shawboro_2064",
       "path": [
         "AlignedWindows"
       ]
     }
   ]
   They may also show up as:
   [
  {
    "extensions": {
      "code": "INTERNAL_SERVER_ERROR"
    },
    "message": "Error while calling rpc Nearest:
                {
                    "code": 7,
                    "metadata": {
                        "_internal_repr": {}
                    },
                    "details": "user does not have permission \"api\" on \"dfr/DFR!BATH_COUNTY_DFR_4\""
                }",
    "path": [
      "Nearest"
    ],
    "locations": []
  }
]
*/
  let lackPermissions = false
  try {
    const error = (graphQLErrors && graphQLErrors[0]) || {}
    const message = error.message
    const match = [
      'PERMISSION_DENIED',
      'user does not have permission',
      '"code": 7'
    ].find(msg => message.includes(msg))
    if (match) lackPermissions = true
  } catch (error) {}

  if (lackPermissions === true) return true
  else return false
}

function handleErrorDefault (args) {
  window.dispatchEvent(new CustomEvent(ERROR_KEY, { detail: args }))
}

const StyledPre = styled.pre`
  overflow: auto;
  width: 100%;
  font-size: 13px;
  max-height: 50vh;
`
const Description = styled.div`
  width: 400px;
  max-width: 30vw;
`

function ExpandableErrorMsg ({ errorMsg }) {
  const [isExpanded, setExpanded] = useState(false)
  return (
    <>
      <Paragraph copyable={{ text: errorMsg }}>
        Copy error to clipboard
      </Paragraph>
      {!isExpanded && (
        <Button onClick={() => setExpanded({ expanded: true })}>
          Show Error
        </Button>
      )}
      {isExpanded && <StyledPre>{errorMsg}</StyledPre>}
    </>
  )
}

const isUnauthenticatedAlignedWindowsError = ({ error }) => {
  const responseError = lodashGet(error, 'detail.response.errors.0.message')
  const responsePath = lodashGet(error, 'detail.response.errors.0.path.0')

  const message = 'user does not have permission'
  const paths = ['AlignedWindows', 'Nearest']

  const { token, username } = getTokenAndUser()
  const isLoggedIn = !!token && !!username

  return (
    !isLoggedIn &&
    responseError.includes(message) &&
    paths.includes(responsePath)
  )
}
export class ErrorListener extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      notificationAlreadyOpen: false,
      expanded: false
    }
  }

  handleEvent = e => {
    const args = e.detail
    const { operation } = args
    const { operationName = '' } = operation
    if (this.state.notificationAlreadyOpen === false) {
      this.setState({ notificationAlreadyOpen: true })

      if (!redesignEnabled()) {
        // There's no guarantee that the alert component will be on the dom when this error is hit.
        // because of that, we don't render an error for redesigns. We have a card to follow up
        // to fix this: https://app.clubhouse.io/pingthings-ws/story/8537/handle-network-errors-via-something-that-s-not-apollo-client
        const _isUnauthenticatedAlignedWindowsError = isUnauthenticatedAlignedWindowsError(
          { error: e }
        )

        console.log({ _isUnauthenticatedAlignedWindowsError })

        let description = `Your credentials did not have permission to access a resource you requested. Ask your admin to give you access or log in as a different user.`
        let message = `Access Denied ${
          operationName ? ' - ' + operationName : ''
        }`

        if (_isUnauthenticatedAlignedWindowsError) {
          description = `Please try to log in to access the resource you requested.`
          message = `Access Denied`
        }

        notification.warn({
          onClose: () =>
            this.setState(prevState => ({ notificationAlreadyOpen: false })),
          message,
          description: (
            <Description>
              <p>{description}</p>
              <ExpandableErrorMsg
                errorMsg={JSON.stringify(args.graphQLErrors, null, 4)}
              />
            </Description>
          ),
          duration: 0
        })
      }
    }
  }

  componentDidMount () {
    window.addEventListener(ERROR_KEY, this.handleEvent)
  }

  componentWillUnmount () {
    window.removeEventListener(ERROR_KEY, this.handleEvent)
  }

  render () {
    return null
  }
}

export function getErrorPlugin (overrides = {}) {
  const { handleError = handleErrorDefault } = overrides
  return {
    name: ERROR_KEY,
    isError,
    handleError,
    ErrorListener
  }
}
