import * as actionTypes from './actionTypes'

export const heatmapQuery = ({ uuid, start, end, resolution }) => {
  return {
    type: actionTypes.QUERY_WINDOWS,
    uuid,
    start,
    end,
    resolution
  }
}

export const startEndQuery = ({ uuid }) => {
  return {
    type: actionTypes.QUERY_START_END,
    uuid
  }
}
