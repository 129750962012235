import React from 'react'

import notification from 'antd/lib/notification'
import 'antd/lib/notification/style/css'

import { redesignEnabled } from '../../../utils/featureFlags'
import { reportGqlError } from '../ApolloClient'

const ERROR_KEY = 'InternalServicesDown'

function isError ({ graphQLErrors, networkError }) {
  /**
   * When the apifrontend or adminapi selectively go down graphql
   * will respond with an error of the shape:
   * {
  "errors": [
    {
      "message": "Error while calling rpc Info:\n{\n    \"code\": 1,\n    \"metadata\": {\n        \"_internal_repr\": {}\n    },\n    \"details\": \"Received http2 header with status: 503\"\n}",
      "locations": [],
      "path": [
        "Info"
      ],
      "extensions": {
        "code": "INTERNAL_SERVER_ERROR",
        "exception": {
          "stacktrace": [
            "Error: Error while calling rpc Info:",
            "{",
            "    \"code\": 1,",
            "    \"metadata\": {",
            "        \"_internal_repr\": {}",
            "    },",
            "    \"details\": \"Received http2 header with status: 503\"",
            "}",
            "    at promise.catch.e (/Users/fredantell/Documents/_source/pingthings/api-graphql/pingthings/src/datasources/utils.js:80:11)"
          ]
        }
      }
    }
  ],
  "data": {
    "Info": null
  },
  "extensions": {}
}

Sometimes we may also see a response of the shape:
{
  "errors": [
    {
      "message": "Error while calling rpc Info:\n{\n    \"code\": 14,\n    \"metadata\": {\n        \"_internal_repr\": {}\n    },\n    \"details\": \"Connect Failed\"\n}",
      "locations": [],
      "path": [
        "Info"
      ],
      "extensions": {
        "code": "INTERNAL_SERVER_ERROR"
      }
    }
  ],
  "data": {
    "Info": null
  },
  "extensions": {}
}
   */
  let servicesDown = false
  try {
    const error = graphQLErrors[0]
    let message = (error && error.message) || {}
    message = (message.details || message).toLowerCase()
    const match = ['http2 header with status: 503', 'connect failed'].find(
      msg => message.includes(msg)
    )
    if (match) servicesDown = true
  } catch (error) {}
  try {
    const { statusCode, bodyText } = networkError || {}
    if (statusCode === 502) servicesDown = true
    if ((bodyText || '').toLowerCase().includes('bad gateway')) {
      servicesDown = true
    }
  } catch (error) {}
  if (servicesDown === true) return true
  else return false
}

function handleErrorDefault (args) {
  window.dispatchEvent(new CustomEvent(ERROR_KEY, { detail: args }))
}

export class ErrorListener extends React.Component {
  handleEvent = e => {
    const { operation } = e.detail
    const { operationName = '' } = operation
    if (!redesignEnabled()) {
      // There's no guarantee that the alert component will be on the dom when this error is hit.
      // because of that, we don't render an error for redesigns. We have a card to follow up
      // to fix this: https://app.clubhouse.io/pingthings-ws/story/8537/handle-network-errors-via-something-that-s-not-apollo-client
      notification.warn({
        message: `${operationName || 'Request'} Failed`,
        description: (
          <div>
            <p>
              We had trouble retrieving data. An underlying service may be down,
              or the graphql server may be misconfigured. Please notify your
              admin if the problem persists.
            </p>
          </div>
        ),
        duration: 10
      })
    }

    reportGqlError(new Error('GraphQL Error from <ErrorListener />'), {
      ...e.detail,
      sentryMsgName: 'GraphQL Requests failing. Services down or bad config?'
    })
  }

  componentDidMount () {
    window.addEventListener(ERROR_KEY, this.handleEvent)
  }

  componentWillUnmount () {
    window.removeEventListener(ERROR_KEY, this.handleEvent)
  }

  render () {
    return null
  }
}

export function getErrorPlugin (overrides = {}) {
  const { handleError = handleErrorDefault } = overrides
  return {
    name: ERROR_KEY,
    isError,
    handleError,
    ErrorListener
  }
}
