import * as d3array from 'd3-array'

export function splitArray (array, shouldSplit) {
  const n = array.length
  if (n === 0) return []

  // get each position in the array where a split should occur
  let indexes = d3array
    .range(1, n)
    .filter(i => shouldSplit(array[i], array[i - 1]))

  // insert start/end points
  indexes = [0, ...indexes, n]

  // group elements between the indexes
  return d3array
    .range(0, indexes.length - 1)
    .map(i => array.slice(indexes[i], indexes[i + 1]))
}
