export const CHANGE_STREAM_AXIS = 'plotter/CHANGE_STREAM_AXIS'
export const CREATE_NEW_AXIS = 'plotter/CREATE_NEW_AXIS'
export const CREATE_NEW_AXIS_AND_ADD_STREAM =
  'plotter/CREATE_NEW_AXIS_AND_ADD_STREAM'
export const REMOVE_AXIS = 'plotter/REMOVE_AXIS'
export const SET_IS_PERMALINK = 'plotter/SET_IS_PERMALINK'
export const SET_LEGEND_NAME = 'plotter/SET_LEGEND_NAME'
export const SET_STREAM_COLOR = 'plotter/SET_STREAM_COLOR'
export const SET_STREAMS_EMPHASIS = 'plotter/SET_STREAMS_EMPHASIS'
export const TOGGLE_AXIS_STREAM_VISIBILITY =
  'plotter/TOGGLE_AXIS_STREAM_VISIBILITY'
export const TOGGLE_LEGEND = 'plotter/TOGGLE_LEGEND'
export const TOGGLE_STREAM = 'plotter/TOGGLE_STREAM'
export const TOGGLE_STREAM_VISIBILITY = 'plotter/TOGGLE_STREAM_VISIBILITY'
export const UPDATE_AXIS = 'plotter/UPDATE_AXIS'
export const UPDATE_AXIS_NAME = 'plotter/UPDATE_AXIS_NAME'
export const UPDATE_CHART = 'plotter/UPDATE_CHART'
export const UPDATE_INSTANCE = 'plotter/UPDATE_INSTANCE'
export const UPDATE_SETTING = 'plotter/UPDATE_SETTING'
