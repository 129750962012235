export const CLEAR_STREAM_TABLE_FILTERS = 'streams/CLEAR_STREAM_TABLE_FILTERS'
export const DEBOUNCE_GET = 'streams/DEBOUNCE_GET'
export const GET = 'streams/GET'
export const GET_METADATA_USAGE = 'streams/GET_METADATA_USAGE'
export const GET_STREAM = 'streams/GET_STREAM'
export const SET_STREAM_TABLE_COLUMNS = 'streams/SET_STREAM_TABLE_COLUMNS'
export const SET_STREAM_TABLE_FILTERS = 'streams/SET_STREAM_TABLE_FILTERS'
export const SET_STREAM_TABLE_HOVERED_ROW =
  'streams/SET_STREAM_TABLE_HOVERED_ROW'

export const GET_ERROR = 'streams/GET_ERROR'
export const GET_METADATA_USAGE_SUCCESS = 'streams/GET_METADATA_USAGE_SUCCESS'
export const GET_STREAM_ERROR = 'streams/GET_STREAM_ERROR'

export const GET_METADATA_USAGE_ERROR = 'streams/GET_METADATA_USAGE_ERROR'
export const GET_STREAM_SUCCESS = 'streams/GET_STREAM_SUCCESS'
export const GET_SUCCESS = 'streams/GET_SUCCESS'
