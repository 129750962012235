import React from 'react'

import 'antd/lib/notification/style/css'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import debounce from 'lodash.debounce'
import gql from 'graphql-tag'
import min from 'lodash.min'
import notification from 'antd/lib/notification'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import throttle from 'lodash.throttle'

import { createDeferred } from '../../utils/utils'
import {
  fetchStreamCache,
  invalidateStreamCache,
  prefetchStreamCache,
  queryStreamCache
} from '../../lib/cache'
import { getScaleResolution, clampRes } from '../../lib/btrdbMath.js'
import { scaleTimeNano } from '../../lib/scaleTimeNano'
import { setGlobalDefaultZoom } from '../../ducks/chart/actions'
import Chart from '../Chart/Chart'
import ChartIcon from '../svgs/ChartIcon'
import plotter from '../../ducks/plotter'

const ALIGNED_WINDOWS = gql`
  subscription alignedwindows(
    $uuid: Uuid!
    $start: String!
    $end: String!
    $pointWidth: Int!
  ) {
    AlignedWindows(
      body: { uuid: $uuid, start: $start, end: $end, pointWidth: $pointWidth }
    ) {
      stat {
        code
        msg
      }
      versionMajor
      versionMinor
      values {
        time
        min
        mean
        max
        count
      }
    }
  }
`

export const QUERY_NEAREST = gql`
  query Nearest($uuid: Uuid!, $start: String!, $end: String!) {
    #time is a string in nanoseconds
    start: Nearest(body: { uuid: $uuid, time: $start, backward: false }) {
      stat {
        code
        msg
      }
      versionMajor
      versionMinor
      value {
        time
        value
      }
    }
    end: Nearest(body: { uuid: $uuid, time: $end, backward: true }) {
      stat {
        code
        msg
      }
      versionMajor
      versionMinor
      value {
        time
        value
      }
    }
  }
`

const chartDefaults = {
  width: 800,
  height: 400,
  margin: {
    top: 50,
    bottom: 100,
    left: 10,
    right: 10
  }
}

const propTypeDefs = {
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    margin: PropTypes.shape({
      top: PropTypes.number.isRequired,
      bottom: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      right: PropTypes.number.isRequired
    })
  })
}

export const ChartMessage = (message, submessage, showIcon = true) => {
  return (
    <ChartMessageStyles>
      {showIcon && <ChartIcon />}
      <div className='message'>{message}</div>
      <div className='subMessage'>{submessage}</div>
    </ChartMessageStyles>
  )
}
const ChartMessageStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;

    path {
      fill: #6c6e6e;
    }
    circle {
      stroke: #6c6e6e;
    }
    g#ticks path {
      fill: none;
    }
  }

  .message {
    font-size: 22px;
    color: #494d52;
  }
  .subMessage {
    margin-top: 5px;
    font-size: 18px;
    color: #6c6e6e;
  }
`

class ChartStreamDataProvider extends React.Component {
  static propTypes = {
    dimensions: propTypeDefs.dimensions,
    plots: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        unit: PropTypes.string,
        color: PropTypes.string,
        visibility: PropTypes.bool
      })
    ),
    useHints: PropTypes.bool,
    client: PropTypes.object.isRequired,
    axes: PropTypes.arrayOf(
      PropTypes.shape({
        unit: PropTypes.string,
        side: PropTypes.oneOf(['left', 'right', 'hide'])
      })
    ),
    showLegend: PropTypes.bool.isRequired
  }

  static defaultProps = {
    dimensions: chartDefaults
  }

  constructor (props) {
    super(props)
    try {
      const { instance, isPermalink } = props
      const { chart } = instance
      const { start, end, selection } = chart
      const startTime = isPermalink ? start || undefined : undefined
      const endTime = isPermalink ? end || undefined : undefined
      this.state = {
        startTime,
        endTime,
        initialSelectedTimeRange: selection
      }

      if (start && end) {
        this.props.setIsPermalink({ setting: false })
      }
    } catch (error) {
      this.state = {
        startTime: props.initialStartTime || undefined,
        endTime: props.initialEndTime || undefined,
        initialSelectedTimeRange: props.initialSelectedTimeRange
      }
    }
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  async componentDidMount () {
    const { plots, client } = this.props
    // If the start/end times have already been set externally (via permalink,
    // rehydrated state, etc) then skip querying for date ranges
    if (
      this.state.startTime !== undefined &&
      this.state.endTime !== undefined
    ) {
      return
    }

    // sometimes we rapidly mount/unmount charts when a user is mousing over different streams
    // wait a few milliseconds before starting to query for data in case the component has already unmounted
    const sleep = time =>
      // eslint-disable-next-line
      new Promise((res, rej) => {
        setTimeout(() => res(true), time)
      })
    await sleep(50)
    if (this._isMounted === false) return

    // query all plots in parallel instead of serially
    const plotTimeRangeReqs = plots.map(plot => {
      return client
        .query({
          query: QUERY_NEAREST,
          variables: {
            uuid: plot.uuid,
            start: '0',
            // Send a query for the end of the day instead of now so the timestamp stays consistent and our gql client can more easily cache the request
            end: new Date().setUTCHours(23, 59, 59, 999) + '000000'
          }
        })
        .then(apolloresp => {
          const { data } = apolloresp
          return {
            start:
              (data.start && data.start.value && data.start.value.time) ||
              undefined,
            end:
              (data.end && data.end.value && data.end.value.time) || undefined
          }
        })
    })
    const plotTimeRanges = await Promise.all(plotTimeRangeReqs).catch(() => [
      {
        start: null,
        end: null
      }
    ])

    // then go through the answers and reduce them into a coherent response
    const minMaxTimeRangeForPlots = plotTimeRanges.reduce(
      (minMaxRange, plotStartEnd) => {
        if (plotStartEnd.start && plotStartEnd.end) {
          return {
            start: minMaxRange.start
              ? Math.min(minMaxRange.start, plotStartEnd.start).toString()
              : plotStartEnd.start.toString(),
            end: minMaxRange.end
              ? Math.max(minMaxRange.end, plotStartEnd.end).toString()
              : plotStartEnd.end.toString()
          }
        }
        return minMaxRange
      },
      { start: null, end: null }
    )
    if (minMaxTimeRangeForPlots.start && minMaxTimeRangeForPlots.end) {
      this._isMounted &&
        this.setState({
          startTime: minMaxTimeRangeForPlots.start,
          endTime: minMaxTimeRangeForPlots.end,
          nodataforstreams: false
        })
    } else {
      this._isMounted &&
        this.setState({
          nodataforstreams: true
        })
    }
  }

  alignedWindows = (uuid, startTime, endTime, resolution) => {
    const observable = this.props.client.subscribe({
      query: ALIGNED_WINDOWS,
      fetchPolicy: 'no-cache',
      variables: {
        uuid,
        start: startTime + '',
        end: endTime + '',
        pointWidth: resolution
      }
    })
    const promise = createDeferred()
    let results = []
    observable.subscribe({
      next: d => {
        results = results.concat(d.data.AlignedWindows.values || [])
      },
      error: error => console.log(error) || promise.reject(error),
      complete: () => promise.resolve(results)
    })
    return promise
  }

  render () {
    const { plots, axes, showLegend } = this.props
    if (this.state.nodataforstreams) {
      return (
        <div style={{ ...this.props.dimensions, boxSizing: 'border-box' }}>
          {ChartMessage(
            'No Data for Streams in DB',
            'Select another stream from Stream Selection'
          )}
        </div>
      )
    }

    if (!this.state.startTime || !this.state.endTime) {
      return <div style={this.props.dimensions}>{/* <ChartLoading /> */}</div>
    }
    return (
      <ChartStream
        {...this.props}
        plots={plots}
        axes={axes}
        dimensions={this.props.dimensions}
        // passing these dates as strings to be consistent with actual backend
        initialStartTime={this.state.startTime}
        initialEndTime={this.state.endTime}
        initialSelectedTimeRange={this.state.initialSelectedTimeRange}
        useHints={this.props.useHints}
        alignedWindows={this.alignedWindows}
        showLegend={showLegend}
        showDebugCache={!!window.localStorage.debugCache}
        resetxDomain={this.props.resetxDomain}
      />
    )
  }
}

function getDefaultScaleTime (start, end, chartDimensions) {
  if (
    start === undefined ||
    end === undefined ||
    chartDimensions === undefined
  ) {
    throw new Error('Missing arguments for getDefaultScaleTime')
  }

  const { width } = chartDimensions
  const margin = { ...chartDefaults.margin, ...chartDimensions.margin }
  const contentWidth = width - margin.left - margin.right

  start = Number(start)
  end = Number(end)

  // ensure we render an x-axis in the case that start and end times are the same
  // this happens when we have a single data point for a stream
  if (start === end) {
    const halfSecondAsNano = 500 * 1e6
    start = start - halfSecondAsNano
    end = end + halfSecondAsNano
  }

  return scaleTimeNano()
    .domain([start, end])
    .range([0, contentWidth])
}

// ----------------------------------------------------------------------
// Chart Operations
// ----------------------------------------------------------------------

/**
 * The purpose of this fn is to selectively take portions of
 * locally managed state from the chart component and make it
 * available to other components throughout the app. We return
 * individual functions to track state and then batch all updates
 * into one debounced function. This avoids excessive renders.
 */
function makeChartStateTrackers ({ updateChart }) {
  let start
  let end
  let resolution
  let axes = {}
  let selection = []

  function trackStartEndResolution (xScale) {
    const [startNext, endNext] = xScale.domain()
    const resolutionNext = clampRes(getScaleResolution(xScale))
    if (
      startNext !== start ||
      endNext !== end ||
      resolutionNext !== resolution
    ) {
      start = startNext
      end = endNext
      resolution = resolutionNext
      flushStateDebounced()
    }
  }

  function trackChartSelection (selectionNext = []) {
    const [ps, pe] = selection || [] // previousStart previousEnd
    const [ns, ne] = selectionNext || [] // nextStart nextEnd
    if (ps !== ns || pe !== ne) {
      selection = selectionNext
      flushStateDebounced()
    }
  }

  /**
   *
   * @param {*} yDomains
   * yDomains is an Object with keys corresponding to the name of an axis
   * where the value is [yMin: Int, yMax: Int]
   */
  function trackYScales (yDomains) {
    if (JSON.stringify(axes) !== JSON.stringify(yDomains)) {
      axes = yDomains
      flushStateDebounced()
    }
  }

  const flushStateDebounced = debounce(flushState, TRACK_RATE, {
    trailing: true,
    leading: false
  })

  function flushState () {
    const data = {
      start,
      end,
      resolution,
      axes,
      selection
    }
    updateChart({ data })
  }

  return {
    trackStartEndResolution,
    trackChartSelection,
    trackYScales
  }
}

function fetchPlots (plots, scaleXTime, callbacks) {
  const promises = plots.map(({ uuid }) =>
    fetchStreamCache(uuid, scaleXTime, callbacks)
  )
  const { onProgress } = callbacks
  if (onProgress) onProgress()
  return Promise.all(promises)
}

function prefetchPlots (plots, scaleXTime, callbacks) {
  const promises = plots.map(({ uuid }) =>
    prefetchStreamCache(uuid, scaleXTime, callbacks)
  )
  const { onProgress } = callbacks
  if (onProgress) onProgress()
  return Promise.all(promises)
}

function queryPlots (plots, scaleXTime) {
  const newPlots = plots.map(plot => {
    const tiles = queryStreamCache(plot.uuid, scaleXTime)
    return { ...plot, tiles }
  })
  return newPlots
}

// ----------------------------------------------------------------------
// Chart Operation Rates
// ----------------------------------------------------------------------

const TRACK_RATE = 250
const FETCH_RATE = 250
const PREFETCH_RATE = 800
const QUERY_RATE = 15
const CHANGES_RATE = 2000

// ----------------------------------------------------------------------
// DEFAULT CHANGED RANGE (for updating live data)
// ----------------------------------------------------------------------

const SECOND = 1e9
const MINUTE = 60 * SECOND
const LIVE_REFRESH_WINDOW = 20 * MINUTE

export function getDefaultChangedRange () {
  const now = +Date.now() * 1e6
  const start = now - LIVE_REFRESH_WINDOW
  const range = [start, now]
  return range
}

export class ChartStream extends React.Component {
  static propTypes = {
    dimensions: propTypeDefs.dimensions.isRequired,
    initialStartTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    initialEndTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    alignedWindows: PropTypes.func.isRequired,
    plots: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        unit: PropTypes.string,
        color: PropTypes.string,
        visibility: PropTypes.bool
      })
    ),
    axes: PropTypes.arrayOf(
      PropTypes.shape({
        unit: PropTypes.string,
        side: PropTypes.oneOf(['left', 'right', 'hide']),
        streams: PropTypes.array
      })
    ),
    showLegend: PropTypes.bool.isRequired,
    showTreeViz: PropTypes.bool,
    showDebugCache: PropTypes.bool,
    monitorChanges: PropTypes.bool
  }

  static defaultProps = {
    monitorChanges: true
  }

  constructor (props) {
    super(props)
    this.state = {}
    this.maxTimestamp = Number(new Date().getTime() + '000000')
    this.queryRate = () => min([this.props.plots.length * QUERY_RATE, 2000])
    this.alignedWindowsCapped = (uuid, startTime, endTime, resolution) => {
      // A user who is scrolling on live data will launch a series of queries.
      // If points are being actively ingested, then the later queries will
      // return more data. The result is that if they scroll back out, when
      // we render the older entries from the cache that had fewer points,
      // data they could previously see disappears.
      // To fix this, we enforce a consistent endTime, which gets updated
      // every time we poll for new data. This way queries dispatched later
      // are still constrained to the earlier time and will not return
      // additional newly ingested points.
      // Note this will still produce some small differences.
      // Because alignedWindows only returns points for whole power of 2 windows, at higher
      // pointWidths a larger alignedWindow may overflow our clampedEndTime and get cut off.
      // At lower point widths, there is more granularity and some of the time range
      // that was excluded at a higher res will now return points.
      const clampedEndTime = Math.min(this.maxTimestamp, endTime)

      return this.props.alignedWindows(
        uuid,
        startTime,
        clampedEndTime,
        resolution
      )
    }

    this.updateInitialChartData(props)
    this._isMounted = true
    this.createRateLimitedFuncs()
    const t = makeChartStateTrackers({ updateChart: props.updateChart })
    this.trackStartEndResolution = t.trackStartEndResolution
    this.trackChartSelection = t.trackChartSelection
    this.trackYScales = t.trackYScales

    if (props.monitorChanges) {
      this.startMonitoringChanges()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
    this.stopMonitoringChanges()
  }

  createRateLimitedFuncs = () => {
    this.fetchPlots_debounced = debounce(fetchPlots, FETCH_RATE)
    this.prefetchPlots_debounced = debounce(prefetchPlots, PREFETCH_RATE)
    this.refreshPlots_throttled = (
      query_rate = min([this.props.plots.length * QUERY_RATE, 2000])
    ) => throttle(this.refreshPlots, query_rate)
    this.setGlobalDefaultZoom_throttled = throttle(
      this.setGlobalDefaultZoom,
      1000,
      { leading: false }
    )
  }

  refreshPlots = () => {
    // Plots are queried using this.scaleXTime, which is updated too frequently
    // for us to store it with setState.
    // Thus, we manually call this when our cache is updated to query new data when needed.

    if (this._isMounted) this.forceUpdate()
  }

  startMonitoringChanges = () => {
    this.changesInterval = setInterval(() => {
      if (document.hidden) return // don't update when tab is hidden
      const scale = JSON.parse(this.prevScale).domain
      const minutes = (6e-10 * (scale[1] - scale[0])) / 60
      if (minutes > 10080) return // don't update when scale is above a week
      // update maxTimestamp allowed for all queries
      const now = Number(new Date().getTime() + '000000')
      this.lastUpdatedAt = this.maxTimestamp || now
      this.maxTimestamp = now
      // TODO: run changes query to retrieve actual changed ranges for each plot
      const changedRanges = [getDefaultChangedRange()]
      for (const cr of changedRanges) {
        if (cr[0] > this.lastUpdatedAt) {
          // It's been a long time since the chart was updated
          // extend the change range so we don't have stale data
          // may happen if a user sleeps their computer, etc.
          cr[0] = this.lastUpdatedAt
        }
      }
      const { plots } = this.props
      const lastIndex = changedRanges.length - 1
      const outOfBounds = scale[1] < changedRanges[lastIndex][1] // determine if plot end is beyond right edge of chart window
      for (const plot of plots) {
        // once implemented move changed ranges here so it can be per plot
        // const changedRanges = changes(plot, fromVersion);
        if (!outOfBounds) this.onPlotChange(plot, changedRanges)
      }

      // fetch
      const { scaleXTime } = this
      const callbacks = {
        fetchPoints: this.alignedWindowsCapped,
        onProgress: this.refreshPlots
      }
      this.fetchPlots_debounced(plots, scaleXTime, callbacks)
      this.prefetchPlots_debounced(plots, scaleXTime, callbacks)
      this.refreshPlots_throttled(this.queryRate())
    }, CHANGES_RATE)
  }

  onPlotChange = (plot, changedRanges) => {
    for (const range of changedRanges) {
      invalidateStreamCache(plot.uuid, range)
    }
  }

  stopMonitoringChanges = () => {
    clearInterval(this.changesInterval)
  }

  updateInitialChartData = props => {
    const { initialStartTime, initialEndTime, dimensions } = props
    const defaultScaleTime = getDefaultScaleTime(
      initialStartTime,
      initialEndTime,
      dimensions
    )
    this.scaleXTime = defaultScaleTime
    this.timeRange = props.initialSelectedTimeRange

    const { plots } = props
    const scaleXTime = defaultScaleTime
    fetchPlots(plots, scaleXTime, {
      fetchPoints: this.alignedWindowsCapped
    }).then(() => {
      this.refreshPlots()
    })
  }

  onScaleXUpdate = scaleXTime => {
    this.scaleXTime = scaleXTime

    const currScale = JSON.stringify({
      domain: scaleXTime.domain(),
      range: scaleXTime.range()
    })
    const scaleChanged = currScale !== this.prevScale
    this.prevScale = currScale
    if (!scaleChanged) return

    const { plots } = this.props

    // Perform a rate-limited fetch and prefetch of the current window.
    const callbacks = {
      fetchPoints: this.alignedWindowsCapped,
      onProgress: this.refreshPlots
    }
    this.fetchPlots_debounced(plots, scaleXTime, callbacks)
    this.prefetchPlots_debounced(plots, scaleXTime, callbacks)

    // Throttle the scale updates.
    this.refreshPlots_throttled(this.queryRate())

    this.setGlobalDefaultZoom_throttled()
  }

  setGlobalDefaultZoom = () => {
    this.props.setGlobalDefaultZoom(this.scaleXTime.domain())
  }

  render () {
    // Retrieve plots from cache
    const plots = queryPlots(this.props.plots, this.scaleXTime)
    return (
      <div
        style={{
          position: 'relative',
          backgroundColor: 'inherit',
          height: '100%'
        }}
      >
        <Chart
          plots={plots}
          axes={this.props.axes}
          initialxDomain={this.scaleXTime.domain()}
          initialSelectedTimeRange={this.timeRange || undefined}
          onScaleXUpdate={this.onScaleXUpdate}
          trackStartEndResolution={this.trackStartEndResolution}
          trackChartSelection={this.trackChartSelection}
          trackYScales={this.trackYScales}
          hideHints={this.props.hideHints}
          showLegend={this.props.showLegend}
          showTreeViz={this.props.showTreeViz}
          showDebugCache={this.props.showDebugCache}
          resetxDomain={this.props.resetxDomain}
          {...this.props.dimensions}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const instance = plotter.selectors.instance(state)
  const isPermalink = plotter.selectors.isPermalink(state)

  return {
    instance,
    isPermalink
  }
}

const mapDispatchToProps = {
  setIsPermalink: plotter.actions.setIsPermalink,
  updateChart: plotter.actions.updateChart,
  setGlobalDefaultZoom
}

const ChartStreamDataProviderWithApolloAndRedux = compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(ChartStreamDataProvider)

class ErrorBoundaryWrappedChart extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null
    }
  }

  componentDidCatch (error, errorInfo) {
    console.error(
      'This application error was caught by an error boundary:',
      error,
      errorInfo
    )
    // ChartStream is being replaced in https://github.com/PingThingsIO/react-pingthings-ui/pull/254
    // and we won't use this component anymore.
    notification.error({
      message: this.props.message || 'Application Error',
      description: 'There was an error with the chart',
      duration: 0
    })
    this.setState({ error, errorInfo })
  }

  render () {
    if (this.state.error !== null) {
      return (
        <div style={this.props.dimensions}>
          {ChartMessage(
            'The chart encountered an error and was removed.',
            '',
            false
          )}
        </div>
      )
    }
    return <ChartStreamDataProviderWithApolloAndRedux {...this.props} />
  }
}

export default ErrorBoundaryWrappedChart
