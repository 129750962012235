import React from 'react'

import 'antd/lib/tooltip/style/css'
import 'react-table-6/react-table.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTable from 'react-table-6'
import styled from 'styled-components'
import Tooltip from 'antd/lib/tooltip'

import { CustomReactTableStyles } from '../styled/styled'
import { inferAxisName } from '../VisualizePage/utils'

const ResetFilterStyles = styled.span`
  .icons {
    font-size: 14px;
  }

  &:hover {
    display: flex;
    color: black;
    cursor: pointer;
  }
  &.noStreams {
    color: #bbb;
  }
  color: #bbb;
`

export const AxisSelect = ({
  axisNames,
  changeStreamAxis,
  createNewAxisAndAddStream,
  metadata,
  plot
}) => {
  const originalName = inferAxisName(metadata)
  const axisHasBeenChanged = !!plot && plot.unit !== originalName

  return (
    <>
      {plot && (
        <>
          <select
            style={{ maxWidth: '100px' }}
            value={plot.unit}
            onChange={e => {
              if (e.target.value === 'Create new...') {
                createNewAxisAndAddStream(plot.uuid)
              } else {
                changeStreamAxis(plot.uuid, e.target.value)
              }
            }}
          >
            {axisNames.concat(['Create new...']).map(a => (
              <option key={a}>{a}</option>
            ))}
          </select>
          {axisHasBeenChanged && (
            <Tooltip title='Reset to original metadata' placement='right'>
              <ResetFilterStyles
                onClick={e => changeStreamAxis(plot.uuid, originalName)}
              >
                <FontAwesomeIcon style={{ marginLeft: '5px' }} icon='undo' />
              </ResetFilterStyles>
            </Tooltip>
          )}
        </>
      )}
    </>
  )
}

export const StreamShowHide = ({
  axisVisibility,
  plotVisibility,
  toggleAxisViz,
  toggleStreamViz
}) => {
  return (
    <>
      <label style={{ marginRight: '10px' }}>
        Stream:
        <input
          style={{ marginLeft: '3px' }}
          type='checkbox'
          checked={plotVisibility}
          onChange={toggleStreamViz}
        />
      </label>
      <label>
        Axis:
        <input
          style={{ marginLeft: '3px' }}
          type='checkbox'
          checked={axisVisibility}
          onChange={toggleAxisViz}
        />
      </label>
    </>
  )
}

class StreamListTable extends React.Component {
  render () {
    const {
      columns,
      constrainPageSizeOptions,
      data,
      filterAllMethod
    } = this.props

    return (
      <>
        <CustomReactTableStyles data-test-name='stream-lists-table'>
          <ReactTable
            className=''
            columns={columns}
            data={data}
            defaultFilterMethod={filterAllMethod}
            defaultPageSize={5}
            filterable={data.length > 2}
            pageSize={data.length < 6 ? data.length : undefined}
            pageSizeOptions={constrainPageSizeOptions(data.length)}
            showPagination={data.length > 5}
          />
        </CustomReactTableStyles>
      </>
    )
  }
}

export default StreamListTable
