import React from 'react'

import PropTypes from 'prop-types'

import { Input, Label } from './EditableLabelComponent'
import {
  Input as InputRedesign,
  Label as LabelRedesign
} from './EditableLabelComponent.redesign'
import { redesignEnabled } from '../../utils/featureFlags'

class EditableLabelContainer extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    commitEdit: PropTypes.func.isRequired,
    entity: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      editVisible: false,
      editing: false,
      value: props.value,
      originalValue: props.value
    }
  }

  static inputEl = null
  static getDerivedStateFromProps (props, prevState) {
    // You can obviate the need for this by making sure the component
    // is always used with a 'key'. Unfortunately React does not let you require key as a prop
    // This is computationally more expensive, but accomplishes the same thing.
    if (props.originalValue !== props.value) {
      return { originalValue: props.value }
    }
  }

  componentWillUnmount () {
    // shouldn't be necessary but just in case something forces an unmount
    document.removeEventListener('click', this.toggleEditMode)
  }

  onMouseEnter = () => {
    this.setState({ editVisible: true })
  }

  onMouseLeave = () => {
    this.setState({ editVisible: false })
  }

  cancelOnClickOutside = e => {
    if (e.target === this.inputEl) return
    this.toggleEditMode()
  }

  toggleEditMode = reason => {
    const { editing } = this.state
    if (editing) {
      // if about to set editing to false clean up events
      document.removeEventListener('click', this.cancelOnClickOutside)
      // commit the changes
      if (reason === 'commit') {
        this.props.commitEdit(
          this.state.originalValue,
          this.state.value,
          this.props.entity
        )
        return this.setState({ editing: !editing, editVisible: false })
      }
      // cancel the changes
      this.setState({
        editing: !editing,
        editVisible: false,
        value: this.state.originalValue
      })
    } else {
      // if about to set editing to true, add events to provide better UI intxns
      document.addEventListener('click', this.cancelOnClickOutside)
      this.setState({
        editing: !editing,
        originalValue: this.state.value
      })
    }
  }

  editLabel = event => {
    this.setState({ value: event.target.value })
  }

  render () {
    const { editVisible, editing, value } = this.state
    const InputComponent = redesignEnabled() ? InputRedesign : Input
    const LabelComponent = redesignEnabled() ? LabelRedesign : Label

    if (editing) {
      return (
        <InputComponent
          customRefFn={node => (this.inputEl = node)}
          onChange={this.editLabel}
          onKeyDownCapture={e => {
            e.key === 'Escape' && this.toggleEditMode('cancel')
            e.key === 'Enter' && this.toggleEditMode('commit')
          }}
          value={value}
        />
      )
    }
    return (
      <LabelComponent
        editVisible={editVisible}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        toggleEditMode={this.toggleEditMode}
        value={this.props.value}
      />
    )
  }
}
export default EditableLabelContainer
