import React, { useContext, useReducer, useEffect } from 'react'

import 'antd/lib/icon/style/css'
import { Icon as FeComponentsIcon } from 'frontend-components'
import { useDispatch, useSelector } from 'react-redux'
import Icon from 'antd/lib/icon'

import { IconButton } from './ResetXScale'
import { InvisibleHoverButton } from '../../Buttons/Buttons'
import { redesignEnabled } from '../../../utils/featureFlags'
import plotter from '../../../ducks/plotter'

export const StreamFocusContext = React.createContext()
export const STREAM_FOCUS_SET = 'STREAM_FOCUS_SET'
export const STREAM_FOCUS_PREVIEW_ACTIVATED = 'STREAM_FOCUS_PREVIEW_ACTIVATED'
export const STREAM_FOCUS_PREVIEW_DEACTIVATED =
  'STREAM_FOCUS_PREVIEW_DEACTIVATED'
const VALID_EVENTS = [
  STREAM_FOCUS_SET,
  STREAM_FOCUS_PREVIEW_ACTIVATED,
  STREAM_FOCUS_PREVIEW_DEACTIVATED
]
const VALID_STATES = {
  noneSelected: 'noneSelected',
  previewing: 'previewing',
  selectionExists: 'selectionExists'
}

function handleNoneSelected (state, action) {
  switch (action.type) {
    case STREAM_FOCUS_PREVIEW_ACTIVATED:
      return {
        activeState: VALID_STATES.previewing,
        focusStream: action.payload.uuid
      }
    case STREAM_FOCUS_SET:
      return {
        activeState: VALID_STATES.selectionExists,
        focusStream: action.payload.uuid
      }
    default:
      return state
  }
}

function handleSelectionExists (state, action) {
  switch (action.type) {
    case STREAM_FOCUS_SET:
      if (state.focusStream === action.payload.uuid) {
        // deselect the already selected stream
        return {
          activeState: VALID_STATES.noneSelected,
          focusStream: undefined
        }
      } else {
        return {
          activeState: VALID_STATES.selectionExists,
          focusStream: action.payload.uuid
        }
      }
    default:
      return state
  }
}

function handlePreviewing (state, action) {
  switch (action.type) {
    case STREAM_FOCUS_SET:
      return {
        activeState: VALID_STATES.selectionExists,
        focusStream: action.payload.uuid
      }
    case STREAM_FOCUS_PREVIEW_ACTIVATED:
      return {
        activeState: VALID_STATES.previewing,
        focusStream: action.payload.uuid
      }
    case STREAM_FOCUS_PREVIEW_DEACTIVATED:
      return {
        activeState: VALID_STATES.noneSelected,
        focusStream: undefined
      }
    default:
      return state
  }
}

function focusReducer (state, action) {
  const { activeState } = state

  switch (activeState) {
    case VALID_STATES.noneSelected:
      return handleNoneSelected(state, action)
    case VALID_STATES.previewing:
      return handlePreviewing(state, action)
    case VALID_STATES.selectionExists:
      return handleSelectionExists(state, action)
    default:
      throw new Error(
        'Focus Reducer encountered an unexpected state:' + activeState
      )
  }
}

const defaultState = { activeState: 'noneSelected' }

export function useStreamFocusReducer ({
  reducer = focusReducer,
  initialState = defaultState
}) {
  const streamFocus = useReducer(reducer, initialState)
  return streamFocus
}

export function StreamFocus (row) {
  const [focusState, dispatchToFocus] = useContext(StreamFocusContext)
  const plot = row.original.plot
  const uuid = plot ? plot.uuid : null
  const hasEmphasis = plot ? plot.emphasis : false

  const getClassName = () =>
    'fullWidthHeight ' +
    (hasEmphasis && focusState.activeState === VALID_STATES.selectionExists
      ? 'active'
      : '')

  if (redesignEnabled()) {
    return (
      <IconButton
        className={getClassName()}
        color={plot ? plot.color : null}
        onMouseEnter={() =>
          dispatchToFocus({
            type: STREAM_FOCUS_PREVIEW_ACTIVATED,
            payload: { uuid }
          })
        }
        onMouseLeave={() =>
          dispatchToFocus({
            type: STREAM_FOCUS_PREVIEW_DEACTIVATED
          })
        }
        onClick={() => {
          dispatchToFocus({ type: STREAM_FOCUS_SET, payload: { uuid } })
        }}
      >
        <FeComponentsIcon
          height={16}
          name='eyeBall'
          viewBox='0 0 23 16'
          width={23}
        />
      </IconButton>
    )
  } else {
    return (
      <>
        {plot && (
          <InvisibleHoverButton
            className={getClassName()}
            onMouseEnter={() =>
              dispatchToFocus({
                type: STREAM_FOCUS_PREVIEW_ACTIVATED,
                payload: { uuid }
              })
            }
            onMouseLeave={() =>
              dispatchToFocus({
                type: STREAM_FOCUS_PREVIEW_DEACTIVATED
              })
            }
            onClick={() => {
              dispatchToFocus({ type: STREAM_FOCUS_SET, payload: { uuid } })
            }}
          >
            <Icon style={{ fontSize: '20px' }} type='eye' />
          </InvisibleHoverButton>
        )}
      </>
    )
  }
}

/**
 *
 * @param {*} streamFocusState
 * Focus state is managed by the Finite State Machine encapsulated in the FocusContext.
 * Meanwhile, we are also tracking which stream has emphasis separately in the state
 * that exists within redux. This function is responsible for keeping those two
 * things in sync with each other. This setup allows us to have more complicated logic
 * within the FSM and then just write out a simple boolean flag to the global app state
 */
export function useReconcileStreamFocusStateEffect ({ streamFocusState }) {
  const dispatch = useDispatch()
  const currentStreamWithFocus = useSelector(plotter.selectors.emphasizedStream)
  const FSMFocusStream = streamFocusState.focusStream

  useEffect(() => {
    if (FSMFocusStream === currentStreamWithFocus) return

    const streamEmphasisUpdates = []

    if (currentStreamWithFocus) {
      streamEmphasisUpdates.push({
        emphasis: undefined,
        uuid: currentStreamWithFocus
      })
    }

    if (FSMFocusStream) {
      streamEmphasisUpdates.push({ emphasis: true, uuid: FSMFocusStream })
    }

    if (streamEmphasisUpdates.length > 0) {
      dispatch(
        plotter.actions.setStreamsEmphasis({ updates: streamEmphasisUpdates })
      )
    }
  }, [currentStreamWithFocus, dispatch, FSMFocusStream])
}

/**
 *
 * @param {*} event a VALID_EVENT from StreamFocus
 * @param {*} uuid  the uuid of the plot you're focusing
 */
export function emitStreamFocusEvent (event, uuid) {
  if (VALID_EVENTS.indexOf(event) === -1) {
    throw new Error('You emitted an unrecognized stream focus event: ' + event)
  }
  document.dispatchEvent(
    new CustomEvent('StreamFocus', {
      detail: { type: event, payload: { uuid } }
    })
  )
}
export function useListenForStreamFocusEvents (dispatchFn) {
  useEffect(() => {
    const handleEvent = e => {
      dispatchFn(e.detail)
    }

    document.addEventListener('StreamFocus', handleEvent)
    return () => document.removeEventListener('StreamFocus', handleEvent)
    // eslint-disable-next-line
  }, [])
}
