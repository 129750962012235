export const QUERY_WINDOWS = 'heatmap/QUERY_WINDOWS'

export const QUERY_WINDOWS_ERROR = 'heatmap/QUERY_WINDOWS_ERROR'

export const QUERY_WINDOWS_SUCCESS = 'heatmap/QUERY_WINDOWS_SUCCESS'

export const QUERY_START_END = 'heatmap/QUERY_START_END'

export const QUERY_START_END_ERROR = 'heatmap/QUERY_START_END_ERROR'

export const QUERY_START_END_SUCCESS = 'heatmap/QUERY_START_END_SUCCESS'
