import * as d3scale from 'd3-scale'
import * as d3array from 'd3-array'
import { scaleTimeNano } from './scaleTimeNano'
import { splitArray } from '../utils/array'

// ----------------------------------------------------------------------------
// Domain rounding to nice intervals
// ----------------------------------------------------------------------------

// When speciying a domain for an extent, make sure it starts and ends on nice
// round values.
export function niceLinearDomain (extent) {
  return d3scale
    .scaleLinear()
    .domain(extent) // set domain
    .nice() // update domain to round values
    .domain() // get result domain
}
export function niceTimeDomain (extent) {
  return scaleTimeNano()
    .domain(extent) // set domain
    .nice() // update domain to round values
    .domain() // get result domain
}

// ----------------------------------------------------------------------------
// Point Density
// ----------------------------------------------------------------------------

export function getMaxPlotDensity (plot, scaleX) {
  let max = 0
  for (const tile of plot.tiles) {
    const pixelW = scaleX(2 ** tile.pointRes) - scaleX(0)
    for (const point of tile.points) {
      const density = point.count / pixelW // points per pixel
      max = Math.max(density, max)
    }
  }
  return max
}

export function getAvgPlotDensity (plot, scaleX) {
  const points = getAllPlotPoints(plot)
  const numPoints = points.reduce((acc, point) => acc + point.count, 0)
  const range = scaleX.range()
  const numPixels = range[1] - range[0]
  return numPoints / numPixels
}

// ----------------------------------------------------------------------
// Get meta shapes from points in a tile
// ----------------------------------------------------------------------

export function getShapes (tile, scaleX) {
  const { allPoints, points, pointRes } = tile
  return {
    lines: getLines(allPoints),
    dots: getDots(points),
    ribbons: getRibbons(allPoints),
    densityHistogram: getDensityHistogram(points, pointRes, scaleX)
  }
}

function contiguousGroups (points) {
  return splitArray(points, (curr, prev) => curr.i !== prev.i + 1)
}

export function getLines (points) {
  const lines = contiguousGroups(points)
  const isRawPoint = line => line.length === 1 && line[0].count === 1
  return lines.filter(line => !isRawPoint(line))
}

export function getDots (points) {
  return points.filter(p => p.count === 1)
}

export function getRibbons (points) {
  const statPoints = points.filter(p => p.count > 1)
  return contiguousGroups(statPoints)
}

function getDensityHistogram (points, pointRes, scaleX) {
  const chunks = contiguousGroups(points)
  const zero = time => ({ time, count: 0 })
  const result = []
  const startTime = scaleX.domain()[0]
  let endTime
  result.push(zero(startTime))
  for (const chunk of chunks) {
    for (const p of chunk) result.push(p)
    endTime = chunk.slice(-1)[0].time + 2 ** pointRes
    result.push(zero(endTime))
  }
  endTime = Math.max(endTime, scaleX.domain()[1])
  result.push(zero(endTime))
  return result
}

// ----------------------------------------------------------------------------
// Derivative Properties
// ----------------------------------------------------------------------------

export function getAllPlotUnits (plots) {
  if (!plots) return []
  return [...new Set(plots.map(p => p.unit))]
}

export function tileHasData (tile) {
  if (!tile) return false
  if (!tile.points) return false
  if (!tile.points.length) return false
  return true
}

export function getAllPlotPoints (plot) {
  if (!plot) return []
  if (!plot.tiles) return []
  const tiles = plot.tiles.filter(tileHasData)
  return [].concat(...tiles.map(tile => tile.points))
}

export function plotHasData (plot) {
  if (!plot) return false
  if (!plot.tiles) return false
  return plot.tiles.some(tileHasData)
}

export function plotsHaveData (plots) {
  if (!plots) return false
  return plots.some(plotHasData)
}

export function getDefaultTimeDomain (plots) {
  if (!plots) return null
  plots = plots.filter(plotHasData)
  if (!plots.length) return null

  const t0 = d3array.min(
    plots,
    ({ tiles }) => tiles.filter(tileHasData)[0].points[0].time
  )
  const t1 = d3array.max(
    plots,
    ({ tiles }) =>
      tiles
        .filter(tileHasData)
        .slice(-1)[0]
        .points.slice(-1)[0].time
  )

  // no longer using nice because it creates a subtle zoom out whenever this fn
  // is run multiple times. This means we may fetch data twice, or permalinks may shift
  // to a slightly wider x range than expected
  // return niceTimeDomain([t0, t1]);
  return scaleTimeNano().domain([t0, t1]) // set domain
}

export function getDefaultYDomain (plots, unit) {
  if (!plots) return null
  plots = plots.filter(p => p.unit === unit && plotHasData(p))
  if (!plots.length) return null

  const plotRanges = plots.map(plot => {
    const points = getAllPlotPoints(plot)
    return {
      min: d3array.min(points, d => d.min),
      max: d3array.max(points, d => d.max)
    }
  })
  let min = d3array.min(plotRanges, d => d.min)
  let max = d3array.max(plotRanges, d => d.max)
  if (min === max) {
    min = Math.min(0, min * 2)
    max = Math.max(0, max * 2)
  } else {
    // most cases this shouldn't make a difference because we are calling .nice()
    // but it ensures points aren't invisible if they fall perfectly on a nice round
    // number and blend into the axis itself
    min = min - Math.abs(min * 0.00005)
    max = max + Math.abs(max * 0.00005)
  }
  return niceLinearDomain([min, max])
}
