import React from 'react'

import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import ReactTable from 'react-table-6'

import { constrainPageSizeOptions } from '../../utils/utils'
import {
  CustomReactTableStyles as SelectedStreamsTable,
  HighlightHint,
  NoStreamsSelected
} from '../styled/styled'
import plotter from '../../ducks/plotter'
import streams from '../../ducks/streams'

const NoDataComponent = () => (
  <NoStreamsSelected>
    Selected streams appear in this table. Add streams from the table below that
    you would like to visualize in the next step.
  </NoStreamsSelected>
)

const TimesCircle = ({ onClick }) => {
  return (
    <HighlightHint className='times-circle' onClick={onClick}>
      <FontAwesomeIcon icon='times-circle' />
    </HighlightHint>
  )
}

class SelectedStreams extends React.Component {
  static propTypes = {
    selectedRows: PropTypes.object.isRequired,
    selectedColumns: PropTypes.array.isRequired
  }

  getTrProps = (state, rowInfo, colInfo, instance) => {
    const { setStreamTableHoveredRow, streamTableHoveredRow } = this.props

    const handlers = {
      onClick: (e, handleOriginal) => {
        e.stopPropagation()

        if (!rowInfo) return
        if (streamTableHoveredRow.uuid !== rowInfo.original.uuid) {
          setStreamTableHoveredRow({ hoveredRow: rowInfo.original })
        }

        // By default a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.
        if (handleOriginal) {
          handleOriginal()
        }
      }
    }
    return { ...handlers }
  }

  render () {
    const { selectedRows } = this.props

    const columns = [
      ...this.props.selectedColumns,
      {
        Header: 'ACTION',
        headerClassName: 'rt-custom-header',
        accessor: 'none',
        id: 'action-hint',
        minWidth: 80,
        maxWidth: 80,
        resizable: false,
        filterable: false,
        className: 'rt-td-pt-button',
        Cell: cellProps => {
          /** "X" button for when you remove  stream from the "Selected Streams" list */
          const TRClickHandler = e => {
            e.preventDefault()
            e.stopPropagation()

            const metadata = cellProps.row?._original
            if (!metadata) return

            const { toggleStream } = this.props
            toggleStream({
              metadata,
              uuid: metadata.uuid
            })
          }
          return (
            <div onClick={TRClickHandler} className='rt-td-pt-button-inner'>
              <TimesCircle />
            </div>
          )
        }
      }
    ]

    const data = Object.keys(selectedRows).map(
      uuid => selectedRows[uuid] && selectedRows[uuid].metadata
    )

    return (
      <SelectedStreamsTable data-test-name='selected-streams-table'>
        <ReactTable
          className='-striped -highlight'
          columns={columns}
          data={data}
          defaultPageSize={5}
          getTrProps={this.getTrProps}
          NoDataComponent={() => <NoDataComponent />}
          pageSize={data.length <= 5 ? 5 : undefined}
          pageSizeOptions={constrainPageSizeOptions(data.length)}
          rowHandlers={this.props.rowHandlers}
          showPagination={data.length > 5}
        />
      </SelectedStreamsTable>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectedColumns = streams.selectors.streamTableSelectedColumns(state)
  const selectedRows = plotter.selectors.selectedRows(state)
  const streamTableHoveredRow = streams.selectors.streamTableHoveredRow(state)

  return {
    selectedColumns,
    selectedRows,
    streamTableHoveredRow
  }
}

const mapDispatchToProps = {
  toggleStream: plotter.actions.toggleStream,
  setStreamTableHoveredRow: streams.actions.setStreamTableHoveredRow
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedStreams)
