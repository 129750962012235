import lodashGet from 'lodash.get'

import { nineDotsEnabled } from '../utils/featureFlags'
import cards from './portalConfig'

const nineDotsConfig = nineDotsEnabled()

const portalCard = {
  title: 'Portal',
  url: lodashGet(nineDotsConfig, 'portal.url')
}

const nineDotsCards = [...cards]

if (portalCard.url) {
  nineDotsCards.push(portalCard)
}

export default nineDotsCards
