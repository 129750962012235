const environment =
  process.env.NODE_ENV === 'production' ? window.__env__ : process.env

export const redesignEnabled = () => {
  if (window.localStorage.getItem('redesignEnabled') === 'true') {
    return window.localStorage.getItem('redesignEnabled')
  } else if (
    typeof window.redesignEnabled === 'undefined' &&
    window.localStorage.getItem('redesignEnabled') !== 'false'
  ) {
    return environment.REACT_APP_REDESIGN_ENABLED === 'true'
  } else {
    return window.redesignEnabled
  }
}

const exportCSVButtonEnabled = () =>
  environment.REACT_APP_EXPORT_CSV_BUTTON_ENABLED !== 'false'

const grouperEnabled = () => environment.REACT_APP_GROUPER_ENABLED === 'true'

const heatmapEnabled = () => environment.REACT_APP_HEATMAP_ENABLED === 'true'

const mapEnabled = () =>
  environment.REACT_APP_GOOGLE_KEY !== undefined &&
  environment.REACT_APP_MAP_ENABLED === 'true'

const ni4aiLinksEnabled = () =>
  environment.REACT_APP_PORTAL_NI4AI_LINKS_ENABLED === 'true'

const nineDotsEnabled = () => {
  const enabled = environment.REACT_APP_NINE_DOTS_ENABLED === 'true'

  if (!enabled) {
    return false
  }

  return {
    portal: {
      url: environment.REACT_APP_PORTAL_URL
    }
  }
}

const portalEnabled = () => {
  const enabled = environment.REACT_APP_PORTAL_ENABLED === 'true'

  if (!enabled) {
    return false
  }

  return {
    plotter: {
      url: environment.REACT_APP_PORTAL_PLOTTER_URL
    },
    manager: {
      url: environment.REACT_APP_PORTAL_MANAGER_URL
    },
    blog: {
      url: environment.REACT_APP_PORTAL_BLOG_URL
    },
    jupyterhub: {
      url: environment.REACT_APP_PORTAL_JUPYTERHUB_URL
    }
  }
}

const toggleSwitchEnabled = () => {
  return environment.REACT_APP_TOGGLE_SWITCH_ENABLED === 'true'
}

export {
  exportCSVButtonEnabled,
  grouperEnabled,
  heatmapEnabled,
  mapEnabled,
  ni4aiLinksEnabled,
  nineDotsEnabled,
  portalEnabled,
  toggleSwitchEnabled
}
