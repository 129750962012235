import lodashIsPlainObject from 'lodash.isplainobject'

import { report as reportSentry } from '../../utils/sentry'
import * as actionTypes from './actionTypes'

const initialState = {
  instance: {
    axes: {},
    chart: {},
    isPermalink: false,
    legend: {
      visible: true
    },
    streams: {}
  },
  settings: { isUTC: true }
}

export const generateNewsAxisName = ({ axes, stream, uuid }) => {
  let newAxisName

  if (stream && uuid) {
    try {
      newAxisName = stream.metadata.tags.name || stream.metadata.collection
    } catch (error) {
      newAxisName = uuid.slice(0, 5)
    }
  }

  if (
    !newAxisName ||
    (lodashIsPlainObject(axes) && axes[newAxisName] !== undefined)
  ) {
    let newAxisNumber = 0
    while (lodashIsPlainObject(axes) && axes['y' + newAxisNumber]) {
      newAxisNumber += 1
    }
    newAxisName = 'y' + newAxisNumber
  }

  return newAxisName
}

export default function plotter (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_STREAM_AXIS: {
      const { uuid, value } = action
      const axes = { ...state.instance.axes }
      const streams = { ...state.instance.streams }

      const stream = streams[uuid]

      let prevUnit

      try {
        prevUnit = stream.plot.unit
        stream.plot.unit = value

        axes[prevUnit].streams = axes[prevUnit].streams.filter(
          axisUuid => uuid !== axisUuid
        )
      } catch (e) {
        console.error(e.message)
        reportSentry(e, {
          level: 'error',
          tags: {
            alertName: 'plotter.reducer'
          },
          extraScope: { action }
        })
        return { ...state }
      }

      if (axes[value]) {
        axes[value].streams.push(uuid)
      } else {
        axes[value] = { side: 'left', streams: [uuid], unit: value }
      }

      return {
        ...state,
        instance: {
          ...state.instance,
          axes,
          streams
        }
      }
    }
    case actionTypes.CREATE_NEW_AXIS: {
      const axes = { ...state.instance.axes }

      const newAxisName = generateNewsAxisName({ axes })

      axes[newAxisName] = { side: 'left', unit: newAxisName, streams: [] }

      return {
        ...state,
        instance: {
          ...state.instance,
          axes
        }
      }
    }
    case actionTypes.CREATE_NEW_AXIS_AND_ADD_STREAM: {
      const { uuid } = action
      const axes = { ...state.instance.axes }
      const streams = { ...state.instance.streams }

      const stream = streams[uuid]

      try {
        const prevUnit = stream.plot.unit
        axes[prevUnit].streams = axes[prevUnit].streams.filter(
          axisUuid => uuid !== axisUuid
        )
      } catch (e) {
        console.error(e.message)
        reportSentry(e, {
          level: 'error',
          tags: {
            alertName: 'plotter.reducer'
          },
          extraScope: { action }
        })
        return { ...state }
      }

      const newAxisName = generateNewsAxisName({ axes, stream, uuid })

      axes[newAxisName] = { side: 'left', unit: newAxisName, streams: [uuid] }
      stream.plot.unit = newAxisName

      return {
        ...state,
        instance: {
          ...state.instance,
          axes,
          streams
        }
      }
    }
    case actionTypes.REMOVE_AXIS: {
      const { name } = action
      const axes = { ...state.instance.axes }

      delete axes[name]

      return {
        ...state,
        instance: {
          ...state.instance,
          axes
        }
      }
    }
    case actionTypes.SET_IS_PERMALINK: {
      return {
        ...state,
        instance: {
          ...state.instance,
          isPermalink: action.setting
        }
      }
    }
    case actionTypes.SET_LEGEND_NAME: {
      const { name, uuid } = action
      const streams = { ...state.instance.streams }

      try {
        streams[uuid].plot.legendName = name
      } catch (e) {
        console.error(e.message)
        reportSentry(e, {
          level: 'error',
          tags: {
            alertName: 'plotter.reducer'
          },
          extraScope: { action }
        })
      }

      return {
        ...state,
        instance: {
          ...state.instance,
          streams
        }
      }
    }
    case actionTypes.SET_STREAM_COLOR: {
      const { color, uuid } = action

      const streams = { ...state.instance.streams }

      try {
        streams[uuid].plot.color = color
      } catch (e) {
        console.error(e.message)
        reportSentry(e, {
          level: 'error',
          tags: {
            alertName: 'plotter.reducer'
          },
          extraScope: { action }
        })
      }

      return {
        ...state,
        instance: {
          ...state.instance,
          streams
        }
      }
    }
    case actionTypes.SET_STREAMS_EMPHASIS: {
      const { updates } = action
      const streams = { ...state.instance.streams }

      for (const update of updates) {
        const { emphasis, uuid } = update

        try {
          streams[uuid].plot.emphasis = emphasis
        } catch (e) {
          console.error(e.message)
          reportSentry(e, {
            level: 'error',
            tags: {
              alertName: 'plotter.reducer'
            },
            extraScope: { action }
          })
        }
      }

      return {
        ...state,
        instance: {
          ...state.instance,
          streams
        }
      }
    }
    case actionTypes.TOGGLE_AXIS_STREAM_VISIBILITY: {
      const { uuid } = action
      const axes = { ...state.instance.axes }
      const streams = { ...state.instance.streams }

      try {
        const plotUnit = streams[uuid].plot.unit
        const axis = axes[plotUnit]
        const axisVisibility = !!axis.streams.find(
          uuid => streams[uuid].plot.visibility === true
        )
        axis.streams.forEach(
          uuid => (streams[uuid].plot.visibility = !axisVisibility)
        )
      } catch (e) {
        console.error(e.message)
        reportSentry(e, {
          level: 'error',
          tags: {
            alertName: 'plotter.reducer'
          },
          extraScope: { action }
        })
        return { ...state }
      }

      return {
        ...state,
        instance: {
          ...state.instance,
          axes,
          streams
        }
      }
    }
    case actionTypes.TOGGLE_LEGEND: {
      const legend = { ...state.instance.legend }
      legend.visible = !legend.visible

      return {
        ...state,
        instance: {
          ...state.instance,
          legend
        }
      }
    }
    case actionTypes.TOGGLE_STREAM: {
      const shouldRemoveStream = !!state.instance.streams[action.uuid]
      if (shouldRemoveStream) {
        const streams = { ...state.instance.streams }
        delete streams[action.uuid]

        return {
          ...state,
          instance: {
            ...state.instance,
            streams: {
              ...streams
            }
          }
        }
      } else {
        return {
          ...state,
          instance: {
            ...state.instance,
            streams: {
              ...state.instance.streams,
              [action.uuid]: { metadata: action.metadata }
            }
          }
        }
      }
    }
    case actionTypes.TOGGLE_STREAM_VISIBILITY: {
      const { uuid } = action
      const streams = { ...state.instance.streams }

      try {
        streams[uuid].plot.visibility = !streams[uuid].plot.visibility
        // if you're setting visibility to true you may filter out the stream
        // from the current view and prevent the mouseLeave event from ever turning
        // off emphasis. And if you turn viz off, you probably don't want emphasis either.
        streams[uuid].plot.emphasis = false
      } catch (e) {
        console.error(e.message)
        reportSentry(e, {
          level: 'error',
          tags: {
            alertName: 'plotter.reducer'
          },
          extraScope: { action }
        })
        return { ...state }
      }

      return {
        ...state,
        instance: {
          ...state.instance,
          streams
        }
      }
    }
    case actionTypes.UPDATE_AXIS: {
      const { name, values } = action
      const axes = { ...state.instance.axes }

      try {
        const axis = axes[name]

        for (const key in values) {
          axis[key] = values[key]
        }
      } catch (e) {
        console.error(e.message)
        reportSentry(e, {
          level: 'error',
          tags: {
            alertName: 'plotter.reducer'
          },
          extraScope: { action }
        })
        return { ...state }
      }

      return {
        ...state,
        instance: {
          ...state.instance,
          axes
        }
      }
    }
    case actionTypes.UPDATE_AXIS_NAME: {
      const { name, prevName } = action
      const axes = { ...state.instance.axes }
      const streams = { ...state.instance.streams }

      try {
        const axis = axes[prevName]
        axis.streams.forEach(uuid => (streams[uuid].plot.unit = name))
        axis.unit = name
        axes[name] = axis
      } catch (e) {
        console.error(e.message)
        reportSentry(e, {
          level: 'error',
          tags: {
            alertName: 'plotter.reducer'
          },
          extraScope: { action }
        })
        return { ...state }
      }

      delete axes[prevName]

      return {
        ...state,
        instance: {
          ...state.instance,
          axes,
          streams
        }
      }
    }
    case actionTypes.UPDATE_CHART: {
      return {
        ...state,
        instance: {
          ...state.instance,
          chart: {
            ...state.instance.chart,
            ...action.data
          }
        }
      }
    }
    case actionTypes.UPDATE_INSTANCE: {
      return {
        ...state,
        instance: {
          ...state.instance,
          ...action.data
        }
      }
    }
    case actionTypes.UPDATE_SETTING: {
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.setting]: action.value
        }
      }
    }
    default:
      return state
  }
}
