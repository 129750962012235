import React from 'react'

import { Helmet } from 'react-helmet'

export default function Bootstrap () {
  return (
    <Helmet>
      <link
        href={process.env.PUBLIC_URL + '/bootstrap.min.css'}
        rel='stylesheet'
        integrity='sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO'
        crossorigin='anonymous'
      />
    </Helmet>
  )
}
