import * as Sentry from '@sentry/browser'
import { ExtraErrorData } from '@sentry/integrations'
import { detectAllocation } from './detectAllocation'
import { getVersionInfo } from './versionInfo'
import { USERNAME_KEY } from '../constants'

// DSN should be in following format 'https://<key>sentry.io/<project>'
const DSN_REGEX = /https:\/\/.{1,}sentry.io\/.{1,}/

export function getRelease () {
  const { version, sha } =
    getVersionInfo(window.__env__, process.env.NODE_ENV) || {}

  return `plotter@${version || 'unknown'}/${sha || 'unknown'}`
}

export function init (dsn) {
  if (!dsn) return console.error('No Sentry DSN provided. Sentry not started')
  if (!DSN_REGEX.test(dsn)) return console.error('Invalid Sentry DSN format!')

  const release = getRelease()

  console.log(`Sentry reporting for release: ${release}`)

  Sentry.init({
    dsn,
    release,
    beforeSend: beforeSendHandler
  })

  Sentry.configureScope(scope => {
    const tags = {
      allocation: detectAllocation(),
      integrations: [new ExtraErrorData({ depth: 10 })],
      component: 'plotter'
    }

    for (const t in tags) {
      if (!tags[t]) continue
      scope.setTag(t, tags[t])
    }
  })

  function beforeSendHandler (event) {
    const username = localStorage.getItem(USERNAME_KEY) || 'public'
    event.user = { ...event.user, username }
    return event
  }
}

export function report (error, options = {}) {
  const { level } = options
  const tags = options.tags || {}
  const extraScope = options.extraScope || {}

  Sentry.withScope(scope => {
    for (const tag in options.tags) {
      scope.setTag(tag, tags[tag])
    }

    for (const key in extraScope) {
      scope.setExtra(key, JSON.stringify(extraScope[key]))
    }

    if (level) {
      scope.setLevel(level)
    }

    if (!(error instanceof Error)) {
      console.warn({
        message:
          'Sentry: Pass actual Error objects to report() for better stack traces',
        error
      })

      const actualError = new Error()
      actualError.name = error.toString()
      error = actualError
    }

    Sentry.captureException(error)
  })

  console.log(`Sentry: ${error.name}`, error, extraScope)
}
