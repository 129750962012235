import lodashGet from 'lodash.get'

import { ni4aiLinksEnabled, portalEnabled } from '../utils/featureFlags'

const portalConfig = portalEnabled()

export const getCardUrl = (config, path) => {
  const url = lodashGet(config, path)

  if (url === 'false') {
    return false
  }

  return url
}

const plotterCard = {
  subtitle:
    'PingThings data visualization tool for seeing and analyzing data streams.',
  title: 'Plotter',
  url: getCardUrl(portalConfig, 'plotter.url')
}

plotterCard.color = 'blue'
plotterCard.image = 'plotter'

const managerCard = {
  subtitle:
    'Data management product for controlling users, groups, events, and usage.',
  title: 'Manager',
  url: getCardUrl(portalConfig, 'manager.url')
}

managerCard.color = 'green'
managerCard.image = 'manager'

const blogCard = {
  subtitle: 'News and information about sensor data research and analytics.',
  title: 'NI4AI Blog',
  url: getCardUrl(portalConfig, 'blog.url')
}

blogCard.color = 'red'
blogCard.image = 'blog'

const jupyterhubCard = {
  subtitle: 'Perform data analytics in the browser against the BTrDB database.',
  title: 'Jupyterhub',
  url: getCardUrl(portalConfig, 'jupyterhub.url')
}

jupyterhubCard.color = 'yellow'
jupyterhubCard.image = 'jupyterHub'

const cards = []

if (plotterCard.url) {
  cards.push(plotterCard)
}

if (managerCard.url) {
  cards.push(managerCard)
}

if (blogCard.url) {
  cards.push(blogCard)
}

if (jupyterhubCard.url) {
  cards.push(jupyterhubCard)
}

// ni4ai specific cards
if (ni4aiLinksEnabled()) {
  const datasetsCard = {
    color: 'blue3',
    iconSize: 38,
    iconViewbox: '128 0 1536 1792',
    image: 'database',
    subtitle: 'Find out more about open access data sets hosted in NI4AI.',
    title: 'Data sets',
    url: 'https://blog.ni4ai.org/post/2100-01-01-datasets'
  }

  cards.push(datasetsCard)

  const btrdbTutorialsCard = {
    color: 'green3',
    iconSize: 38,
    iconViewbox: '64 0 1664 1792',
    image: 'tutorial',
    subtitle: 'Learn how to get started using the BTrDB API.',
    title: 'API tutorials',
    url: 'https://github.com/PingThingsIO/ni4ai-notebooks/tree/main/tutorials'
  }

  cards.push(btrdbTutorialsCard)

  const githubCard = {
    color: 'red3',
    iconSize: 38,
    iconViewbox: '128 128 1536 1498',
    image: 'github',
    subtitle: 'Explore open source code other NI4AI users have built.',
    title: 'Github repo',
    url: 'https://github.com/PingThingsIO/ni4ai-notebooks'
  }

  cards.push(githubCard)

  const apidocsCard = {
    color: 'yellow3',
    iconSize: 38,
    iconViewbox: '42 128 1964 1395',
    image: 'wifi',
    subtitle: 'Access data programmatically using our Python library.',
    title: 'API docs',
    url: 'https://btrdb.readthedocs.io'
  }

  cards.push(apidocsCard)

  const workshopVideosCard = {
    color: 'blue1',
    iconSize: 38,
    iconViewbox: '0 256 1792 1280',
    image: 'video',
    subtitle: 'Watch recordings of past workshops and webinars.',
    title: 'Videos',
    url:
      'https://www.youtube.com/playlist?list=PLqSksWmTnkQ64OW0zreAvMIYefARkfrdT'
  }

  cards.push(workshopVideosCard)

  const apikeyCard = {
    color: 'green1',
    iconSize: 38,
    iconViewbox: '0 128 1683 1592',
    image: 'key',
    subtitle: 'Get your unique key to query the data using our API.',
    title: 'API key',
    url: 'https://ni4ai.org/profile'
  }

  cards.push(apikeyCard)

  const eventsCard = {
    color: 'red1',
    iconSize: 38,
    iconViewbox: '64 0 1664 1792',
    image: 'calendar',
    subtitle: 'See schedule of community workshops and webinars.',
    title: 'Events',
    url: 'https://ni4ai.org/events'
  }

  cards.push(eventsCard)
}

export default cards
