import React, { Component } from 'react'

import AppProviders from '../../providers/AppProviders'
import Main from './Main'

class App extends Component {
  render () {
    return (
      <AppProviders>
        <Main />
      </AppProviders>
    )
  }
}

export default App
