import React from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withTheme } from 'styled-components'
import debounce from 'lodash.debounce'

import { inferPlotsAxesFromStreams } from './utils'
import chartDuck from '../../ducks/chart'
import ChartControls from '../ChartControls/ChartControls'
import ChartStream from '../ChartStream/ChartStream'
import plotter from '../../ducks/plotter'

class VisualizePage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      chartDimensions: undefined
    }
    this.recalcChartDims = debounce(this.recalcChartDims, 100, {
      leading: true,
      trailing: true
    })
  }

  recalcChartDims = () => {
    // Check the dimensions of the chart's containing div and then create a chart with the full width and half that height
    if (!this.refEl) return
    const rectDims = this.refEl.getBoundingClientRect()
    let width = rectDims.width
    let height = width / 2
    if (width > 1440 && width < 2550) {
      width = 1440
    } else if (width > 2550) {
      width = 2560
      height = width / 2.8
    } else if (width < 750) {
      width = 750
    }
    const chartDimensions = {
      width: width,
      height: height
    }
    this.setState({ chartDimensions })
  }

  componentDidMount () {
    this.recalcChartDims()
    window.addEventListener('resize', this.recalcChartDims)
  }

  componentDidUpdate (prevProps) {
    const { didResetChartZoom } = this.props

    if (!prevProps.didResetChartZoom && didResetChartZoom) {
      this.forceUpdate()
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.recalcChartDims)
  }

  forceUpdate () {
    this.setState({ chartDimensions: undefined }, () => {
      this.recalcChartDims()
    })
  }

  render () {
    const { axesArray, legend, plotsArray, resetGlobalDefaultZoom } = this.props

    return (
      <div
        ref={node => {
          this.refEl = node
        }}
      >
        {this.state.chartDimensions && (
          <>
            <ChartStream
              axes={axesArray}
              className='chart-0'
              dimensions={this.state.chartDimensions}
              // initialEndTime={globalDefaultZoom[1]}
              // initialStartTime={globalDefaultZoom[0]}
              plots={plotsArray}
              resetxDomain={resetGlobalDefaultZoom}
              showLegend={legend.visible}
            />
            <ChartControls />
          </>
        )}
      </div>
    )
  }
}

class Wrapper extends React.Component {
  render () {
    const {
      axes,
      axesArray,
      chart,
      didResetChartZoom,
      globalDefaultZoom,
      legend,
      plotsArray,
      resetGlobalDefaultZoom,
      selectedRows,
      streamsSelected,
      theme,
      updateInstance
    } = this.props

    const waitForNextRender = inferPlotsAxesFromStreams({
      axes,
      chart,
      selectedRows,
      streamsSelected,
      theme,
      updateInstance
    })

    if (waitForNextRender) return null

    if (!streamsSelected) {
      return <Redirect to='/select' />
    }

    return (
      <VisualizePage
        axesArray={axesArray}
        didResetChartZoom={didResetChartZoom}
        globalDefaultZoom={globalDefaultZoom}
        legend={legend}
        plotsArray={plotsArray}
        resetGlobalDefaultZoom={resetGlobalDefaultZoom}
      />
    )
  }
}

const mapStateToProps = state => {
  const axes = plotter.selectors.axes(state)
  const axesArray = plotter.selectors.axesArray(state)
  const chart = plotter.selectors.chart(state)
  const didResetChartZoom = chartDuck.selectors.didReset(state)
  const globalDefaultZoom = chartDuck.selectors.globalDefaultZoom(state)
  const legend = plotter.selectors.legend(state)
  const plotsArray = plotter.selectors.plotsArray(state)
  const selectedRows = plotter.selectors.selectedRows(state)
  const streamsSelected = plotter.selectors.streamsSelected(state)

  return {
    axes,
    axesArray,
    chart,
    didResetChartZoom,
    globalDefaultZoom,
    legend,
    plotsArray,
    selectedRows,
    streamsSelected
  }
}

const mapDispatchToProps = {
  resetGlobalDefaultZoom: chartDuck.actions.resetGlobalDefaultZoom,
  updateInstance: plotter.actions.updateInstance
}

const enhance = compose(withTheme, connect(mapStateToProps, mapDispatchToProps))

export default enhance(Wrapper)
