import { saveStateToLocalStorage } from '../../services/localStorage'
import streams from '../../ducks/streams'

export const persistState = ({ store }) => () => {
  const currentStoreState = store.getState()
  const lastActionType = currentStoreState.lastAction.type

  if (lastActionType.startsWith('plotter/')) {
    saveStateToLocalStorage('plotter', {
      ...currentStoreState.plotter
    })
    return
  }

  if (lastActionType.startsWith('ui/resizableGridLayout/')) {
    saveStateToLocalStorage('ui.resizableGridLayout', {
      ...currentStoreState.ui.resizableGridLayout
    })
    return
  }

  switch (lastActionType) {
    case streams.actionTypes.SET_STREAM_TABLE_COLUMNS: {
      saveStateToLocalStorage(
        'streams.streamTable.selectedColumns',
        currentStoreState.streams.streamTable.selectedColumns
      )
      break
    }
    case streams.actionTypes.SET_STREAM_TABLE_FILTERS:
    case streams.actionTypes.CLEAR_STREAM_TABLE_FILTERS: {
      saveStateToLocalStorage(
        'streams.streamTable.filters',
        currentStoreState.streams.streamTable.filters
      )
      break
    }
    default: {
      break
    }
  }
}
