import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'

import { SecondaryButton } from '../Buttons/Buttons'
import { MODALS } from '../../constants'
import { redesignEnabled } from '../../utils/featureFlags'
import modals from '../../ducks/modals'

const ColumnSelectorButton = () => {
  const dispatch = useDispatch()
  const handleClick = () =>
    dispatch(
      modals.actions.open({ viewMode: MODALS.STREAM_TABLE.COLUMN_SELECTOR })
    )

  if (redesignEnabled()) {
    return (
      <li>
        <button
          className='no-icon column-selector-button'
          onClick={handleClick}
          type='button'
        >
          <span className='item-text'>Edit table columns</span>
        </button>
      </li>
    )
  } else {
    return (
      <SecondaryButton key='column-editor' onClick={handleClick}>
        <>
          <span data-test-name='column-selector'>Edit Table Columns</span>
          <FontAwesomeIcon
            style={{ paddingLeft: '10px', width: 'inherit' }}
            icon='chevron-right'
          />
        </>
      </SecondaryButton>
    )
  }
}

export default ColumnSelectorButton
