import {
  grouperEnabled,
  heatmapEnabled,
  mapEnabled
} from '../utils/featureFlags'

export const LOCAL_STORAGE = {
  REDUX_STATE_KEY: 'redux:state',
  THEME_CHOICE: 'theme:choice'
}

export const MODALS = {
  PLOTTER: {
    GRID_SETTINGS: 'MODALS/PLOTTER/GRID_SETTINGS',
    MINI_PLOTTER: 'MODALS/PLOTTER/MINI_PLOTTER'
  },
  STREAM_TABLE: {
    COLUMN_SELECTOR: 'MODALS/STREAM_TABLE/COLUMN_SELECTOR'
  }
}

export const RESIZABLE_GRID = {
  ITEM_KEYS: {
    ALL_STREAMS: 'AllStreamsGridItem',
    BIG_CHART: 'BigChartGridItem',
    METADATA: 'MetadataGridItem',
    SELECTED_STREAMS: 'SelectedStreamsGridItem',
    STREAM_LIST_TABLE: 'StreamListTableGridItem'
  }
}

if (grouperEnabled()) {
  RESIZABLE_GRID.ITEM_KEYS.GROUPER = 'GrouperGridItem'
}

if (heatmapEnabled()) {
  RESIZABLE_GRID.ITEM_KEYS.HEATMAP = 'Heatmap'
}

if (mapEnabled()) {
  RESIZABLE_GRID.ITEM_KEYS.MAP = 'MapGridItem'
}

export const RESIZABLE_GRID_ITEM_HEADER_ACTIONS = {
  GRID_SETTINGS: 'Grid settings',
  EDIT_TABLE_COLUMNS: 'Edit table columns',
  EXPORT_TO_IMAGE: 'Save chart as image',
  EXPORT_TO_JUPYTER: 'Export to Jupyter',
  EXPORT_TO_CSV: 'Export to CSV',
  GENERATE_PERMALINK: 'Generate permalink'
}

export const RESIZABLE_GRID_ITEM_HEADER_MENUS = {
  [RESIZABLE_GRID.ITEM_KEYS.ALL_STREAMS]: [
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.GRID_SETTINGS,
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.EDIT_TABLE_COLUMNS
  ],
  [RESIZABLE_GRID.ITEM_KEYS.BIG_CHART]: [
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.GRID_SETTINGS,
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.EXPORT_TO_JUPYTER,
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.EXPORT_TO_CSV,
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.GENERATE_PERMALINK
  ],
  [RESIZABLE_GRID.ITEM_KEYS.SELECTED_STREAMS]: [
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.GRID_SETTINGS,
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.EDIT_TABLE_COLUMNS
  ],
  [RESIZABLE_GRID.ITEM_KEYS.STREAM_LIST_TABLE]: [
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.GRID_SETTINGS,
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.EDIT_TABLE_COLUMNS
  ]
}

if (grouperEnabled()) {
  RESIZABLE_GRID_ITEM_HEADER_MENUS[RESIZABLE_GRID.ITEM_KEYS.GROUPER] = [
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.GRID_SETTINGS,
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.EDIT_TABLE_COLUMNS
  ]
}

if (heatmapEnabled()) {
  RESIZABLE_GRID_ITEM_HEADER_MENUS[RESIZABLE_GRID.ITEM_KEYS.HEATMAP] = [
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.GRID_SETTINGS
  ]
}

if (mapEnabled()) {
  RESIZABLE_GRID_ITEM_HEADER_MENUS[RESIZABLE_GRID.ITEM_KEYS.MAP] = [
    RESIZABLE_GRID_ITEM_HEADER_ACTIONS.GRID_SETTINGS
  ]
}

export const STREAM_TABLE_DEFAULTS = {
  page: 0,
  pageSize: 20
}

export const TOKEN_KEY = 'pg_graphql_token'
export const TOKEN_ERROR_KEY = 'pg_graphql_token_error'
export const USERNAME_KEY = 'pg_graphql_username'

export const errorMsgs = {
  TokenExpiredError: 'Please login again. The previous credentials expired.',
  JsonWebTokenError:
    'Please login again. The server could not validate your previous credentials.'
}
