import React from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash.debounce'
import notification from 'antd/lib/notification'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { globalDefaultZoom as globalDefaultZoomSelector } from '../../ducks/chart/selectors'
import { redesignEnabled } from '../../utils/featureFlags'
import { resetGlobalDefaultZoom } from '../../ducks/chart/actions'
import { TableControlBarBorderless } from '../styled/styled'
import ChartStream from '../ChartStream/ChartStream'
import ColumnSelector from './ColumnSelector'
import MetadataPreview from './MetadataPreview'
import plotter from '../../ducks/plotter'
import SelectedStreams from './SelectedStreams'
import streams from '../../ducks/streams'
import StreamTableContainer from './StreamTableContainer'

const {
  actions: { toggleStream }
} = plotter

const {
  actions: { getStream, setStreamTableHoveredRow },
  selectors: {
    stream: streamSelector,
    streamError: streamErrorSelector,
    streamLoading: streamLoadingSelector,
    streamTableHoveredRow: streamTableHoveredRowSelector
  }
} = streams

const chartDimensions = {
  width: 600,
  height: 350,
  margin: {
    top: 40,
    bottom: 20,
    left: 0,
    right: 30
  }
}

const EmptyPreview = styled.span`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const StreamSearchPageStyles = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  .ReactTable {
    .-pagination {
      div.-center {
        flex-wrap: unset;
      }
    }
  }
  .rt-tr {
    cursor: pointer;
  }

  .rightPanel {
    display: flex;
    align-items: flex-start;
    width: 50%;
    min-width: 500px;
    padding: 20px 20px 20px 10px;

    .streamPreview {
      width: 100%;
      position: sticky;
      top: 0px;

      .chartContainer {
        width: ${props => {
          const { chartDimensions } = props
          const result = (chartDimensions && chartDimensions.width) || '100%'
          return `${result}px;`
        }};
        height: ${props => {
          const { chartDimensions } = props
          const result = (chartDimensions && chartDimensions.height) || '100%'
          return `${result}px;`
        }};
      }
      .selected-title {
        margin: 20px 0 0;
      }
    }
  }
  .streamTables {
    width: 50%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    padding: 20px 10px 20px 20px;
  }
  .metadataTitle {
    margin: 20px 0 10px;
  }
`

export class StreamSearchPage extends React.Component {
  static propTypes = {
    globalDefaultZoom: PropTypes.array.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {}
    this.recalcChartDims = debounce(this.recalcChartDims, 100, {
      leading: true,
      trailing: true
    })
  }

  recalcChartDims = () => {
    // Check the dimensions of the chart's containing div and then create a chart with the full width and half that height
    const rectDims = this.refEl.getBoundingClientRect()
    const chartDimensions = {
      width: rectDims.width,
      height: rectDims.width * (3.5 / 6)
    }
    this.setState({ chartDimensions })
  }

  handleError = () => {
    if (!redesignEnabled()) {
      notification.warn({
        message: 'Error generating plot',
        description:
          'Error generating plot. Please try again or contact support.',
        duration: 5,
        key: 'streamsError'
      })
    }
  }

  componentDidMount () {
    if (!redesignEnabled()) {
      this.recalcChartDims()
      window.addEventListener('resize', this.recalcChartDims)
    }

    const { getStream, uuid } = this.props
    if (uuid) {
      getStream({ useCache: false, uuid })
    }
  }

  componentDidUpdate (prevProps) {
    const {
      history,
      metadata,
      setStreamTableHoveredRow,
      streamError,
      streamLoading,
      streamTableHoveredRow,
      toggleStream,
      uuid
    } = this.props
    const didFetchStream = !prevProps.metadata && metadata && !streamLoading
    const shouldRedirect = !!uuid && !!streamTableHoveredRow.uuid && !!metadata

    if (streamError) {
      this.handleError()
    } else if (didFetchStream) {
      setStreamTableHoveredRow({
        hoveredRow: { uuid: metadata.uuid, metadata }
      })
    } else if (shouldRedirect) {
      toggleStream({ metadata, uuid })
      const to = redesignEnabled() ? '/app' : '/visualize'
      history.push(to)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.recalcChartDims)
  }

  // terrible horrible no good very bad hack
  async resetChart () {
    // trigger redux reset of the global zoom variable
    this.props.resetGlobalDefaultZoom()
    const { chartDimensions } = this.state
    // reset the entire chart by unmounting then remounting it
    await new Promise(resolve =>
      this.setState({ chartDimensions: null }, resolve)
    )
    await new Promise(resolve => this.setState({ chartDimensions }, resolve))
  }

  render () {
    const { streamTableHoveredRow } = this.props

    const uuid = streamTableHoveredRow && streamTableHoveredRow.uuid

    if (redesignEnabled()) {
      return <></>
    }

    return (
      <StreamSearchPageStyles chartDimensions={this.state.chartDimensions}>
        <div className='streamTables'>
          <TableControlBarBorderless>
            <div className='tableTitle'>All Streams</div>
            <ColumnSelector />
          </TableControlBarBorderless>
          <StreamTableContainer />
          <div>
            <TableControlBarBorderless>
              <div className='tableTitle metadataTitle'>Metadata</div>
            </TableControlBarBorderless>
            <MetadataPreview metadata={streamTableHoveredRow} />
          </div>
        </div>
        <div className='rightPanel'>
          <div
            className='streamPreview'
            ref={node => {
              this.refEl = node
            }}
          >
            {this.state.chartDimensions && (
              <div className='chartContainer'>
                <TableControlBarBorderless className='preview-title'>
                  <div className='tableTitle'>Stream Preview</div>
                </TableControlBarBorderless>
                {uuid ? (
                  <ChartStream
                    key={uuid}
                    plots={[
                      {
                        uuid,
                        unit: '',
                        color: 'blue',
                        visibility: true
                      }
                    ]}
                    axes={[{ unit: '', side: 'left', streams: [uuid] }]}
                    uuid={uuid}
                    // initialStartTime={globalDefaultZoom[0]}
                    // initialEndTime={globalDefaultZoom[1]}
                    resetxDomain={() => this.resetChart()}
                    dimensions={{
                      ...chartDimensions,
                      ...this.state.chartDimensions
                    }}
                    showLegend={false}
                  />
                ) : (
                  <EmptyPreview>
                    Click a row to the left to see a preview of its data
                  </EmptyPreview>
                )}
              </div>
            )}
            <div>
              <TableControlBarBorderless className='selected-title'>
                <div className='tableTitle'>Selected Streams</div>
              </TableControlBarBorderless>
              <SelectedStreams />
            </div>
          </div>
        </div>
      </StreamSearchPageStyles>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const uuid = ownProps.match.params.uuid
  const metadata = uuid ? streamSelector(state, uuid) : null

  const globalDefaultZoom = globalDefaultZoomSelector(state)
  const streamError = streamErrorSelector(state)
  const streamLoading = streamLoadingSelector(state)
  const streamTableHoveredRow = streamTableHoveredRowSelector(state)

  return {
    globalDefaultZoom,
    metadata,
    streamError,
    streamLoading,
    streamTableHoveredRow,
    uuid
  }
}

const mapDispatchToProps = {
  getStream,
  resetGlobalDefaultZoom,
  setStreamTableHoveredRow,
  toggleStream
}

const enhance = compose(
  withRouter,
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(StreamSearchPage)
