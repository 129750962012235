import get from 'lodash.get'

export function updateNode ({ data, id, grouperData }) {
  if (id.length === 0) {
    return data
  }

  let idArray = id.split('.')

  idArray = idArray.map((el, i) => {
    if (idArray.length > 1 && i !== idArray.length - 1) {
      el = `${el}.subRows`
    }

    return el
  })

  id = idArray.join('.')

  if (!get(grouperData, id).subRows) {
    get(grouperData, id).subRows = data
  }

  return grouperData
}

export function streamFinder ({ id, grouperData }) {
  const idArray = id.split('.')
  let stream = grouperData[idArray[0]]

  for (let i = 1; i < idArray.length; i++) {
    stream = stream.subRows[idArray[i]]
  }

  return stream
}
