import React from 'react'

import styled from 'styled-components'

const Pencil = styled.span`
  display: inline-block;
  transform: rotateZ(90deg);
  margin-left: 15px;
  line-height: 18px;
`
const EditIcon = () => <Pencil>&#9998;</Pencil>

class InputWithFocus extends React.Component {
  componentDidMount () {
    this.inputEl.focus()
    this.inputEl.select()
  }

  render () {
    return (
      <input
        ref={node => {
          this.inputEl = node
          this.props.customRefFn && this.props.customRefFn(node)
        }}
        type='text'
        value={this.props.value}
        onChange={this.props.onChange}
        onKeyDownCapture={this.props.onKeyDownCapture}
      />
    )
  }
}

export const Input = ({ customRefFn, onChange, onKeyDownCapture, value }) => (
  <div style={{ zIndex: 101 }}>
    <InputWithFocus
      value={value}
      customRefFn={customRefFn}
      onChange={onChange}
      onKeyDownCapture={onKeyDownCapture}
    />
  </div>
)

export const Label = ({
  editVisible,
  onMouseEnter,
  onMouseLeave,
  toggleEditMode,
  value
}) => (
  <div
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={toggleEditMode}
  >
    <span>{value}</span>
    {editVisible && <EditIcon />}
  </div>
)
