import React from 'react'

import styled from 'styled-components'

import {
  ChartControlTable,
  LegendShowHide
} from '../ChartControls/ChartControls'
import { exportCSVButtonEnabled } from '../../utils/featureFlags'
import { TableControlBarBorderless } from '../styled/styled'
import ExportToCSV from './ExportToCSV'
import ExportToJupyter from './ExportToJupyter'
import GeneratePermalink from './GeneratePermalink'

const Controls = styled(TableControlBarBorderless)`
  justify-content: flex-end;
`

const ExportGroup = styled.div`
  & > * {
    margin-right: 10px;
  }
  & > *:last-child {
    margin-right: 0px;
  }
`

const ExportControls = () => {
  return (
    <ChartControlTable style={{ paddingTop: '0px', marginTop: '-65px' }}>
      <Controls>
        <div style={{ marginRight: '60px' }}>
          <LegendShowHide />
        </div>
        <ExportGroup>
          <ExportToJupyter />
          {exportCSVButtonEnabled() && <ExportToCSV />}
          <GeneratePermalink />
        </ExportGroup>
      </Controls>
    </ChartControlTable>
  )
}

export default ExportControls
