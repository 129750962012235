import gql from 'graphql-tag'

import { checkApiResponse } from '../../utils/checkApiResponse'

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($username: ID!, $password: String!) {
    SetUserPassword(body: { username: $username, password: $password }) {
      error {
        code
        msg
      }
    }
  }
`

const GET_GROUPS = gql`
  query GetGroups {
    GetPublicUser {
      error {
        code
        msg
      }
      public {
        username
        groups {
          name
          prefixes
          capabilities
        }
      }
    }
  }
`

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    Login(body: { username: $username, password: $password }) {
      username
      token
    }
  }
`

const RESET_API_KEY = gql`
  mutation ResetAPIKey($username: ID!) {
    ResetAPIKey(body: { username: $username }) {
      error {
        code
        msg
      }
      apikey
    }
  }
`

export async function changePassword ({ client, username, newPassword }) {
  try {
    await client.mutate({
      mutation: CHANGE_PASSWORD,
      variables: { username, password: newPassword },
      errorPolicy: 'all'
    })

    return { data: { success: true } }
  } catch (e) {
    console.log({ error: e.message })
    return { error: e.message }
  }
}

export async function getGroups ({ client }) {
  try {
    const data = await client.query({
      query: GET_GROUPS,
      fetchPolicy: 'no-cache',
      variables: {}
    })

    return { data: data.data.GetPublicUser.public }
  } catch (e) {
    console.log({ error: e.message })
    return { error: e.message }
  }
}

export async function login ({ client, password, username }) {
  let res

  try {
    res = await client.mutate({
      mutation: LOGIN,
      variables: { password, username },
      errorPolicy: 'all'
    })

    checkApiResponse({ res })

    const { token } = res.data.Login

    return { data: { token, username } }
  } catch (e) {
    console.log({ error: e.message, res })
    return { error: e.message }
  }
}

export async function resetApiKey ({ client, username }) {
  let res

  try {
    res = await client.mutate({
      mutation: RESET_API_KEY,
      variables: { username },
      errorPolicy: 'all'
    })

    checkApiResponse({ res })

    const { apikey } = res.data.ResetAPIKey

    return { data: { apikey } }
  } catch (e) {
    console.log({ error: e.message, res })
    return { error: e.message }
  }
}
