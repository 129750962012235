import React from 'react'
import PropTypes from 'prop-types'
import styled from '@xstyled/styled-components'
import {
  Button as RSButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTable from 'react-table-6'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import RSRadio from 'antd/lib/radio'
import ToolTip from 'antd/lib/tooltip'

import {
  Button,
  FloatingModal as RedesignModal,
  Radio,
  NumberInput,
  Range,
  bindTheme
} from 'frontend-components'
import { CustomReactTableStyles } from '../styled/styled'
import {
  ensureBase64Uuid,
  ensureHexUuid,
  createDeferred
} from '../../utils/utils'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import {
  nanoToDuration,
  nanoToHuman,
  preciseTimestamp
} from '../../lib/timestamp'
import { redesignEnabled } from '../../utils/featureFlags'
import { SecondaryButton } from '../Buttons/Buttons'
import { TOKEN_KEY } from '../../constants'
import plotter from '../../ducks/plotter'

const color = bindTheme({
  light: {
    text: 'black',
    subText: 'neutral6',
    blue: 'blue2',
    red: 'red2',
    subBackground: 'neutral1'
  },
  dark: {
    text: 'white',
    subText: 'neutral3',
    blue: 'cyan2',
    red: 'magenta3',
    subBackground: 'neutral6'
  }
})

const ALIGNED_WINDOWS = gql`
  subscription AlignedWindows(
    $uuid: Uuid!
    $start: String!
    $end: String!
    $pointWidth: Int!
  ) {
    AlignedWindows(
      body: { uuid: $uuid, start: $start, end: $end, pointWidth: $pointWidth }
    ) {
      stat {
        code
        msg
      }
      versionMajor
      versionMinor
      values {
        time
        min
        mean
        max
        count
      }
    }
  }
`

const ToolTipMsgs = {
  measurements: {
    raw: (
      <p>
        Each record will be a raw measurement in the form of:
        <code style={{ display: 'block' }}>timestamp, value</code>
      </p>
    ),
    summary: (
      <p>
        Each CSV record defines a block of time and summarizes the raw
        measurements that fall within it. The size of each time block is
        customizable below.
      </p>
    )
  },
  lineFeed: (
    <>
      <p>
        CSVs separate each row of data with a new line. Different operating
        systems tend to use different characters to represent these new lines.
        Linux/Posix OSes tend to use Line Feed (LF) while Windows favors
        Carriage Return Line Feed (CRLF).
      </p>
      <p>
        Ideally the program that consumes your CSV will be able to read both,
        but some programs specifically require one or the other. We recommend
        you choose the option typically favored by your OS.
      </p>
    </>
  ),
  estimateErrors: (
    <>
      <p>
        These estimates are only accurate when points are evenly distributed.
        Because time windows that contain zero raw measurements are omitted, a
        CSV will contain fewer rows if a stream is irregular.
      </p>
      <p>
        Only an aligned window that both starts and stops within the beginning
        and ending times of the query will be included. This means that any
        points contained in a partial window whose end time runs past the
        query's end time will be omitted. At the extreme, if you choose an
        aligned window duration greater than the duration of the entire query,
        then zero rows would be returned.
      </p>
    </>
  )
}

const prepareCSVQueryArgs = state => {
  const { start, end, resolution, selected, queryType, lineFeed } = state
  const getLabel = (uuid, streams) => {
    const decodedUuid = ensureHexUuid(uuid) // already standard uuid and not base64bin
    if (!streams[uuid] && !streams[decodedUuid]) return decodedUuid
    const { metadata: meta } = streams[uuid] || streams[decodedUuid]
    if (
      !meta ||
      !meta.collection ||
      (!meta.tags.name && !meta.tags.description)
    ) {
      return decodedUuid
    } else {
      const friendlyName =
        meta.collection +
        '/' +
        (meta.tags.name || meta.tags.description) +
        ' uuid: ' +
        decodedUuid.slice(0, 6)
      return friendlyName
    }
  }
  const args = {
    startTime: Number(start),
    endTime: Number(end),
    includeVersions: true,
    lineFeed,
    streams: selected.map(uuid => {
      const b64uuid = ensureBase64Uuid(uuid)
      return {
        uuid: b64uuid,
        label: getLabel(b64uuid, state.streams)
      }
    }),
    token: localStorage.getItem(TOKEN_KEY) || undefined
  }
  if (queryType === 'raw') args.queryType = 'RAW_QUERY'
  else if (queryType === 'aligned') {
    args.queryType = 'ALIGNED_WINDOWS_QUERY'
    args.depth = resolution
  }

  // TODO: do proptype validation on args
  return args
}
const fetchCSV = base64CSVArgs => {
  const url = `/api/graphql/export/csv/${base64CSVArgs}`
  console.log('url:', url)
  window.open(url, '_blank')
}

const epochNanoToISO = epochNano => {
  if (!epochNano) return
  const microEpoch = epochNano.toString().slice(0, 13)
  const isoStringMicro = new Date(Number(microEpoch)).toISOString()
  const isoStringNano =
    isoStringMicro.slice(0, -1) +
    epochNano.toString().slice(13) +
    isoStringMicro.slice(-1) // Z
  return isoStringNano
}

class ExportToCSVWrapper extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modal: false
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  isLoading = () => {
    const {
      chart: { start, end, resolution }
    } = this.props

    if ((start === undefined, end === undefined, resolution === undefined)) {
      return true
    }

    return false
  }

  renderLoadingModal = () => {
    return (
      <Modal key='csvmodal' className='modal-lg' isOpen>
        <ModalHeader>Export to CSV</ModalHeader>
        <ModalBody>Loading...</ModalBody>
      </Modal>
    )
  }

  render () {
    if (!redesignEnabled()) {
      return this.renderOld()
    }

    const { streamsSelected } = this.props
    const disabled = !streamsSelected
    const open = this.state.modal && !this.isLoading()
    const loading = this.state.modal && this.isLoading()

    return (
      <>
        <li>
          <button
            className='no-icon'
            disabled={disabled}
            id='export-to-csv'
            onClick={this.toggle}
            type='button'
          >
            <span className='item-text'>Export to CSV</span>
          </button>
        </li>
        {open ? this.renderModal() : null}
        {loading ? this.renderLoadingModal() : null}
      </>
    )
  }

  renderModal () {
    const { chart, selectedRows } = this.props
    return (
      <ErrorBoundary>
        <ExportToCSV
          chart={chart}
          selectedRows={selectedRows}
          isOpen={this.state.modal}
          toggle={this.toggle}
        />
      </ErrorBoundary>
    )
  }

  renderOld () {
    // if (this.state.error)
    const isLoading = this.isLoading()
    return (
      <>
        <SecondaryButton onClick={this.toggle}>Export to CSV</SecondaryButton>
        {this.state.modal && isLoading === true && this.renderLoadingModal()}
        {this.state.modal && isLoading === false && this.renderModal()}
      </>
    )
  }
}

class ExportToCSV extends React.Component {
  constructor (props) {
    super(props)
    const { chart, selectedRows } = props
    const start = (chart.selection || [])[0] || chart.start
    const end = (chart.selection || [])[1] || chart.end
    const visibleStreams = Object.keys(selectedRows).filter(
      skey => selectedRows[skey].plot.visibility
    )
    this.state = {
      start,
      end,
      resolution: chart.resolution || undefined,
      selected: visibleStreams,
      streams: selectedRows,
      loadingCounts: true
    }
  }

  static getDerivedStateFromProps (props, state) {
    const { chart } = props

    const oldStart = state.start
    const oldEnd = state.end

    const newStart = (chart.selection || [])[0] || chart.start
    const newEnd = (chart.selection || [])[1] || chart.end

    if (newStart !== oldStart || newEnd !== oldEnd) {
      return {
        start: newStart,
        end: newEnd
      }
    }
    return {}
  }

  componentDidMount () {
    const { streams, start, end } = this.state
    this.queryForTotalPoints(streams, start, end)
  }

  toggleSelectedStream = uuid => {
    const current = this.state.selected
    let updated = []
    if (current.includes(uuid)) updated = current.filter(u => u !== uuid)
    else updated = [...current, uuid]
    this.setState({ selected: updated })
  }

  calcCoarseResolution = (start, end) => {
    const duration = end - start
    const coarseResolution = Math.floor(Math.log2(duration / 100))
    return coarseResolution
  }

  queryForTotalPoints = async (streams, start, end) => {
    const coarseResolution = this.calcCoarseResolution(start, end)
    const callsToMake = Object.keys(streams).map(base64uuid => ({
      uuid: base64uuid,
      start: start.toString(),
      end: end.toString(),
      pointWidth: coarseResolution
    }))
    const responses = await this.alignedWindows(callsToMake)
    const countByStream = this.extractCounts(responses).map((v, i) => ({
      uuid: callsToMake[i].uuid,
      count: v
    }))

    const updatedStreams = { ...streams }
    for (const o of countByStream) {
      Object.assign(updatedStreams[o.uuid], { count: o.count })
    }
    this.setState({ streams: updatedStreams, loadingCounts: false })
  }

  extractCounts (responses) {
    var values = responses.map(r => r.values || [])

    var subCounts = values.map(streamVals =>
      streamVals.reduce((pv, cv) => pv + parseInt(cv.count, 10), 0)
    )

    return subCounts
  }

  alignedWindows = async queryVarArray => {
    const queriesToMake = queryVarArray.map(qvars => {
      const observable = this.props.client.subscribe({
        query: ALIGNED_WINDOWS,
        variables: qvars
      })
      const promise = createDeferred()
      let results
      observable.subscribe({
        next: d => {
          if (!results) results = d.data.AlignedWindows
          else {
            results.values = results.values.concat(
              d.data.AlignedWindows.values || []
            )
          }
        },
        error: error => console.log(error) || promise.reject(error),
        complete: () => promise.resolve(results)
      })
      return promise
    })
    const all = await Promise.all(queriesToMake)
    return all
  }

  validateState = () => {
    const { queryType, lineFeed } = this.state
    let valid = true
    if (queryType !== 'raw' && queryType !== 'aligned') {
      this.setState({ queryTypeError: 'required' })
      valid = false
    }
    if (lineFeed !== 'lf' && lineFeed !== 'crlf') {
      this.setState({ lineFeedError: 'required' })
      valid = false
    }
    return valid
  }

  download = e => {
    e.preventDefault()
    const isValid = this.validateState()
    if (isValid === false) return
    const args = prepareCSVQueryArgs(this.state)
    const base64CSVArgs = btoa(JSON.stringify(args))
    console.log('Use these args for the fetch:', args)
    fetchCSV(base64CSVArgs)
  }

  renderTimeRange () {
    const { start, end, isRawTimeRange } = this.state

    const timeText = t =>
      isRawTimeRange
        ? preciseTimestamp(t) + ' ns'
        : epochNanoToISO(t) + ' (UTC)'

    return (
      <div className='dateTimeRange'>
        <div className='section-header first'>
          DATE/TIME RANGE
          <span
            className='toggle-rawTimeRange'
            onClick={() => this.setState({ isRawTimeRange: !isRawTimeRange })}
          >
            {isRawTimeRange ? 'show human units' : 'show raw units'}
          </span>
        </div>
        <div>
          <span className='label'>Start:</span>
          <span className='data'>{timeText(start)}</span>
        </div>
        <div>
          <span className='label'>End:</span>
          <span className='data'>{timeText(end)}</span>
        </div>
        <div>
          <span className='label'>Duration:</span>
          <span className='data'>{nanoToDuration(end - start)}</span>
        </div>
      </div>
    )
  }

  renderRedesign () {
    const { loadingCounts, resolution, selected, streams } = this.state

    const nothingSelected = selected.length === 0

    const downloadButton = (
      <Button
        disabled={nothingSelected}
        key='download'
        label='Download CSV'
        onClick={this.download}
        size='sm'
      />
    )

    return (
      <RedesignModal
        buttons={[downloadButton]}
        cancel={this.props.toggle}
        style={{ width: 680 }}
        title='Export to CSV'
      >
        <CSVModalBody>
          {this.renderTimeRange()}

          <div>
            <div className='section-header'>STREAMS</div>
            <CSVStreamsTable
              loadingCounts={loadingCounts}
              selected={selected}
              streamsObj={streams}
              toggleSelectedStream={this.toggleSelectedStream}
            />
          </div>

          <ExportOptions>
            <div className='section-header'>OPTIONS</div>
            <div className='optionRow'>
              <span className='label'>Measurement Type:</span>
              {this.state.queryTypeError && (
                <span className='errorMsg'>Required</span>
              )}
              <Radio
                onChange={value => {
                  this.setState({
                    queryType: value,
                    queryTypeError: undefined
                  })
                }}
                layout='horizontal'
              >
                {[
                  { title: 'Raw Data', value: 'raw' },
                  { title: 'Aggregates', value: 'aligned' }
                ]}
              </Radio>
            </div>
            <div className='optionRow'>
              <span className='label'>
                <span>Line Feed: </span>
                <ToolTip placement='bottom' title={ToolTipMsgs.lineFeed}>
                  <FontAwesomeIcon icon='info-circle' />
                </ToolTip>
              </span>
              {this.state.lineFeedError && (
                <span className='errorMsg'>Required</span>
              )}
              <Radio
                onChange={value => {
                  this.setState({
                    lineFeed: value,
                    lineFeedError: undefined
                  })
                }}
                layout='horizontal'
              >
                {[
                  { title: 'LF (Linux)', value: 'lf' },
                  { title: 'CRLF (Windows)', value: 'crlf' }
                ]}
              </Radio>
            </div>
          </ExportOptions>

          {this.state.queryType === 'aligned' && (
            <DataFrequency>
              <div className='section-header'>DATA FREQUENCY</div>
              <div className='df-description'>
                One point every 2^
                {resolution} nanoseconds{' '}
                <span className='df-sampleRate'>
                  (~
                  {nanoToHuman(2 ** resolution)})
                </span>
              </div>
              <div className='df-inputgroup'>
                <NumberInput
                  className='df-input'
                  min={10}
                  max={55}
                  value={resolution}
                  precision={0}
                  onChange={e => {
                    this.setState({ resolution: e.target.value })
                  }}
                />
                <Range
                  className='df-slider'
                  min={10}
                  max={55}
                  value={resolution}
                  onChange={e => this.setState({ resolution: e.target.value })}
                />
              </div>
            </DataFrequency>
          )}

          <DownloadSummary {...this.state} />
        </CSVModalBody>
      </RedesignModal>
    )
  }

  render () {
    const { resolution, streams, selected, loadingCounts } = this.state
    const nothingSelected = selected.length === 0

    if (redesignEnabled()) {
      return this.renderRedesign()
    }

    return (
      <Modal
        key='csvmodal'
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className='modal-lg'
      >
        <ModalHeader toggle={this.toggle}>Export to CSV</ModalHeader>
        <CSVModalBody>
          {this.renderTimeRange()}

          <div>
            <div className='section-header'>STREAMS</div>
            <CSVStreamsTable
              streamsObj={streams}
              toggleSelectedStream={this.toggleSelectedStream}
              selected={selected}
              loadingCounts={loadingCounts}
            />
          </div>

          <ExportOptions>
            <div className='section-header'>OPTIONS</div>
            <div className='optionRow'>
              <span className='label'>Measurement Type:</span>
              <RSRadio.Group
                onChange={e =>
                  this.setState({
                    queryType: e.target.value,
                    queryTypeError: undefined
                  })
                }
                value={this.state.queryType}
                size='small'
              >
                <ToolTip placement='top' title={ToolTipMsgs.measurements.raw}>
                  <RSRadio.Button value='raw'>Raw Data</RSRadio.Button>
                </ToolTip>
                <ToolTip
                  placement='top'
                  title={ToolTipMsgs.measurements.summary}
                >
                  <RSRadio.Button value='aligned'>Aggregates</RSRadio.Button>
                </ToolTip>
              </RSRadio.Group>
              {this.state.queryTypeError && (
                <span className='errorMsg'>Required</span>
              )}
            </div>
            <div className='optionRow'>
              <span className='label'>
                <span>Line Feed: </span>
                <ToolTip placement='bottom' title={ToolTipMsgs.lineFeed}>
                  <FontAwesomeIcon icon='info-circle' />
                </ToolTip>
              </span>
              <RSRadio.Group
                onChange={e => {
                  this.setState({
                    lineFeed: e.target.value,
                    lineFeedError: undefined
                  })
                }}
                value={this.state.lineFeed}
                size='small'
              >
                <RSRadio.Button value='lf'>LF (Linux)</RSRadio.Button>
                <RSRadio.Button value='crlf'>CRLF (Windows)</RSRadio.Button>
              </RSRadio.Group>
              {this.state.lineFeedError && (
                <span className='errorMsg'>Required</span>
              )}
            </div>
          </ExportOptions>

          {this.state.queryType === 'aligned' && (
            <DataFrequency>
              <div className='section-header'>DATA FREQUENCY</div>
              <div className='df-description'>
                One point every 2^
                {resolution} nanoseconds{' '}
                <span className='df-sampleRate'>
                  (~
                  {nanoToHuman(2 ** resolution)})
                </span>
              </div>
              <div className='df-inputgroup'>
                <NumberInput
                  className='df-input'
                  min={10}
                  max={55}
                  value={resolution}
                  precision={0}
                  onChange={e => {
                    this.setState({ resolution: e.target.value })
                  }}
                />
                <Range
                  className='df-slider'
                  min={10}
                  max={55}
                  value={resolution}
                  onChange={e => this.setState({ resolution: e.target.value })}
                />
              </div>
            </DataFrequency>
          )}

          <DownloadSummary {...this.state} />
        </CSVModalBody>
        <ModalFooter>
          <RSButton
            color='primary'
            disabled={nothingSelected}
            onClick={this.download}
          >
            Download CSV
          </RSButton>
          <RSButton color='secondary' onClick={this.props.toggle}>
            Cancel
          </RSButton>
        </ModalFooter>
      </Modal>
    )
  }
}

// eslint-disable-next-line no-class-assign
ExportToCSV = withApollo(ExportToCSV)

class DownloadSummary extends React.Component {
  static propTypes = {
    // client: PropTypes.object.isRequired,
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    resolution: PropTypes.number.isRequired,
    selected: PropTypes.array.isRequired,
    streams: PropTypes.object.isRequired,
    loadingCounts: PropTypes.bool.isRequired
  }

  calcWindows = () => {
    const { start, end, resolution } = this.props
    const duration = end - start
    const windowSize = 2 ** resolution
    const numWindows = Math.floor(duration / windowSize) // use floor because any partial window is omitted from results
    return numWindows
  }

  renderLoading () {
    return (
      <div className='downloadSummary'>
        <div className='section-header'>
          <span>ESTIMATED DOWNLOAD SIZE</span>
        </div>
        <div style={{ marginTop: '10px' }}>Loading data...</div>
      </div>
    )
  }

  renderNoStreamsSelected () {
    return (
      <div className='downloadSummary'>
        <div className='section-header'>ESTIMATED DOWNLOAD SIZE</div>
        <div style={{ marginTop: '10px' }}>
          No streams currently selected. Select a stream above.
        </div>
      </div>
    )
  }

  renderAlignedWindowsEstimate () {
    const { selected, streams } = this.props

    if (this.props.loadingCounts === true) return this.renderLoading()
    if (selected.length === 0) return this.renderNoStreamsSelected()

    const rowEstimate = this.calcWindows()
    // const totalPoints = selected.reduce((pv, cv) => {
    //   const streamCount = streams[cv].count;
    //   return pv + streamCount;
    // }, 0);
    const mostDenseStream = Math.max(
      ...selected.map(uuid => streams[uuid].count)
    )
    const avgCountPerRow = Math.max(
      1.0,
      (mostDenseStream / rowEstimate).toFixed(1)
    )

    return (
      <div className='downloadSummary'>
        <div className='section-header'>
          <span>ESTIMATED DOWNLOAD SIZE</span>
        </div>
        <div>
          <div>
            <span className='data'>
              {Math.min(rowEstimate, mostDenseStream)}
            </span>
            <span className='label'>rows of data (maximum estimate)</span>
            <ToolTip placement='right' title={ToolTipMsgs.estimateErrors}>
              <span>
                <FontAwesomeIcon icon='info-circle' />
              </span>
            </ToolTip>
          </div>
          <div>
            <span className='data'>
              {avgCountPerRow === Infinity ? 'n/a' : avgCountPerRow}
            </span>
            <span className='label'>
              points summarized per row (most dense stream)
            </span>
          </div>
        </div>
        <div className='info'>
          If this is too much, either reduce the frequency, select fewer
          streams, or zoom in on the chart to select a narrower time range.
        </div>
      </div>
    )
  }

  sigDigits = (points, error = 0.05) => {
    const numDigitsToZeroOut = (points * error).toFixed(0).length
    const stem = ('' + points).slice(0, numDigitsToZeroOut * -1)
    return (
      stem +
      Array(numDigitsToZeroOut)
        .fill('0')
        .join('')
    )
  }

  renderRawPointEstimate () {
    const { selected, streams } = this.props

    if (this.props.loadingCounts === true) return this.renderLoading()
    if (selected.length === 0) return this.renderNoStreamsSelected()

    const totalPoints = Object.keys(streams)
      .filter(k => streams[k].selected) // get only selected streams
      .map(k => streams[k].count) // grab the # of points
      .reduce((sum, count) => sum + count, 0) // sum points in all streams
    // totalPoints is really an estimate that we should expect to be off
    // by about 1% and as much as 2% given that we used 100 aligned windows
    // to construct it and there could be a max 1% error on start and 1% on end
    // We should use an estimate of points in the UI so users don't get confused
    // if their CSV doesn't match perfectly
    const estPoints = this.sigDigits(totalPoints, 0.01)
    const estFileSizeKb = (totalPoints * 51) / 1024 // empirically it's about 51 bytes per row
    return (
      <div className='downloadSummary'>
        <div className='section-header'>
          <span>ESTIMATED DOWNLOAD SIZE</span>
        </div>
        <div>
          <div>
            <span className='data'>~{estPoints}</span>
            <span className='label'>measurements</span>{' '}
          </div>
          <div>
            <span className='data'>
              ~
              {estFileSizeKb > 1024
                ? (estFileSizeKb / 1024).toFixed(0) + ' MB'
                : estFileSizeKb.toFixed(0) + ' KB'}
            </span>
            <span className='label'>estimated file size</span>
          </div>
        </div>
        <div className='info'>
          If this is too much data, either choose 'Aggregates' (i.e.
          Min/Max/Mean) instead of 'Raw Data,' select fewer streams, or zoom in
          on the chart to select a narrower time range.
        </div>
      </div>
    )
  }

  render () {
    if (this.props.queryType === 'raw') return this.renderRawPointEstimate()
    else if (this.props.queryType === 'aligned') {
      return this.renderAlignedWindowsEstimate()
    }
    if (this.props.queryType) {
      console.error(
        'The CSV download estimate did not render because it could not recognize the render type:\n',
        this.props.queryType,
        "\nExpected one of 'raw' or 'aligned'"
      )
    }
    return null
  }
}

class CSVStreamsTable extends React.Component {
  static propTypes = {
    streamsObj: PropTypes.object.isRequired,
    toggleSelectedStream: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired
  }

  render () {
    const { streamsObj } = this.props
    const streams = Object.keys(streamsObj).map(k => {
      // merge in selected status here
      const stream = streamsObj[k]
      Object.assign(stream, { selected: this.props.selected.includes(k) })
      return stream
    })
    return (
      <CustomReactTableStyles>
        <ReactTable
          style={{ opacity: 0.75 }}
          data={streams}
          showPagination={false}
          defaultPageSize={streams.length}
          columns={[
            {
              Header: '',
              accessor: 'selected',
              maxWidth: 70,
              style: {
                overflow: 'visible',
                display: 'flex',
                alignItems: 'center'
              },
              Cell: row => {
                // console.log("Row Info:", row);
                return (
                  <input
                    type='checkbox'
                    checked={row.original.selected}
                    readOnly
                  />
                )
              },
              filterable: false
            },
            {
              Header: 'COLLECTION',
              accessor: 'metadata.collection'
            },
            {
              Header: 'UUID',
              accessor: d => {
                // console.log("stream", d);
                // return base64HexToUUID(d.metadata.uuid);
                return d.metadata.uuid
              },
              id: 'uuid'
            },
            {
              Header: 'NAME',
              accessor: d => {
                const md = d.metadata
                const name = md.tags.name || md.annotations.name || '--'
                return name
              },
              id: 'name'
            },
            {
              Header: 'POINTS IN RANGE',
              accessor: d => {
                if (this.props.loadingCounts) return 'loading...'
                return d.count
              },
              id: 'points'
            }
          ]}
          getTrProps={(state, rowInfo, colInfo, instance) => {
            const handlers = {
              onClick: (e, handleOriginal) => {
                if (!rowInfo) return
                console.log('Clicked on a row!', rowInfo)
                const { uuid } = rowInfo.original.metadata
                this.props.toggleSelectedStream(uuid)
                // this.toggleStreamEmphasis("on-hold", uuid, original);
                // probably need to make a toggleEmphasis handler

                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                if (handleOriginal) {
                  handleOriginal()
                }
              }
            }
            return {
              ...handlers,
              style: rowInfo.original.selected
                ? { opacity: '1' }
                : { opacity: '0.5' }
            }
          }}
        />
      </CustomReactTableStyles>
    )
  }
}

const CSVModalBody = (redesignEnabled() ? styled.div : styled(ModalBody))`
  color: ${color('text')};

  .dateTimeRange {
    .label {
      display: inline-block;
      width: 90px;
      margin-right: 10px;
    }
    .data {
    }
  }
  .section-header {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.83;
    letter-spacing: 0.3px;
    color: ${color('subText')};
    opacity: 0.9;

    margin: 30px 0 10px;

    &.first {
      margin-top: 10px;
    }

    .toggle-rawTimeRange {
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
      color: ${color('blue')};
      cursor: pointer;
      margin-left: 12px;
    }
  }
  .downloadSummary {
    margin-top: 40px;
    padding: 10px;
    border-radius: 4px;
    background-color: ${color('subBackground')};

    .section-header {
      margin-top: 15px;
    }
    i {
      margin-left: 10px;
    }

    span.data {
      font-size: 20px;
      font-weight: bold;
      opacity: 0.9;
      margin-right: 10px;
    }
    span.label {
      font-style: italic;
    }
    div.info {
      margin-top: 20px;
      color: ${color('text')}
    }
  }
`

const DataFrequency = styled.div`
  .df-inputgroup {
    display: flex;
    align-items: baseline;
  }
  .df-slider,
  .df-input {
    display: inline-block;
  }
  .df-slider {
    width: 50%;
  }
  .df-input {
    margin-right: 15px;
  }
  .df-sampleRate {
    font-size: 1.1em;
    font-weight: bold;
  }
`

const ExportOptions = styled.div`
  .optionRow {
    margin-bottom: 5px;

    label.ant-radio-button-wrapper {
      width: 120px;
      text-align: center;
    }

    .errorMsg {
      margin-left: 10px;
      color: ${color('red')};
    }
  }

  span.label {
    display: inline-block;
    width: 150px;
  }
`

const mapStateToProps = state => {
  const chart = plotter.selectors.chart(state)
  const selectedRows = plotter.selectors.selectedRows(state)
  const streamsSelected = plotter.selectors.streamsSelected(state)

  return {
    chart,
    selectedRows,
    streamsSelected
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ExportToCSVWrapper)
