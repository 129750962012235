// ----------------------------------------------------------------------------
// FIXME: 64-BIT FLOAT NANOSECONDS ARE ONLY ACCURATE TO MICROSECONDS.
//
// MATH:
// beta.observablehq.com/@shaunlebron/btrdb-limits-in-javascript
//
// SOLUTION:
// This will probably have to be done by splitting the timestamp into a
// millisecond float, and a remainder nanosecond float.
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// Date to nanosecond timestamp conversion
// ----------------------------------------------------------------------------
export function nsToDate (ns) {
  const ms = Math.floor(ns / 1e6)
  return new Date(ms)
}
export function dateToNs (date) {
  const ms = date.getTime()
  return ms * 1e6
}
export function msToNs (ms) {
  return ms * 1e6
}

// ----------------------------------------------------------------------------
// Durations in nanoseconds
// ----------------------------------------------------------------------------

export const durationNanosecond = 1
export const durationMicrosecond = 1000 * durationNanosecond
export const durationMillisecond = 1000 * durationMicrosecond
export const durationSecond = 1000 * durationMillisecond
export const durationMinute = 60 * durationSecond
export const durationHour = 60 * durationMinute
export const durationDay = 24 * durationHour
export const durationWeek = 7 * durationDay
export const durationMonth = 30 * durationDay
export const durationYear = 365 * durationDay

// ----------------------------------------------------------------------------
// Date constructor
// ----------------------------------------------------------------------------
export function NsDateUtc (Y, M, D, h, m, s, ms, us = 0, ns = 0) {
  return msToNs(Date.UTC(Y, M, D, h, m, s, ms)) + us * durationMicrosecond + ns
}
export function NsDate (Y, M, D, h, m, s, ms, us = 0, ns = 0) {
  return (
    dateToNs(new Date(Y, M, D, h, m, s, ms)) + us * durationMicrosecond + ns
  )
}

// ----------------------------------------------------------------------
// Exact Timestamp Printing
// (Timestamps can be accurately printed to the microsecond, but we have
//  to coax JS to stringify them with enough precision.)
// ----------------------------------------------------------------------

const maxTimestampDigits = Math.ceil(Math.log10(2 ** 64)) // = 20

export function preciseTimestamp (t) {
  const str = t.toPrecision(maxTimestampDigits)
  return str.split('.')[0]
}

// ----------------------------------------------------------------------------
// Duration printing
// ----------------------------------------------------------------------------

const DURATIONS = [
  { ns: durationYear, label: 'years', shortLabel: 'yrs.' },
  { ns: durationMonth, label: 'months', shortLabel: 'mos.' },
  { ns: durationWeek, label: 'weeks', shortLabel: 'wks.' },
  { ns: durationDay, label: 'days' },
  { ns: durationHour, label: 'hours', shortLabel: 'h' },
  { ns: durationMinute, label: 'minutes', shortLabel: 'min' },
  { ns: durationSecond, label: 'seconds', shortLabel: 's' },
  {
    ns: durationMillisecond,
    label: 'milliseconds',
    shortLabel: 'ms',
    hz: 1000
  },
  {
    ns: durationMicrosecond,
    label: 'microseconds',
    shortLabel: 'µs',
    hz: 1000000
  },
  {
    ns: durationNanosecond,
    label: 'nanoseconds',
    shortLabel: 'ns',
    hz: 1000000000
  }
]

export const nanoToDuration = (ns, { short } = {}) => {
  if (!ns) return
  const amounts = DURATIONS.map(t => ({
    num: ns / t.ns,
    label: (short ? t.shortLabel : t.label) || t.label,
    hz: t.hz
  }))
  const biggest = amounts.find(a => a.num >= 1)
  return biggest.num.toFixed(1) + ' ' + biggest.label
}

function readablizeNumber (num) {
  var s = ['', 'K', 'M', 'B']
  var e = Math.floor(Math.log(num) / Math.log(1000))
  if (e === 0) return num.toFixed(0)
  return (num / Math.pow(1000, e)).toFixed(0) + s[e]
}

export const nanoToHuman = ns => {
  if (!ns) return
  const amounts = DURATIONS.map(t => ({
    num: ns / t.ns,
    label: t.label,
    hz: t.hz
  }))
  const biggest = amounts.find(a => a.num >= 1)
  // console.log("The biggest time was:", biggest.num.toFixed(1), biggest.label);
  if (biggest.hz) return readablizeNumber(biggest.hz / biggest.num) + ' Hz'
  else return biggest.num.toFixed(1) + ' ' + biggest.label + ' per point'

  // console.log("    Approx:", readablizeNumber(biggest.hz / biggest.num), "Hz");
}
