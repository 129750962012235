import * as actionTypes from './actionTypes'

const initialState = {
  data: [],
  error: false,
  loading: false,
  success: false
}

export default function heatmap (state = initialState, action) {
  const { data, error, message, uuid } = action
  switch (action.type) {
    case actionTypes.QUERY_WINDOWS:
      return {
        ...state,
        data: [],
        error: false,
        loading: true,
        success: false
      }
    case actionTypes.QUERY_WINDOWS_ERROR:
      return {
        ...state,
        error: true,
        err: error,
        loading: false,
        message: message,
        success: false
      }
    case actionTypes.QUERY_WINDOWS_SUCCESS:
      return {
        ...state,
        data: data.values,
        matrix: {
          ...state.matrix,
          [uuid]: data.values
        },
        error: false,
        loading: false,
        success: true,
        version: data.versionMajor + '.' + data.versionMinor
      }
    case actionTypes.QUERY_START_END:
      return {
        ...state,
        startEnd: data,
        times: {
          ...state.times,
          [uuid]: data
        },
        error: false,
        loading: true,
        success: false
      }
    case actionTypes.QUERY_START_END_ERROR:
      return {
        ...state,
        error: true,
        err: error,
        loading: false,
        success: false
      }
    case actionTypes.QUERY_START_END_SUCCESS:
      return {
        ...state,
        startEnd: data,
        times: {
          ...state.times,
          [uuid]: data
        },
        error: false,
        loading: false,
        success: true
      }
    default:
      return state
  }
}
