export const ADD_RESIZABLE_GRID_ITEM =
  'ui/resizableGridLayout/ADD_RESIZABLE_GRID_ITEM'
export const LOCK_MINIPLOTTER = 'ui/miniplotter/LOCK_MINIPLOTTER'
export const REMOVE_RESIZABLE_GRID_ITEM =
  'ui/resizableGridLayout/REMOVE_RESIZABLE_GRID_ITEM'
export const RESET_RESIZABLE_GRID =
  'ui/resizableGridLayout/RESET_RESIZABLE_GRID'
export const SET_MINIPLOTTER_POSITION =
  'ui/miniplotter/SET_MINIPLOTTER_POSITION'
export const SET_RESIZABLE_GRID_DIRTY =
  'ui/resizableGridLayout/SET_RESIZABLE_GRID_DIRTY'
export const SET_RESIZABLE_GRID_DRAGGING =
  'ui/resizableGridLayout/SET_RESIZABLE_GRID_DRAGGING'
export const SET_RESIZABLE_GRID_LAYOUTS =
  'ui/resizableGridLayout/SET_RESIZABLE_GRID_LAYOUTS'
export const TOGGLE_LOCK_RESIZABLE_GRID_ITEMS =
  'ui/resizableGridLayout/TOGGLE_LOCK_RESIZABLE_GRID_ITEMS'
export const UNLOCK_MINIPLOTTER = 'ui/miniplotter/UNLOCK_MINIPLOTTER'
