import LRU from 'lru-cache'

let instance = null

const CACHE_SIZE = process.env.REACT_APP_CACHE_SIZE
  ? Number(process.env.REACT_APP_CACHE_SIZE)
  : 15000

const CACHE_MAX_AGE = process.env.REACT_APP_CACHE_AGE
  ? Number(process.env.REACT_APP_CACHE_AGE)
  : 5 * 60 * 1000

export default class LruCache {
  constructor () {
    if (instance) {
      return instance
    }

    this.cache = new LRU({
      size: CACHE_SIZE,
      maxAge: CACHE_MAX_AGE
    })

    instance = this
  }

  get ({ key }) {
    return this.cache.get(key)
  }

  set ({ key, value }) {
    this.cache.set(key, value)
  }
}
