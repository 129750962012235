import React from 'react'
import ReactDOM from 'react-dom'

import './assets/styles/index.css'
import { createBrowserHistory } from 'history'
import { init as initSentry } from './utils/sentry'
import designLeakCheck from './utils/designLeakCheck'
import { library } from '@fortawesome/fontawesome-svg-core'
import { portalEnabled, redesignEnabled } from './utils/featureFlags'
import { Router, Switch, Route } from 'react-router-dom'
import { unregister } from './registerServiceWorker'
import { warnMissingEnvVars } from './utils/warnMissingEnvVars'
import App from './components/_App/App'
import Portal from './components/Portal'
import ScreenshotPage, {
  ScreenshotImage
} from './components/ScreenshotPage/ScreenshotPage'

import {
  faPlus,
  faTimes,
  faAngleLeft,
  faAngleRight,
  faInfoCircle,
  faChevronRight,
  faChevronLeft,
  faTimesCircle,
  faPlusCircle,
  faUndo
} from '@fortawesome/free-solid-svg-icons'
import { initGoogleAnalytics } from './utils/initGoogleAnalytics'
library.add(
  faPlus,
  faTimes,
  faAngleLeft,
  faAngleRight,
  faInfoCircle,
  faChevronRight,
  faChevronLeft,
  faTimesCircle,
  faPlusCircle,
  faUndo
)

// Load Roboto typeface
require('typeface-roboto')

// env vars injected at build time must take form REACT_APP_XXXX
// if injected at runtime via (env2js.sh -> config.js -> import by index.html)
// they can be of any form
const expectedVars = []
window.__env__ = window.__env__ || {}
if (process.env.NODE_ENV !== 'production') {
  window.__env__ = { ...window.__env__, ...process.env }
}
warnMissingEnvVars(expectedVars, window.__env__)

// initialize Sentry error handling system
initSentry(window.__env__.SENTRY_DSN)

// use history instead of a built in react router to let Google Analytics
// listen to hitory events
const history = createBrowserHistory()
initGoogleAnalytics({
  trackingId: window.__env__.GOOGLE_ANALYTICS_ID || undefined,
  history
})

function initReact () {
  if (redesignEnabled()) {
    ;(async () => {
      const { default: RedesignApp } = await import(
        './components/_App/RedesignApp'
      )
      ReactDOM.render(
        <RedesignApp history={history} />,
        document.getElementById('root'),
        designLeakCheck
      )
    })()
  } else {
    ReactDOM.render(
      <>
        <Router history={history}>
          <Switch>
            <Route
              exact
              path='/screenshot/html/:width/:permalinkId'
              component={ScreenshotPage}
            />
            <Route
              exact
              path='/screenshot/image/:width/:permalinkId'
              component={ScreenshotImage}
            />
            {portalEnabled() && (
              <Route exact path='/portal' component={Portal} />
            )}
            <Route path='*' component={App} />
          </Switch>
        </Router>
      </>,
      document.getElementById('root')
    )
  }
}

initReact()
window.reinit = initReact

// registerServiceWorker();
unregister()
