import React, { useState } from 'react'

import { FloatingModal, ModalContent } from 'frontend-components'
import { useDispatch, useSelector } from 'react-redux'

import { MODALS } from '../../constants'
import modals from '../../ducks/modals'
import streamsDuck from '../../ducks/streams'
import useMetadataUsage from '../../hooks/useMetadataUsage'

const ColumnSelectorModal = ({ selectedRows }) => {
  const { metadataUsageStatus } = useMetadataUsage()
  const dispatch = useDispatch()

  let columnNames = useSelector(state =>
    streamsDuck.selectors.availableColumns(state)
  )
  const modalIsOpen = useSelector(state =>
    modals.selectors.modalOpen(state, MODALS.STREAM_TABLE.COLUMN_SELECTOR)
  )

  const [selectedColumns, setSelectedColumns] = useState(
    useSelector(state =>
      streamsDuck.selectors.streamTableSelectedColumns(state)
    )
  )

  if (selectedRows) {
    columnNames = streamsDuck.serializers.serializeAvailableColumns({
      addAnnotations: true,
      streamsData: Object.keys(selectedRows).map(
        uuid => selectedRows[uuid].metadata
      )
    })
  }
  const error = metadataUsageStatus === 'error'
  const loading = metadataUsageStatus === 'loading'

  const columnIsSelected = name =>
    selectedColumns.map(col => col.id).includes(name)

  const handleClose = () => dispatch(modals.actions.close())

  const handleColumnClick = name => {
    const nameIsSelected = columnIsSelected(name)
    if (nameIsSelected) {
      const newColumns = selectedColumns.filter(
        selectedCol => selectedCol.id !== name
      )
      setSelectedColumns(newColumns)
    } else {
      const newColumn = streamsDuck.serializers.serializeColumn({ name })
      setSelectedColumns([...selectedColumns, newColumn])
    }
  }

  const handleSubmit = () => {
    dispatch(streamsDuck.actions.setStreamTableColumns({ selectedColumns }))
    handleClose()
  }

  return (
    <>
      {modalIsOpen && (
        <FloatingModal
          cancel={handleClose}
          hideButtons
          variants={['inline-block', 'skeleton']}
        >
          <ModalContent
            handleColumnClick={handleColumnClick}
            columnNames={columnNames}
            error={error}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            loading={loading}
            selectedColumns={selectedColumns}
          />
        </FloatingModal>
      )}
    </>
  )
}

export default ColumnSelectorModal
