import * as d3time from 'd3-time'
import * as d3timeformat from 'd3-time-format'
import * as d3format from 'd3-format'
import * as d3array from 'd3-array'
import { fastTextWidth } from './canvasText.js'
import { isInRange } from './btrdbMath.js'

// ------------------------------------------------------------------------------
// Axis spec
//
// Configure the axis' cell division strategy.
// Each row is [unitName, unitsPerCell, ...labelStrings]
// ------------------------------------------------------------------------------

// NOTE: First character prefix of each format is significant:
//
//  "_" = region    |-----|
//                    Jan
//
//  else point      ---|---
//                    Jan
const specData = [
  ['year', 1000, '_%Ys'],
  ['year', 100, '_%Ys'],
  ['year', 50, '%Y'],
  ['year', 20, '%Y'],
  ['year', 10, '_%Ys'],
  ['year', 5, '%Y'],
  ['year', 2, '%Y'],
  ['year', 1, '_%Y'],
  ['month', 6, '%b'],
  ['month', 4, '%b'],
  ['month', 3, '%b'],
  ['month', 2, '%b'],
  ['month', 1, '_%b %Y', '_%b'],
  ['week', 1, '%a %d', '%d'],
  ['day', 1, '_%a %b %-d, %Y', '_%a %-d', '_%d'],

  ['hour', 12, '%I %p'],
  ['hour', 6, '%I %p'],
  ['hour', 4, '%I %p'],
  ['hour', 3, '%I %p'],
  ['hour', 2, '%I %p'],
  ['hour', 1, '%I %p'],
  ['minute', 30, '%I:%M %p'],
  ['minute', 15, '%I:%M %p'],
  ['minute', 10, '%I:%M %p'],
  ['minute', 5, '%I:%M %p'],
  ['minute', 2, '%I:%M %p'],
  [
    'minute',
    1,
    '_%a %b %-d, %Y (%I:%M %p)',
    '_%b %-d, %Y (%I:%M %p)',
    '%I:%M %p'
  ],
  // ["second", 30, ":%S…"],
  ['second', 15, ':%S'],
  ['second', 10, ':%S'],
  ['second', 5, ':%S'],
  ['second', 2, ':%S'],
  ['second', 1, '_%a %b %-d, %Y (%I:%M:%S %p)', '%I:%M:%S %p', ':%S'],
  ['microsecond', 1e5, '.1f'],
  ['microsecond', 5e4, '.2f'],
  ['microsecond', 2e4, '.2f'],
  ['microsecond', 1e4, '.2f'],
  ['microsecond', 5e3, '.3f'],
  ['microsecond', 2e3, '.3f'],
  ['microsecond', 1e3, '.3f'],
  ['microsecond', 500, '.4f'],
  ['microsecond', 200, '.4f'],
  ['microsecond', 100, '.4f'],
  ['microsecond', 50, '.5f'],
  ['microsecond', 20, '.5f'],
  ['microsecond', 10, '.5f'],
  ['microsecond', 5, '.6f'],
  ['microsecond', 2, '.6f'],
  ['microsecond', 1, '.6f']
]

// ------------------------------------------------------------------------------
// Date/D3 wrappers for:
// - easier utc/local switching
// - timestamp input/output (instead of Date objects)
// ------------------------------------------------------------------------------

// to make get/set functions that take/return timestamp values (not Date objects)
function wrapDateUnits (utc) {
  const names = ['FullYear', 'Month', 'Date', 'Hours', 'Minutes', 'Seconds']
  const make = name => {
    const nameGet = utc ? `getUTC${name}` : `get${name}`
    const nameSet = utc ? `setUTC${name}` : `set${name}`
    return {
      get: t => new Date(t)[nameGet](),
      set: (t, val) => +new Date(t)[nameSet](val)
    }
  }
  const funcs = {}
  for (const name of names) funcs[name] = make(name)
  return funcs
}
const dateUnits = {
  utc: wrapDateUnits(true),
  local: wrapDateUnits(false)
}

function wrapD3TimeUnits (utc) {
  const names = ['Year', 'Month', 'Week', 'Day', 'Hour', 'Minute', 'Second']
  const make = name => {
    const int = d3time[utc ? `utc${name}` : `time${name}`]
    return {
      floor: (...args) => +int.floor(...args),
      ceil: (...args) => +int.ceil(...args),
      count: (...args) => int.count(...args),
      offset: (...args) => +int.offset(...args),
      range: (...args) => int.range(...args).map(date => +date)
    }
  }
  const funcs = {}
  for (const name of names) funcs[name] = make(name)
  return funcs
}
const d3TimeUnits = {
  utc: wrapD3TimeUnits(true),
  local: wrapD3TimeUnits(false)
}

const d3TimeFormats = {
  utc: d3timeformat.utcFormat,
  local: d3timeformat.timeFormat
}

const specs = {
  utc: compileSpec('utc', specData),
  local: compileSpec('local', specData)
}

// ------------------------------------------------------------------------------
// Time units for counting multiples
// ------------------------------------------------------------------------------

// count multiples of arbitrary standard time units (measured by native Date and d3-time)
function multiUnit (timezone, keyDate, keyD3, size) {
  const dateUnit = dateUnits[timezone][keyDate]
  const d3TimeUnit = d3TimeUnits[timezone][keyD3]
  if (size === 1) return d3TimeUnit
  const idx = (t, method) => Math[method || 'floor'](dateUnit.get(t) / size)
  const _round = (t, roundMethod) => {
    t = d3TimeUnit.floor(t)
    return dateUnit.set(t, idx(t, roundMethod) * size)
  }
  const floor = t => _round(t, 'floor')
  const ceil = t => _round(t, 'ceil')
  const offset = (t, step) => dateUnit.set(t, (idx(t) + step) * size)

  // NOTE: This kept undershooting the desired value for getting the number of ticks correctly
  // so I am adding ceil + 1 to we get at least the correct number of ticks.
  const count = (start, end) =>
    Math.ceil(d3TimeUnit.count(start, end) / size) + 1

  const range = (start, end, step = 1) =>
    d3array.range(0, count(start, end), step).map(i => offset(start, i))
  const f = floor
  f.floor = floor
  f.ceil = ceil
  f.count = count
  f.offset = offset
  f.range = range
  return f
}

// count microsecond multiples
function multiUnitMicro (timezone, size) {
  const micros = t => {
    const t0 = d3TimeUnits[timezone].Second.floor(t)
    const ms = t - t0
    const us = ms * 1e3
    return { t0, us }
  }
  const timestamp = ({ t0, us }) => {
    const ms = us / 1e3
    return t0 + ms
  }
  const idx = (us, method) => Math[method || 'floor'](us / size)
  const _round = (t, roundMethod) => {
    const { t0, us } = micros(t)
    return timestamp({ t0, us: idx(us, roundMethod) * size })
  }
  const floor = t => _round(t, 'floor')
  const ceil = t => _round(t, 'ceil')
  const offset = (t, step) => t + (step * size) / 1e3
  const count = (start, end) => {
    const ms = end - start
    const us = ms * 1e3
    return us / size
  }
  const range = (start, end, step = 1) => {
    return d3array.range(0, count(start, end), step).map(i => offset(start, i))
  }
  const field = t => micros(t).us
  const f = floor
  f.floor = floor
  f.ceil = ceil
  f.count = count
  f.offset = offset
  f.range = range
  f.field = field
  return f
}

// ------------------------------------------------------------------------------
// Compile an axis spec into appropriate functions
//
// Spec: [unitName, unitsPerCell, ...labelStrings]
// => {
//       unitName,      // Name of our base unit (for debugging).
//       unit,          // A d3-time unit object for measuring desired multiples
//                      // with functions { floor, ceil, range, offset, count }.
//       labels,        // Array of objects for formatting fitted labels with
//                      // {
//                      //   biggestUnit,   // integer (bigger means bigger unit)
//                      //   format,        // function to stringify value
//                      // }
//    }
// ------------------------------------------------------------------------------

function compileSpec (timezone, spec) {
  const units = {
    year: size => multiUnit(timezone, 'FullYear', 'Year', size),
    month: size => multiUnit(timezone, 'Month', 'Month', size),
    week: size => multiUnit(timezone, null, 'Week', size), // size ignored
    day: size => multiUnit(timezone, 'Date', 'Day', size),
    hour: size => multiUnit(timezone, 'Hours', 'Hour', size),
    minute: size => multiUnit(timezone, 'Minutes', 'Minute', size),
    second: size => multiUnit(timezone, 'Seconds', 'Second', size),
    microsecond: size => multiUnitMicro(timezone, size)
  }
  const unitNames = Object.getOwnPropertyNames(units).reverse()
  const unitIndex = name => unitNames.indexOf(name)
  const keyToUnit = {
    '%Y': 'year',
    '%y': 'year',
    '%b': 'month',
    '%d': 'day',
    '%I': 'hour',
    '%p': 'hour',
    '%M': 'minute',
    '%S': 'second'
  }
  const biggestLabelUnit = label =>
    label
      .match(/%[-_0]?./g)
      .map(s => '%' + s.slice(-1))
      .map(key => keyToUnit[key])
      .filter(name => name)
      .map(unitIndex)
      .sort((a, b) => a - b)
      .slice(-1)[0]

  const timeFormat = d3TimeFormats[timezone]
  const microFormat = (unit, label) => t => {
    const s = unit.field(t) / 1e6
    const ellipsis = label.endsWith('…')
    return (
      d3format
        .format(ellipsis ? label.slice(0, -1) : label)(s)
        .slice(1) + (ellipsis ? '…' : '')
    )
  }
  const compileSpecRow = specRow => {
    const [unitName, size, ...labels] = specRow
    const unit = units[unitName](size)
    const compileLabel = label => {
      const isRegion = label.startsWith('_')
      if (isRegion) label = label.slice(1)
      return unitName === 'microsecond'
        ? {
            format: microFormat(unit, label),
            biggestUnit: unitIndex(unitName),
            isRegion
          }
        : {
            format: timeFormat(label),
            biggestUnit: biggestLabelUnit(label),
            isRegion
          }
    }
    return { unitName, unit, labels: labels.map(compileLabel) }
  }

  return spec.map(compileSpecRow)
}

function getAxisLayers (spec, scale) {
  const [a, b] = scale.domain()
  let result = []
  const pad = 10
  for (const { unitName, unit, labels } of spec) {
    // get visible cells
    const start = unit.floor(a)
    const end = unit.ceil(b)

    // get size of one cell
    const t0 = unit.floor(0)
    const t1 = unit.offset(t0, 1)
    const cellW = scale(t1) - scale(t0)

    // find a label that fits in cell
    const label = getFittedLabel(cellW - pad, t0, labels)
    if (label) {
      result = result.filter(i => i.label.biggestUnit > label.biggestUnit)
      const ticks = unit.range(start, end)
      const cells = ticks.map(t => [t, unit.offset(t, 1)])
      result.push({ unitName, unit, label, labels, ticks, cells })
    }
  }
  return result
}

function getFittedLabel (w, t, labels) {
  return labels.find(({ format }) => {
    const text = format(t)
    const textW = fastTextWidth(text)
    return textW < w
  })
}

export function computeAxisLayers (scale, { isUTC }) {
  const spec = specs[isUTC ? 'utc' : 'local']
  return getAxisLayers(spec, scale).reverse()
}

export function drawAxisLayers (
  ctx,
  scale,
  layers,
  { rowH, hoverCoord, hoverMode, selectedTimeRange }
) {
  const xBounds = scale.range()
  const tickH = rowH * 0.2
  ctx.save()
  ctx.beginPath()
  ctx.textBaseline = 'middle'
  const fitText = (label, t, [x0, x1]) => {
    if (!label) return
    const text = label.format(t)
    ctx.reactTextAttrs = { key: `tick-${text}-${t}` }
    ctx.textCss = { userSelect: 'none', pointerEvents: 'none' }

    // draw tick label
    if (!label.isRegion) {
      ctx.textAlign = 'center'
      ctx.fillText(text, x0, rowH / 2)
      return
    }

    // draw region label
    const croppedLeft = x0 < xBounds[0] && x1 < xBounds[1]
    const croppedRight = xBounds[0] < x0 && xBounds[1] < x1
    x0 = Math.max(x0, xBounds[0])
    x1 = Math.min(x1, xBounds[1])
    const boxW = x1 - x0
    const pad = 5
    const fits = fastTextWidth(text) <= boxW - pad * 2
    const y = rowH / 2
    if (croppedLeft && !fits) {
      ctx.textAlign = 'right'
      ctx.fillText(text, x1 - pad, y)
    } else if (croppedRight && !fits) {
      ctx.textAlign = 'left'
      ctx.fillText(text, x0 + pad, y)
    } else {
      ctx.textAlign = 'center'
      ctx.fillText(text, (x0 + x1) / 2, y)
    }
  }

  const rowAlphas = [1, 0.6].slice(0, layers.length)

  // draw ticks and labels
  ctx.save()
  for (const [row, { label, ticks, cells }] of layers.entries()) {
    ctx.globalAlpha = rowAlphas[row]
    for (const t of ticks) {
      const x = scale(t)
      if (isInRange(x, scale.range())) {
        ctx.moveTo(x, 0)
        ctx.lineTo(x, tickH)
      }
    }
    ctx.stroke()
    for (const [col, [t0, t1]] of cells.entries()) {
      const [x0, x1] = [t0, t1].map(scale)
      if (hoverCoord) {
        const hoverMe = row === hoverCoord.row && col === hoverCoord.col
        if (hoverMe) {
          if (hoverMode === 'area') {
            ctx.beginPath()
            ctx.moveTo(x0, -0.5)
            ctx.lineTo(x1, -0.5)
            ctx.stroke()
          } else if (hoverMode === 'tick') {
            const t = hoverCoord.snapT
            const dx = !selectedTimeRange
              ? 0
              : t === selectedTimeRange[0]
              ? -0.5
              : 0.5
            const x = scale(t / 1e6) + dx
            ctx.save()
            ctx.beginPath()
            ctx.moveTo(x, -row * rowH)
            ctx.lineTo(x, rowH)
            ctx.lineWidth = 2
            ctx.stroke()
            ctx.restore()
          }
        }
      }
      fitText(label, t0, [x0, x1])
    }
    ctx.translate(0, rowH)
  }
  ctx.restore()

  // draw horizontal lines
  ctx.save()
  for (const alpha of rowAlphas) {
    ctx.globalAlpha = alpha
    ctx.beginPath()
    ctx.moveTo(xBounds[0], 0.5)
    ctx.lineTo(xBounds[1], 0.5)
    ctx.stroke()
    ctx.translate(0, rowH)
  }
  ctx.restore()

  ctx.restore()
}
