import lodashGet from 'lodash.get'

import clone from 'lodash/cloneDeep'

import * as actionTypes from './actionTypes'
import generateInitialLayout from './initialLayout'
import { report as reportSentry } from '../../utils/sentry'

export const initialState = {
  miniplotter: {
    locked: false,
    position: {}
  },
  resizableGridLayout: {
    isDirty: false,
    isDraggable: true,
    isResizable: true,
    layouts: {
      xxs: generateInitialLayout()
    }
  }
}

const getBaseLayout = ({ itemName }) => {
  return initialState.resizableGridLayout.layouts.xxs.find(
    layout => layout.i === itemName
  )
}

export const getLastItem = ({ layout }) =>
  layout.reduce(function (prev, current) {
    if (+current.y > +prev.y) {
      return current
    } else {
      return prev
    }
  })

export default function ui (state = initialState, action) {
  if (action.type && action.type.startsWith('ui/miniplotter/')) {
    return { ...state, miniplotter: miniplotter(state.miniplotter, action) }
  } else if (action.type && action.type.startsWith('ui/resizableGridLayout/')) {
    return {
      ...state,
      resizableGridLayout: resizableGridLayout(
        state.resizableGridLayout,
        action
      )
    }
  }

  return state
}

function miniplotter (state, action) {
  switch (action.type) {
    case actionTypes.LOCK_MINIPLOTTER: {
      return {
        ...state,
        locked: true
      }
    }
    case actionTypes.SET_MINIPLOTTER_POSITION: {
      const { top } = action
      const newPosition = { ...state.position }
      if (top) {
        newPosition.top = top
      }

      return {
        ...state,
        position: newPosition
      }
    }
    case actionTypes.UNLOCK_MINIPLOTTER: {
      return {
        ...state,
        locked: false
      }
    }

    default:
      return state
  }
}

function resizableGridLayout (state, action) {
  switch (action.type) {
    case actionTypes.ADD_RESIZABLE_GRID_ITEM: {
      const newState = clone(state)
      const layouts = { ...newState.layouts }
      const layout = lodashGet(layouts, 'xxs')

      if (Array.isArray(layout)) {
        const itemToAdd = getBaseLayout({ itemName: action.itemName })
        const layoutIncludesMissingItemToAdd = !!layout.find(
          item => item.i === action.itemName
        )

        if (layoutIncludesMissingItemToAdd || !itemToAdd) {
          reportSentry(
            new Error(
              `error adding resizable grid item; itemName: ${
                action.itemName
              }; layouts: ${JSON.stringify(layouts)}`
            ),
            {
              level: 'error',
              tags: {
                alertName: 'error adding resizable grid item'
              }
            }
          )
          return newState
        }

        const lastItem = getLastItem({ layout })
        const newY = lastItem.y + 1
        const newX = 0
        itemToAdd.y = newY
        itemToAdd.x = newX
        layout.push(itemToAdd)
        layouts.xxs = layout
      } else {
        reportSentry(
          new Error(
            `error adding resizable grid item; itemName: ${
              action.itemName
            }; layouts: ${JSON.stringify(layouts)}`
          ),
          {
            level: 'error',
            tags: {
              alertName: 'error adding resizable grid item'
            }
          }
        )
      }

      return {
        ...newState,
        isDirty: true,
        layouts
      }
    }
    case actionTypes.REMOVE_RESIZABLE_GRID_ITEM: {
      const newState = clone(state)
      const layouts = { ...newState.layouts }

      let layout = lodashGet(layouts, 'xxs')

      if (Array.isArray(layout)) {
        const layoutContainsElement = !!layout.find(
          item => item.i === action.itemName
        )
        layout = layout.filter(item => item.i !== action.itemName)
        if (!layout || !layoutContainsElement) {
          reportSentry(
            new Error(
              `error removing resizable grid item; itemName: ${
                action.itemName
              }; layouts: ${JSON.stringify(layouts)}`
            ),
            {
              level: 'error',
              tags: {
                alertName: 'error removing resizable grid item'
              }
            }
          )
          return newState
        }
        layouts.xxs = layout
      } else {
        reportSentry(
          new Error(
            `error removing resizable grid item; itemName: ${
              action.itemName
            }; layouts: ${JSON.stringify(layouts)}`
          ),
          {
            level: 'error',
            tags: {
              alertName: 'error removing resizable grid item'
            }
          }
        )
      }

      return {
        ...newState,
        isDirty: true,
        layouts
      }
    }
    case actionTypes.RESET_RESIZABLE_GRID: {
      return {
        ...initialState.resizableGridLayout
      }
    }
    case actionTypes.SET_RESIZABLE_GRID_DIRTY: {
      return {
        ...state,
        isDirty: true
      }
    }
    case actionTypes.SET_RESIZABLE_GRID_DRAGGING: {
      return {
        ...state,
        dragging: action.status,
        isDirty: true
      }
    }
    case actionTypes.SET_RESIZABLE_GRID_LAYOUTS: {
      return {
        ...state,
        layouts: action.layouts
      }
    }
    case actionTypes.TOGGLE_LOCK_RESIZABLE_GRID_ITEMS: {
      return {
        ...state,
        isDirty: true,
        isDraggable: !state.isDraggable,
        isResizable: !state.isResizable
      }
    }

    default:
      return state
  }
}
