import React from 'react'

import { bindTheme, Button, Checkbox, Select, Table } from 'frontend-components'
import styled from '@xstyled/styled-components'

import { AxisSideSelector } from './AxesTable.redesign'
import { inferAxisName } from '../VisualizePage/utils'
import { StreamFocus } from './StreamListTableComponents/StreamFocus'
import { ResetXScale } from './StreamListTableComponents/ResetXScale'
import { theme } from './streamListTableTheme'

const colors = bindTheme(theme)

const StyledButton = styled(Button)`
  border: none !important;
  padding: 0 4px;
  outline: 0;

  &:hover {
    color: ${colors('button.color')};
  }
`

const StyledSelect = styled(Select)`
  font-size: 12px;
  padding: 4px 8px;
`

const StyledTable = styled(Table)`
  margin-bottom: 24px;
`

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const CheckboxWrapper = styled(Wrapper)`
  > div {
    margin-bottom: 0;

    label {
      font-size: 12px;
      margin-left: 0;
      display: flex;
      align-items: center;
      padding-left: 0;
    }

    svg {
      height: 22px;
      padding-right: 4px;
      width: 22px;

      path {
        fill: ${props => props.color};
      }
    }

    &:not(:first-of-type) {
      margin-left: 8px;
    }
  }
`

export const ActionColumn = ({ axes, row, updateAxis }) => {
  const axis = row.values.axis
  const side = axes[axis] ? axes[axis].side : null

  return (
    <Wrapper>
      <ResetXScale {...row} />
      <StreamFocus {...row} />
      {side && axis && (
        <AxisSideSelector axis={axis} side={side} updateAxis={updateAxis} />
      )}
    </Wrapper>
  )
}

export const AxisSelect = ({
  axisNames,
  changeStreamAxis,
  createNewAxisAndAddStream,
  metadata,
  plot
}) => {
  const createNewOption = 'Create new...'
  axisNames = axisNames.concat([createNewOption])

  const originalName = inferAxisName(metadata)
  const axisHasBeenChanged = !!plot && plot.unit !== originalName

  const handleChange = e => {
    if (e.target.value === createNewOption) {
      createNewAxisAndAddStream(plot.uuid)
    } else {
      changeStreamAxis(plot.uuid, e.target.value)
    }
  }

  return (
    <Wrapper>
      <StyledSelect
        className='styled-select'
        items={axisNames}
        onChange={handleChange}
        value={plot ? plot.unit : ''}
        variant='simple'
      />
      {axisHasBeenChanged && (
        <StyledButton
          label='reset'
          onClick={() => changeStreamAxis(plot.uuid, originalName)}
          size='sm'
          variant='ghost'
        />
      )}
    </Wrapper>
  )
}

export const StreamShowHide = ({
  axisVisibility,
  color,
  plotVisibility,
  toggleAxisViz,
  toggleStreamViz
}) => {
  return (
    <CheckboxWrapper color={color}>
      <Checkbox
        checked={plotVisibility}
        label='Stream'
        onChange={toggleStreamViz}
        value={plotVisibility}
      />
      <Checkbox
        checked={axisVisibility}
        label='Axis'
        onChange={toggleAxisViz}
        value={axisVisibility}
      />
    </CheckboxWrapper>
  )
}

const StreamListTable = ({ columns, data }) => {
  return (
    <StyledTable
      columns={columns}
      data={data}
      pagination={{ pageIndex: 0, pageSize: 1000 }}
    />
  )
}

export default StreamListTable
