import { TOKEN_KEY, USERNAME_KEY } from '../constants'

export function getTokenAndUser () {
  const token = window.localStorage.getItem(TOKEN_KEY)
  const username = window.localStorage.getItem(USERNAME_KEY)
  return {
    token: token === 'undefined' ? null : token,
    username: username === 'undefined' ? null : username
  }
}

export const clearTokenAndUser = () => {
  window.localStorage.removeItem(TOKEN_KEY)
  window.localStorage.removeItem(USERNAME_KEY)
}
