import * as actionTypes from './actionTypes'

export const addResizableGridItem = ({ itemName }) => {
  return {
    type: actionTypes.ADD_RESIZABLE_GRID_ITEM,
    itemName
  }
}

export const lockMiniplotter = () => {
  return {
    type: actionTypes.LOCK_MINIPLOTTER
  }
}

export const setMiniplotterPosition = ({ top }) => {
  return {
    type: actionTypes.SET_MINIPLOTTER_POSITION,
    top
  }
}

export const setResizableGridDirty = () => {
  return {
    type: actionTypes.SET_RESIZABLE_GRID_DIRTY
  }
}

export const setResizableGridDragging = ({ status }) => {
  return {
    type: actionTypes.SET_RESIZABLE_GRID_DRAGGING,
    status
  }
}

export const setResizableGridLayouts = ({ layouts }) => {
  return {
    type: actionTypes.SET_RESIZABLE_GRID_LAYOUTS,
    layouts
  }
}

export const removeResizableGridItem = ({ itemName }) => {
  return {
    type: actionTypes.REMOVE_RESIZABLE_GRID_ITEM,
    itemName
  }
}

export const resetResizableGrid = () => {
  return {
    type: actionTypes.RESET_RESIZABLE_GRID
  }
}

export const toggleLockResizableGridItems = () => {
  return {
    type: actionTypes.TOGGLE_LOCK_RESIZABLE_GRID_ITEMS
  }
}

export const unlockMiniplotter = () => {
  return {
    type: actionTypes.UNLOCK_MINIPLOTTER
  }
}
