import gql from 'graphql-tag'

// Aligned Windows API call
const ALIGNED_WINDOWS = gql`
  subscription alignedwindows(
    $uuid: Uuid!
    $start: String!
    $end: String!
    $pointWidth: Int!
  ) {
    AlignedWindows(
      body: { uuid: $uuid, start: $start, end: $end, pointWidth: $pointWidth }
    ) {
      stat {
        code
        msg
      }
      versionMajor
      versionMinor
      values {
        time
        min
        mean
        max
        count
      }
    }
  }
`

export const alignedWindows = async ({
  client,
  uuid,
  start,
  end,
  resolution // pass exponent: 2**12 <-- pass 12 only (base 2 is assumed)
}) => {
  const observable = client.subscribe({
    query: ALIGNED_WINDOWS,
    fetchPolicy: 'no-cache',
    variables: {
      uuid,
      start: start + '',
      end: end + '',
      pointWidth: resolution
    }
  })

  function queryPromise (response = {}) {
    return new Promise((resolve, reject) => {
      observable.subscribe({
        next: res => {
          response = res.data.AlignedWindows
        },
        // Better UI for Error & Loading States to be handled: https://app.clubhouse.io/pingthings-ws/story/11436/handle-error-and-loading-states-for-heatmap?vc_group_by=day
        error: error => console.log(error) || reject(error),
        complete: () => resolve(response)
      })
    })
  }

  try {
    const response = await queryPromise()
    return { data: response, error: false, loading: false }
  } catch (e) {
    console.error(e.message)
    return { error: true, loading: false, message: e.message }
  }
}

// Query Nearest API call
const QUERY_NEAREST = gql`
  query Nearest($uuid: Uuid!, $start: String!, $end: String!) {
    #time is a string in nanoseconds
    start: Nearest(body: { uuid: $uuid, time: $start, backward: false }) {
      stat {
        code
        msg
      }
      versionMajor
      versionMinor
      value {
        time
        value
      }
    }
    end: Nearest(body: { uuid: $uuid, time: $end, backward: true }) {
      stat {
        code
        msg
      }
      versionMajor
      versionMinor
      value {
        time
        value
      }
    }
  }
`

export const queryStartEnd = async ({ client, uuid }) => {
  const promise = client.query({
    query: QUERY_NEAREST,
    variables: {
      uuid,
      start: '0',
      // Send a query for the end of the day instead of now so the timestamp stays consistent and our gql client can more easily cache the request
      end: new Date().setUTCHours(23, 59, 59, 999) + '000000'
    }
  })
  const result = await promise
  let returnValue = {}
  try {
    returnValue = {
      ...result,
      data: [result.data.start.value.time, result.data.end.value.time]
    }
  } catch (error) {
    returnValue = result
  }
  return returnValue
}
