import React from 'react'
import styled from 'styled-components'

const NotFound = styled.div`
  margin-top: 10%;
  text-align: center;
`

const NotFoundPage = () => {
  return (
    <NotFound>
      <h1>Oops! That page can't be found.</h1>
    </NotFound>
  )
}

export default NotFoundPage
