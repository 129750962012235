import * as actionTypes from './actionTypes'

export const authorize = ({ token, username }) => {
  return {
    type: actionTypes.AUTHORIZE,
    token,
    username
  }
}

export const changePassword = ({ currPassword, newPassword, username }) => {
  return {
    type: actionTypes.CHANGE_PASSWORD,
    currPassword,
    newPassword,
    username
  }
}

export const clearChangePassword = () => {
  return {
    type: actionTypes.CLEAR_CHANGE_PASSWORD
  }
}

export const clearApiKey = () => {
  return {
    type: actionTypes.CLEAR_API_KEY
  }
}

export const login = ({ password, username }) => {
  return {
    type: actionTypes.LOGIN,
    password,
    username
  }
}

export const logout = () => {
  return {
    type: actionTypes.LOGOUT
  }
}

export const resetApiKey = ({ password, username }) => {
  return {
    type: actionTypes.RESET_API_KEY,
    password,
    username
  }
}
