import React from 'react'

import { connect } from 'react-redux'
import { Tooltip } from 'antd'
import * as d3array from 'd3-array'
import * as d3color from 'd3-color'
import * as d3format from 'd3-format'
import * as d3interpolate from 'd3-interpolate'
import * as d3scale from 'd3-scale'
import * as d3shape from 'd3-shape'
import * as d3transition from 'd3-transition'
import assert from 'assert'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  getShapes,
  getAllPlotUnits,
  getDefaultTimeDomain,
  getDefaultYDomain,
  tileHasData,
  getMaxPlotDensity,
  getAvgPlotDensity
} from '../../lib/plot'

import {
  timeMin,
  timeMax,
  isInRange,
  clamp,
  clampRange,
  rangesEqual,
  isRangeEmpty,
  rangeIntersect,
  getScaleResolution,
  roundTime,
  cropRange
} from '../../lib/btrdbMath'

import {
  ctxTextExtractor,
  ctxWithoutText,
  fastTextWidth,
  canvasTextCss
} from '../../lib/canvasText'

import {
  emitStreamFocusEvent,
  STREAM_FOCUS_SET
} from '../ChartControls/StreamListTableComponents/StreamFocus'

import { ChartMessage } from '../ChartStream/ChartStream'
import { computeAxisLayers, drawAxisLayers } from '../../lib/timeAxis'
import { DebugCache } from '../DebugCache/DebugCache'
import { KeyboardShortcut } from '../../providers/KeyboardShortcuts'
import { preciseTimestamp, nanoToDuration } from '../../lib/timestamp'
import { SecondaryButton as Button } from '../Buttons/Buttons'
import { splitArray } from '../../utils/array'
import { timeFormatter, utcFormatter } from '../../lib/dateFormat'
import plotter from '../../ducks/plotter'
import TreeViz from '../TreeViz/TreeViz'

import '../../assets/styles/chart.css'

// Chart event commands
export const SET_X_SCALE = 'chartCommand_setXScale'

const {
  actions: { updateSetting },
  selectors: { isUTC: isUTCSelector }
} = plotter

// Controls how far we can pan left and right.
const DOMAIN_BOUNDS = [timeMin, timeMax]
const MAX_DOMAIN_WIDTH = timeMax - timeMin
const MIN_DOMAIN_WIDTH = 1e6 // 1e6 ns = 1 ms

function fadeColor (color, opacity) {
  const c = d3color.color(color)
  c.opacity = opacity
  return c + ''
}

const fuzzyFormatter = d3format.format(',.2s')
function formatFuzzy (d) {
  if (d < 1000) return d
  return fuzzyFormatter(d).replace('G', 'B') // Billion points, not Gigapoints
}

function formatLongInt (num) {
  num = String(num)
  if (!num.match(/^(\d+)$/)) return num
  const n = 3
  const delim = ','
  if (num.length <= n * 2) return num
  const rem = num.length % n
  if (rem !== 0) {
    num = ' '.repeat(n - rem) + num
  }
  return d3array
    .range(0, num.length, n)
    .map(i => [num.slice(i, i + n).trim()])
    .join(delim)
}

// TODO: move to plot.js
function getPointAtTime (plot, t) {
  if (!plot || !t) return
  for (const { timeRange, points, pointRes } of plot.tiles) {
    if (isInRange(t, timeRange)) {
      for (const point of points) {
        if (isInRange(t, [point.time, point.time + 2 ** pointRes])) {
          return { ...point, pointRes }
        }
      }
      break
    }
  }
}

function snapTimeToPoint (plot, t, scaleX, pixelR = 10) {
  const timeR = scaleX.invert(pixelR) - scaleX.invert(0)
  return getClosestPointInRadius(plot, t, timeR)
}

function emptyPointAt (t, pointRes) {
  const t0 = roundTime(t, pointRes, Math.floor)
  return { time: t0, count: 0, pointRes }
}

function getClosestPointInRadius (plot, t, r) {
  const points = getSelectedPoints(plot, [t - r, t + r])
  if (!points) return
  const dist = p => {
    const [a, b] = [p.time, p.time + 2 ** p.pointRes]
    if (isInRange(t, [a, b])) return 0
    return Math.min(Math.abs(a - t), Math.abs(b - t))
  }
  const closest = points.sort((a, b) => dist(a) - dist(b))[0]
  return closest
}

function getSelectedPoints (plot, timeRange) {
  if (!plot || !timeRange || isRangeEmpty(timeRange)) return
  const result = []
  for (const tile of plot.tiles) {
    const { pointRes } = tile
    if (rangeIntersect(timeRange, tile.timeRange)) {
      for (const point of tile.points) {
        if (
          rangeIntersect(timeRange, [point.time, point.time + 2 ** pointRes])
        ) {
          result.push({ ...point, pointRes: tile.pointRes })
        }
      }
    }
  }
  if (result.length === 0) return
  return result
}

function getPointsAggregate (points) {
  if (!points) return
  const max = d3array.max(points, p => p.max)
  const min = d3array.min(points, p => p.min)
  const count = d3array.sum(points, p => p.count)
  const mean = d3array.sum(points, p => p.mean * p.count) / count
  return { max, min, count, mean }
}

function timeAxisCoordsEqual (a, b) {
  a = a || {}
  b = b || {}
  return a.row === b.row && a.col === b.col && a.snapT === b.snapT
}

const pointsType = PropTypes.arrayOf(
  PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    mean: PropTypes.number,
    time: PropTypes.number,
    count: PropTypes.number
  })
)

const calcUTCTimeDependentState = (
  isUTC,
  isHumanUnits,
  scaleXDomain,
  scaleRes
) => {
  const { formatUnitRange } = isUTC ? utcFormatter : timeFormatter
  const contextUnit = (isUTC ? utcFormatter : timeFormatter).smallestCommonUnit(
    scaleXDomain
  )
  const tickUnit = Math.max(
    0,
    utcFormatter.smallestCommonUnit(0, 2 ** scaleRes) - 1
  )

  const dateContextLabel = formatUnitRange(scaleXDomain[0], contextUnit)

  const { formatWidth, formatTime, formatCount } = genFormatFunctions({
    isHumanUnits,
    tickUnit,
    contextUnit,
    formatUnitRange
  })
  return { dateContextLabel, formatWidth, formatTime, formatCount }
}
const genFormatFunctions = ({
  isHumanUnits,
  tickUnit,
  contextUnit,
  formatUnitRange
}) => {
  // clone to avoid a memory leak
  const formatUnitRangeClone = formatUnitRange.bind()
  const formatWidth = ({ ns, pointRes }) =>
    nanoToDuration(ns || 2 ** pointRes, { short: true })
  const formatTime = t =>
    isHumanUnits
      ? formatUnitRangeClone(t, tickUnit, contextUnit)
      : formatLongInt(preciseTimestamp(t)) + ' ns'
  const formatCount = n =>
    (isHumanUnits ? formatFuzzy(n) : formatLongInt(n)) +
    ' point' +
    (n === 1 ? '' : 's')
  return { formatWidth, formatTime, formatCount }
}

const genLabelWidth = ({ tickW, labelPad }) => {
  const labelWidth = (label, exact) =>
    tickW + labelPad * 2 + fastTextWidth(label, exact)
  return labelWidth
}

class Chart extends React.Component {
  static propTypes = {
    axes: PropTypes.arrayOf(
      PropTypes.shape({
        unit: PropTypes.string.isRequired,
        side: PropTypes.oneOf(['left', 'right'])
      })
    ),

    plots: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        unit: PropTypes.string,
        color: PropTypes.string,
        visibility: PropTypes.bool,
        tiles: PropTypes.arrayOf(
          PropTypes.shape({
            timeRange: PropTypes.arrayOf(PropTypes.number),
            pointRes: PropTypes.number,
            points: pointsType,
            allPoints: pointsType
          })
        )
      })
    ),

    showLegend: PropTypes.bool.isRequired,
    showTreeViz: PropTypes.bool,
    showDebugCache: PropTypes.bool,

    // bake the text into the canvas pixels, or render as selectable DOM elements
    rasterizeText: PropTypes.bool,

    // pixel width and height of the plot
    width: PropTypes.number,
    height: PropTypes.number,

    // This component should call this function with the scaleX object
    // whenever it is updated so the ChartStream can pass it new data.
    onScaleXUpdate: PropTypes.func
  }

  static defaultProps = {
    width: 800,
    height: 400,
    margin: {
      top: 50,
      bottom: 100,
      left: 20,
      right: 20
    },
    colors: {},
    histH: 32,
    showLegend: false,
    dotRadius: 1.8,
    dotRadiusActive: 1.8 * 1.4,
    minAxisWidth: 50,
    axisProjHighlightW: 6,
    axisProjSelectionW: 9,
    annotationHeight: 20,
    selectionLineDash: [5, 2],
    labelH: 18,
    yAxisTickLabelH: 24,
    labelPad: 4,
    labelTopRows: 1,
    labelBotRows: 2,
    deemphAlpha: 0.7,
    labelColor: '#222',
    timeAxisRowH: 24,
    timeAxisRows: 2,
    zoomMargin: 0.4,
    zoomDblClickScale: 2,
    rasterizeText: false,
    tickW: 4
  }

  constructor (props) {
    super(props)
    this.state = {
      xDomain: props.initialxDomain,
      selectedTimeRange: props.initialSelectedTimeRange,
      hideHints: props.hideHints,
      isHumanUnits: true,
      selectableText: true
    }
    this.lastMouseMoveEvent = { offsetX: 0, offsetY: 0 }
    this.controlFunc = this.controlIdling
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
    document.addEventListener('mouseup', this.handleMouseUp)
    document.addEventListener('mousemove', this.handleMouseMove)
    document.addEventListener(SET_X_SCALE, this.handleSetXScale)
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
    document.removeEventListener('mouseup', this.handleMouseUp)
    document.removeEventListener('mousemove', this.handleMouseMove)
    document.removeEventListener(SET_X_SCALE, this.handleSetXScale)
  }

  static getDerivedStateFromProps (props, state) {
    let plots = props.plots || []
    let axes = props.axes || []

    assert(plots.length !== 0, '<Chart /> passed zero plots')

    // Create default domains if undefined
    const xDomain = state.xDomain || getDefaultTimeDomain(plots)
    const yDomains = {}
    const units = getAllPlotUnits(plots)

    // If an axis has its own scale declared, use that. Otherwise autofit.
    for (const unit of units) {
      const axis = axes.find(a => a.unit === unit)
      if (axis && axis.scale) {
        yDomains[unit] = axis.scale
      } else {
        yDomains[unit] =
          getDefaultYDomain(plots, unit) || (state.yDomains || {})[unit]
      }
    }

    const { height, isUTC, margin, minAxisWidth, width } = props

    const canvasRatio = window.devicePixelRatio || 1
    const canvasWidth = width - margin.left - margin.right
    const canvasHeight = height - margin.top - margin.bottom

    // Plots with drawable shapes
    plots = plots.map(plot => ({
      ...plot,
      tiles: (plot.tiles || []).map(tile => ({
        ...tile
      }))
    }))

    // Active plot (for histogram and selected/highlighted points)
    const activePlot =
      plots.find(p => p.emphasis) ||
      plots.find(p => p.uuid === (state.activePlot || {}).uuid) ||
      plots[0]
    const activeAxis = activePlot.emphasis && activePlot.unit

    // Y constraints
    const {
      labelH,
      labelTopRows,
      labelBotRows,
      labelPad,
      timeAxisRowH,
      timeAxisRows,
      tickW
    } = props
    const rows = {
      hist: { h: props.histH },
      histInfo: { h: labelH * labelTopRows + labelPad * 2 },
      plot: { h: 0 },
      timeInfo: { h: labelH * labelBotRows + labelPad * 2 },
      timeAxis: { h: timeAxisRowH * timeAxisRows }
    }
    // calc plot h as what's left over (canvasHeight - sum of all other rows)
    rows.plot.h =
      canvasHeight -
      Object.values(rows)
        .map(o => o.h)
        .reduce((a, b) => a + b)
    {
      const rowOrder = ['hist', 'histInfo', 'plot', 'timeInfo', 'timeAxis']
      let y = 0
      for (const key of rowOrder) {
        const row = rows[key]
        row.y = y
        y += row.h
      }
    }
    const histY = rows.hist.y
    const histH = rows.hist.h
    const histInfoY = rows.histInfo.y
    const histInfoH = rows.histInfo.h
    const plotY = rows.plot.y
    const plotH = rows.plot.h
    const timeInfoY = rows.timeInfo.y
    const timeInfoH = rows.timeInfo.h
    const timeAxisY = rows.timeAxis.y
    const timeAxisH = rows.timeAxis.h

    // Y scales
    const scaleYs = {}
    for (const unit of units) {
      const yDomain = yDomains[unit]
      if (yDomain) {
        scaleYs[unit] = d3scale
          .scaleLinear()
          .domain(yDomain)
          .range([plotY + plotH, plotY])
      }
    }

    // Y axes
    const numYAxisTicks = Math.ceil(plotH / props.yAxisTickLabelH)
    const labelWidth = genLabelWidth({ labelPad, tickW })
    const createYAxes = side =>
      axes
        .filter(a => a.side === side && scaleYs[a.unit])
        .map(({ unit }) => {
          const scale = scaleYs[unit]
          const ticks = scale.ticks(numYAxisTicks)
          const tickFormat = scale.tickFormat(numYAxisTicks)
          const labels = ticks.map(tickFormat)
          const width = Math.max(
            minAxisWidth,
            labelWidth(unit, true),
            ...labels.map(label => labelWidth(label, false))
          )
          return { side, unit, scale, ticks, labels, width }
        })
    const leftYAxes = createYAxes('left')
    const rightYAxes = createYAxes('right')
    const yAxes = [...leftYAxes, ...rightYAxes]
    const leftYAxesW = leftYAxes.map(a => a.width).reduce((a, b) => a + b, 0)
    const rightYAxesW = rightYAxes.map(a => a.width).reduce((a, b) => a + b, 0)

    // X constraints
    const plotX = leftYAxesW
    const plotW = canvasWidth - leftYAxesW - rightYAxesW

    // X scale
    const scaleX = d3scale
      .scaleLinear()
      .domain(xDomain || DOMAIN_BOUNDS)
      .range([plotX, plotX + plotW])
    const scaleMs = d3scale
      .scaleLinear()
      .domain(scaleX.domain().map(t => t / 1e6)) // ns to ms
      .range(scaleX.range())
    const scaleRes = getScaleResolution(scaleX)

    // Metrics for histogram
    let maxDensity, avgDensity
    if (activePlot) {
      maxDensity = getMaxPlotDensity(activePlot, scaleX) || 0
      avgDensity = getAvgPlotDensity(activePlot, scaleX) || 0
    }

    const histScaleY =
      activePlot &&
      d3scale
        .scaleLinear()
        .domain([0, maxDensity])
        .range([histH, histY + 1])

    // Selection/Highlight
    const { highlightedTime, snapHighlightedTime } = state
    const highlightedPoint =
      highlightedTime != null
        ? (snapHighlightedTime
            ? snapTimeToPoint(activePlot, highlightedTime, scaleX)
            : getPointAtTime(activePlot, highlightedTime)) ||
          emptyPointAt(highlightedTime, scaleRes)
        : null

    const selectedPoints = getSelectedPoints(
      activePlot,
      state.selectedTimeRange
    )
    const selectionAggregate = getPointsAggregate(selectedPoints)

    const hasAnnotation = highlightedPoint || selectedPoints
    let anAxisIsDeemph = false
    for (const axis of yAxes) {
      if (hasAnnotation) axis.deemph = activePlot.unit !== axis.unit
      else axis.deemph = activeAxis && activeAxis !== axis.unit
      if (axis.deemph) anAxisIsDeemph = true
    }
    // Bring emphasized axis closest to plot
    if (anAxisIsDeemph) {
      leftYAxes.sort((a, b) => a.deemph - b.deemph)
      rightYAxes.sort((a, b) => a.deemph - b.deemph)
    } else {
      // if all axes are emphasized, then make activeplot's axis closest
      leftYAxes.sort((a, b) => (activePlot.unit === a.unit ? -1 : 0))
      rightYAxes.sort((a, b) => (activePlot.unit === a.unit ? -1 : 0))
    }

    for (const plot of plots) {
      for (const tile of plot.tiles) {
        tile.shapes = getShapes(tile, scaleX)
      }
    }

    // Now that all other state has been calculated, filter for visible plots and axes
    plots = (plots || []).filter(
      plot => plot.visibility === true || plot.visibility === undefined
    )
    axes = (axes || []).filter(
      axis =>
        axis.side !== 'hide' &&
        axis.streams.some(uuid => plots.some(p => p.uuid === uuid))
    )

    for (const plot of plots) {
      plot.axis = yAxes.find(axis => axis.unit === plot.unit)
    }

    // Label formatting
    const utcOffset = new Date().getTimezoneOffset() / 60

    const { isHumanUnits } = state
    const {
      formatWidth,
      formatTime,
      formatCount,
      dateContextLabel
    } = calcUTCTimeDependentState(
      isUTC,
      isHumanUnits,
      [...scaleX.domain()],
      scaleRes
    )
    const timeAxisLayers = computeAxisLayers(scaleMs, { isUTC })

    const rasterizeText = state.isSavingImage || props.rasterizeText
    const hideHints = state.isSavingImage || props.hideHints

    return {
      activePlot,
      avgDensity,
      canvasHeight,
      canvasRatio,
      canvasWidth,
      dateContextLabel,
      formatCount,
      formatTime,
      formatWidth,
      hideHints,
      highlightedPoint,
      histInfoH,
      histInfoY,
      histScaleY,
      leftYAxes,
      maxDensity,
      plotH,
      plots,
      plotW,
      plotX,
      plotY,
      rasterizeText,
      rightYAxes,
      scaleMs,
      scaleRes,
      scaleX,
      scaleYs,
      selectedPoints,
      selectionAggregate,
      timeAxisH,
      timeAxisLayers,
      timeAxisY,
      timeInfoH,
      timeInfoY,
      utcOffset,
      xDomain,
      yAxes,
      yDomains
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      onScaleXUpdate,
      trackStartEndResolution,
      trackChartSelection,
      trackYScales
    } = this.props
    if (onScaleXUpdate) {
      const curr = this.state.scaleX
      const prev = prevState.scaleX
      const scalesEqual =
        rangesEqual(curr.domain(), prev.domain()) &&
        rangesEqual(curr.range(), prev.range())
      if (!scalesEqual) {
        onScaleXUpdate(curr)
        trackStartEndResolution(curr)
      }
    }
    if (trackChartSelection) trackChartSelection(this.state.selectedTimeRange)
    if (trackYScales) trackYScales(this.state.yDomains)
  }

  handleSetXScale = e => {
    if (!e || !e.detail) {
      return console.error('setXScale did not receive a scale', e)
    }
    const newScale = e.detail
    if (Array.isArray(newScale) === false) {
      return console.error('Expected an array, saw:', newScale)
    }
    if (newScale.length !== 2) {
      return console.error(
        'Expected a new X scale of shape [min, max]. Saw:',
        newScale
      )
    }
    this.setState({ xDomain: e.detail })
  }

  handleKeyDown = e => {
    this.controlFunc('keydown', e)
  }

  handleKeyUp = e => {
    this.controlFunc('keyup', e)
  }

  handleWheel = e => {
    this.controlFunc('wheel', e)
  }

  handleMouseEnter = e => {
    this.isMouseInside = true
    this.controlFunc('mouseenter', e)
  }

  handleMouseLeave = e => {
    this.isMouseInside = false
    this.controlFunc('mouseleave', e)
  }

  handleMouseDown = e => {
    this.controlFunc('mousedown', e)
  }

  handleMouseUp = e => {
    this.controlFunc('mouseup', e)
  }

  handleMouseMove = e => {
    this.lastMouseMoveEvent = { offsetX: e.offsetX, offsetY: e.offsetY, ...e }
    this.controlFunc('mousemove', e)

    // Mouse move events are not called when context menu is open,
    // so this is as close as we can get to an exit event for the context menu:
    this.handleContextMenuClose(e)
  }

  handleContextMenuClose = e => {
    if (this.state.isSavingImage) this.setState({ isSavingImage: false })
  }

  handleContextMenu = e => {
    this.setState({ isSavingImage: true })
  }

  handleDblClick = e => {
    this.controlFunc('dblclick', e)
  }

  xDomainZoom = (mx, k) => {
    const { scaleX } = this.state
    const oldW = scaleX.domain()[1] - scaleX.domain()[0]
    const newW = clamp(oldW / k, [MIN_DOMAIN_WIDTH, MAX_DOMAIN_WIDTH])
    const clampK = oldW / newW
    const xDomain = clampRange(
      scaleX.range().map(x => scaleX.invert(mx + (x - mx) / clampK)),
      DOMAIN_BOUNDS
    )
    return xDomain
  }

  zoomWithWheel = e => {
    const mx = this.lastMouseMoveEvent.offsetX
    const wheelDelta = (-e.deltaY * (e.deltaMode ? 120 : 1)) / 500 // from d3-zoom
    const k = 2 ** wheelDelta
    const xDomain = this.xDomainZoom(mx, k)
    this.setState({ xDomain, timeAxisHoverCoord: null })
    e.preventDefault() // prevent normal scroll event
  }

  selectClosestPlot = (t, y) => {
    const { plots, scaleYs, scaleX } = this.state
    let dist = Infinity
    let closestPlot = null
    for (const plot of plots) {
      const scaleY = scaleYs[plot.unit]
      const point = snapTimeToPoint(plot, t, scaleX)
      if (!point) continue
      const currDist = Math.abs(scaleY(point.mean) - y)
      if (currDist < dist) {
        dist = currDist
        closestPlot = plot
      }
    }
    return closestPlot
  }

  transitionZoomToRange = ([t0, t1], margin) => {
    const { xDomain } = this.state
    const t = (t0 + t1) / 2
    const w = clamp((t1 - t0) * (1 + 2 * margin), [
      MIN_DOMAIN_WIDTH,
      MAX_DOMAIN_WIDTH
    ])
    const range = clampRange([t - w / 2, t + w / 2], DOMAIN_BOUNDS)
    d3transition
      .transition()
      .duration(200)
      .tween('tick', () => {
        const interp = d3interpolate.interpolate(xDomain, range)
        return t => {
          this.setState({ xDomain: interp(t) })
        }
      })
  }

  timeAxisCoordAtMouse = () => {
    const { scaleMs, timeAxisLayers, timeAxisY } = this.state
    const { timeAxisRowH } = this.props
    const mouseX = this.lastMouseMoveEvent.offsetX
    const mouseT = scaleMs.invert(mouseX)
    const mouseY = this.lastMouseMoveEvent.offsetY - timeAxisY
    const row = Math.floor(mouseY / timeAxisRowH)

    if (!this.isMouseInside) return
    if (!isInRange(mouseX, scaleMs.range())) return

    const layer = timeAxisLayers[row]
    if (!layer) return

    const cell = layer.cells.find(r => isInRange(mouseT, r))
    if (!cell) return

    const [t0, t1] = cell
    const [x0, x1] = cropRange([t0, t1].map(scaleMs), scaleMs.range())
    const snapT = ((mouseX - x0) / (x1 - x0) < 0.5 ? t0 : t1) * 1e6
    const col = layer.cells.indexOf(cell)
    return { row, col, snapT }
  }

  plotPointAtMouse = () => {
    const plot = this.state.activePlot
    if (!plot) return
    const { scaleX, plotY, plotH } = this.state
    const mouseX = this.lastMouseMoveEvent.offsetX
    const mouseY = this.lastMouseMoveEvent.offsetY
    const mouseT = scaleX.invert(mouseX)
    if (plotY <= mouseY && mouseY < plotY + plotH) {
      return snapTimeToPoint(plot, mouseT, scaleX)
    }
  }

  timeAtMouse = () => {
    const x = this.lastMouseMoveEvent.offsetX
    const t = this.state.scaleX.invert(x)
    return t
  }

  selectionEndpoint = () => {
    const axisCoord = this.state.timeAxisHoverCoord
    const plotPoint = this.plotPointAtMouse()
    if (axisCoord) {
      const t = axisCoord.snapT
      return [t, t]
    } else if (plotPoint) {
      const t0 = plotPoint.time
      const t1 = t0 + 2 ** plotPoint.pointRes
      return [t0, t1]
    } else {
      const t = this.timeAtMouse()
      const { scaleRes } = this.state
      const point = emptyPointAt(t, scaleRes)
      const t0 = point.time
      const t1 = t0 + 2 ** point.pointRes
      return [t0, t1]
    }
  }

  selection = () => {
    const head = this.selectedTimeHead
    const anchor = this.selectionEndpoint()
    const ts = [...head, ...anchor].sort((a, b) => a - b)
    const t0 = ts[0]
    const t1 = ts[ts.length - 1]
    return [t0, t1]
  }

  deselectAll = () => {
    // clear any selected text (like annotations)
    window.getSelection().removeAllRanges()

    // remove highlight and selection guides
    this.setState({ highlightedTime: null, selectedTimeRange: null })
  }

  updateTimeAxisMouse = () => {
    const coord = this.timeAxisCoordAtMouse()
    if (!timeAxisCoordsEqual(coord, this.state.timeAxisHoverCoord)) {
      this.setState({ timeAxisHoverCoord: coord })
    }
    return coord
  }

  controlIdling = (eventName, e) => {
    if (eventName === 'mousemove') {
      const axisCoord = this.updateTimeAxisMouse()
      const cursor = axisCoord ? 'pointer' : 'default'
      if (cursor !== this.state.cursor) this.setState({ cursor })
    } else if (
      eventName === 'keydown' &&
      e.key === 'Escape' &&
      this.isMouseInside
    ) {
      this.deselectAll()
    } else if (
      eventName === 'keydown' &&
      e.key === 'Shift' &&
      this.isMouseInside
    ) {
      const axisCoord = this.updateTimeAxisMouse()
      if (!axisCoord) {
        const t = this.timeAtMouse()
        const y = this.lastMouseMoveEvent.offsetY
        const activePlot = this.selectClosestPlot(t, y)
        const point = this.plotPointAtMouse()
        const snapHighlightedTime = point != null
        this.setState({
          highlightedTime: t,
          snapHighlightedTime,
          activePlot
        })
      }
      this.setState({ cursor: 'crosshair', selectableText: false })
      this.controlFunc = this.controlHighlightingPoint
    } else if (eventName === 'mousedown' && e.button === 0) {
      const axisCoord = this.updateTimeAxisMouse()
      const { timeAxisLayers } = this.state
      if (axisCoord) {
        const { row, col } = axisCoord
        this.setState({
          selectedTimeRange: timeAxisLayers[row].cells[col].map(t => t * 1e6),
          highlightedTime: null,
          timeAxisHoverCoord: null,
          cursor: 'default'
        })
      } else {
        const { pageX } = e
        const scaleX = this.state.scaleX.copy()
        this.panStart = { pageX, scaleX }
        this.controlFunc = this.controlPanningView
        this.mouseDragged = false
        this.setState({ selectableText: false })
      }
      e.preventDefault() // prevent normal drag event (like text selection)
    } else if (eventName === 'wheel') {
      this.zoomWithWheel(e)
    } else if (eventName === 'dblclick') {
      const { scaleX, selectedTimeRange } = this.state
      const { zoomMargin, zoomDblClickScale } = this.props
      const t = scaleX.invert(e.offsetX)
      if (selectedTimeRange && isInRange(t, selectedTimeRange)) {
        this.transitionZoomToRange(selectedTimeRange, zoomMargin)
      } else {
        this.transitionZoomToRange(
          this.xDomainZoom(this.lastMouseMoveEvent.offsetX, zoomDblClickScale),
          0
        )
      }
    }
  }

  controlHighlightingPoint = (eventName, e) => {
    if (eventName === 'keyup' && e.key === 'Shift') {
      this.setState({ cursor: 'default', selectableText: true })
      this.controlFunc = this.controlIdling
    } else if (eventName === 'mousemove' && this.isMouseInside) {
      const axisCoord = this.updateTimeAxisMouse()
      const t = this.timeAtMouse()
      if (axisCoord) {
        this.setState({ highlightedTime: null })
      } else {
        const snapHighlightedTime = this.plotPointAtMouse() != null
        this.setState({ highlightedTime: t, snapHighlightedTime })
      }
    } else if (eventName === 'wheel') {
      this.zoomWithWheel(e)
    } else if (eventName === 'mousedown' && e.button === 0) {
      const range = this.selectionEndpoint()
      this.selectedTimeHead = range
      this.setState({
        selectedTimeRange: isRangeEmpty(range) ? null : range,
        highlightedTime: null,
        creatingSelection: true
      })
      this.controlFunc = this.controlCreatingSelection
      e.preventDefault() // prevent normal drag event (like text selection)
      this.mouseDragged = false
    } else if (eventName === 'dblclick') {
      const { scaleX } = this.state
      const { selectedTimeHead } = this
      const { zoomMargin, zoomDblClickScale } = this.props
      if (selectedTimeHead) {
        if (!isRangeEmpty(selectedTimeHead)) {
          this.transitionZoomToRange(selectedTimeHead, zoomMargin)
        } else {
          const t = selectedTimeHead[0]
          const x = scaleX(t)
          this.transitionZoomToRange(this.xDomainZoom(x, zoomDblClickScale), 0)
          this.setState({ selectedTimeRange: null })
        }
      }
    }
  }

  controlCreatingSelection = (eventName, e) => {
    if (eventName === 'mousemove' && this.isMouseInside) {
      this.updateTimeAxisMouse()
      const selectedTimeRange = this.selection()
      if (!isRangeEmpty(selectedTimeRange)) {
        this.setState({ selectedTimeRange })
      }
      this.mouseDragged = true
    } else if (eventName === 'mouseup' && e.button === 0) {
      this.controlFunc = this.controlIdling
      this.setState({
        cursor: 'default',
        creatingSelection: false,
        selectableText: true
      })
    } else if (eventName === 'keyup' && e.key === 'Shift') {
      this.setState({
        cursor: 'default',
        creatingSelection: false,
        selectableText: true
      })
      this.controlFunc = this.controlIdling
    }
  }

  controlPanningView = (eventName, e) => {
    if (eventName === 'mousemove') {
      const { scaleX, pageX } = this.panStart
      const dx = e.pageX - pageX
      const xDomain = clampRange(
        scaleX.range().map(x => scaleX.invert(x - dx)),
        DOMAIN_BOUNDS
      )
      this.setState({ xDomain, cursor: 'grabbing' })
      this.mouseDragged = true
    } else if (eventName === 'mouseup' && e.button === 0) {
      if (!this.mouseDragged) {
        const { scaleX, selectedTimeRange } = this.state
        const t = scaleX.invert(e.offsetX)
        if (selectedTimeRange && isInRange(t, selectedTimeRange)) {
          this.setState({ highlightedTime: null })
        } else {
          this.deselectAll()
        }
      }
      this.setState({ cursor: 'default', selectableText: true })
      this.controlFunc = this.controlIdling
    }
  }

  drawTile = (ctx, plot, tile) => {
    const { unit } = plot
    const emphasis = plot.emphasis || plot === this.state.activePlot
    const { timeRange, pointRes, shapes } = tile
    const { lines, dots, ribbons } = shapes
    const color = plot.color || 'rgb(0,100,200)'
    const { canvasHeight, plotY } = this.state

    // scales
    const { scaleX, histScaleY } = this.state
    const scaleY = this.state.scaleYs[unit]

    // point width (time and pixels)
    const pw = 2 ** pointRes
    const pixelW = scaleX(pw) - scaleX(0)

    // shape drawing functions
    const d3DrawLine = d3shape
      .line()
      .curve(d3shape.curveMonotoneX)
      .x(d => scaleX(d.time + pw / 2))
      .y(d => scaleY(d.mean))
      .context(ctx)
    const convertStatPointToLine = singleStatPointLine => {
      const [a] = singleStatPointLine
      const LineStartxPixel = scaleX(a.time)
      const LineEndxPixel = Math.max(
        scaleX(a.time + pw),
        // ensure it's at least 4 pixels so it's visible and recognizable as a line
        LineStartxPixel + 4
      )
      const LineEndTime = scaleX.invert(LineEndxPixel)
      const b = { ...a, time: LineEndTime }
      return [a, b]
    }
    const drawLine = line => {
      if (line.length === 1) {
        // it's a single stat point line; give it a start/end
        const [a, b] = convertStatPointToLine(line)
        return d3DrawLine([a, b])
      } else {
        return d3DrawLine(line)
      }
    }
    // const drawRibbon = d3shape
    //   .area()
    //   .x(d => scaleX(d.time + pw / 2))
    //   .y0(d => scaleY(d.min))
    //   .y1(d => scaleY(d.max))
    //   .context(ctx);
    const drawRibbonBoxes = ribbon => {
      for (let i = 0; i < ribbon.length; i++) {
        const d = ribbon[i]
        const y = scaleY(d.max)
        if (i === 0) ctx.moveTo(scaleX(d.time), y)
        ctx.lineTo(scaleX(d.time), y)
        ctx.lineTo(scaleX(d.time + pw), y)
      }
      for (let i = ribbon.length - 1; i >= 0; i--) {
        const d = ribbon[i]
        const y = scaleY(d.min)
        ctx.lineTo(scaleX(d.time + pw), y)
        ctx.lineTo(scaleX(d.time), y)
      }
    }

    const { dotRadius } = this.props
    const drawDot = d => {
      const { time, mean } = d
      const x = scaleX(time + pw / 2)
      const y = scaleY(mean)
      ctx.moveTo(x, y)
      ctx.arc(x, y, dotRadius, 0, Math.PI * 2)
    }

    const drawHist = d3shape
      .line()
      .curve(d3shape.curveStepAfter)
      .x(d => scaleX(d.time))
      .y(d => histScaleY(d.count / pixelW))
      .context(ctx)

    // Apply the clip region (height of y axis, length of x axis )
    const plotX0 = scaleX(timeRange[0])
    const plotX1 = scaleX(timeRange[1])
    ctx.save()
    ctx.beginPath()
    ctx.rect(
      plotX0,
      this.state.histInfoY, // plotY,
      plotX1 - plotX0,
      this.state.timeAxisY - this.state.histInfoY // canvasHeight// plotH
    )
    ctx.clip()

    // Min/Max Ribbons
    ctx.fillStyle = fadeColor(color, emphasis ? 0.2 : 0.1)
    ctx.beginPath()
    ribbons.forEach(drawRibbonBoxes)
    ctx.fill()

    // Mean Lines
    ctx.strokeStyle = color
    ctx.lineWidth = emphasis ? 1 : 1
    ctx.beginPath()
    lines.forEach(drawLine)
    ctx.strokeStyle = fadeColor(color, emphasis ? 1 : 0.5)
    ctx.stroke()

    // Raw Points
    if (dotRadius > 0.3) {
      ctx.fillStyle = color
      ctx.beginPath()
      dots.forEach(drawDot)
      ctx.fill()
    }

    // Clear the clip region
    ctx.restore()

    // Apply the clip region (full height, length of x axis)
    ctx.save()
    ctx.beginPath()
    ctx.rect(plotX0, 0, plotX1 - plotX0, canvasHeight)
    ctx.clip()

    // DRAW TRANSLUCENT RECTS FOR PLOTS THAT EXTEND ABOVE/BELOW Y AXIS TICKS
    ctx.beginPath()
    ctx.rect(
      plotX0,
      this.state.histInfoY,
      plotX1 - plotX0,
      plotY - this.state.histInfoY
    )
    ctx.rect(
      plotX0,
      this.state.timeInfoY,
      plotX1 - plotX0,
      this.state.timeInfoH
    )
    ctx.fillStyle = 'rgba(255, 255, 255, 0.8)'
    ctx.fill()

    // Histogram
    if (plot === this.state.activePlot) {
      ctx.beginPath()
      drawHist(tile.shapes.densityHistogram)
      ctx.fillStyle = fadeColor(color, 0.1)
      ctx.fill()
      ctx.strokeStyle = fadeColor(color, 0.1)
      ctx.stroke()

      ctx.beginPath()
      for (const p of tile.points) {
        const y = histScaleY(p.count / pixelW)
        ctx.moveTo(scaleX(p.time), y)
        ctx.lineTo(scaleX(p.time + pw), y)
      }
      ctx.strokeStyle = color
      ctx.stroke()
    }

    // Clear the clip region
    ctx.restore()
  }

  drawYAxisAnnotations = (ctx, options) => {
    const { min, mean, max, count } = options
    const {
      labelPad,
      labelH,
      labelColor,
      axisProjSelectionW,
      deemphAlpha
    } = this.props
    const { activePlot, scaleYs, plotX, plotW, plotY, plotH } = this.state

    const plot = activePlot
    if (!plot.axis) return
    const { side } = plot.axis

    const scaleY = scaleYs[plot.unit]
    const y = scaleY(mean)

    const textRows =
      count === 1
        ? [['value', mean]]
        : [
            ['max', max],
            ['mean', mean],
            ['min', min]
          ]

    const descW = d3array.max(textRows, ([desc, value]) => fastTextWidth(desc))
    const valW = d3array.max(textRows, ([desc, value]) => fastTextWidth(value))

    const boxW = descW + valW + labelPad * 3
    const boxH = labelPad * 2 + labelH * textRows.length
    const [boxY] = clampRange(
      [y - boxH / 2, y + boxH / 2],
      [plotY, plotY + plotH]
    )
    const descX = labelPad + descW
    const valueX = descX + labelPad

    ctx.save()

    const pad = axisProjSelectionW + labelPad
    function getXCoord () {
      if (side === 'right') {
        return plotX + plotW - pad - boxW
      } else if (side === 'left') {
        return plotX + pad
      }
    }
    // ANNOTATION BOX FILL
    ctx.beginPath()
    const boxX = getXCoord()
    ctx.translate(boxX, 0)
    ctx.rect(0, boxY, boxW, boxH)
    ctx.fillStyle = fadeColor('white', 0.9)
    ctx.fill()
    ctx.strokeStyle = '#c0c0c0'
    ctx.stroke()

    // ANNOTATION BOX TEXT
    ctx.fillStyle = labelColor
    ctx.textBaseline = 'middle'
    for (const [i, [desc, value]] of textRows.entries()) {
      const rowY = boxY + labelPad + labelH * (i + 0.5)

      // PRINT DESCRIPTION (min/mean/max)
      ctx.globalAlpha = deemphAlpha
      ctx.textAlign = 'right'
      ctx.reactTextAttrs = { key: `${desc}-title` }
      ctx.fillText(desc, descX, rowY)

      // PRINT VALUE (floats)
      ctx.globalAlpha = 1
      ctx.textAlign = 'left'
      ctx.reactTextAttrs = { key: `${desc}-value` }
      ctx.fillText(value, valueX, rowY)
    }

    ctx.restore()
  }

  drawYAxisGuides = (ctx, options) => {
    const { plotX, plotW, activePlot, scaleYs } = this.state

    const plot = activePlot
    if (!plot.axis) return

    const scaleY = scaleYs[plot.unit]
    const { side } = plot.axis

    const {
      min,
      mean,
      max,
      w,
      projFills,
      projStroke,
      projLineDash,
      isHighlight
    } = options

    ctx.save()
    if (side === 'right') {
      ctx.translate(plotX + plotW - w, 0)
    } else if (side === 'left') {
      ctx.translate(plotX, 0)
    }

    // PROJECTED BOX
    ctx.beginPath()
    const y0 = scaleY(max)
    const y1 = scaleY(min)
    const yMaxIsInBounds = y0 > this.state.plotY
    const yMinIsInBounds = y1 < this.state.plotY + this.state.plotH
    const clampedYMax = Math.max(this.state.plotY, y0)
    const clampedYMin = Math.min(this.state.plotY + this.state.plotH, y1)
    ctx.rect(0, clampedYMax, w, clampedYMin - clampedYMax)
    for (const projFill of projFills) {
      ctx.fillStyle = projFill
      ctx.fill()
    }
    // draw dashed lines
    ctx.beginPath()
    ctx.moveTo(w, clampedYMax)
    ctx.lineTo(w, clampedYMin)
    if (yMaxIsInBounds) {
      ctx.moveTo(0, clampedYMax)
      ctx.lineTo(w, clampedYMax)
    }
    if (yMinIsInBounds) {
      ctx.moveTo(0, clampedYMin)
      ctx.lineTo(w, clampedYMin)
    }
    ctx.setLineDash(projLineDash || [])
    if (projStroke) {
      ctx.strokeStyle = projStroke
      ctx.stroke()
    }

    if (isHighlight) {
      // MEAN DOT
      const y = scaleY(mean)
      const r = this.props.dotRadiusActive * 0.75
      ctx.beginPath()
      ctx.arc(w / 2, y, r, 0, 2 * Math.PI)
      ctx.fillStyle = plot.color
      ctx.fill()
    } else {
      // LINE FOR MEAN
      ctx.beginPath()
      const y = scaleY(mean)
      ctx.moveTo(0, y)
      ctx.lineTo(w, y)
      ctx.setLineDash([])
      ctx.strokeStyle = plot.color
      ctx.stroke()
    }

    ctx.restore()
  }

  drawHighlight = ctx => {
    const point = this.state.highlightedPoint
    if (!point) return

    const plot = this.state.activePlot
    const { scaleX, formatTime, formatCount, formatWidth } = this.state
    const scaleY = this.state.scaleYs[plot.unit]
    const { mean, min, max, count, time, pointRes } = point
    const pw = 2 ** pointRes
    const x0 = scaleX(time)
    const x1 = scaleX(time + pw)
    const x = (x0 + x1) / 2
    const color = plot.color || 'rgb(0,100,200)'

    const w = x1 - x0

    const { plotY, timeInfoY, timeInfoH, histInfoY, histInfoH } = this.state

    // DARKEN THE HIGHLIGHTED SLICE in the plot
    if (count > 0) {
      const [y0, y1] = [max, min].map(scaleY)
      const h = y1 - y0
      ctx.beginPath()
      ctx.rect(x0, y0, w, h)
      ctx.fillStyle = fadeColor(color, 0.6)
      ctx.fill()

      // draw dot at highlight
      const r = this.props.dotRadiusActive
      const y = scaleY(mean)
      ctx.beginPath()
      ctx.arc(x, y, r, 0, 2 * Math.PI)
      ctx.fillStyle = color
      ctx.fill()

      // draw vertical line guide
      ctx.beginPath()
      ctx.moveTo(x, plotY)
      ctx.lineTo(x, y0)
      ctx.moveTo(x, y1)
      ctx.lineTo(x, timeInfoY)
      ctx.strokeStyle = fadeColor(color, 0.2)
      ctx.setLineDash(this.props.selectionLineDash)
      ctx.stroke()
      ctx.setLineDash([])
    } else {
      // draw vertical line guide
      ctx.beginPath()
      ctx.moveTo(x, plotY)
      ctx.lineTo(x, timeInfoY)
      ctx.strokeStyle = fadeColor(color, 0.2)
      ctx.setLineDash(this.props.selectionLineDash)
      ctx.stroke()
      ctx.setLineDash([])
    }

    // DARKEN THE HIGHLIGHTED SLICE in the histogram
    {
      const { histScaleY } = this.state
      const [y0, y1] = [count / (x1 - x0), 0].map(histScaleY)
      const h = y1 - y0
      ctx.beginPath()
      ctx.rect(x0, y0, w, h)
      ctx.fill()
    }

    // DRAW TOP/BOTTOM AXIS BAR ANNOTATIONS
    {
      const { labelH, labelPad, labelColor, deemphAlpha } = this.props
      const { plotX, plotW } = this.state

      // draw highlight bar for point
      ctx.beginPath()
      ctx.rect(x0, timeInfoY + labelPad, w, timeInfoH - labelPad)
      ctx.rect(x0, histInfoY, w, histInfoH - labelPad)
      ctx.fillStyle = fadeColor(color, 0.2)
      ctx.fill()

      // calculate text y-positions
      const yPointWidth = timeInfoY + labelPad + labelH * 0.5
      const yTime = yPointWidth + labelH
      const yCount = histInfoY + labelPad + labelH * 0.5

      // calculate text x-positions
      const xLeft = x0 - labelPad
      const xRight = x0 + w + labelPad
      const midX = plotX + plotW / 2
      const labelOnLeft = (x0 + x1) / 2 < midX
      const xLabel = labelOnLeft ? xLeft : xRight
      const xValue = labelOnLeft ? xRight : xLeft

      // draw attribute titles
      ctx.textAlign = labelOnLeft ? 'right' : 'left'
      ctx.fillStyle = labelColor
      ctx.textBaseline = 'middle'
      ctx.globalAlpha = deemphAlpha

      ctx.reactTextAttrs = { key: 'pointwidth-title' }
      ctx.fillText('point width', xLabel, yPointWidth)
      ctx.reactTextAttrs = { key: 'time-title' }
      ctx.fillText('time', xLabel, yTime)
      ctx.reactTextAttrs = { key: 'count-title' }
      ctx.fillText('count', xLabel, yCount)

      // draw attribute values
      ctx.globalAlpha = 1
      ctx.textAlign = labelOnLeft ? 'left' : 'right'
      ctx.reactTextAttrs = { key: 'pointwidth-value' }
      ctx.fillText(formatWidth({ pointRes }), xValue, yPointWidth)
      ctx.reactTextAttrs = { key: 'time-value' }
      ctx.fillText(formatTime(time), xValue, yTime)
      ctx.reactTextAttrs = { key: 'count-value' }
      ctx.fillText(formatCount(count), xValue, yCount)
    }

    if (count > 0) {
      const { axisProjHighlightW } = this.props
      this.drawYAxisGuides(ctx, {
        min,
        mean,
        max,
        isHighlight: true,
        w: axisProjHighlightW,
        projFills: [fadeColor('white', 0.8), fadeColor(color, 0.2)],
        projStroke: fadeColor('#000', 0.8)
      })
      this.drawYAxisAnnotations(ctx, { min, mean, max, count })
    }
  }

  drawSelection = ctx => {
    const {
      scaleX,
      scaleYs,
      timeAxisY,
      creatingSelection,
      timeAxisHoverCoord,
      selectedTimeRange,
      selectionAggregate,
      highlightedPoint,
      formatTime,
      formatWidth,
      plotX,
      plotW
    } = this.state
    const { axisProjSelectionW, timeAxisRowH } = this.props

    const plot = this.state.activePlot
    const scaleY = scaleYs[plot.unit]
    if (!selectedTimeRange || !scaleY) return
    const lineDash = this.props.selectionLineDash

    const [x0, x1] = selectedTimeRange.map(scaleX)

    const endpointsVisible = plotX < x0 && x1 < plotX + plotW - 1

    // FILL SHADOW OVER UNSELECTED REGIONS
    if (endpointsVisible) {
      ctx.beginPath()
      const axisCoord = timeAxisHoverCoord
      const h = creatingSelection
        ? timeAxisY + (axisCoord ? axisCoord.row + 1 : 0) * timeAxisRowH
        : timeAxisY
      ctx.rect(plotX, 0, x0 - plotX, h)
      ctx.rect(x1, 0, plotX + plotW - x1, h)
      ctx.fillStyle = 'rgba(0,0,0,0.1)'
      ctx.fill()
    }

    // get aggregates
    const { min, max, mean, count } = selectionAggregate || { count: 0 }

    if (selectionAggregate) {
      // Clamp y0 and y1 so box does not extend above or below chart when manually scaled
      const y0 = scaleY(max)
      const y1 = scaleY(min)
      const yMaxIsInBounds = y0 > this.state.plotY
      const yMinIsInBounds = y1 < this.state.plotY + this.state.plotH
      // DOTTED BOUND BOX
      if (endpointsVisible) {
        ctx.beginPath()
        ctx.strokeStyle = plot.color
        ctx.setLineDash(lineDash)

        if (yMaxIsInBounds && yMinIsInBounds) {
          // if everything is within bounds just draw the rect
          ctx.rect(x0, y0, x1 - x0, y1 - y0)
        } else {
          // if either one is out of bounds, fallback to drawing each side
          const clampedYMax = Math.max(this.state.plotY, y0)
          const clampedYMin = Math.min(this.state.plotY + this.state.plotH, y1)
          ctx.moveTo(x0, clampedYMax)
          ctx.lineTo(x0, clampedYMin)
          ctx.moveTo(x1, clampedYMax)
          ctx.lineTo(x1, clampedYMin)
          if (yMaxIsInBounds) {
            ctx.moveTo(x0, clampedYMax)
            ctx.lineTo(x1, clampedYMax)
          }
          if (yMinIsInBounds) {
            ctx.moveTo(x0, clampedYMin)
            ctx.lineTo(x1, clampedYMin)
          }
        }

        ctx.stroke()
      }

      // DRAW LINES BETWEEN RAW POINTS
      const drawLine = d3shape
        .line()
        .curve(d3shape.curveMonotoneX)
        .x(d => scaleX(d.time + 2 ** d.pointRes / 2))
        .y(d => scaleY(d.mean))
        .context(ctx)
      const points = this.state.selectedPoints
      ctx.beginPath()
      splitArray(points, (a, b) => a.count !== b.count)
        .filter(points => points[0].count === 1)
        .forEach(drawLine)
      ctx.strokeStyle = fadeColor(plot.color, 0.3)
      ctx.stroke()

      ctx.setLineDash([])

      if (endpointsVisible) {
        this.drawYAxisGuides(ctx, {
          min,
          mean,
          max,
          w: axisProjSelectionW,
          projFills: ['rgba(255,255,255,0.7)'],
          projStroke: plot.color,
          projLineDash: lineDash
        })
        if (!highlightedPoint) {
          this.drawYAxisAnnotations(ctx, { min, mean, max, count })
        }
      }
    }

    if (!highlightedPoint && endpointsVisible) {
      const pad = 3
      const getMargin = w => (x1 - x0 - (w + pad * 2)) / 2

      // TIME ANNOTATIONS AT BOTTOM
      // e.g.
      //       start     width      end
      //      ..... |----....-----| ...
      {
        const { labelH, labelPad } = this.props
        const { timeInfoY, timeAxisY } = this.state
        const [t0, t1] = selectedTimeRange
        const ns = t1 - t0

        const label = formatWidth({ ns })
        const w = fastTextWidth(label)
        const margin = getMargin(w)
        const x = (x0 + x1) / 2
        const y = timeInfoY + labelPad + labelH * 0.5
        const y2 = y + labelH

        const { deemphAlpha, labelColor } = this.props
        ctx.fillStyle = labelColor
        ctx.textBaseline = 'middle'

        // start/end labels
        ctx.textAlign = 'right'
        ctx.globalAlpha = deemphAlpha
        ctx.reactTextAttrs = { key: 'start-title' }
        ctx.fillText('start', x0 - labelPad, y)
        ctx.globalAlpha = 1
        ctx.reactTextAttrs = { key: 'start-value' }
        ctx.fillText(formatTime(t0), x0 - labelPad, y2)
        ctx.textAlign = 'left'
        ctx.globalAlpha = deemphAlpha
        ctx.reactTextAttrs = { key: 'end-title' }
        ctx.fillText('end', x1 + labelPad, y)
        ctx.globalAlpha = 1
        ctx.reactTextAttrs = { key: 'end-value' }
        ctx.fillText(formatTime(t1), x1 + labelPad, y2)

        // vertical lines
        ctx.beginPath()
        ctx.moveTo(x0, y2 - labelH / 2)
        ctx.lineTo(x0, timeAxisY)
        ctx.moveTo(x1, y2 - labelH / 2)
        ctx.lineTo(x1, timeAxisY)
        ctx.strokeStyle = '#222'
        ctx.stroke()

        if (margin > 0) {
          // duration label
          ctx.textAlign = 'center'
          ctx.globalAlpha = deemphAlpha
          ctx.reactTextAttrs = { key: 'duration-title' }
          ctx.fillText('width', x, y)
          ctx.globalAlpha = 1
          ctx.reactTextAttrs = { key: 'duration-value' }
          ctx.fillText(label, x, y2)

          // horizontal lines
          ctx.beginPath()
          ctx.moveTo(x0, y2)
          ctx.lineTo(x0 + margin, y2)
          ctx.moveTo(x1, y2)
          ctx.lineTo(x1 - margin, y2)
          ctx.stroke()
        } else {
          // horizontal lines
          ctx.beginPath()
          ctx.moveTo(x0, y2)
          ctx.lineTo(x1, y2)
          ctx.stroke()
        }
      }

      // COUNT ANNOTATION AT TOP
      // e.g.
      // --------25 points---------
      {
        const { labelH, labelPad } = this.props
        const { histInfoY } = this.state

        const shortLabel = formatFuzzy(count)
        const longLabel = formatLongInt(count)
        const unitLabel = count === 1 ? ' point' : ' points'
        const shortW = fastTextWidth(shortLabel)
        const longW = fastTextWidth(longLabel)
        const unitW = fastTextWidth(unitLabel, true)

        const shortMargin = getMargin(shortW + unitW)
        const longMargin = getMargin(longW + unitW)
        let margin = 0
        let label = ''
        if (longMargin > 0) {
          margin = longMargin
          label = longLabel + unitLabel
        } else if (shortMargin > 0) {
          margin = shortMargin
          label = shortLabel + unitLabel
        }

        if (margin > 0) {
          // label
          ctx.fillStyle = '#222'
          ctx.textBaseline = 'middle'
          ctx.textAlign = 'center'
          const x = (x0 + x1) / 2
          const y = histInfoY + labelPad + labelH * 0.5
          ctx.reactTextAttrs = { key: 'count-value' }
          ctx.fillText(label, x, y)

          // lines
          ctx.beginPath()
          ctx.moveTo(x0, y)
          ctx.lineTo(x0 + margin, y)
          ctx.moveTo(x1, y)
          ctx.lineTo(x1 - margin, y)
          ctx.strokeStyle = '#222'
          ctx.stroke()
        }
      }
    }
  }

  drawTimeAxis = ctx => {
    const {
      timeAxisY,
      scaleMs,
      timeAxisLayers,
      cursor,
      selectedTimeRange
    } = this.state
    const hoverMode = { pointer: 'area', crosshair: 'tick' }[cursor]
    const hoverCoord = this.state.timeAxisHoverCoord
    const rowH = this.props.timeAxisRowH
    ctx.save()
    ctx.translate(0, timeAxisY)
    ctx.strokeStyle = '#000'
    ctx.fillStyle = '#000'
    drawAxisLayers(ctx, scaleMs, timeAxisLayers, {
      rowH,
      hoverCoord,
      hoverMode,
      selectedTimeRange
    })
    ctx.restore()
  }

  getPlotClip = () => {
    const { plotX, plotW, canvasHeight } = this.state
    const [l, r, t, b] = [plotX, plotX + plotW, 0, canvasHeight]
    const points = [
      [l, t],
      [r, t],
      [r, b],
      [l, b]
    ]
    const ctxClip = new Path2D()
    ctxClip.rect(l, t, r - l, b - t)
    const pointStr = p => p.map(c => `${c}px`).join(' ')
    const pointsStr = points.map(pointStr).join(',')
    const cssClip = `polygon(${pointsStr})`
    return { ctxClip, cssClip }
  }

  drawCanvasContent = ctx => {
    const { plots } = this.state
    const { ctxClip, cssClip } = this.getPlotClip()
    ctx.save()
    ctx.clip(ctxClip)
    ctx.textClip = cssClip
    for (const plot of plots) {
      for (const tile of plot.tiles.filter(tileHasData)) {
        this.drawTile(ctx, plot, tile)
      }
    }
    this.drawSelection(ctx)
    this.drawHighlight(ctx)
    this.drawTimeAxis(ctx)
    this.drawUnitLabel(ctx)
    this.drawTimeZoneLabel(ctx)
    this.drawLegend(ctx)
    ctx.restore()
    this.drawYAxes(ctx)
  }

  drawCanvas = node => {
    const { canvasRatio, canvasWidth, canvasHeight } = this.state
    const ctx = node.getContext('2d')
    ctx.save()
    ctx.scale(canvasRatio, canvasRatio)
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, canvasWidth, canvasHeight)
    if (this.state.rasterizeText) {
      this.drawCanvasContent(ctx)
    } else {
      const ctxProxy = ctxWithoutText(ctx)
      this.drawCanvasContent(ctxProxy)
    }
    ctx.restore()
  }

  drawLegend (ctx) {
    const { showLegend, labelPad, labelH, axisProjSelectionW } = this.props
    const {
      plots,
      plotY,
      plotX,
      activePlot,
      plotW,
      highlightedTime,
      selectedTimeRange
    } = this.state
    if (!showLegend || plots.length === 0) return
    ctx.save()

    // coords
    const boxPad = 6
    const lineW = 10
    let x = plotX + 20
    const y = plotY
    const h = plots.length * labelH + boxPad * 2
    const w =
      boxPad * 2 +
      labelPad +
      lineW +
      Math.max(...plots.map(p => fastTextWidth(p.legendName, true))) * (11 / 10)
    const pad = axisProjSelectionW + labelPad
    const plot = activePlot
    function getXCoord () {
      // if no highlight, render on the left side
      if (
        (highlightedTime === null || highlightedTime === undefined) &&
        (selectedTimeRange === null || selectedTimeRange === undefined)
      ) {
        return plotX + pad
      }
      // if highlight exists, render on opposite side of active axis
      const side = (plot && plot.axis && plot.axis.side) || 'left'
      if (side === 'left') {
        return plotX + plotW - pad - w
      } else if (side === 'right') {
        return plotX + pad
      }
    }
    x = getXCoord()

    // draw box
    ctx.beginPath()
    ctx.rect(x, y, w, h)
    ctx.fillStyle = fadeColor('white', 0.8)
    ctx.fill()
    ctx.strokeStyle = '#c0c0c0'
    ctx.stroke()

    // draw labels
    ctx.textAlign = 'left'
    ctx.textBaseline = 'middle'
    for (const [i, plot] of plots.entries()) {
      const name = plot.legendName
      Object.assign(ctx, {
        font: '13px sans-serif',
        fillStyle: this.props.labelColor,
        textCss: { userSelect: 'none', cursor: 'pointer' },
        reactTextAttrs: {
          key: `legend-${name}${i}`,
          onClick: () => {
            // allow the chart to set its own activePlot in case its being rendered in isolation
            this.setState({ activePlot: plot })

            // The function for listening to this event is in StreamFocus
            // If it's currently mounted, it will take over
            emitStreamFocusEvent(STREAM_FOCUS_SET, plot.uuid)
          }
        }
      })
      const midY = y + boxPad + labelH * (i + 0.5)
      const lineX = x + boxPad
      const textX = lineX + lineW + labelPad
      ctx.fillText(name, textX, midY)
      ctx.beginPath()
      ctx.moveTo(lineX, midY)
      ctx.lineTo(lineX + lineW, midY)
      ctx.lineWidth = 2
      ctx.strokeStyle = plot.color
      ctx.stroke()
    }
    ctx.restore()
  }

  renderLegend () {
    // REPLACED BY drawLegend
    // (but use this if you prefer the DOM version when adding new features to legend.)
    const { showLegend, margin } = this.props
    const { plots, plotY, canvasLeft } = this.state
    if (!showLegend || plots.length === 0) return
    const ChartLegend = styled.div`
      top: ${margin.top + plotY}px;
      left: ${margin.left + canvasLeft + 20}px;
      border: 1px solid #c0c0c0;
      font-size: 13px;
      position: absolute;
      padding: 5px;
      background-color: rgba(255, 255, 255, 0.8);
      z-index: 1;

      & > div {
        margin: 5px;
      }
      .colored-dash {
        height: 0px;
        width: 10px;
        border: 3px solid;
        display: inline-block;
        text-align: center;
        margin-right: 5px;
      }
    `
    return (
      <ChartLegend>
        {plots.map((p, i) => (
          <div
            key={p.uuid}
            style={{ cursor: 'pointer' }}
            onClick={() => this.setState({ activePlot: p })}
          >
            <span className='colored-dash' style={{ borderColor: p.color }} />
            <span>{p.legendName}</span>
          </div>
        ))}
      </ChartLegend>
    )
  }

  drawTimeZoneLabel (ctx) {
    const { isUTC, labelPad, labelH, updateSetting } = this.props
    const {
      activePlot,
      timeInfoY,
      utcOffset,
      showTimezoneTitle,
      plotX,
      plotW
    } = this.state
    const x = plotX + plotW - labelPad
    const y0 = timeInfoY + labelPad + labelH * 1.5
    const y1 = y0 - labelH

    const color = activePlot && activePlot.color
    ctx.textBaseline = 'middle'

    // UTC label switch
    ctx.save()
    Object.assign(ctx, {
      reactTextAttrs: {
        'data-test-name': 'utc-label',
        key: 'utc-label',
        onMouseEnter: () => this.setState({ showTimezoneTitle: true }),
        onMouseLeave: () => this.setState({ showTimezoneTitle: false }),
        onClick: () => {
          updateSetting({ setting: 'isUTC', value: !isUTC })
        }
      },
      textCss: { cursor: 'pointer', userSelect: 'none' },
      textAlign: 'right',
      fillStyle: color
    })
    const utcSign = utcOffset <= 0 ? '+' : '−'
    const utcText = isUTC ? 'UTC+0' : `UTC${utcSign}${Math.abs(utcOffset)}`
    ctx.fillText(utcText, x, y0)
    ctx.restore()

    // Underline
    {
      ctx.beginPath()
      const y = y0 + labelH / 2 - 1.5
      const w = fastTextWidth(utcText, true)
      ctx.moveTo(x - w, y)
      ctx.lineTo(x, y)
      ctx.strokeStyle = color
      ctx.stroke()
    }

    // Hover description
    if (showTimezoneTitle) {
      ctx.save()
      const text = isUTC ? 'Standard Time' : 'Local Time'
      Object.assign(ctx, {
        reactTextAttrs: { key: 'utc-desc' },
        textCss: { userSelect: 'none' },
        fillStyle: fadeColor(color, 0.8),
        textAlign: 'right'
      })
      ctx.fillText(text, x, y1)
      ctx.restore()
    }
  }

  drawUnitLabel (ctx) {
    const { labelPad, labelH, labelColor } = this.props
    const { hideHints } = this.state
    if (hideHints) return
    const {
      isHumanUnits,
      highlightedTime,
      selectedTimeRange,
      activePlot,
      timeInfoY,
      plotX
    } = this.state
    const hasAxisInfo = selectedTimeRange || highlightedTime
    const y = timeInfoY + labelPad + labelH * 1.5

    ctx.textBaseline = 'middle'
    const x0 = plotX + labelPad

    if (!hasAxisInfo) {
      ctx.save()
      Object.assign(ctx, {
        fillStyle: fadeColor('#000', 0.5),
        textAlign: 'left',
        reactTextAttrs: { key: 'unit-label-0' }
      })
      ctx.fillText(
        'Shift key to inspect and esc key to exit inspecting mode',
        x0,
        y
      )
      ctx.restore()
      return
    }

    const color = activePlot && activePlot.color

    // Possible labels align like this:
    //    Raw units ->
    //  Human units ->
    // |<-w->|  (clickable region always same size)
    // x0    x
    const maxLabel = 'Human'
    const label = isHumanUnits ? 'Human' : 'Raw'
    const maxW = fastTextWidth(maxLabel, true)
    const w = fastTextWidth(label, true)
    const x = x0 + maxW

    // Current unit (Human/Raw)
    ctx.save()
    Object.assign(ctx, {
      textAlign: 'right',
      reactTextAttrs: { key: 'unit-label-1' },
      fillStyle: color
    })
    ctx.fillText(label, x, y)
    ctx.restore()

    // Invisible, clickable label
    // (lets user click twice to turn on/off without the width of the label affecting click position)
    ctx.save()
    Object.assign(ctx, {
      reactTextAttrs: {
        key: 'unit-label-click',
        onMouseDown: () => this.setState({ isHumanUnits: !isHumanUnits })
      },
      textCss: { cursor: 'pointer', userSelect: 'none' },
      textAlign: 'right',
      globalAlpha: 0
    })
    ctx.fillText(maxLabel, x, y)
    ctx.restore()

    // Underline
    ctx.beginPath()
    const y0 = y + labelH / 2 - 1.5
    ctx.moveTo(x - w, y0)
    ctx.lineTo(x, y0)
    ctx.strokeStyle = color
    ctx.stroke()

    // units ->
    ctx.save()
    Object.assign(ctx, {
      textAlign: 'left',
      fillStyle: labelColor,
      reactTextAttrs: { key: 'unit-label-2' },
      textCss: { userSelect: 'none' }
    })
    ctx.fillText('units →', x + 2, y)
    ctx.restore()
  }

  renderTreeViz () {
    if (!this.props.showTreeViz) return
    const { margin } = this.props
    const {
      canvasHeight,
      highlightedPoint,
      selectedTimeRange,
      activePlot
    } = this.state
    const pad = 20
    const mouse = this.isMouseInside && this.lastMouseMoveEvent
    return (
      <div style={{ position: 'absolute', left: 0, top: margin.top }}>
        <TreeViz
          height={canvasHeight}
          highlightColor={activePlot.color}
          highlightedPoint={highlightedPoint}
          mouseX={mouse && mouse.offsetX}
          plotScale={this.state.scaleX}
          selectedTimeRange={selectedTimeRange}
          width={margin.left - pad}
        />
      </div>
    )
  }

  renderDebugCache () {
    if (!this.props.showDebugCache) return
    // setting to allow us to debug cache issues on client machines if they come up
    // (draws cache diagram)
    const { scaleX, plotW, plotX, activePlot } = this.state
    const { margin, width, height } = this.props
    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: height,
          background: 'white'
        }}
      >
        <DebugCache
          plot={activePlot}
          plotScale={scaleX}
          width={width}
          height={height * 0.5}
          plotW={plotW}
          plotX={margin.left + plotX}
        />
      </div>
    )
  }

  drawYAxes (ctx) {
    const {
      leftYAxes,
      rightYAxes,
      plotX,
      plotW,
      plotY,
      plotH,
      histInfoY,
      histInfoH
    } = this.state
    const { tickW, labelPad } = this.props
    ctx.strokeStyle = '#333'
    ctx.fillStyle = '#333'
    ctx.textBaseline = 'middle'

    const drawYAxis = axis => {
      const dir = { left: -1, right: 1 }[axis.side]
      ctx.save()
      if (axis.deemph) ctx.globalAlpha = 0.5
      const labelX = (tickW + labelPad) * dir
      const tickX = tickW * dir
      const unitY = histInfoY + histInfoH / 2 - labelPad
      ctx.reactTextAttrs = { key: `y-axis-${axis.unit}-unit` }
      ctx.fillText(axis.unit, labelX, unitY)
      ctx.beginPath()
      ctx.moveTo(0, plotY)
      ctx.lineTo(0, plotY + plotH)
      for (const [i, tick] of axis.ticks.entries()) {
        const y = axis.scale(tick)
        const label = axis.labels[i]
        ctx.moveTo(0, y)
        ctx.lineTo(tickX, y)
        ctx.reactTextAttrs = { key: `y-axis-${axis.unit}-${label}` }
        ctx.fillText(label, labelX, y)
      }
      ctx.stroke()
      ctx.restore()
    }

    // Left axes
    ctx.save()
    ctx.translate(plotX, 0)
    ctx.textAlign = 'right'
    for (const axis of leftYAxes) {
      drawYAxis(axis)
      ctx.translate(-axis.width, 0)
    }
    ctx.restore()

    // Right axes
    ctx.save()
    ctx.translate(plotX + plotW, 0)
    ctx.textAlign = 'left'
    for (const axis of rightYAxes) {
      drawYAxis(axis)
      ctx.translate(axis.width, 0)
    }
    ctx.restore()
  }

  renderCanvas () {
    const { margin } = this.props
    const { canvasWidth, canvasHeight, canvasRatio, cursor } = this.state
    return (
      <canvas
        ref={node => {
          if (!node) return
          this.canvas = node
          const active = { passive: false }
          node.removeEventListener('mousedown', this.handleMouseDown)
          node.removeEventListener('wheel', this.handleWheel)
          node.removeEventListener('mouseenter', this.handleMouseEnter)
          node.removeEventListener('mouseleave', this.handleMouseLeave)
          node.removeEventListener('dblclick', this.handleDblClick)
          node.removeEventListener('contextmenu', this.handleContextMenu)
          node.addEventListener('mousedown', this.handleMouseDown, active)
          node.addEventListener('wheel', this.handleWheel, active)
          node.addEventListener('mouseenter', this.handleMouseEnter, active)
          node.addEventListener('mouseleave', this.handleMouseLeave, active)
          node.addEventListener('dblclick', this.handleDblClick, active)
          node.addEventListener('contextmenu', this.handleContextMenu, active)

          this.drawCanvas(node)
        }}
        width={canvasWidth * canvasRatio}
        height={canvasHeight * canvasRatio}
        style={{
          pointerEvents: 'all',
          cursor,
          width: `${canvasWidth}px`,
          height: `${canvasHeight}px`,
          left: `${margin.left}px`,
          top: `${margin.top}px`,
          position: 'absolute'
        }}
      />
    )
  }

  renderCanvasTextContainer (clipPath, texts) {
    const { margin } = this.props
    const { canvasWidth, canvasHeight, selectableText } = this.state

    const makeCommaUnselectable = text => {
      // so long integers can be copied without the comma
      const groups = String(text)
        .split(',')
        .map(g => [g])
        .reduce((a, b) => a.concat(',', b))
      const selectable = text => (text === ',' ? { userSelect: 'none' } : {})
      return (
        <div>
          {groups.map((text, i) => (
            <span key={i} style={{ ...selectable(text) }}>
              {text}
            </span>
          ))}
        </div>
      )
    }
    return (
      <div
        key={`text-container-${clipPath}`}
        style={{
          width: canvasWidth,
          height: canvasHeight,
          left: `${margin.left}px`,
          top: `${margin.top}px`,
          position: 'absolute',
          overflow: 'hidden',
          pointerEvents: 'none',
          clipPath
        }}
      >
        {texts.map((obj, i) => (
          <div
            key={i}
            style={{
              userSelect: 'all',
              pointerEvents: selectableText ? 'bounding-box' : 'none',
              // transition: "transform 80ms",
              ...canvasTextCss(obj)
            }}
            {...obj.reactTextAttrs}
          >
            {makeCommaUnselectable(obj.text)}
          </div>
        ))}
      </div>
    )
  }

  renderCanvasText () {
    if (this.state.rasterizeText) return
    const { ctx, texts } = ctxTextExtractor()
    this.drawCanvasContent(ctx)
    const clipPaths = Array.from(new Set(texts.map(obj => obj.textClip)))
    return clipPaths.map(clipPath =>
      this.renderCanvasTextContainer(
        clipPath,
        texts.filter(obj => obj.textClip === clipPath)
      )
    )
  }

  render () {
    const { width, height, margin } = this.props
    const { plots, xDomain } = this.state
    const text = (
      <span>Use scroll wheel, trackpad or double click to zoom in and out</span>
    )

    if (plots.length === 0) {
      return (
        <div style={{ position: 'relative' }}>
          <div style={{ width, height, margin }}>
            {ChartMessage(
              'All streams are currently hidden',
              'Show a stream to display its data'
            )}
          </div>
        </div>
      )
    }

    // Rendering before xDomain is computed creates a FOUC with default x axes
    if (xDomain === null) return null

    return (
      <div
        style={{ width, height, position: 'relative', display: 'inline-block' }}
      >
        <div
          className='reset-button'
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: 30
          }}
        >
          <KeyboardShortcut
            shortcut='z'
            onTrigger={this.props.resetxDomain}
            description='Reset chart zoom'
          />
          <Button onClick={this.props.resetxDomain} style={{ flexGrow: 0 }}>
            Reset Zoom
          </Button>
        </div>
        <Tooltip
          placement='top'
          title={text}
          overlayStyle={{ minWidth: '338px' }}
        >
          {this.renderCanvas()}
        </Tooltip>
        {this.renderCanvasText()}
        {this.renderDebugCache()}
        {this.renderTreeViz()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const isUTC = isUTCSelector(state)

  return {
    isUTC
  }
}

const mapDispatchToProps = {
  updateSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(Chart)
