import * as actionTypes from './actionTypes'

export default function auth (state = {}, action) {
  switch (action.type) {
    case actionTypes.AUTHORIZE: {
      return {
        ...state,
        authorizeLoading: true,
        error: false
      }
    }
    case actionTypes.LOGIN: {
      return {
        ...state,
        error: false,
        loginLoading: true
      }
    }
    case actionTypes.LOGOUT: {
      return {}
    }
    case actionTypes.AUTHENTICATE_ERROR: {
      if (!action.maintainState) {
        return {
          authenticateError: action.error
        }
      } else {
        return {
          ...state,
          authenticateError: true
        }
      }
    }
    case actionTypes.AUTHORIZE_ERROR: {
      return {
        authorizeLoading: false,
        error: true
      }
    }
    case actionTypes.LOGIN_ERROR: {
      return {
        error: true,
        loginLoading: false
      }
    }
    case actionTypes.AUTHENTICATE_SUCCESS: {
      return {
        ...state,
        authenticateError: false
      }
    }
    case actionTypes.AUTHORIZE_SUCCESS: {
      const { groups, token, username } = action
      return {
        ...state,
        error: false,
        authorizeLoading: false,
        groups,
        token,
        username
      }
    }
    case actionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        error: false,
        loginLoading: false
      }
    }
    case actionTypes.CHANGE_PASSWORD: {
      return {
        ...state,
        changePasswordStatus: 'loading'
      }
    }
    case actionTypes.CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        changePasswordStatus: 'error'
      }
    }
    case actionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordStatus: 'success'
      }
    }
    case actionTypes.CLEAR_CHANGE_PASSWORD: {
      return {
        ...state,
        changePasswordStatus: null
      }
    }
    case actionTypes.CLEAR_API_KEY: {
      return {
        ...state,
        resetApiKeyStatus: null,
        apikey: null
      }
    }
    case actionTypes.RESET_API_KEY: {
      return {
        ...state,
        resetApiKeyStatus: 'loading'
      }
    }
    case actionTypes.RESET_API_KEY_ERROR: {
      return {
        ...state,
        resetApiKeyStatus: 'error'
      }
    }
    case actionTypes.RESET_API_KEY_SUCCESS: {
      return {
        ...state,
        apikey: action.apikey,
        resetApiKeyStatus: 'success'
      }
    }
    default:
      return state
  }
}
