import lodashGet from 'lodash.get'

import { RESIZABLE_GRID } from '../../constants'

export const miniplotterLocked = state => !!state.ui.miniplotter.locked
export const miniplotterPosition = state => state.ui.miniplotter.position
export const resizableGridDragging = state =>
  !state.ui.resizableGridLayout.dragging
export const resizableGridIsDirty = state =>
  state.ui.resizableGridLayout.isDirty
export const resizableGridItem = (state, itemName) => {
  const layout = lodashGet(resizableGridLayouts(state), 'xxs')
  if (Array.isArray(layout)) {
    const gridItem = layout.find(item => item.i === itemName)
    return gridItem
  }
  return null
}
export const resizableGridItemMap = state => {
  const map = {}
  for (const item of Object.values(RESIZABLE_GRID.ITEM_KEYS)) {
    map[item] = resizableGridItem(state, item)
  }
  return map
}
export const resizableGridItemIsVisible = (state, itemName) => {
  let isVisible = false

  const layout = lodashGet(resizableGridLayouts(state), 'xxs')
  if (Array.isArray(layout)) {
    const gridItem = layout.find(item => item.i === itemName)
    isVisible = !!gridItem
  }

  return isVisible
}
export const resizableGridLayoutIsDraggable = state =>
  state.ui.resizableGridLayout.isDraggable
export const resizableGridLayoutIsResizable = state =>
  state.ui.resizableGridLayout.isResizable
export const resizableGridLayouts = state =>
  state.ui.resizableGridLayout.layouts
export const resizableGridItemVisibleMap = state => {
  const map = {}
  for (const item of Object.values(RESIZABLE_GRID.ITEM_KEYS)) {
    map[item] = resizableGridItemIsVisible(state, item)
  }
  return map
}
