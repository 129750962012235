import gql from 'graphql-tag'

import {
  convertArrayToObject,
  generateCollection,
  isInnerNode
} from './collectionGen'

const GET_GROUPS = gql`
  query GroupQuery($prefix: [String]!) {
    GroupQuery(body: { prefix: $prefix }) {
      innerNodes {
        key
        value
      }
      leafNodes {
        uuid
        collection
        annotations
        tags
      }
    }
  }
`

export async function getGroups ({ client, prefix }) {
  try {
    const data = await client.query({
      query: GET_GROUPS,
      variables: { prefix }
    })

    if (Object.values(data)[0].GroupQuery.innerNodes.length > 0) {
      const a = Object.values(data)[0].GroupQuery.innerNodes.map(node =>
        Object.values(node)
      )
      const b = generateCollection(a)
      const c = convertArrayToObject(b, 'collection')
      const collections = isInnerNode(c, true)
      return { data: collections }
    } else {
      const c = Object.values(data)[0].GroupQuery.leafNodes
      const collections = isInnerNode(c, false)
      return { data: collections }
    }
  } catch (e) {
    return { error: e.message }
  }
}
