import set from 'lodash.set'

import * as constants from '../constants'

export const clearStateFromLocalStorage = () => {
  localStorage.removeItem(constants.LOCAL_STORAGE.REDUX_STATE_KEY)
}

export const saveStateToLocalStorage = (path, value) => {
  try {
    const storedState = getStateFromLocalStorage() || {}
    const serializedState = JSON.stringify(set(storedState, path, value))
    localStorage.setItem(
      constants.LOCAL_STORAGE.REDUX_STATE_KEY,
      serializedState
    )
  } catch (err) {
    console.error('Error ', err)
  }
}

export const getStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem(
      constants.LOCAL_STORAGE.REDUX_STATE_KEY
    )
    if (serializedState === null) {
      return {}
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return {}
  }
}

const VALID_THEMES = ['system', 'dark', 'light']

export const saveThemeToLocalStorage = theme => {
  if (!VALID_THEMES.includes(theme)) throw new Error('invalid theme')
  localStorage[constants.LOCAL_STORAGE.THEME_CHOICE] = theme
}

export const getThemeFromLocalStorage = () => {
  let theme
  try {
    theme = localStorage[constants.LOCAL_STORAGE.THEME_CHOICE]
    if (!VALID_THEMES.includes(theme)) throw new Error('invalid theme')
  } catch (e) {
    theme = 'system'
    saveThemeToLocalStorage(theme)
  }
  return theme
}
