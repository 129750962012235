import { useState } from 'react'

/*
most from https://github.com/theKashey/use-callback-ref
*/

const useCallbackRef = ({ initialValue, callback }) => {
  const [ref] = useState(() => ({
    value: initialValue,
    callback,
    facade: {
      get current () {
        return ref.value
      },
      set current (value) {
        const last = ref.value
        if (last !== value) {
          ref.value = value
          ref.callback(value, last)
        }
      }
    }
  }))

  ref.callback = callback

  return ref.facade
}

export default useCallbackRef
