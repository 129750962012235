import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { redesignEnabled } from '../../../utils/featureFlags'
import {
  PopConfirm as PopConfirmRedesign,
  YScaleControl as YScaleControlRedesign
} from './YScaleControlComponent.redesign'
import {
  PopConfirmContentComponent,
  YScaleControlComponent
} from './YScaleControlComponent'
import plotter from '../../../ducks/plotter'

const YScalePopconfirm = ({
  min,
  setMin,
  max,
  setIsAuto,
  setMax,
  setShowPopconfirm,
  confirmHandler = () => {},
  cancelHandler = () => {
    setIsAuto(true)
    setShowPopconfirm(false)
  },
  showPopconfirm
}) => {
  const minInputRef = useRef()
  useLayoutEffect(() => {
    if (showPopconfirm) {
      requestAnimationFrame(
        () =>
          minInputRef.current &&
          minInputRef.current.focus &&
          minInputRef.current.focus()
      )
    }
  }, [showPopconfirm])
  const stepSize = useState(Math.floor(Math.abs(max - min) * 0.05))[0]
  const verifyNumber = (setFn, val) => {
    // eslint-disable-next-line no-self-compare
    if (Number(val) !== Number(val) || val === null) return setFn(undefined)
    setFn(val)
  }

  const Component = redesignEnabled()
    ? PopConfirmRedesign
    : PopConfirmContentComponent

  return (
    <Component
      cancelHandler={cancelHandler}
      confirmHandler={confirmHandler}
      max={max}
      min={min}
      minInputRef={minInputRef}
      setIsAuto={setIsAuto}
      setMax={setMax}
      setMin={setMin}
      stepSize={stepSize}
      verifyNumber={verifyNumber}
    />
  )
}

const Container = row => {
  const [isAuto, setIsAuto] = useState(row.original.scale === undefined)
  const [showPopconfirm, setShowPopconfirm] = useState(false)
  const [min, setMin] = useState()
  const [max, setMax] = useState()
  const axes = useSelector(plotter.selectors.axes)
  const axis = axes[row.original.unit]
  const dispatch = useDispatch()

  useEffect(() => {
    // helps keep state in sync by always removing the axis scale
    // and hiding the popconfirm when autoscaling is on
    if (isAuto && axis.scale !== undefined) {
      dispatch(
        plotter.actions.updateAxis({
          name: row.original.unit,
          values: {
            scale: undefined
          }
        })
      )
    }

    if (isAuto) {
      setIsAuto(true)
      setShowPopconfirm(false)
    }
    // eslint-disable-next-line
  }, [axis, isAuto, row.original.unit])

  useEffect(() => {
    if (Array.isArray(axis) && axis.length === 2) {
      setMin(axis[0])
      setMax(axis[1])
    }
  }, [isAuto, setMin, setMax, axis])

  const confirmHandler = () => {
    if (
      min === undefined ||
      min === null ||
      max === undefined ||
      max === null
    ) {
      return
    }

    dispatch(
      plotter.actions.updateAxis({
        name: row.original.unit,
        values: {
          scale: [min, max]
        }
      })
    )
    setIsAuto(false)
    setShowPopconfirm(false)
  }

  const cancelHandler = () => {
    setShowPopconfirm(false)
    setIsAuto(true)
  }

  const Component = redesignEnabled()
    ? YScaleControlRedesign
    : YScaleControlComponent

  return (
    <Component
      cancelHandler={cancelHandler}
      confirmHandler={confirmHandler}
      isAuto={isAuto}
      max={max}
      min={min}
      renderPopConfirm={() => (
        <YScalePopconfirm
          cancelHandler={cancelHandler}
          confirmHandler={confirmHandler}
          max={max}
          min={min}
          setIsAuto={setIsAuto}
          setMax={setMax}
          setMin={setMin}
          showPopconfirm={showPopconfirm}
        />
      )}
      setIsAuto={setIsAuto}
      setMax={setMax}
      setMin={setMin}
      setShowPopconfirm={setShowPopconfirm}
      scale={row.original.scale}
      showPopconfirm={showPopconfirm}
    />
  )
}

export default Container
