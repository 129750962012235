export const heatmapValues = state =>
  state.heatmap.data ? state.heatmap.data : []
export const heatmapMatrix = state =>
  state.heatmap.matrix ? state.heatmap.matrix : []
export const heatmapLoading = state => state.heatmap.loading
export const heatmapError = state => state.heatmap.error

export const nearestValues = state => state.heatmap.startEnd || []
export const nearestTimes = state => state.heatmap.times || []
export const nearestLoading = state => state.heatmap.nearest.loading
export const nearestError = state => state.heatmap.nearest.error
