import React from 'react'

import styled from '@xstyled/styled-components'

import Card from './Card'
import portalConfig from '../../services/portalConfig'

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -16px;

  @media (max-width: 758px) {
    justify-content: center;
  }
`

const cards = portalConfig

const Cards = () => {
  const total = cards.length

  return (
    <CardsContainer>
      {cards.map((card, key) => (
        <Card counter={key} key={key} total={total} {...card} />
      ))}
    </CardsContainer>
  )
}

export default Cards
