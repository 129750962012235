import jwtDecode from 'jwt-decode'

const retrieveCookie = key => {
  const cookies = document.cookie.split(';').map(c => c.split('='))
  const foundCookies = cookies.find(([k, _]) => k.trim() === key)
  const cookie = foundCookies && foundCookies[1]

  return cookie && decodeURI(cookie)
}

export const getSsoToken = () => {
  return retrieveCookie('token')
}

const decodeSsoToken = () => {
  const encodedToken = getSsoToken()

  if (encodedToken) {
    try {
      return jwtDecode(encodedToken)
    } catch (err) {
      console.error('Unable to decode token:', encodedToken, err)
    }
  }
}

export const getSsoUsername = () => {
  const decodedToken = decodeSsoToken()
  return decodedToken?.attr?.pt_username?.[0]
}

export const isLoggedInSso = () => !!getSsoToken()
