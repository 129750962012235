import React from 'react'

import ColumnSelectorButton from './ColumnSelectorButton'
import ColumnSelectorModal from './ColumnSelectorModal'

const ColumnSelector = ({ selectedRows }) => {
  return (
    <>
      <ColumnSelectorButton />
      <ColumnSelectorModal selectedRows={selectedRows} />
    </>
  )
}

export default ColumnSelector
