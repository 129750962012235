import * as actions from './actions'
import * as actionTypes from './actionTypes'
import reducer from './reducer'
import * as sagas from './sagas'
import * as selectors from './selectors'
import * as serializers from './serializers'

export default {
  actions,
  actionTypes,
  reducer,
  sagas,
  selectors,
  serializers
}
