export const AUTHORIZE = 'auth/AUTHORIZE'
export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD'
export const CLEAR_API_KEY = 'auth/CLEAR_API_KEY'
export const CLEAR_CHANGE_PASSWORD = 'auth/CLEAR_CHANGE_PASSWORD'
export const GET_GROUPS = 'auth/GET_GROUPS'
export const LOGIN = 'auth/LOGIN'
export const LOGOUT = 'auth/LOGOUT'
export const RESET_API_KEY = 'auth/RESET_API_KEY'

export const AUTHENTICATE_ERROR = 'auth/AUTHENTICATE_ERROR'
export const AUTHORIZE_ERROR = 'auth/AUTHORIZE_ERROR'
export const CHANGE_PASSWORD_ERROR = 'auth/CHANGE_PASSWORD_ERROR'
export const GET_GROUPS_ERROR = 'auth/GET_GROUPS_ERROR'
export const LOGIN_ERROR = 'auth/LOGIN_ERROR'
export const RESET_API_KEY_ERROR = 'auth/RESET_API_KEY_ERROR'

export const AUTHENTICATE_SUCCESS = 'auth/AUTHENTICATE_SUCCESS'
export const AUTHORIZE_SUCCESS = 'auth/AUTHORIZE_SUCCESS'
export const CHANGE_PASSWORD_SUCCESS = 'auth/CHANGE_PASSWORD_SUCCESS'
export const GET_GROUPS_SUCCESS = 'auth/GET_GROUPS_SUCCESS'
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS'
export const RESET_API_KEY_SUCCESS = 'auth/RESET_API_KEY_SUCCESS'
