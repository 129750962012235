import { call, takeEvery } from 'redux-saga/effects'
import { report as reportSentry } from '../../utils/sentry'

export function * report (action) {
  yield call(reportSentry, new Error(action.type), {
    level: 'info',
    tags: {
      alertName: 'redux-error'
    },
    extraScope: { action }
  })
}

export function watchPredicate (action) {
  const okList = ['auth/LOGIN_ERROR', 'auth/AUTHENTICATE_ERROR']

  const isOkListError = okList.includes(action.type)

  if (isOkListError) {
    return false
  }

  return /_ERROR$/.test(action.type)
}

export const watchers = [takeEvery(watchPredicate, report)]
