import gql from 'graphql-tag'
import lodashGet from 'lodash.get'

export const SQL_QUERY = gql`
  subscription SQLQuery($query: String!, $params: [String]!) {
    SQLQuery(body: { query: $query, params: $params }) {
      SQLQueryRow
    }
  }
`

export const query = async ({ client, params, query }) => {
  const observable = client.subscribe({
    query: SQL_QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      query,
      params
    }
  })

  function queryPromise (results = []) {
    return new Promise((resolve, reject) => {
      observable.subscribe({
        next: response => {
          const queryRows = lodashGet(response, 'data.SQLQuery.SQLQueryRow', [])
          results = [...results, ...queryRows]
        },
        error: error => reject(error),
        complete: () => {
          resolve(results)
        }
      })
    })
  }

  try {
    const results = await queryPromise()
    return { data: results }
  } catch (e) {
    console.error(e.message)
    return { error: e.message }
  }
}
