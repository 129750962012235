import { call, getContext, put, takeEvery } from '@redux-saga/core/effects'

import * as actionTypes from './actionTypes'
import * as api from './api'
import LruCache from '../../services/lruCache'

export const lruCache = new LruCache()

function * getHeatmapStreams (action) {
  const client = yield getContext('client')

  const { uuid, start, end, resolution } = action

  const { data, error } = yield call(api.alignedWindows, {
    client,
    uuid,
    start,
    end,
    resolution
  })

  if (error) {
    yield put({ type: actionTypes.QUERY_WINDOWS_ERROR, error })
  } else {
    yield put({ type: actionTypes.QUERY_WINDOWS_SUCCESS, data, uuid })
  }
}

function * getNearestQuery (action) {
  const client = yield getContext('client')

  const { uuid } = action

  const { data, error } = yield call(api.queryStartEnd, {
    client,
    uuid
  })
  if (error) {
    yield put({ type: actionTypes.QUERY_START_END_ERROR, error })
  } else {
    yield put({ type: actionTypes.QUERY_START_END_SUCCESS, data, uuid })
  }
}

const watchers = [
  takeEvery(actionTypes.QUERY_WINDOWS, getHeatmapStreams),
  takeEvery(actionTypes.QUERY_START_END, getNearestQuery)
]

export { getHeatmapStreams, getNearestQuery, watchers }
