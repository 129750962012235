import React from 'react'

import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import styled from 'styled-components'

import { mapEnabled } from '../../utils/featureFlags'
import LoginStatus from '../Login/LoginStatus'
import logo from '../../assets/images/logo.svg'
import plotter from '../../ducks/plotter'

const HeaderNavWrapper = styled.div`
  background: rgba(220, 220, 220);
  border-left: 10px solid rgba(200, 200, 200);
  color: #333;
  display: inline-block;
  font-family: Fira Code, monospace;
  font-size: 14px;
  margin-left: 20px;
  padding: 10px 20px;
  text-align: center;
  transform: skew(-20deg);
  transition: all 0.3s ease;

  &.active {
    background: #ffb84e;
    border-left-color: #ff9c29;
  }

  &.hidden {
    cursor: default;
    opacity: 0;
  }

  &:hover {
    background: rgba(255, 184, 78, 0.7);
    border-left-color: rgba(255, 156, 41, 0.7);
    cursor: pointer;
  }
`

const StatusWrapper = styled.div`
  position: relative;
`

const StyledLogo = styled.img`
  margin-left: -12px;
  margin-right: 60px;
  width: 178px;
`

const StyledNav = styled.nav`
  align-items: center;
  background: #e8e8e8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 96px;
  min-width: 1050px;
  padding-left: 20px;
  padding-right: 20px;
`

const StyledNavLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;
`

const LinkItem = ({ hidden, name, to }) => {
  const { pathname } = useLocation()

  const active = pathname === to

  const className = classNames({
    active,
    hidden
  })

  return (
    <StyledNavLink to={to}>
      <HeaderNavWrapper className={className}>{name}</HeaderNavWrapper>
    </StyledNavLink>
  )
}

const LogoItem = () => (
  <StyledNavLink to='/'>
    <StyledLogo src={logo} className='nav-logo' alt='PingThings' />
  </StyledNavLink>
)

const HeaderNav = () => {
  const { pathname } = useLocation()

  const loginIsVisible = !pathname.includes('/login')
  const mapIsVisible = loginIsVisible
  const selectIsVisible = loginIsVisible
  const streamsSelected = useSelector(plotter.selectors.streamsSelected)
  const visualizeIsVisable = !!streamsSelected && loginIsVisible

  return (
    <StyledNav>
      <div>
        <LogoItem />
        <LinkItem
          hidden={!selectIsVisible}
          name='Stream Selection'
          to='/select'
        />
        {mapEnabled() && (
          <span data-test-name='pmu-map'>
            <LinkItem hidden={!mapIsVisible} name='Map' to='/map' />
          </span>
        )}
        <span data-test-name='stream-visualization'>
          <LinkItem
            hidden={!visualizeIsVisable}
            name='Stream Visualization'
            to='/visualize'
          />
        </span>
      </div>
      <StatusWrapper>{loginIsVisible && <LoginStatus />}</StatusWrapper>
    </StyledNav>
  )
}

export default HeaderNav
