import React from 'react'

import { Helmet } from 'react-helmet'

import Cards from './Cards'
import Nav from './Nav'
import PageWrap from './PageWrap'
import Title from './Title'
import { ThemeProvider, lightTheme } from 'frontend-components'

const PortalContainer = () => (
  <ThemeProvider theme={lightTheme}>
    <Nav />
    <Portal />
  </ThemeProvider>
)

export const Portal = () => (
  <>
    <Helmet>
      <title>PingThings - Portal</title>
    </Helmet>
    <PageWrap>
      <Title />
      <Cards />
    </PageWrap>
  </>
)

export default PortalContainer
