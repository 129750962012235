// ----------------------------------------------------------------------
// Resolutions
// (a resolution of n means a stat-point width of 2^n nanoseconds)
// ----------------------------------------------------------------------

export const maxRes = 56 // top-level node starts at this resolution
export const stepRes = 6 // moving up/down a tree level changes by this amount in resolution
export const minRes = 9 // 64-bit floats cannot descend further than this (see timestamp.js for math)
export const bottomRes = 2 // actual min

export function clampRes (res) {
  return clamp(res, [minRes, maxRes])
}

// ----------------------------------------------------------------------
// Timestamp Bounds
// ----------------------------------------------------------------------

// To determine the timeMin and timeMax of a BTrDB tree,
// we look at the root node containing 64 points.
// The unix epoch 1970 is defined as the 16th point on this node.
//
//  0    8   16   24   32   40   48   56   64 (index)
//  |----|----|----|----|----|----|----|----|
// -16  -8    0    8   16   24   32   40   48 (time * 2^56 ns)
//            ^ epoch

export const timeMin = 0 - 16 * 2 ** maxRes
export const timeMax = 0 + 48 * 2 ** maxRes
const timeBounds = [timeMin, timeMax]

export function clampTime (t) {
  return clamp(t, timeBounds)
}

export function clampTimeRange (range) {
  return cropRange(range, timeBounds)
}

// ----------------------------------------------------------------------
// Indexing of points at a given resolution
// ----------------------------------------------------------------------

// limit lookups for each resolution
export const indexMin = {}
export const indexMax = {}
export const indexBounds = {}
for (let res = minRes; res <= maxRes; res++) {
  const min = timeToIndex(timeMin, res)
  const max = timeToIndex(timeMax, res)
  indexMin[res] = min
  indexMax[res] = max
  indexBounds[res] = [min, max]
}

export function clampIndex (i, res) {
  return clamp(i, indexBounds[res])
}

export function clampIndexRange (range, res) {
  return cropRange(range, indexBounds[res])
}

export function timeToIndex (t, res, round = Math.floor) {
  const pointWidth = 2 ** res
  const i = round(t / pointWidth)
  return i
}

export function indexToTime (i, res) {
  const pointWidth = 2 ** res
  const t = i * pointWidth
  return t
}

export function indexRangeToTime ([a, b], res) {
  return [indexToTime(a, res), indexToTime(b, res)]
}

export function timeRangeToIndex ([a, b], res) {
  return [timeToIndex(a, res), timeToIndex(b, res)]
}

export function convertIndex (i, fromRes, toRes, round = Math.floor) {
  const t = indexToTime(i, fromRes)
  return timeToIndex(t, toRes, round)
}

export function convertIndexRange ([a, b], fromRes, toRes) {
  a = convertIndex(a, fromRes, toRes, Math.floor)
  b = convertIndex(b, fromRes, toRes, Math.ceil)
  return [a, b]
}

// ----------------------------------------------------------------------
// Timestamp Rounding
// ----------------------------------------------------------------------

export function roundTime (t, res, round) {
  const i = timeToIndex(t, res, round)
  return indexToTime(i, res)
}

export function roundTimeRange (range, res) {
  if (!range) return
  let [a, b] = range
  a = roundTime(a, res, Math.floor)
  b = roundTime(b, res, Math.ceil)
  return [a, b]
}

export function visibleTimeRange ([a, b], res) {
  // get all visible points at this resolution
  ;[a, b] = roundTimeRange([a, b], res)

  // add a point on either side
  // (these points are not visible, but the lines connecting to them are)
  const pointWidth = 2 ** res
  a -= pointWidth
  b += pointWidth

  return [a, b]
}

// ----------------------------------------------------------------------
// Compute Resolution
// ----------------------------------------------------------------------

export function getExactResolution ([minTime, maxTime], [minPixel, maxPixel]) {
  const ns = maxTime - minTime
  const pixels = maxPixel - minPixel
  const nsPerPixel = ns / pixels

  // BTrDB defines resolution as an integer exponent of 2
  const res = Math.log2(nsPerPixel)
  return res
}

export function getResolution ([minTime, maxTime], [minPixel, maxPixel]) {
  return Math.ceil(getExactResolution([minTime, maxTime], [minPixel, maxPixel]))
}

export function getScaleResolution (scale) {
  return getResolution(scale.domain(), scale.range())
}

export function getExactScaleResolution (scale) {
  return getExactResolution(scale.domain(), scale.range())
}

// ----------------------------------------------------------------------
// Range functions [start,end)
// (start is inclusive, end is exclusive)
// ----------------------------------------------------------------------

export function isRangeEmpty ([a, b]) {
  return a === b
}

export function isInRange (i, [a, b]) {
  return a <= i && i < b
}

export function rangesEqual ([a, b], [c, d]) {
  return a === b && c === d
}

export function rangeIntersect (a, b) {
  const [a0, a1] = a
  const [b0, b1] = b
  if (a1 <= b0 || b1 <= a0) return null
  return [Math.max(a0, b0), Math.min(a1, b1)]
}

export function cropRange ([a, b], [min, max]) {
  return [Math.max(min, a), Math.min(max, b)]
}

export function clamp (val, [min, max]) {
  val = Math.min(max, val)
  val = Math.max(min, val)
  return val
}

export function clampRange ([a, b], [min, max]) {
  let d = 0
  if (a < min) d = min - a
  else if (b > max) d = max - b
  return [a + d, b + d]
}
