import { useSelector, useDispatch } from 'react-redux'
import pmus from '../ducks/pmus'

export default function usePmus ({ useCache }) {
  const dispatch = useDispatch()

  const loading = useSelector(pmus.selectors.loading)
  const error = useSelector(pmus.selectors.error)
  const success = useSelector(pmus.selectors.success)
  const data = Object.values(useSelector(pmus.selectors.pmusData))

  if (!success && !loading && !error) {
    dispatch(pmus.actions.getPmus({ useCache }))
  }

  return { data, loading, error }
}
