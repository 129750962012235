import React from 'react'

import { connect } from 'react-redux'
import lodashGet from 'lodash.get'
import PropTypes from 'prop-types'

import { constrainPageSizeOptions, filterAllMethod } from '../../utils/utils'
import { redesignEnabled } from '../../utils/featureFlags'
import { ResetXScale } from './StreamListTableComponents/ResetXScale'
import {
  StreamFocus,
  StreamFocusContext,
  useListenForStreamFocusEvents,
  useReconcileStreamFocusStateEffect,
  useStreamFocusReducer
} from './StreamListTableComponents/StreamFocus'
import ColorPicker from '../ColorPicker/ColorPicker'
import EditableLabelContainer from '../EditableLabel/EditableLabelContainer'
import plotter from '../../ducks/plotter'
import streams from '../../ducks/streams'

import StreamListTable, { AxisSelect, StreamShowHide } from './StreamListTable'
import StreamListTableRedesign, {
  ActionColumn,
  AxisSelect as AxisSelectRedesign,
  StreamShowHide as StreamShowHideRedesign
} from './StreamListTable.redesign'
import ColorPickerRedesign from '../ColorPicker/ColorPicker.redesign'
import YScaleControlContainer from './AxesTableComponents/YScaleControlContainer'

export function StreamListTableWrapper (props) {
  const streamFocus = useStreamFocusReducer({})
  const [streamFocusState, streamFocusDispatch] = streamFocus
  useReconcileStreamFocusStateEffect({ streamFocusState })
  useListenForStreamFocusEvents(streamFocusDispatch)

  return (
    <StreamFocusContext.Provider value={streamFocus}>
      <StreamListTableContainer {...props} />
    </StreamFocusContext.Provider>
  )
}

class StreamListTableContainer extends React.Component {
  static propTypes = {
    axes: PropTypes.object.isRequired,
    legend: PropTypes.object.isRequired,
    selectedColumns: PropTypes.array.isRequired
  }

  changeAxisName = (prevName, name) => {
    const { updateAxisName } = this.props
    updateAxisName({ name, prevName })
  }

  changeLegendName = (oldName, name, stream) => {
    const { setLegendName } = this.props
    setLegendName({ name, uuid: stream.plot.uuid })
  }

  changeStreamAxis = (uuid, value) => {
    const { changeStreamAxis } = this.props
    changeStreamAxis({ uuid, value })
  }

  createNewAxisAndAddStream = uuid => {
    const { createNewAxisAndAddStream } = this.props
    createNewAxisAndAddStream({ uuid })
  }

  changeStreamColor = (uuid, color) => {
    const { setStreamColor } = this.props
    setStreamColor({ color, uuid })
  }

  defaultStreamListColumns = ({ axes, axisNames, legend, selectedRows }) => {
    const actionColumn = {
      Header: 'action',
      maxWidth: 128,
      Cell: row => {
        row = redesignEnabled() ? row.row : row
        return (
          <ActionColumn axes={axes} row={row} updateAxis={this.updateAxis} />
        )
      }
    }

    const axisColumn = {
      Header: 'axis',
      id: 'stream-unit',
      accessor: d => d.plot && d.plot.unit,
      minWidth: 130,
      maxWidth: 200,
      Cell: row => {
        row = redesignEnabled() ? row.row : row
        const Component = redesignEnabled() ? AxisSelectRedesign : AxisSelect
        const { plot, metadata } = row.original
        return (
          <Component
            axisNames={axisNames}
            changeStreamAxis={this.changeStreamAxis}
            createNewAxisAndAddStream={this.createNewAxisAndAddStream}
            metadata={metadata}
            plot={plot}
          />
        )
      },
      filterAll: true
    }

    const colorColumn = {
      Header: 'color',
      accessor: 'plot.color',
      maxWidth: 70,
      style: {
        overflow: 'visible',
        display: 'flex',
        alignItems: 'center'
      },
      Cell: row => {
        row = redesignEnabled() ? row.row : row
        const Component = redesignEnabled() ? ColorPickerRedesign : ColorPicker

        return (
          <Component
            plot={row.original.plot}
            changeStreamColor={this.changeStreamColor}
          />
        )
      },
      filterable: false
    }

    const focusColumn = {
      Header: 'focus',
      maxWidth: 65,
      Cell: row => {
        row = redesignEnabled() ? row.row : row
        return <StreamFocus {...row} />
      }
    }

    const showHideColumn = {
      Header: 'show / hide',
      accessor: 'plot.visibility',
      minWidth: 150,
      maxWidth: 200,
      Cell: row => {
        row = redesignEnabled() ? row.row : row
        const plot = row.original.plot

        if (!plot) {
          return null
        }

        const axisName = plot.unit
        const axisVisibility =
          !!axes[axisName] &&
          !!axes[axisName].streams.find(
            uuid => lodashGet(selectedRows[uuid], 'plot.visibility') === true
          )

        const Component = redesignEnabled()
          ? StreamShowHideRedesign
          : StreamShowHide

        return (
          <Component
            axisVisibility={axisVisibility}
            color={plot.color}
            plotVisibility={plot.visibility}
            toggleAxisViz={() => this.toggleAxisStreamViz(plot.uuid)}
            toggleStreamViz={() => this.toggleStreamViz(plot.uuid)}
          />
        )
      }
    }

    const unitColumn = {
      Header: 'edit units',
      accessor: 'unit',
      Cell: row => {
        const unit = lodashGet(row, 'row.original.plot.unit')
        return (
          <EditableLabelContainer
            value={unit}
            commitEdit={this.changeAxisName}
            entity={axes[unit]}
          />
        )
      }
    }

    const yScaleColumn = {
      Header: 'y-scale',
      Cell: row => {
        row = row.row
        const axisName = row.values.axis
        const axisData = axes[axisName]

        return (
          <>
            {axisData && <YScaleControlContainer {...{ original: axisData }} />}
          </>
        )
      }
    }

    const zoomColumn = {
      Header: 'zoom',
      maxWidth: 65,
      Cell: row => {
        row = redesignEnabled() ? row.row : row
        return <ResetXScale row={row} />
      }
    }

    if (redesignEnabled()) {
      const shouldSkipUnitColumn = this.props.selectedColumns.some(
        col => col.id === 'tags.unit'
      )

      const cols = [
        colorColumn,
        ...this.renderLegendNameCol(legend),
        axisColumn,
        showHideColumn,
        yScaleColumn,
        actionColumn,
        ...this.props.selectedColumns.map(col => ({
          ...col,
          filterAll: true
        }))
      ]

      if (!shouldSkipUnitColumn) {
        cols.splice(4, 0, unitColumn)
      }

      return cols
    } else {
      return [
        colorColumn,
        ...this.renderLegendNameCol(legend),
        ...this.props.selectedColumns.map(col => ({
          ...col,
          filterAll: true
        })),
        axisColumn,
        zoomColumn,
        focusColumn,
        showHideColumn
      ]
    }
  }

  renderLegendNameCol = legend => {
    if (legend && !legend.visible) return []
    return [
      {
        Header: 'legend',
        id: 'stream-legendName',
        accessor: 'plot.legendName',
        filterAll: true,
        Cell: row => {
          row = redesignEnabled() ? row.row : row

          const { plot } = row.original
          return (
            <>
              {plot && (
                <EditableLabelContainer
                  value={plot.legendName}
                  commitEdit={this.changeLegendName}
                  entity={row.original}
                />
              )}
            </>
          )
        }
      }
    ]
  }

  toggleStreamViz = uuid => {
    const { toggleStreamVisibility } = this.props
    toggleStreamVisibility({ uuid })
  }

  toggleAxisStreamViz = (uuid, chartIndex) => {
    const { toggleAxisStreamVisibility } = this.props
    toggleAxisStreamVisibility({ uuid })
  }

  updateAxis = (name, values) => {
    const { updateAxis } = this.props
    updateAxis({ name, values })
  }

  render () {
    const { axes, legend, selectedRows } = this.props

    const streamsArray = Object.keys(selectedRows)
      .sort()
      .map(k => selectedRows[k])

    const axisNames = Object.keys(axes)

    const Component = redesignEnabled()
      ? StreamListTableRedesign
      : StreamListTable

    return (
      <>
        <Component
          columns={this.defaultStreamListColumns({
            axes,
            axisNames,
            legend,
            selectedRows
          })}
          constrainPageSizeOptions={constrainPageSizeOptions}
          data={streamsArray}
          filterAllMethod={filterAllMethod}
        />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const axes = plotter.selectors.axes(state)
  const legend = plotter.selectors.legend(state)
  const selectedColumns = streams.selectors.streamTableSelectedColumns(state)
  const selectedRows =
    ownProps.selectedRows || plotter.selectors.selectedRows(state)

  return {
    axes,
    legend,
    selectedColumns,
    selectedRows
  }
}

const mapDispatchToProps = {
  changeStreamAxis: plotter.actions.changeStreamAxis,
  createNewAxisAndAddStream: plotter.actions.createNewAxisAndAddStream,
  setLegendName: plotter.actions.setLegendName,
  setStreamColor: plotter.actions.setStreamColor,
  toggleAxisStreamVisibility: plotter.actions.toggleAxisStreamVisibility,
  toggleStreamVisibility: plotter.actions.toggleStreamVisibility,
  updateAxisName: plotter.actions.updateAxisName,
  updateAxis: plotter.actions.updateAxis
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StreamListTableWrapper)
