import {
  grouperEnabled,
  heatmapEnabled,
  mapEnabled
} from '../../utils/featureFlags'
import { RESIZABLE_GRID } from '../../constants'

export const defaultLayout = [
  {
    w: 6,
    h: 14,
    x: 0,
    y: 24,
    i: RESIZABLE_GRID.ITEM_KEYS.STREAM_LIST_TABLE,
    moved: false,
    static: false
  },
  {
    w: 12,
    h: 24,
    x: 0,
    y: 0,
    i: RESIZABLE_GRID.ITEM_KEYS.BIG_CHART,
    minW: 3,
    minH: 12,
    moved: false,
    static: false
  },
  {
    w: 6,
    h: 14,
    x: 6,
    y: 24,
    i: RESIZABLE_GRID.ITEM_KEYS.SELECTED_STREAMS,
    moved: false,
    static: false
  },
  {
    w: 8,
    h: 53,
    x: 0,
    y: 38,
    i: RESIZABLE_GRID.ITEM_KEYS.ALL_STREAMS,
    moved: false,
    static: false
  },
  {
    w: 6,
    h: 23,
    x: 0,
    y: 91,
    i: RESIZABLE_GRID.ITEM_KEYS.HEATMAP,
    moved: false,
    static: false
  },
  {
    w: 4,
    h: 28,
    x: 8,
    y: 63,
    i: RESIZABLE_GRID.ITEM_KEYS.METADATA,
    moved: false,
    static: false
  },
  {
    w: 4,
    h: 25,
    x: 8,
    y: 38,
    i: RESIZABLE_GRID.ITEM_KEYS.MAP,
    moved: false,
    static: false
  },
  {
    w: 6,
    h: 23,
    x: 6,
    y: 91,
    i: RESIZABLE_GRID.ITEM_KEYS.GROUPER,
    moved: false,
    static: false
  }
]

export const emptyLayout = [
  {
    w: 6,
    h: 14,
    x: 0,
    y: 24,
    i: RESIZABLE_GRID.ITEM_KEYS.STREAM_LIST_TABLE,
    moved: false,
    static: false
  },
  {
    w: 12,
    h: 24,
    x: 0,
    y: 0,
    i: RESIZABLE_GRID.ITEM_KEYS.BIG_CHART,
    minW: 3,
    minH: 12,
    moved: false,
    static: false
  },
  {
    w: 6,
    h: 14,
    x: 6,
    y: 24,
    i: RESIZABLE_GRID.ITEM_KEYS.SELECTED_STREAMS,
    moved: false,
    static: false
  },
  {
    w: 9,
    h: 50,
    x: 0,
    y: 38,
    i: RESIZABLE_GRID.ITEM_KEYS.ALL_STREAMS,
    moved: false,
    static: false
  },
  {
    w: 3,
    h: 50,
    x: 9,
    y: 38,
    i: RESIZABLE_GRID.ITEM_KEYS.METADATA,
    moved: false,
    static: false
  }
]

export default () => {
  if (grouperEnabled() && heatmapEnabled() && mapEnabled()) {
    return [
      {
        w: 6,
        h: 14,
        x: 0,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.STREAM_LIST_TABLE,
        moved: false,
        static: false
      },
      {
        w: 12,
        h: 24,
        x: 0,
        y: 0,
        i: RESIZABLE_GRID.ITEM_KEYS.BIG_CHART,
        minW: 3,
        minH: 12,
        moved: false,
        static: false
      },
      {
        w: 6,
        h: 14,
        x: 6,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.SELECTED_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 8,
        h: 53,
        x: 0,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.ALL_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 6,
        h: 23,
        x: 0,
        y: 91,
        i: RESIZABLE_GRID.ITEM_KEYS.HEATMAP,
        moved: false,
        static: false
      },
      {
        w: 4,
        h: 28,
        x: 8,
        y: 63,
        i: RESIZABLE_GRID.ITEM_KEYS.METADATA,
        moved: false,
        static: false
      },
      {
        w: 4,
        h: 25,
        x: 8,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.MAP,
        moved: false,
        static: false
      },
      {
        w: 6,
        h: 23,
        x: 6,
        y: 91,
        i: RESIZABLE_GRID.ITEM_KEYS.GROUPER,
        moved: false,
        static: false
      }
    ]
  } else if (grouperEnabled() && heatmapEnabled() && !mapEnabled()) {
    return [
      {
        w: 6,
        h: 14,
        x: 0,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.STREAM_LIST_TABLE,
        moved: false,
        static: false
      },
      {
        w: 12,
        h: 24,
        x: 0,
        y: 0,
        i: RESIZABLE_GRID.ITEM_KEYS.BIG_CHART,
        minW: 3,
        minH: 12,
        moved: false,
        static: false
      },
      {
        w: 6,
        h: 14,
        x: 6,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.SELECTED_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 9,
        h: 51,
        x: 0,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.ALL_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 6,
        h: 17,
        x: 0,
        y: 89,
        i: RESIZABLE_GRID.ITEM_KEYS.HEATMAP,
        moved: false,
        static: false
      },
      {
        w: 3,
        h: 51,
        x: 9,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.METADATA,
        moved: false,
        static: false
      },
      {
        w: 6,
        h: 17,
        x: 6,
        y: 89,
        i: RESIZABLE_GRID.ITEM_KEYS.GROUPER,
        moved: false,
        static: false
      }
    ]
  } else if (grouperEnabled() && !heatmapEnabled() && mapEnabled()) {
    return [
      {
        w: 6,
        h: 14,
        x: 0,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.STREAM_LIST_TABLE,
        moved: false,
        static: false
      },
      {
        w: 12,
        h: 24,
        x: 0,
        y: 0,
        i: RESIZABLE_GRID.ITEM_KEYS.BIG_CHART,
        minW: 3,
        minH: 12,
        moved: false,
        static: false
      },
      {
        w: 6,
        h: 14,
        x: 6,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.SELECTED_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 8,
        h: 53,
        x: 0,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.ALL_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 4,
        h: 28,
        x: 8,
        y: 63,
        i: RESIZABLE_GRID.ITEM_KEYS.METADATA,
        moved: false,
        static: false
      },
      {
        w: 4,
        h: 25,
        x: 8,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.MAP,
        moved: false,
        static: false
      },
      {
        w: 12,
        h: 23,
        x: 0,
        y: 91,
        i: RESIZABLE_GRID.ITEM_KEYS.GROUPER,
        moved: false,
        static: false
      }
    ]
  } else if (!grouperEnabled() && heatmapEnabled() && mapEnabled()) {
    return [
      {
        w: 6,
        h: 14,
        x: 0,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.STREAM_LIST_TABLE,
        moved: false,
        static: false
      },
      {
        w: 12,
        h: 24,
        x: 0,
        y: 0,
        i: RESIZABLE_GRID.ITEM_KEYS.BIG_CHART,
        minW: 3,
        minH: 12,
        moved: false,
        static: false
      },
      {
        w: 6,
        h: 14,
        x: 6,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.SELECTED_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 8,
        h: 53,
        x: 0,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.ALL_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 12,
        h: 23,
        x: 0,
        y: 91,
        i: RESIZABLE_GRID.ITEM_KEYS.HEATMAP,
        moved: false,
        static: false
      },
      {
        w: 4,
        h: 28,
        x: 8,
        y: 63,
        i: RESIZABLE_GRID.ITEM_KEYS.METADATA,
        moved: false,
        static: false
      },
      {
        w: 4,
        h: 25,
        x: 8,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.MAP,
        moved: false,
        static: false
      }
    ]
  } else if (!grouperEnabled() && !heatmapEnabled() && mapEnabled()) {
    return [
      {
        w: 6,
        h: 14,
        x: 0,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.STREAM_LIST_TABLE,
        moved: false,
        static: false
      },
      {
        w: 12,
        h: 24,
        x: 0,
        y: 0,
        i: RESIZABLE_GRID.ITEM_KEYS.BIG_CHART,
        minW: 3,
        minH: 12,
        moved: false,
        static: false
      },
      {
        w: 6,
        h: 14,
        x: 6,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.SELECTED_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 8,
        h: 51,
        x: 0,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.ALL_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 4,
        h: 26,
        x: 8,
        y: 63,
        i: RESIZABLE_GRID.ITEM_KEYS.METADATA,
        moved: false,
        static: false
      },
      {
        w: 4,
        h: 25,
        x: 8,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.MAP,
        moved: false,
        static: false
      }
    ]
  } else if (grouperEnabled() && !heatmapEnabled() && !mapEnabled()) {
    return [
      {
        w: 6,
        h: 14,
        x: 0,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.STREAM_LIST_TABLE,
        moved: false,
        static: false
      },
      {
        w: 12,
        h: 24,
        x: 0,
        y: 0,
        i: RESIZABLE_GRID.ITEM_KEYS.BIG_CHART,
        minW: 3,
        minH: 12,
        moved: false,
        static: false
      },
      {
        w: 6,
        h: 14,
        x: 6,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.SELECTED_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 9,
        h: 50,
        x: 0,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.ALL_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 3,
        h: 50,
        x: 9,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.METADATA,
        moved: false,
        static: false
      },
      {
        w: 12,
        h: 22,
        x: 0,
        y: 88,
        i: RESIZABLE_GRID.ITEM_KEYS.GROUPER,
        moved: false,
        static: false
      }
    ]
  } else if (!grouperEnabled() && heatmapEnabled() && !mapEnabled()) {
    return [
      {
        w: 6,
        h: 14,
        x: 0,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.STREAM_LIST_TABLE,
        moved: false,
        static: false
      },
      {
        w: 12,
        h: 24,
        x: 0,
        y: 0,
        i: RESIZABLE_GRID.ITEM_KEYS.BIG_CHART,
        minW: 3,
        minH: 12,
        moved: false,
        static: false
      },
      {
        w: 6,
        h: 14,
        x: 6,
        y: 24,
        i: RESIZABLE_GRID.ITEM_KEYS.SELECTED_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 9,
        h: 50,
        x: 0,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.ALL_STREAMS,
        moved: false,
        static: false
      },
      {
        w: 12,
        h: 18,
        x: 0,
        y: 88,
        i: RESIZABLE_GRID.ITEM_KEYS.HEATMAP,
        moved: false,
        static: false
      },
      {
        w: 3,
        h: 50,
        x: 9,
        y: 38,
        i: RESIZABLE_GRID.ITEM_KEYS.METADATA,
        moved: false,
        static: false
      }
    ]
  } else if (!grouperEnabled() && !heatmapEnabled() && !mapEnabled()) {
    return emptyLayout
  } else {
    return defaultLayout
  }
}
