import React from 'react'

import 'antd/lib/notification/style/css'
import notification from 'antd/lib/notification'

import { redesignEnabled } from '../../../utils/featureFlags'

const ERROR_KEY = 'TimeoutError'

function isError ({ graphQLErrors }) {
  let timedOut = false
  try {
    const error = graphQLErrors[0]
    let message = error && error.message
    if (!message) return false
    if (typeof message === 'object') message = JSON.parse(error.message)

    message = (message.details || message).toLowerCase()
    const match = ['timed out', 'timeout'].find(msg => message.includes(msg))
    if (match) timedOut = true
  } catch (error) {}

  if (timedOut === true) return true
  else return false
}

function handleErrorDefault ({ operation }) {
  window.dispatchEvent(new CustomEvent(ERROR_KEY, { detail: operation }))
}

export class ErrorListener extends React.Component {
  handleEvent = e => {
    const operation = e.detail
    const { operationName = '' } = operation
    if (!redesignEnabled()) {
      // There's no guarantee that the alert component will be on the dom when this error is hit.
      // because of that, we don't render an error for redesigns. We have a card to follow up
      // to fix this: https://app.clubhouse.io/pingthings-ws/story/8537/handle-network-errors-via-something-that-s-not-apollo-client
      notification.warn({
        message: 'A request for data timed out',
        description: operationName
          ? `The '${operationName}' request timed out`
          : 'Try again and notify an administrator if you continue to experience issues.',
        duration: 10
      })
    }
  }

  componentDidMount () {
    window.addEventListener(ERROR_KEY, this.handleEvent)
  }

  componentWillUnmount () {
    window.removeEventListener(ERROR_KEY, this.handleEvent)
  }

  render () {
    return null
  }
}

export function getErrorPlugin (overrides = {}) {
  const { handleError = handleErrorDefault } = overrides
  return {
    name: ERROR_KEY,
    isError,
    handleError,
    ErrorListener
  }
}
