import { call, getContext, put, takeEvery } from 'redux-saga/effects'

import * as actionTypes from './actionTypes'
import * as api from './api'

export function * getGroups ({ id, prefix }) {
  const client = yield getContext('client')

  const { data, error } = yield call(api.getGroups, { client, prefix })

  if (!error) {
    yield put({ type: actionTypes.GET_COLLECTION_SUCCESS, data, id })
  } else {
    yield put({ type: actionTypes.GET_COLLECTION_ERROR, error })
  }
}

export const watchers = [takeEvery(actionTypes.GET_COLLECTION, getGroups)]
