import React from 'react'

import 'react-table-6/react-table.css'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { TableControlBarBorderless } from '../styled/styled'
import AxesTable from './AxesTable'
import ColumnSelector from '../StreamSearch/ColumnSelector'
import ExportControls from '../ExportControls/ExportControls'
import plotter from '../../ducks/plotter'
import StreamListTableContainer from './StreamListTableContainer'

export const SideSelector = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  width: 100%;
  box-sizing: border-box;
  color: #494d52;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.333%;
    height: 20px;
    border-radius: 3px;
    cursor: pointer;

    &.active {
      background-color: hsla(220, 25%, 40%, 0.15);
      cursor: default;
    }
  }
`

export const ChartControlTable = styled.div`
  min-width: 750px; /* 88% of 750  */
  max-width: 1440px; /* 88% of 1440 */
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
  @media screen and (min-width: 2560px) {
    max-width: 2560px;
  }
`

const LegendShowHideControl = styled.div`
  cursor: pointer;

  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.85;
  letter-spacing: normal;
  color: #494d52;

  label input {
    margin-left: 3px;
  }
`

export const LegendShowHide = () => {
  const dispatch = useDispatch()
  const legend = useSelector(plotter.selectors.legend)
  const toggleLegend = () => dispatch(plotter.actions.toggleLegend())

  return (
    <LegendShowHideControl>
      <label>
        Show Legend
        <input
          type='checkbox'
          checked={legend.visible}
          onChange={toggleLegend}
        />
      </label>
    </LegendShowHideControl>
  )
}

const ChartControls = () => {
  const selectedRows = useSelector(plotter.selectors.selectedRows)

  return (
    <>
      <ExportControls />
      <>
        <ChartControlTable>
          {!!window.localStorage.debugCache && (
            <div style={{ height: '25vw' }} />
          )}
          <TableControlBarBorderless>
            <div className='tableTitle'>Streams</div>
            <ColumnSelector selectedRows={selectedRows} />
          </TableControlBarBorderless>
          <StreamListTableContainer />
        </ChartControlTable>
        <ChartControlTable>
          <AxesTable />
        </ChartControlTable>
      </>
    </>
  )
}

export default ChartControls
