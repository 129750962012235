import React from 'react'

import 'antd/lib/input-number/style/css'
import 'antd/lib/popconfirm/style/css'
import 'antd/lib/radio/style/css'
import InputNumber from 'antd/lib/input-number'
import Popconfirm from 'antd/lib/popconfirm'
import Radio from 'antd/lib/radio'
import styled from 'styled-components'

const PopconfirmContent = styled.div`
  & div.title {
    margin-bottom: 10px;
  }

  & span {
    margin: 0 5px;
  }
`

const YScaleControlStyles = styled.div`
  .ant-radio-button-wrapper-checked {
    box-shadow: none;
  }

  .ant-radio-button-wrapper {
    color: #494d52;
    font-weight: 300;
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: hsla(220, 25%, 40%, 0.15);
    border-color: hsla(220, 25%, 40%, 0.15);
    color: #494d52;

    :hover {
      background: hsla(220, 25%, 40%, 0.15);
      border-color: hsla(220, 25%, 40%, 0.15);
      color: #494d52;
      font-weight: 300;
    }
  }

  .ant-radio-button-wrapper:hover {
    position: relative;
    color: hsla(220, 25%, 40%, 0.3);
    background: hsla(220, 25%, 40%, 0.5);
    border-color: hsla(220, 25%, 40%, 0.5);
    color: #fff;
  }
`

export const PopConfirmContentComponent = ({
  cancelHandler,
  confirmHandler,
  max,
  min,
  minInputRef,
  setMax,
  setMin,
  stepSize,
  verifyNumber
}) => {
  return (
    <PopconfirmContent key='xsetYScale'>
      <div className='title'>Set min max values for y axis</div>
      <div
        onKeyDown={e => {
          if (e.key === 'Enter') confirmHandler()
          if (e.key === 'Escape') cancelHandler()
        }}
      >
        <InputNumber
          key='axisMinInput'
          ref={minInputRef}
          step={stepSize}
          value={min}
          onChange={val => verifyNumber(setMin, val)}
        />
        <span>to</span>
        <InputNumber
          key='axisMaxInput'
          step={stepSize}
          value={max}
          onChange={val => verifyNumber(setMax, val)}
        />
      </div>
    </PopconfirmContent>
  )
}

export const YScaleControlComponent = ({
  cancelHandler,
  confirmHandler,
  isAuto,
  max,
  min,
  renderPopConfirm,
  setIsAuto,
  setShowPopconfirm,
  showPopconfirm
}) => {
  return (
    <YScaleControlStyles>
      <Popconfirm
        key='setYScale'
        visible={showPopconfirm}
        icon={null}
        cancelText='Autoscale'
        okText='Set Scale'
        okType={
          min === undefined || min === null || max === undefined || max === null
            ? 'disabled'
            : 'primary'
        }
        onConfirm={confirmHandler}
        onCancel={cancelHandler}
        title={renderPopConfirm()}
      >
        <Radio.Group
          size='small'
          buttonStyle='solid'
          value={isAuto}
          onChange={e => {
            const val = e.target.value
            setIsAuto(val)
          }}
        >
          <Radio.Button value>Autoscale</Radio.Button>
          <Radio.Button onClick={() => setShowPopconfirm(true)} value={false}>
            Manual
          </Radio.Button>
        </Radio.Group>
      </Popconfirm>
    </YScaleControlStyles>
  )
}
