import ReactGA from 'react-ga'
import { USERNAME_KEY } from '../constants'

function recordPageView (location) {
  const user = localStorage.getItem(USERNAME_KEY)
  ReactGA.set({ userId: user || 'public', page: location.pathname })
  ReactGA.pageview(location.pathname) // Record a pageview for the given page
}

export function initGoogleAnalytics ({ trackingId, history }) {
  if (trackingId === undefined) {
    console.log(
      'Google Analytics not running. Did not receive a trackingId when trying to initialize'
    )
    return
  }

  ReactGA.initialize(trackingId)
  recordPageView(window.location) // record initial pageview
  history.listen(recordPageView) // record all subsequent pageviews
}
