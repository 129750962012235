import React, { useEffect } from 'react'

import 'antd/lib/button/style/css'
import 'antd/lib/form/style/css'
import 'antd/lib/icon/style/css'
import 'antd/lib/input/style/css'
import 'antd/lib/notification/style/css'
import { Mutation } from 'react-apollo'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import gql from 'graphql-tag'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import lodashGet from 'lodash.get'
import notification from 'antd/lib/notification'
import styled from 'styled-components'

import auth from '../../ducks/auth'
import { isLoggedInSso } from '../../lib/sso'

const {
  actions: { login },
  selectors: {
    authenticateError: authenticateErrorSelector,
    isLoggedIn: isLoggedInSelector,
    loginLoading: loginLoadingSelector
  }
} = auth

const CenteredPage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #e8e8e8;
  position: relative;

  & > form {
    position: absolute;
    top: 150px;
    background: white;
    padding: 40px;
    border: 1px solid #ebedf0;
    border-radius: 4px;
    width: 460px;
    color: rgba(0, 0, 0, 0.65);

    & > :last-child {
      margin-bottom: 0;
    }
    .cardTitle {
      font-size: 35px;
      margin-bottom: 8px;
    }
    .cardText {
      font-size: 14px;
      color: hsl(210, 11%, 51%);
      margin-bottom: 16px;
    }
  }
`
const FormItem = Form.Item

function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

function handleSubmit (e, validateFields, dispatch) {
  e.preventDefault()

  validateFields((_error, values) => {
    const { password, username } = values
    dispatch(login({ password, username }))
  })
}

const LoginErrorMessage = ({ error }) => {
  if (error === 'invalid credentials') {
    return (
      <p data-test-name='login-error-message'>
        The username and password don't match our records. Please check them and
        try logging in again.
      </p>
    )
  } else if (error) {
    return (
      <>
        <p>An error occurred:</p>
        <pre>{error}</pre>
      </>
    )
  } else {
    return <p>An unknown error occurred:</p>
  }
}

const LoginFormBase = props => {
  const {
    form: {
      getFieldDecorator,
      getFieldError,
      getFieldsError,
      isFieldTouched,
      validateFields
    },
    layout
  } = props

  const dispatch = useDispatch()
  const authenticateError = useSelector(state =>
    authenticateErrorSelector(state)
  )
  const loading = useSelector(state => loginLoadingSelector(state))

  useEffect(() => {
    if (authenticateError) {
      // redesigns use /src/pages/Login.js, so redesigns won't get this notification
      notification.error({
        message: 'Login Error',
        description: <LoginErrorMessage error={authenticateError} />,
        duration: 0,
        style: { top: 74, right: -8 }
      })
    }
  }, [authenticateError])

  const userNameError = isFieldTouched('username') && getFieldError('username')
  const passwordError = isFieldTouched('password') && getFieldError('password')

  return (
    <Form
      layout={layout}
      onSubmit={e => handleSubmit(e, validateFields, dispatch)}
    >
      {layout !== 'inline' && (
        <>
          <div className='cardTitle'>Login</div>
          <div className='cardText'>Sign into your account</div>
        </>
      )}
      <FormItem
        validateStatus={userNameError ? 'error' : ''}
        help={userNameError || ''}
      >
        {getFieldDecorator('username', {
          rules: [{ required: true, message: 'Please input your username!' }]
        })(
          <Input
            name='username'
            placeholder='Username'
            prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        )}
      </FormItem>
      <FormItem
        validateStatus={passwordError ? 'error' : ''}
        help={passwordError || ''}
      >
        {getFieldDecorator('password', {
          rules: [{ required: true, message: 'Please input your Password!' }]
        })(
          <Input
            name='password'
            placeholder='Password'
            prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
            type='password'
          />
        )}
      </FormItem>
      <FormItem>
        <Button
          data-test-name='submit-log-in'
          disabled={hasErrors(getFieldsError())}
          htmlType='submit'
          loading={loading}
          type='primary'
        >
          Log in
        </Button>
      </FormItem>
    </Form>
  )
}

const WrappedLoginFormBase = Form.create()(LoginFormBase)

export const LoginPage = props => {
  const isLoggedIn =
    useSelector(state => isLoggedInSelector(state)) || isLoggedInSso()
  const { msg } = lodashGet(props, 'match.params')

  useEffect(() => {
    if (msg === 'expired') {
      notification.warn({
        message: 'Login Expired',
        description:
          'Your previous login may have expired. Please log in again',
        duration: 10
      })
    }
    if (msg === 'credentials') {
      notification.warn({
        message: 'Please login again',
        description:
          'The server encountered an issue verifying your credentials.',
        duration: 10
      })
    }
  }, [msg])

  useEffect(() => {
    if (isLoggedIn) {
      props.history.push('/')
    }
  }, [isLoggedIn, props.history])

  return (
    <CenteredPage>
      <Mutation mutation={LOGIN}>
        {() => (
          <>
            <WrappedLoginFormBase layout='vertical' />
          </>
        )}
      </Mutation>
    </CenteredPage>
  )
}

const InlineLoginStyles = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 580px;
  z-index: 1;

  & .ant-form-inline .ant-form-item {
    margin-right: 10px !important;
  }

  & .ant-form .ant-form-item-with-help {
    margin-bottom: 0px;
  }
`
const LinkButton = styled.button`
  border: none;
  background: none;
  color: hsl(220, 90%, 55%);
  text-decoration: underline;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
`
const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    Login(body: { username: $username, password: $password }) {
      username
      token
    }
  }
`
export class InlineLogin extends React.Component {
  render () {
    return (
      <InlineLoginStyles>
        <Mutation mutation={LOGIN}>
          {() => (
            <>
              <WrappedLoginFormBase layout='inline' />
              <LinkButton onClick={this.props.collapseLogin}>Cancel</LinkButton>
            </>
          )}
        </Mutation>
      </InlineLoginStyles>
    )
  }
}
