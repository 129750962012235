import React from 'react'
import PropTypes from 'prop-types'
import { GithubPicker } from 'react-color'
import styled, { withTheme } from 'styled-components'
import { CHART_COLORS } from './Palettes'

const ColorSquare = styled.div`
  width: 25px;
  height: 25px;
  background-color: ${props => props.color};
  display: inline-block;
  position: relative;

  & > div {
    position: absolute;
    top: -6px;
    left: 35px;
    z-index: 101;
    box-sizing: content-box;
    width: 150px !important;
  }
`

class ColorPicker extends React.Component {
  static propTypes = {
    plot: PropTypes.object,
    changeStreamColor: PropTypes.func.isRequired
  }

  state = {
    visible: false
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.toggleViz)
    document.removeEventListener('keydown', this.escFn)
  }

  toggleViz = () => {
    if (this.state.visible) {
      // clean up our event listeners when we hide the colorpicker
      document.removeEventListener('click', this.toggleViz)
      document.removeEventListener('keydown', this.escFn)
    } else {
      this.escFn = e => {
        e.key === 'Escape' && this.toggleViz()
      }
      // add event listeners to help close the color picker
      document.addEventListener('click', this.toggleViz)
      document.addEventListener('keydown', this.escFn)
    }
    this.setState({ visible: !this.state.visible })
  }

  render () {
    const { changeStreamColor, plot, theme } = this.props

    return (
      <>
        {plot && (
          <ColorSquare color={plot.color} onClick={this.toggleViz}>
            {this.state.visible && (
              <GithubPicker
                colors={CHART_COLORS({ theme })}
                triangle='hide'
                onChange={colorVals =>
                  changeStreamColor(plot.uuid, colorVals.hex)
                }
              />
            )}
          </ColorSquare>
        )}
      </>
    )
  }
}
export default withTheme(ColorPicker)
