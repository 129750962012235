import React from 'react'

import styled from '@xstyled/styled-components'

const Styled = styled.div`
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'system-ui';
  margin: 0 auto;
  margin-bottom: 64px;
  max-width: 758px;
  padding-top: 64px;

  @media (max-width: 758px) {
    max-width: 414px;
    padding-left: 24px;
    padding-right: 24px;
  }
`

const PageWrap = ({ children }) => <Styled>{children}</Styled>

export default PageWrap
