// Use in a Web Worker if aggregating ~1000 points takes more than 10ms
// (based on an estimate for the width of the screen and desired framerate)

export function aggregatePair (left, right, res) {
  left = left || {
    count: 0,
    mean: 0, // any number (ignored)
    min: Infinity,
    max: -Infinity,
    i: right.i - 1,
    entryI: right.entryI - 1,
    time: right.time - 2 ** res
  }
  right = right || {
    count: 0,
    mean: 0, // any number (ignored)
    min: Infinity,
    max: -Infinity
    // i: left.i + 1, // don't need
    // entryI: left.entryI + 1, // don't need
    // time: left.time + 2 ** res, // don't need
  }
  const count = left.count + right.count
  const time = left.time
  const i = left.i / 2
  const entryI = left.entryI / 2
  const min = Math.min(left.min, right.min)
  const max = Math.max(left.max, right.max)
  const mean = (left.mean * left.count + right.mean * right.count) / count
  return { count, time, i, entryI, min, max, mean }
}

export function getPairs (points) {
  const result = []
  for (let i = 0; i < points.length; i++) {
    const curr = points[i]
    const next = points[i + 1]
    const isCurrLeft = curr.i % 2 === 0
    const isNextAdj = next && curr.i + 1 === next.i

    let left, right
    if (isCurrLeft && isNextAdj) {
      left = curr
      right = next
      i++ // skip next
    } else if (isCurrLeft) {
      left = curr
      right = null
    } else {
      left = null
      right = curr
    }
    result.push([left, right])
  }
  return result
}

export function genAggregates (points, inRes, maxRes) {
  const aggs = {}
  for (let res = inRes + 1; res <= maxRes; res++) {
    const pairs = getPairs(points)
    points = pairs.map(([a, b]) => aggregatePair(a, b, res - 1))
    aggs[res] = points
  }
  return aggs
}
