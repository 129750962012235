import React from 'react'

import { ServerSideFilter, Table } from 'frontend-components'

export const TableFilter = props => {
  return <ServerSideFilter {...props} />
}

const StreamTable = ({
  columns,
  currentPage,
  data,
  handleRowMouseEnter,
  handleTableMouseLeave,
  isLoading,
  numPages,
  onClick,
  pageChangeHandler,
  pageSize,
  sorted,
  sortedChangeHandler
}) => {
  const manualSortOptions = {
    onSortedChange: sortedChangeHandler,
    order: sorted[1],
    terms: sorted[0]
  }

  const genPageOptions = () => Array.from(Array(numPages).keys())

  return (
    <Table
      columns={columns}
      data={data}
      dataTestName='allstreams-table'
      handleRowClick={onClick}
      handleRowMouseEnter={data => handleRowMouseEnter(data)}
      handleTableMouseLeave={data => handleTableMouseLeave(data)}
      loading={isLoading}
      manualPaginationOptions={{
        currentPage,
        pageOptions: genPageOptions(),
        pageSize: pageSize,
        setCurrentPage: pageChangeHandler
      }}
      manualSortOptions={manualSortOptions}
      tableOptions={{
        manualFilters: true,
        manualPagination: true,
        pageCount: numPages
      }}
    />
  )
}

export default StreamTable
