import React from 'react'

import { Button, Modal, NumberInput, Radio } from 'frontend-components'
import styled, { th } from '@xstyled/styled-components'

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 8px;
  width: 100%;
`

const ModalHeader = styled.p`
  ${th('typography.body1')};

  margin-bottom: 8px;
`

const RightButton = styled(Button)`
  margin-left: 8px;
`

const StyledModal = styled(Modal)`
  padding: 16px;
  position: absolute;
  z-index: 999;
`

const SupportText = styled.span`
  ${th('typography.body1')};

  padding: 0 8px;
`

export const PopConfirm = ({
  cancelHandler,
  confirmHandler,
  max,
  min,
  minInputRef,
  setIsAuto,
  setMax,
  setMin,
  stepSize,
  verifyNumber
}) => {
  return (
    <StyledModal variants={['skeleton', 'inline-block']}>
      <div
        onKeyDown={e => {
          if (e.key === 'Enter') confirmHandler()
          if (e.key === 'Escape') cancelHandler()
        }}
      >
        <ModalHeader>Set min max values for y axis</ModalHeader>
        <NumberInput
          key='axisMinInput'
          ref={minInputRef}
          step={stepSize}
          value={min}
          onChange={e => verifyNumber(setMin, e.target.value)}
        />
        <SupportText>to</SupportText>
        <NumberInput
          key='axisMaxInput'
          step={stepSize}
          value={max}
          onChange={e => verifyNumber(setMax, e.target.value)}
        />
      </div>
      <ButtonsWrapper>
        <Button
          label='Autoscale'
          onClick={cancelHandler}
          size='sm'
          type='button'
          variant='secondary'
        />
        <RightButton
          label='Set scale'
          onClick={confirmHandler}
          size='sm'
          type='button'
          variant='primary'
        />
      </ButtonsWrapper>
    </StyledModal>
  )
}

export const YScaleControl = ({
  renderPopConfirm,
  scale,
  setIsAuto,
  setShowPopconfirm,
  showPopconfirm
}) => {
  const manual = 'Manual'
  const autoscale = 'Autoscale'

  const handleChange = e => {
    if (e === manual) {
      setShowPopconfirm(true)
    } else if (e === autoscale) {
      setIsAuto(true)
    }
  }

  const handleClick = e => {
    const { value } = e.target
    if (value === manual) {
      setShowPopconfirm(true)
    } else {
      setShowPopconfirm(false)
    }
  }

  const isAuto = typeof scale === 'undefined'

  return (
    <>
      <Radio layout='vertical' onChange={handleChange}>
        {[
          {
            title: autoscale,
            value: autoscale,
            checked: isAuto,
            onClick: handleClick
          },
          {
            title: manual,
            value: manual,
            checked: !isAuto,
            onClick: handleClick
          }
        ]}
      </Radio>
      {showPopconfirm && renderPopConfirm()}
    </>
  )
}
