import * as d3array from 'd3-array'
import LRU from 'lru-cache'
import { RBTree } from 'bintrees'

import { splitArray } from '../utils/array'

import {
  maxRes,
  minRes,
  getScaleResolution,
  timeToIndex,
  clampRes,
  roundTimeRange,
  convertIndexRange,
  indexToTime,
  rangeIntersect,
  cropRange,
  isRangeEmpty,
  indexBounds,
  clampIndexRange,
  clampTimeRange,
  timeRangeToIndex,
  visibleTimeRange,
  indexRangeToTime
} from './btrdbMath'
import { genAggregates } from './btrdbAggregate'

// ----------------------------------------------------------------------
// Settings
// ----------------------------------------------------------------------

// MEMORY LIMIT OF CACHE
const MEGABYTES = 1e6 // 1000000 bytes
const MAX_CACHE_BYTES = 300 * MEGABYTES // <-- TODO: make configurable

// Calculate the size of a single BTrDB point.
const NUMBERS_PER_POINT = 7 // (i, entryI, time, mean, min, max, count)
const BYTES_PER_NUMBER = 8 // numbers are 64-bit floats
const BYTES_PER_POINT = NUMBERS_PER_POINT * BYTES_PER_NUMBER

// Calculate max number of points that cache can hold.
const MAX_CACHE_POINTS = MAX_CACHE_BYTES / BYTES_PER_POINT // max # of points to cache

// All entries are aggregated this many times for use at lower resolutions:
const AGGREGATION_LEVELS = 6

// When filling gaps, use lower-res entries this many levels far from the current res.
const BLUR_LEVELS = 56

// Enable this if you want to trace which entries formed the query results.
let TRACE_ENTRIES = true
export function enableEntryTracing (on) {
  TRACE_ENTRIES = on
}

const STALE_FALLBACK = true

const RESOLUTIONS = d3array.range(minRes, maxRes + 1)

// ----------------------------------------------------------------------
// State
// ----------------------------------------------------------------------

const lru = makeLru(MAX_CACHE_POINTS)
export const streamCaches = {} // stream ID to stream cache
window.streamCaches = streamCaches

// ----------------------------------------------------------------------
// Main API
// ----------------------------------------------------------------------

export function fetchStreamCache (streamId, scale, callbacks) {
  const meta = metaStreamCache(streamId, scale)
  const { caches, res, fetchRange, fetchAggRes } = meta
  if (isRangeEmpty(fetchRange)) return Promise.resolve(null)
  return fetch(caches[res], fetchRange, fetchAggRes, callbacks)
}

export function prefetchStreamCache (streamId, scale, callbacks) {
  const meta = metaStreamCache(streamId, scale)
  const { caches, res, fetchRange, fetchAggRes } = meta
  if (isRangeEmpty(fetchRange)) return Promise.resolve(null)
  return prefetch(caches, res, fetchRange, fetchAggRes, callbacks)
}

export function queryStreamCache (streamId, scale) {
  const meta = metaStreamCache(streamId, scale)
  const { caches, res, queryRange, queryMinRes, queryMaxRes } = meta
  const { tiles, gaps } = isRangeEmpty(queryRange)
    ? { tiles: [], gaps: [] }
    : queryTiles(caches, res, queryRange, queryMinRes, queryMaxRes)

  if (STALE_FALLBACK) {
    for (const gapRange of gaps) {
      tiles.push(
        // multiResQueries on stale cache sometimes leads to bugs where we fill
        // in stale data that is out of sync with fresh data. However, we
        // continue to query same res stale data to prevent chart blinking when
        // live data is being updated.
        // ...queryStaleTiles(caches, res, gapRange, queryMinRes, queryMaxRes)
        ...queryStaleTiles(caches, res, gapRange, res, res)
      )
    }
  }

  // For convenience, add timestamp range to each tile
  // (Since all the cache functions deal with indexes instead of timestamps,
  //  we convert them here for the public API convenience only)
  for (const tile of tiles) {
    tile.timeRange = indexRangeToTime(tile.range, res)
  }

  return tiles
}

export function invalidateStreamCache (streamId, timeRange) {
  const caches = ensureStreamCaches(streamId)
  for (const res of RESOLUTIONS) {
    const range = timeRangeToIndex(timeRange, res)
    invalidateCache(caches[res], range)
  }
}

export function metaStreamCache (streamId, scale) {
  const caches = ensureStreamCaches(streamId)
  const res = clampRes(getScaleResolution(scale))
  const queryTimeRange = clampTimeRange(visibleTimeRange(scale.domain(), res))
  const queryMinRes = clampRes(res - AGGREGATION_LEVELS)
  const queryMaxRes = clampRes(res + BLUR_LEVELS)
  const fetchAggRes = clampRes(res + AGGREGATION_LEVELS)
  const fetchTimeRange = roundTimeRange(queryTimeRange, fetchAggRes)
  const queryRange = timeRangeToIndex(queryTimeRange, res)
  const fetchRange = timeRangeToIndex(fetchTimeRange, res)
  return {
    caches,
    res,
    queryTimeRange,
    queryMinRes,
    queryMaxRes,
    fetchAggRes,
    fetchTimeRange,
    queryRange,
    fetchRange
  }
}

// ----------------------------------------------------------------------
// Handling stale cache entries
// (kept around for querying to use as fallback until new data is fetched)
// (invariant: state entries are always disjoint to existing entries)
// (this invariant can be relaxed at the cost of extra complexity here)
// ----------------------------------------------------------------------

export function addStaleCaches (caches) {
  caches.stales = {}
  for (const res of RESOLUTIONS) {
    const { range, lru, streamId } = caches[res]
    const stale = makeResCache(range, res, lru, streamId)
    caches.stales[res] = caches[res].stale = stale
  }
}

export function queryStaleTiles (caches, res, range, minRes, maxRes) {
  if (!caches.stales) return []
  const { tiles } = queryTiles(caches.stales, res, range, minRes, maxRes)

  // NOTE: marking tiles as "stale" will let us render some cue that they're being refreshed
  for (const tile of tiles) tile.isStale = true

  return tiles
}

export function invalidateCache (cache, range) {
  const removedEntries = clearCache(cache, range)

  const { stale } = cache
  if (!stale) return
  for (const removed of removedEntries) {
    // There should be no existing stale entries for this range, but if there
    // are due to race conditions, collapse them all into a single gap entry.
    const existingStaleEntries = intersectCache(stale, removed.range)
    let gap
    for (const entry of existingStaleEntries) {
      gap = collapseCache(stale, entry)
    }
    trimGapCache(stale, gap, removed.range)
    insertCache(stale, removed)
  }
}

export function clearStaleCache (cache, range) {
  if (cache.stale) {
    return clearCache(cache.stale, range)
  }
}

// ----------------------------------------------------------------------
// Fetching points into the cache
// ----------------------------------------------------------------------

export function fetch (cache, range, aggRes, callbacks) {
  if (!cache) return
  const { fetchPoints, onProgress } = callbacks

  // validate range
  const { res } = cache
  range = clampIndexRange(range, res)
  if (isRangeEmpty(range)) return

  // Resolve entries
  const resolved = []
  const fetching = []
  for (let entry of intersectCache(cache, range)) {
    const gap = entry.isGap ? entry : null
    if (gap) {
      entry = spliceInNewEntry(cache, range, gap)
      const fetchTimeRange = entry.range.map(i => indexToTime(i, res))
      entry.loaded = (async () => {
        const { streamId } = cache
        const [startTime, endTime] = fetchTimeRange
        const points = await fetchPoints(
          streamId,
          startTime,
          endTime + 2 ** res, // ensure we don't underfetch
          res
        )
        const normPoints = normalizePoints({ points, res, range: entry.range })
        entry.points = normPoints
        entry.aggregates = genAggregates(normPoints, res, aggRes)
        clearStaleCache(cache, entry.range)
        if (onProgress) onProgress()
      })()
      fetching.push(entry)
    }
    resolved.push(entry)
  }

  // Update the LRU
  const { lru } = cache
  if (lru) lruUpdate(lru, resolved)

  // Notify completion
  const promises = fetching.map(entry => entry.loaded)
  return Promise.all(promises)
}

export function prefetch (caches, res, range, aggRes, callbacks) {
  const promises = []

  // prefetch for panning
  const [a, b] = range
  const w = b - a
  promises.push(fetch(caches[res], [a - w, a], aggRes, callbacks)) // left
  promises.push(fetch(caches[res], [b, b + w], aggRes, callbacks)) // right

  // prefetch for zooming in
  const zoomRes = clampRes(res - 2)
  if (zoomRes !== res) {
    const zoomRange = convertIndexRange(range, res, zoomRes)
    promises.push(fetch(caches[zoomRes], zoomRange, aggRes, callbacks))
  }

  // (ensure main range entries are ahead in the LRU)
  fetch(caches[res], range, aggRes, callbacks)

  return Promise.all(promises)
}

// ----------------------------------------------------------------------
// Querying Drawable Tiles
// ----------------------------------------------------------------------

export function queryTiles (caches, res, range, minRes = res, maxRes = res) {
  const allChunks = queryMultiResChunks(caches, res, range, minRes, maxRes)

  // group consecutive chunks with same resolution
  const resChunks = splitArray(
    allChunks.filter(chunk => chunk.pointRes),
    (a, b) => a.pointRes !== b.pointRes
  )

  // turn each group of same-res chunks into a "tile"
  const tiles = resChunks
    .map(chunks => makeTile(caches, chunks, minRes))
    .filter(e => e) // filter out empty tiles

  const gaps = allChunks.filter(c => !c.points).map(c => c.range)
  return { tiles, gaps }
}

export function querySinglePoint (caches, res, i, minRes) {
  const chunks = queryMultiResChunks(caches, res, [i, i + 1], minRes)
  const points = chunksToPoints(chunks)
  return points[0]
}

export function makeTile (caches, chunks, minQueryRes) {
  const { pointRes } = chunks[0]
  const points = chunksToPoints(chunks)
  if (points.length === 0) return

  // get points before and after in case we need them for drawing
  const beforeI = points[0].i - 1
  const afterI = points[points.length - 1].i + 1
  const pointBefore = querySinglePoint(caches, pointRes, beforeI, minQueryRes)
  const pointAfter = querySinglePoint(caches, pointRes, afterI, minQueryRes)

  // add adjacent points to "allPoints"
  let allPoints
  if (pointBefore) allPoints = [pointBefore, ...points]
  else allPoints = [...points]
  if (pointAfter) allPoints.push(pointAfter)

  // get full tile range
  const startI = chunks[0].range[0]
  const endI = chunks[chunks.length - 1].range[1]
  const range = [startI, endI]

  const result = { range, pointRes, points, allPoints }
  if (TRACE_ENTRIES) result.chunks = chunks
  return result
}

export function chunksToPoints (chunks) {
  const result = []
  for (const { points, pointSlice } of chunks) {
    if (points) {
      for (const point of points.slice(...pointSlice)) {
        result.push(point)
      }
    }
  }
  return result
}

// ----------------------------------------------------------------------
// Querying Cache Chunks
// (multi-res querying via projection)
// ----------------------------------------------------------------------

// Query functions return chunks. A chunk has:
//
//   range:       index range at the requested resolution
//   points:      source of the points that will fill this range
//   pointSlice:  the subset [start,end) of the points that fill this range
//   pointRes:    the resolution of the points that fill this range

export function makeChunk ({ range, points, pointSlice, pointRes, entry }) {
  const result = { range, points, pointSlice, pointRes }
  if (TRACE_ENTRIES) result.entry = entry
  return result
}
export function emptyChunk (range) {
  return { range }
}

export function queryExactResChunks (cache, range) {
  const chunks = []
  const entries = queryCache(cache, range)
  for (const a of entries) {
    const outFillRange = rangeIntersect(a.range, range)
    const { points } = a
    if (points) {
      const pointSlice = selectPoints(points, outFillRange)
      const range = outFillRange
      const pointRes = cache.res
      const entry = a
      chunks.push(makeChunk({ range, points, pointSlice, pointRes, entry }))
    } else {
      chunks.push(emptyChunk(outFillRange))
    }
  }
  return chunks
}

export function queryMultiResChunks (
  caches,
  res,
  range,
  minRes = res,
  maxRes = res
) {
  const chunks = []
  for (const a of queryExactResChunks(caches[res], range)) {
    if (a.points) {
      chunks.push(a)
    } else {
      for (const b of queryHigherResChunks(caches, res, a.range, minRes)) {
        if (b.points) {
          chunks.push(b)
        } else {
          for (const chunk of queryLowerResChunks(
            caches,
            res,
            b.range,
            maxRes
          )) {
            chunks.push(chunk)
          }
        }
      }
    }
  }
  return chunks
}

export function queryHigherResChunks (
  caches,
  outRes,
  outRange,
  minRes,
  inRes = outRes - 1
) {
  const chunks = []
  if (inRes < minRes) {
    return [emptyChunk(outRange)]
  }
  const inRange = convertIndexRange(outRange, outRes, inRes)
  const entries = queryCache(caches[inRes], inRange)
  for (const entry of entries) {
    const inFillRange = rangeIntersect(entry.range, inRange)
    const outFillRange = convertIndexRange(inFillRange, inRes, outRes)
    const points = entry.aggregates[outRes]
    if (points) {
      const pointSlice = selectPoints(points, outFillRange)
      const range = outFillRange
      const pointRes = outRes
      const chunk = makeChunk({ range, points, pointSlice, pointRes, entry })
      chunks.push(chunk)
    } else {
      for (const chunk of queryHigherResChunks(
        caches,
        outRes,
        outFillRange,
        minRes,
        inRes - 1
      )) {
        chunks.push(chunk)
      }
    }
  }
  return chunks
}

export function queryLowerResChunks (
  caches,
  outRes,
  outRange,
  maxRes,
  inRes = outRes + 1
) {
  const chunks = []
  if (inRes > maxRes) {
    return [emptyChunk(outRange)]
  }
  const inRange = convertIndexRange(outRange, outRes, inRes)
  const entries = queryCache(caches[inRes], inRange)
  for (const entry of entries) {
    const inFillRange = rangeIntersect(entry.range, inRange)
    const outFillRange = cropRange(
      convertIndexRange(inFillRange, inRes, outRes),
      outRange
    )
    const { points } = entry
    if (points) {
      const pointSlice = selectPoints(points, inFillRange)
      const range = outFillRange
      const pointRes = inRes
      const chunk = makeChunk({ range, points, pointSlice, pointRes, entry })
      chunks.push(chunk)
    } else {
      for (const chunk of queryLowerResChunks(
        caches,
        outRes,
        outFillRange,
        maxRes,
        inRes + 1
      )) {
        chunks.push(chunk)
      }
    }
  }
  return chunks
}

// ----------------------------------------------------------------------
// Points
// ----------------------------------------------------------------------

// The `fetchPoints` function passed to our cache functions are assumed
// to operate based on BTrDB's `alignedWindows` API.
// `points` is an array of objects representing non-empty points (i.e. count !== 0)
//
// [
//   {
//      time: "...",
//      min: "...",
//      max: "...",
//      mean: "...",
//      count: "...",
//   }
// ]
function normalizePoint ({ time, min, max, mean, count }, res, range) {
  // Convert these strings to numbers
  const timeNum = Number(time) // NOTE: Loses a few digits of precision
  min = Number(min)
  max = Number(max)
  mean = Number(mean)
  count = Number(count)

  // Compute stat point indexes
  const i = timeToIndex(time, res) // stat point index
  const entryI = i - range[0] // relative index for this entry
  return { i, entryI, time: timeNum, timeStr: time, min, max, mean, count }
}

function normalizePoints ({ range, res, points }) {
  const normalizedPoints = []
  for (const p of points) {
    const result = normalizePoint(p, res, range)
    // Because the points returned by AlignedWindows may not always perfectly line up
    // with the cache entry we're trying to construct, filter for overfetching on both ends
    if (result.entryI < 0) {
      // filter out an overfetched point before start range
      continue
    }
    if (result.i >= range[1]) {
      // filter out an overfetched point after end range
      continue
    }
    normalizedPoints.push(result)
  }

  return normalizedPoints
}

const pointBisector = d3array.bisector(point => point.i)

export function selectPoints (points, range) {
  const a = pointBisector.left(points, range[0])
  const b = pointBisector.left(points, range[1])
  return [a, b]
}

// ----------------------------------------------------------------------
// Constructors
// ----------------------------------------------------------------------

export function makeLru (size) {
  return new LRU({
    max: size,
    length: lruCost,
    dispose: onLruEvict
  })
}

function ensureStreamCaches (streamId) {
  const caches = streamCaches[streamId] || makeStreamCaches(streamId)
  streamCaches[streamId] = caches
  return caches
}

export function makeStreamCaches (streamId) {
  const caches = {}
  for (const res of RESOLUTIONS) {
    const range = indexBounds[res]
    caches[res] = makeResCache(range, res, lru, streamId)
  }
  addStaleCaches(caches)
  return caches
}

export function makeResCache (range, res, lru, streamId) {
  const cache = {
    res, // resolution of this cache
    range,
    entries: {}, // map start time to entry object
    indexes: new RBTree((a, b) => a - b), // sorted start indexes of entries
    lru, // reference to the LRU that evicts our entries
    streamId // stream's UUID
  }
  if (range) insertCache(cache, makeGapEntry(cache, range))
  return cache
}

export function makeCacheEntry (cache, indexRange) {
  const [a, b] = indexRange
  return {
    cache, // keep reference to the cache container
    res: cache.res,

    i: a, // index used for lookup in cache.indexes
    length: b - a, // size of this entry
    range: [a, b], // start index (inclusive) and end index (exclusive)

    isGap: false, // indicates if this entry indicates the _absence_ of an entry to be filled

    // The following are defined if and only if `isGap` is false.

    // populated when `loaded` has completed
    points: null,
    aggregates: {},

    loaded: null // promise for fetched points
  }
}

export function makeGapEntry (cache, indexRange) {
  const entry = makeCacheEntry(cache, indexRange)
  entry.isGap = true
  return entry
}

// ----------------------------------------------------------------------
// LRU functions
// ----------------------------------------------------------------------

export function lruCost (entry) {
  return entry.length
}

function onLruEvict (entry) {
  if (entry.isRemoved) return // removed entries have already been cleaned up in removeCache()
  console.assert(!entry.isRemoved)
  collapseCache(entry.cache, entry)
}

export function lruUpdate (lru, entries) {
  // Run "get" on all pre-existing entries first to prevent their removal
  for (const entry of entries) {
    lru.get(entry)
  }
  // Add new entries to the LRU
  for (const entry of entries) {
    if (!lru.has(entry)) lru.set(entry, entry)
  }
}

// ----------------------------------------------------------------------
// Cache functions
// ----------------------------------------------------------------------

export function insertCache (cache, entry) {
  const { i } = entry
  cache.entries[i] = entry
  cache.indexes.insert(i)
}

export function removeCache (cache, entry) {
  const { i } = entry
  delete cache.entries[i]
  cache.indexes.remove(i)

  // immediately evict removed entries instead of waiting for space constraints
  entry.isRemoved = true
  lru.del(entry)
}

export function intersectCache (cache, range) {
  const result = []
  const it = cache.indexes.upperBound(range[0])
  let t = it.prev()
  if (t === null) {
    t = it.next()
  }
  while (t !== null) {
    const entry = cache.entries[t]
    if (!rangeIntersect(entry.range, range)) break
    result.push(entry)
    t = it.next()
  }
  return result
}

// like intersectCache, except it treats pending entries without points as gaps
// TODO: refactor to use splitArray
export function queryCache (cache, range) {
  const result = []
  let gap = null
  for (const e of intersectCache(cache, range)) {
    if (e.points) {
      if (gap) {
        result.push(gap)
        gap = null
      }
      result.push(e)
    } else {
      const gapRange = gap ? [gap.range[0], e.range[1]] : e.range
      gap = makeGapEntry(cache, gapRange)
    }
  }
  if (gap) result.push(gap)
  return result
}

export function clearCache (cache, range) {
  const all = intersectCache(cache, range)
  const entries = all.filter(e => !e.isGap)
  for (const e of entries) collapseCache(cache, e)
  return entries
}

export function adjacentCache (cache, entry) {
  const { i } = entry
  const it = cache.indexes.findIter(i)
  if (!it) return [null, null]
  const left = it.prev()
  const right = (it.next(), it.next())
  return [cache.entries[left], cache.entries[right]]
}

export function gapFillRange (gap, window) {
  const fillW = window[1] - window[0]
  let [t0, t1] = rangeIntersect(gap, window)
  if (t1 - t0 < fillW) {
    if (gap[0] < window[0]) t0 = Math.max(gap[0], gap[1] - fillW)
    if (gap[1] > window[1]) t1 = Math.min(gap[1], gap[0] + fillW)
  }
  return [t0, t1]
}

export function trimGapCache (cache, gap, trimRange) {
  removeCache(cache, gap)
  const [a, d] = gap.range
  const [b, c] = trimRange
  if (a < b) insertCache(cache, makeGapEntry(cache, [a, b])) // left gap
  if (c < d) insertCache(cache, makeGapEntry(cache, [c, d])) // right gap
}

export function spliceInNewEntry (cache, range, gap) {
  if (!gap) gap = intersectCache(cache, range)[0]

  // Trim the gap
  const indexRange = gapFillRange(gap.range, range)
  trimGapCache(cache, gap, indexRange)

  // Create and insert new entry
  const entry = makeCacheEntry(cache, indexRange)
  insertCache(cache, entry)
  return entry
}

export function collapseCache (cache, entry) {
  let [t0, t1] = entry.range
  const [l, r] = adjacentCache(cache, entry)
  if (l && l.isGap) { removeCache(cache, l); t0 = l.range[0] } // prettier-ignore
  if (r && r.isGap) { removeCache(cache, r); t1 = r.range[1] } // prettier-ignore
  removeCache(cache, entry)
  const gap = makeGapEntry(cache, [t0, t1])
  insertCache(cache, gap)
  return gap
}
