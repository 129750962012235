import * as actionTypes from './actionTypes'

export const create = ({ state }) => {
  return {
    type: actionTypes.CREATE,
    state
  }
}

export const fetch = ({ id }) => {
  return {
    type: actionTypes.FETCH,
    id
  }
}
