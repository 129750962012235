import React from 'react'

import { Icon, literalThemeValue } from 'frontend-components'
import styled, { useTheme } from '@xstyled/styled-components'

import { redesignEnabled } from '../../utils/featureFlags'
import LoadingImage from './Ripple-1s-128px.svg'

const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

const theme = {
  dark: {
    incompleteWrapper: {
      iconColor: 'cyan2'
    }
  },
  light: {
    incompleteWrapper: {
      iconColor: 'blue2'
    }
  }
}

const Loader = props => {
  const themeProps = useTheme()

  if (redesignEnabled()) {
    return (
      <LoadingContainer {...props}>
        <Icon
          name='loading'
          color={literalThemeValue(theme, `incompleteWrapper.iconColor`, {
            theme: themeProps
          })}
          size={96}
        />
      </LoadingContainer>
    )
  } else {
    return <img src={LoadingImage} alt='Loading...' {...props} />
  }
}

export default Loader
