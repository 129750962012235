import gql from 'graphql-tag'

const GET_METADATA_USAGE = gql`
  query GetMetadataUsage($prefix: String!) {
    GetMetadataUsage(body: { prefix: $prefix }) {
      tags {
        key
        count
      }
      annotations {
        key
        count
      }
    }
  }
`

export async function getMetadataUsage ({ client, prefix }) {
  try {
    const data = await client.query({
      query: GET_METADATA_USAGE,
      variables: { prefix }
    })

    return { data: data.data.GetMetadataUsage }
  } catch (e) {
    console.error({ error: e.message })
    return { error: e.message }
  }
}
