import { redesignEnabled } from '../../utils/featureFlags'

export const CHART_COLORS = ({ theme }) => {
  if (redesignEnabled()) {
    const { name: themeName } = theme
    if (themeName === 'light') {
      return [
        theme.colors.blue2,
        theme.colors.red2,
        theme.colors.green2,
        theme.colors.yellow2,
        theme.colors.blue3,
        theme.colors.red3,
        theme.colors.green3,
        theme.colors.yellow3
      ]
    } else {
      return [
        theme.colors.cyan2,
        theme.colors.magenta2,
        theme.colors.mint2,
        theme.colors.orange2,
        theme.colors.cyan1,
        theme.colors.magenta1,
        theme.colors.mint1,
        theme.colors.orange1,
        theme.colors.cyan3,
        theme.colors.magenta3,
        theme.colors.mint3,
        theme.colors.orange3
      ]
    }
  } else {
    return ['#e41a1c', '#377eb8', '#05b100', '#984ea3', '#e27100', '#635716']
  }
}
