import React from 'react'

import { bindTheme } from 'frontend-components'
import styled, { th } from '@xstyled/styled-components'

import { portalTheme } from './theme'

const colors = bindTheme(portalTheme)

const HeaderContainer = styled.div`
  margin-bottom: 16px;

  h1 {
    ${th('typography.display4')};

    color: ${colors('title.h1.color')};
  }
`

const Subtitle = styled.h2`
  ${th('typography.body3')};

  color: ${colors('title.h1.color')};
`

const TitleContainer = styled.div`
  margin-bottom: 64px;
`

const Title = () => (
  <TitleContainer>
    <HeaderContainer>
      <h1>PingThings portal</h1>
    </HeaderContainer>
    <Subtitle>All your PingThings products in one place.</Subtitle>
  </TitleContainer>
)

export default Title
