import {
  call,
  delay,
  fork,
  getContext,
  put,
  race,
  take,
  takeLatest
} from 'redux-saga/effects'

import * as actionTypes from './actionTypes'
import * as authApi from './api'
import * as authActions from './actions'
import { clearStateFromLocalStorage } from '../../services/localStorage'
import { clearTokenAndUser } from '../../services/authStorage'
import { redesignEnabled } from '../../utils/featureFlags'
import { TOKEN_KEY, USERNAME_KEY } from '../../constants'
import modals from '../modals'

function * authenticate ({ maintainState, password, username }) {
  const client = yield getContext('client')

  const { data, error } = yield call(authApi.login, {
    client,
    password,
    username
  })

  if (error) {
    yield put({ type: actionTypes.AUTHENTICATE_ERROR, error, maintainState })
  } else {
    // see https://stackoverflow.com/a/57342625
    yield call(
      [window.localStorage, window.localStorage.setItem],
      TOKEN_KEY,
      data.token
    )
    yield call(
      [window.localStorage, window.localStorage.setItem],
      USERNAME_KEY,
      username
    )
    yield put({ type: actionTypes.AUTHENTICATE_SUCCESS, data })
  }
}

function * authorize ({ token, username }) {
  yield put({ type: actionTypes.GET_GROUPS })
  const { getGroupsSuccess, getGroupsError } = yield race({
    getGroupsSuccess: take(actionTypes.GET_GROUPS_SUCCESS),
    getGroupsError: take(actionTypes.GET_GROUPS_ERROR)
  })

  if (getGroupsError) {
    yield call(clearTokenAndUser)
    yield put({ type: actionTypes.AUTHORIZE_ERROR, error: true })
  } else {
    const {
      data: { groups }
    } = getGroupsSuccess
    yield put({ type: actionTypes.AUTHORIZE_SUCCESS, groups, token, username })
  }
}

function * changePassword ({ currPassword, newPassword, username }) {
  yield fork(authenticate, {
    maintainState: true,
    password: currPassword,
    username
  })
  const { authenticateError } = yield race({
    authenticateSuccess: take(actionTypes.AUTHENTICATE_SUCCESS),
    authenticateError: take(actionTypes.AUTHENTICATE_ERROR)
  })

  if (authenticateError) {
    yield put(
      modals.actions.openAlert({
        kind: 'error',
        message:
          'There was an error. Please try again or contact support@pingthings.io'
      })
    )
    yield put({ type: actionTypes.CHANGE_PASSWORD_ERROR })
    return
  }

  const client = yield getContext('client')
  const { error } = yield call(authApi.changePassword, {
    client,
    username,
    newPassword
  })

  if (error) {
    yield put(
      modals.actions.openAlert({
        kind: 'error',
        message:
          'There was an error. Please try again or contact support@pingthings.io'
      })
    )
    yield put({ type: actionTypes.CHANGE_PASSWORD_ERROR, error })
    return
  }

  yield fork(authenticate, {
    maintainState: true,
    password: newPassword,
    username
  })
  const { authenticateError: reauthenticateError } = yield race({
    authenticateSuccess: take(actionTypes.AUTHENTICATE_SUCCESS),
    authenticateError: take(actionTypes.AUTHENTICATE_ERROR)
  })

  if (reauthenticateError) {
    yield put(
      modals.actions.openAlert({
        kind: 'error',
        message:
          'There was an error. Please try again or contact support@pingthings.io'
      })
    )

    yield delay(2000)
    yield put({ type: actionTypes.CHANGE_PASSWORD_ERROR, error })
    yield put(authActions.logout())
    return
  }

  yield put(
    modals.actions.openAlert({
      kind: 'success',
      message: 'Password updated!'
    })
  )
  yield put({ type: actionTypes.CHANGE_PASSWORD_SUCCESS })
}

function * getGroups () {
  const client = yield getContext('client')

  const { data, error } = yield call(authApi.getGroups, { client })

  if (error) {
    yield put({ type: actionTypes.GET_GROUPS_ERROR, error })
  } else {
    yield put({ type: actionTypes.GET_GROUPS_SUCCESS, data })
  }
}

function * login ({ password, username }) {
  yield fork(authenticate, { password, username })
  const { authenticateSuccess, authenticateError } = yield race({
    authenticateSuccess: take(actionTypes.AUTHENTICATE_SUCCESS),
    authenticateError: take(actionTypes.AUTHENTICATE_ERROR)
  })

  if (authenticateError) {
    yield call(clearTokenAndUser)
    const isRedesignEnabled = yield call(redesignEnabled)

    if (isRedesignEnabled) {
      yield put(
        modals.actions.openAlert({
          kind: 'error',
          message:
            'There was an error. Please try again or contact support@pingthings.io'
        })
      )
    }
    yield put({ type: actionTypes.LOGIN_ERROR, error: true })
  } else {
    const {
      data: { token }
    } = authenticateSuccess

    yield put({ type: actionTypes.AUTHORIZE, token, username })
    const { authorizeError } = yield race({
      authorizeSuccess: take(actionTypes.AUTHORIZE_SUCCESS),
      authorizeError: take(actionTypes.AUTHORIZE_ERROR)
    })

    if (authorizeError) {
      const isRedesignEnabled = yield call(redesignEnabled)

      if (isRedesignEnabled) {
        yield put(
          modals.actions.openAlert({
            kind: 'error',
            message:
              'There was an error. Please try again or contact support@pingthings.io'
          })
        )
      }
      yield put({ type: actionTypes.LOGIN_ERROR, error: true })
    } else {
      yield put({ type: actionTypes.LOGIN_SUCCESS })

      if (!redesignEnabled()) {
        yield call([window.location, window.location.reload])
      }
    }
  }
}

function * logout () {
  yield call(clearTokenAndUser)
  yield call(clearStateFromLocalStorage)
  yield call([window.location, window.location.reload])
}

function * resetApiKey ({ password, username }) {
  yield fork(authenticate, { maintainState: true, password, username })
  const { authenticateError } = yield race({
    authenticateSuccess: take(actionTypes.AUTHENTICATE_SUCCESS),
    authenticateError: take(actionTypes.AUTHENTICATE_ERROR)
  })

  if (authenticateError) {
    yield put(
      modals.actions.openAlert({
        kind: 'error',
        message:
          'There was an error. Please try again or contact support@pingthings.io'
      })
    )
    yield put({ type: actionTypes.RESET_API_KEY_ERROR })
    return
  }

  const client = yield getContext('client')

  const { data, error } = yield call(authApi.resetApiKey, { client, username })

  if (error) {
    yield put(
      modals.actions.openAlert({
        kind: 'error',
        message:
          'There was an error. Please try again or contact support@pingthings.io'
      })
    )
    yield put({ type: actionTypes.RESET_API_KEY_ERROR, error })
    return
  }

  yield fork(authenticate, { maintainState: true, password, username })
  const { authenticateError: reauthenticateError } = yield race({
    authenticateSuccess: take(actionTypes.AUTHENTICATE_SUCCESS),
    authenticateError: take(actionTypes.AUTHENTICATE_ERROR)
  })

  if (reauthenticateError) {
    yield put(
      modals.actions.openAlert({
        kind: 'error',
        message:
          'There was an error. Please try again or contact support@pingthings.io'
      })
    )

    yield delay(2000)
    yield put({ type: actionTypes.RESET_API_KEY_ERROR, error })
    yield put(authActions.logout())
    return
  }

  yield put({ type: actionTypes.RESET_API_KEY_SUCCESS, apikey: data.apikey })
}

const watchers = [
  takeLatest(actionTypes.AUTHORIZE, authorize),
  takeLatest(actionTypes.CHANGE_PASSWORD, changePassword),
  takeLatest(actionTypes.GET_GROUPS, getGroups),
  takeLatest(actionTypes.LOGIN, login),
  takeLatest(actionTypes.LOGOUT, logout),
  takeLatest(actionTypes.RESET_API_KEY, resetApiKey)
]

export {
  authenticate,
  authorize,
  changePassword,
  getGroups,
  login,
  logout,
  resetApiKey,
  watchers
}
