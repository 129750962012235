import React, { useContext, useRef, useState } from 'react'

import { Modal, useOutsideClick } from 'frontend-components'
import { useDispatch } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'

import { CHART_COLORS } from './Palettes'
import plotter from '../../ducks/plotter'

const ColorSquare = styled.div`
  background-color: ${props => props.color};
  border-radius: 4px;
  height: 24px;
  width: 100%;
`

const ModalColorSquare = styled(ColorSquare)`
  height: 24px;
  margin-right: 4px;
  width: 40px;

  &:last-of-type {
    margin-right: 0;
  }
`

const StyledModal = styled(Modal)`
  left: 8px;
  min-width: unset;
  position: absolute;
  top: -32px;
  z-index: 999;
`

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`

const ModalWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  padding: 8px;
`

const ColorPickerModal = ({ changeStreamColor, closeModal }) => {
  const modalRef = useRef()
  const theme = useContext(ThemeContext)
  const colors = CHART_COLORS({ theme })

  useOutsideClick(modalRef, closeModal)

  return (
    <StyledModal variants={['skeleton', 'inline-block']}>
      <ModalWrapper ref={modalRef}>
        {colors.map(color => (
          <ModalColorSquare
            color={color}
            key={color}
            onClick={() => changeStreamColor({ color })}
          />
        ))}
      </ModalWrapper>
    </StyledModal>
  )
}

const Container = ({ plot }) => {
  const dispatch = useDispatch()

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const changeStreamColor = ({ color }) =>
    dispatch(plotter.actions.setStreamColor({ color, uuid: plot.uuid }))
  const closeModal = () => setModalIsOpen(false)
  const openModal = () => setModalIsOpen(true)

  return (
    <Component
      changeStreamColor={changeStreamColor}
      closeModal={closeModal}
      modalIsOpen={modalIsOpen}
      openModal={openModal}
      plot={plot}
    />
  )
}

const Component = ({
  changeStreamColor,
  closeModal,
  modalIsOpen,
  openModal,
  plot
}) => {
  return (
    <>
      {plot && (
        <Wrapper>
          <ColorSquare color={plot.color} onClick={openModal} />
          {modalIsOpen && (
            <ColorPickerModal
              changeStreamColor={changeStreamColor}
              closeModal={closeModal}
            />
          )}
        </Wrapper>
      )}
    </>
  )
}

export default Container
