import { combineReducers } from 'redux'

import auth from './auth'
import chart from './chart'
import grouper from './grouper'
import heatmap from './heatmap'
import modals from './modals'
import permalinks from './permalinks'
import plotter from './plotter'
import pmus from './pmus'
import sql from './sql'
import streams from './streams'
import ui from './ui'

const { reducer: authReducer } = auth
const { reducer: chartReducer } = chart
const { reducer: grouperReducer } = grouper
const { reducer: heatmapReducer } = heatmap
const { reducer: modalsReducer } = modals
const { reducer: permalinksReducer } = permalinks
const { reducer: plotterReducer } = plotter
const { reducer: pmusReducer } = pmus
const { reducer: sqlReducer } = sql
const { reducer: streamsReducer } = streams
const { reducer: uiReducer } = ui

function lastAction (state = null, action) {
  return action
}

const reducers = combineReducers({
  auth: authReducer,
  chart: chartReducer,
  grouper: grouperReducer,
  heatmap: heatmapReducer,
  lastAction,
  modals: modalsReducer,
  permalinks: permalinksReducer,
  plotter: plotterReducer,
  pmus: pmusReducer,
  sql: sqlReducer,
  streams: streamsReducer,
  ui: uiReducer
})

export default reducers
