import { timeFormat, utcFormat } from 'd3-time-format'
// prettier-ignore
import {
  timeYear, timeMonth, timeWeek, timeDay, timeHour, timeMinute, timeSecond, timeMillisecond,
  utcYear, utcMonth, utcWeek, utcDay, utcHour, utcMinute, utcSecond, utcMillisecond
} from 'd3-time'
import { nsToDate } from './timestamp'

function _build (year, month, week, day, hour, minute, second, milli, format) {
  const floors = {
    micro: t => (t.ns + '').slice(0, -3),
    milli: t => milli(t.date),
    second: t => second(t.date),
    minute: t => minute(t.date),
    hour: t => hour(t.date),
    day: t => day(t.date),
    month: t => month(t.date),
    year: t => year(t.date)
  }
  //  time            date
  //  n u m . s m h / d m y      < units
  //  0 1 2   3 4 5   6 7 8 9    < indexes
  const units = Object.getOwnPropertyNames(floors)
  const idx = u => units.indexOf(u)

  const _format = formatStr => {
    const fmt = format(formatStr)
    return t => fmt(t.date)
  }
  const _formatNs = (headStr, tailStr) => {
    const fmtHead = format(headStr)
    const fmtTail = format(tailStr)
    return t => {
      const digits = (t.ns + '').slice(-6, -3)
      return fmtHead(t.date) + digits + fmtTail(t.date)
    }
  }

  const dateFmtKey = (start, end) => {
    let key = ''
    for (let i = idx('day'); i <= idx('year'); i++) {
      key += start <= i && i < end ? 'X' : ' '
    }
    return key
  }

  // prettier-ignore
  const dateFormats = {
  // DMY - Day Month Year                   M   D   Y
    'X  ': _format('%a %-d'), // Thu --- 21  ----
    ' X ': _format('%b'), // --- Dec --  ----
    '  X': _format('%Y'), // --- --- --  2017
    'XX ': _format('%a %b %-d'), // Thu Dec 21  ----
    ' XX': _format('%b %Y'), // --- Dec --  2017
    XXX: _format('%a %b %-d, %Y') // Thu Dec 21, 2017
  }

  //  time            date
  //  u m . s m h / d m y
  //  0 1   2 3 4   5 6 7 8
  //                      ]    < max end
  //                ]          < min end bound
  //                             (effectively forces full time to be displayed)
  //                             (e.g. hour will never be omitted)
  function clampUnitRange (start, end) {
    end = Math.max(idx('day'), end)
    start = Math.min(start, end)
    return [start, end]
  }

  // prettier-ignore
  const timeFormats = [
    _formatNs('%-I:%M:%S.%L', ' %p'), // 11:15:30.023XXXXXX PM
    _format('%-I:%M:%S.%L %p'), // 11:15:30.023 PM
    _format('%-I:%M:%S %p'), // 11:15:30 PM
    _format('%-I:%M %p'), // 11:15 PM
    _format('%-I%p') // 11PM
  ]

  const smallestCommonUnit = (t0, t1) => {
    const a = { date: nsToDate(t0), ns: t0 }
    const b = { date: nsToDate(t1), ns: t1 }
    const unit = units.find(u => +floors[u](a) === +floors[u](b))
    return unit ? idx(unit) : units.length
  }

  const formatUnitRange = (ns, start, end = units.length) => {
    // This semicolon is actually needed!
    // See https://standardjs.com/rules-en.html#semicolons
    const t = { ns, date: nsToDate(ns) }
    ;[start, end] = clampUnitRange(start, end)
    const dateFmt = dateFormats[dateFmtKey(start, end)]
    const timeFmt = timeFormats[start]
    const date = dateFmt && dateFmt(t)
    const time = timeFmt && timeFmt(t)
    if (date && time) return `${date} (${time})`
    return date || time
  }

  return {
    smallestCommonUnit,
    formatUnitRange,
    units
  }
}

// prettier-ignore
export const utcFormatter = _build(
  utcYear, utcMonth, utcWeek, utcDay, utcHour, utcMinute, utcSecond, utcMillisecond, utcFormat
)
// prettier-ignore
export const timeFormatter = _build(
  timeYear, timeMonth, timeWeek, timeDay, timeHour, timeMinute, timeSecond, timeMillisecond, timeFormat
)
