import { TOKEN_KEY } from '../../../constants'
import { getSsoToken } from '../../../lib/sso'

export const getAuthHeader = () => {
  // get the authentication token from local storage if it exists
  const token = getSsoToken() || localStorage.getItem(TOKEN_KEY)
  // return the headers to the context so httpLink can read them
  const authHeader = token ? { authorization: `Bearer ${token}` } : {}
  return authHeader
}

export default getAuthHeader
