import {
  call,
  fork,
  getContext,
  put,
  race,
  takeEvery,
  take
} from 'redux-saga/effects'

import * as actionTypes from './actionTypes'
import * as api from './api'
import { encrypt } from '../../services/cryptoHash'
import LruCache from '../../services/lruCache'

export const lruCache = new LruCache()

function * query ({ id, params, query, useCache = false }) {
  const saga = useCache ? _cachedQuery : _query

  yield call(saga, { id, params, query })
}

function * _cachedQuery ({ id, params, query }) {
  const queryHash = yield call(encrypt, {
    value: JSON.stringify({ query, params })
  })
  const cached = yield call([lruCache, lruCache.get], { key: queryHash })

  if (cached) {
    yield put({ type: actionTypes.QUERY_SUCCESS, data: cached, id })
  } else {
    yield fork(_query, { id, params, query })
    const { querySuccess } = yield race({
      querySuccess: take(
        action => action.type === actionTypes.QUERY_SUCCESS && action.id === id
      ),
      queryError: take(
        action => action.type === actionTypes.QUERY_ERROR && action.id === id
      )
    })

    if (querySuccess) {
      const queryHash = yield call(encrypt, {
        value: JSON.stringify({ query, params })
      })
      yield call([lruCache, lruCache.set], {
        key: queryHash,
        value: querySuccess.data
      })
    }
  }
}

function * _query ({ id, params, query }) {
  const client = yield getContext('client')

  const { data, error } = yield call(api.query, { client, params, query })

  if (error) {
    yield put({ type: actionTypes.QUERY_ERROR, error, id })
  } else {
    yield put({ type: actionTypes.QUERY_SUCCESS, data, id })
  }
}

const watchers = [takeEvery(actionTypes.QUERY, query)]

export { _cachedQuery, _query, query, watchers }
