import React from 'react'

import { ApolloClient } from 'apollo-client'
import { ApolloLink, split } from 'apollo-link'
import { ApolloProvider } from 'react-apollo'
import { getMainDefinition } from 'apollo-utilities'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { WebSocketLink } from 'apollo-link-ws'

import { getErrorPlugin as getIncorrectCredentialsHandler } from './errors/IncorrectCredentials'
import { getErrorPlugin as getLackPermissionsHandler } from './errors/LackPermissions'
import { getErrorPlugin as getServicesDownHandler } from './errors/ServicesDown'
import { getErrorPlugin as getTimeoutHandler } from './errors/TimeoutError'
import { getErrorPlugin as getTokenHandler } from './errors/TokenError'
import { redesignEnabled } from '../../utils/featureFlags'
import { report as reportSentry } from '../../utils/sentry'
import getAuthHeader from './utils/getAuthHeader'

const httpUri =
  window.location.protocol + '//' + window.location.host + '/api/graphql'
const wsUri = httpUri.replace('http', 'ws') + '/subscription'

const httpLink = new HttpLink({ uri: httpUri })

const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true,
    connectionParams: getAuthHeader()
  }
})

const authLink = setContext((_, { headers }) => {
  const authHeader = getAuthHeader()
  return {
    headers: {
      ...headers,
      ...authHeader
    }
  }
})

/**
 * Each error handler contains
 * {
 *    name: The name of the error the handler checks for. Useful for logging.
 *    isError: a method to check whether this handler's error occurred,
 *    handleError: a method for handling the error if it's encountered,
 *    ErrorListener: A react component that mounts into the tree, renders
 *                   nothing, but can react to any dispatched events or
 *                   localStorage items that indicate the error occurred.
 *                   Useful for notifying the user what happened.
 * }
 */
const errorHandlers = [
  getTokenHandler(),
  getTimeoutHandler(),
  getServicesDownHandler(),
  getLackPermissionsHandler(),
  getIncorrectCredentialsHandler()
]

const errorLink = onError(args => {
  if (redesignEnabled()) {
    return
  }

  const { graphQLErrors, networkError, response } = args
  console.log('ErrorLink ran')
  let isHandled = false

  for (const errorHandler of errorHandlers) {
    const isError = errorHandler.isError(args)
    console.log('Checking for:', errorHandler.name, 'Result:', isError)
    if (isError) {
      errorHandler.handleError(args)
      try {
        isHandled = true
        // sometimes a component may handle errors from its own requests
        // this notifies the component that a graphQL error was already handled here
        response.errors[0].handled = true
      } catch (errors) {}
    } else if (isError && errorHandler.name === 'TokenExpiredError') {
      const level = 'info'
      reportGqlError(new Error('User jwt token expired'), { ...args, level })
    }
  }

  if (!isHandled) {
    console.log(
      'Encountered a network or GraphQL error not covered by our handlers'
    )
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
    }

    const userHasNoConnection = networkError && !response
    if (userHasNoConnection) {
      const level = 'info'
      reportGqlError(new Error('User lost internet connection'), {
        ...args,
        level
      })
    } else {
      reportGqlError(new Error('Unhandled error from Graphql ErrorLink'), args)
    }
  }
})

export function reportGqlError (err, gqlScope) {
  err = err || new Error('Unknown GraphQL error')

  const {
    graphQLErrors,
    level = 'warning',
    networkError,
    response,
    operation
  } = gqlScope

  reportSentry(err, {
    level,
    tags: {
      alertname: 'graphql-network-error'
    },
    extraScope: { graphQLErrors, networkError, response, operation }
  })
}

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  ApolloLink.from([authLink, errorLink, wsLink]),
  ApolloLink.from([authLink, errorLink, httpLink])
)

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
})

export default class ApolloProviderWithClient extends React.Component {
  render () {
    return (
      <ApolloProvider client={client}>
        {errorHandlers.map(eh => {
          const { ErrorListener, name } = eh
          if (ErrorListener) return <ErrorListener key={name} />
          else return null
        })}
        {this.props.children}
      </ApolloProvider>
    )
  }
}
