import styled from 'styled-components'

export const CustomReactTableStyles = styled.div`
  .rt-thead.-header,
  .rt-tr-group {
    min-height: 40px;
  }
  .rt-table {
    overflow: scroll !important;
    position: relative;
  }
  .rt-tbody {
    overflow: unset;
  }
  .rt-tr-group {
    flex-direction: row;
  }
  .rt-thead {
    &.-header,
    &.-filters {
      background: white;
      position: sticky;
    }
    &.-header {
      top: 0;
    }
    &.-filters {
      top: 40px;
    }
  }
  /* Control the row highlighting for hover and select */
  .ReactTable .rt-tr-group .rt-tr.isHovered {
    background-color: hsl(210, 4%, 91%);
  }
  .ReactTable .rt-tr-group .rt-tr.rowSelected {
    background-color: hsl(60, 100%, 90%);
  }
  .ReactTable .rt-tr-group .rt-tr.rowSelected.isHovered {
    background-color: hsl(60, 95%, 75%);
  }
  /* End row highlighting for hover and select */

  .ReactTable .rt-th,
  .ReactTable .rt-td {
    display: flex;
    align-items: center;
  }
  .ReactTable .-header .rt-th {
    text-transform: uppercase;
  }

  .ReactTable .rt-thead.-filters .rt-tr > * input {
    width: 100%;
  }

  .ReactTable .rt-td {
    padding-left: 15px;
  }

  .ReactTable .rt-td.rt-td-pt-button {
    padding: 0;
  }
  .ReactTable .rt-td.rt-td-pt-button .rt-td-pt-button-inner {
    width: 100%;
    height: 100%;
  }
  .rowSelected .rt-td.rt-td-pt-button .rt-td-pt-button-inner {
    display: none;
  }

  .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background-color: #e7e8e9;
  }

  .ReactTable .rt-td,
  .ReactTable .rt-thead.-filters {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.79;
    letter-spacing: normal;
    color: #494d52;
  }
  .rt-resizable-header-content,
  .rt-custom-header > div {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 10px;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.83;
    letter-spacing: 0.3px;
    color: #494d52;
  }
  .rt-resizable-header-content {
    letter-spacing: 0.5px;
  }
  .ReactTable .rt-thead.-filters .rt-tr {
    & > * {
      padding: 5px;

      input {
        line-height: 18px;
        padding: 5px;
        padding-left: 10px;
      }
    }
  }

  .-striped {
    height: 412px;
    @media screen and (min-width: 2560px) {
      height: 890px;
    }
  }

  .-highlight {
    height: 300px;
    @media screen and (min-width: 2560px) {
      height: 320px;
    }
  }
`

export const HighlightHint = styled.span`
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
  font-size: 16px;

  .icons {
    font-size: 14px;
  }

  .ReactTable .rt-tbody .rt-tr:not(.-padRow):hover & {
    display: flex;
    color: black;
  }
  .rowSelected &,
  .ReactTable .rt-tbody .rt-tr.rowSelected:not(.-padRow):hover & {
    display: none;
  }
  display: flex;
  color: #ccc;
`

export const NoStreamsSelected = styled.div`
  display: block;
  position: absolute;
  width: 66%;
  height: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  text-align: center;
`

export const TableControlBar = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-width: ${props => (props.bottomBorder ? '1' : '0')};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    margin-right: 40px;
  }
  position: relative;
  .ReactTable {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    width: 300px;
    left: 101%;
    top: 0px;
  }
`

export const TableControlBarBorderless = styled(TableControlBar)`
  border: none;
  justify-content: space-between;
  margin: 0;

  .tableTitle {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: #494d52;
    margin-bottom: -4px; /* Visual weight of title makes it look more aligned if it's shifted down*/
  }
  & > * {
    margin-right: 0;
  }
`
