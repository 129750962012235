import { takeEvery, put, delay } from 'redux-saga/effects'

import * as actions from './actions'
import * as actionTypes from './actionTypes'

export const ALERT_TIMEOUT = 8 * 1000

export function * autoCloseAlert (alert) {
  yield delay(ALERT_TIMEOUT)
  yield put(actions.closeAlert(alert))
}

export const watchers = [takeEvery(actionTypes.OPEN_ALERT, autoCloseAlert)]
