import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import assert from 'assert'

import { openAlert } from '../../ducks/modals/actions'
import { redesignEnabled } from '../../utils/featureFlags'
import { SecondaryButton } from '../Buttons/Buttons'
import permalinks from '../../ducks/permalinks'
import plotter from '../../ducks/plotter'

function copyToClipboard (str) {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

function idToLink (id) {
  return window.location.origin + '/permalink/' + id
}

function PermalinkNotification ({ id, link }) {
  return (
    <div style={{ cursor: 'default' }}>
      <b>Permalink Generated!</b>
      <p
        className={'permalink-string ' + id}
        data-test-name='permalink-generated'
      >
        {link}
      </p>
      <p>
        {/* eslint-disable-next-line */}
        <a href='#' onClick={() => copyToClipboard(link)}>
          Click here
        </a>{' '}
        to copy to clipboard.
      </p>
    </div>
  )
}

export default function GeneratePermalink () {
  const dispatch = useDispatch()

  const [generating, setGenerating] = useState(false)

  const error = useSelector(permalinks.selectors.createError)
  const generated = useSelector(permalinks.selectors.newest)
  const instance = useSelector(plotter.selectors.instance)
  const selectedRows = useSelector(plotter.selectors.selectedRows)
  const streamsSelected = useSelector(plotter.selectors.streamsSelected)

  const disabled = !streamsSelected || generating

  const genPermalink = () => {
    try {
      for (const stream of Object.values(selectedRows)) {
        assert(
          stream.plot,
          'tried to generate permalink for stream without .plot'
        )
      }
      dispatch(permalinks.actions.create({ state: instance }))
      setGenerating(true)
    } catch (e) {
      dispatch(
        openAlert({
          kind: 'error',
          message: 'We ran into an unknown issue creating a permalink'
        })
      )
    }
  }

  useEffect(() => {
    if (generating && generated) {
      const id = generated
      dispatch(
        openAlert({
          kind: 'success',
          message: <PermalinkNotification link={idToLink(id)} id={id} />
        })
      )
      setGenerating(false)
    }
  }, [generated, generating, dispatch])

  useEffect(() => {
    if (generating && error) {
      dispatch(
        openAlert({
          kind: 'error',
          message: 'We ran into an unknown issue creating a permalink'
        })
      )
      setGenerating(false)
    }
  }, [generating, error, dispatch])

  if (!redesignEnabled()) {
    return renderOld()
  }

  return (
    <li>
      <button
        className='no-icon generate-permalink'
        disabled={disabled}
        onClick={genPermalink}
        type='button'
      >
        <span className='item-text'>Generate permalink</span>
      </button>
    </li>
  )

  function renderOld () {
    function handleClick () {
      if (disabled) {
        dispatch(
          openAlert({
            kind: 'error',
            message:
              "Can't create a permalink if there is no active chart. This may happen if data has not finished loading or if you have only selected streams with no data."
          })
        )
      } else {
        genPermalink()
      }
    }

    return (
      <SecondaryButton onClick={handleClick} className='generate-permalink'>
        Generate Permalink
      </SecondaryButton>
    )
  }
}
