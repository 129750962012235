export const resetXScaleTheme = {
  dark: {
    icon: {
      color: 'neutral4',
      active: {
        backgroundColor: 'neutral6'
      },
      hover: {
        color: 'cyan2'
      }
    }
  },
  light: {
    icon: {
      color: 'black',
      active: {
        backgroundColor: 'neutral3'
      },
      hover: {
        color: 'blue2'
      }
    }
  }
}
