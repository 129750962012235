import React, { useState } from 'react'

import { ChartStreamDataProvider as FeComponentsChartStreamDataProvider } from 'frontend-components'

import { useDispatch } from 'react-redux'
import { useApolloClient } from 'react-apollo'

import { report as reportSentry } from '../../utils/sentry'

import chartDuck from '../../ducks/chart'
import modals from '../../ducks/modals'
import plotter from '../../ducks/plotter'

export const ChartStreamDataProvider = ({
  axes,
  dimensions,
  plots,
  showLegend,
  ...rest
}) => {
  const client = useApolloClient()
  const dispatch = useDispatch()

  const [error, setError] = useState(false)

  const openAlert = data => dispatch(modals.actions.openAlert(data))
  const setGlobalDefaultZoom = domain =>
    dispatch(chartDuck.actions.setGlobalDefaultZoom(domain))
  const updateChart = ({ data }) =>
    dispatch(plotter.actions.updateChart({ data }))

  const handleError = ({ err }) => {
    if (!error) {
      console.error(
        'This application error was caught by an error boundary:',
        err
      )
      openAlert({
        kind: 'error',
        message: `There was an error. Please try again or contact support@pingthings.io`
      })
      reportSentry(err, {
        level: 'error',
        tags: {
          alertName: 'redesigned plotter'
        }
      })
      setError(true)
    }
  }

  try {
    return (
      <FeComponentsChartStreamDataProvider
        axes={axes}
        client={client}
        dimensions={dimensions}
        plots={plots}
        setGlobalDefaultZoom={setGlobalDefaultZoom}
        showLegend={showLegend}
        updateChart={updateChart}
        {...rest}
      />
    )
  } catch (err) {
    handleError({ err })

    return (
      <div style={dimensions}>
        The chart encountered an error and was removed.
      </div>
    )
  }
}
