import * as sql from 'sql-query-generator'

import isUUID from 'validator/lib/isUUID'
import lodashGet from 'lodash.get'
import lodashIsNil from 'lodash.isnil'
import lodashOmitBy from 'lodash.omitby'

import { MalformedParameterError } from '../../utils/customError'

export const deserializeCount = ({ countData = [] }) => {
  if (!Array.isArray(countData)) {
    throw new MalformedParameterError('countData must be array')
  }

  countData = countData.map(datum => JSON.parse(datum))
  return countData.length > 0 ? lodashGet(countData[0], 'count', 0) : 0
}

export const deserializeList = ({ listData = [] }) => {
  const decodeResponseItem = ({ datum }) => {
    datum = JSON.parse(datum)

    if (datum.uuid && !isUUID(datum.uuid)) {
      try {
        datum.uuid = atob(datum.uuid)
      } catch (e) {}
    }

    if (datum.annotations) {
      try {
        datum.annotations = JSON.parse(atob(datum.annotations))
      } catch (e) {
        console.log(e.message)
        console.log({ datum })
        // TODO sentry
        datum.annotations = {}
      }
    }

    return datum
  }

  if (!Array.isArray(listData)) {
    throw new MalformedParameterError('listData must be array')
  }

  const propertiesToTagify = ['ingress', 'name', 'unit', 'distiller', 'unit']

  const deserialized = listData
    .map(datum => {
      datum = decodeResponseItem({ datum })

      const item = {
        tags: {}
      }

      for (const [property, value] of Object.entries(datum)) {
        if (propertiesToTagify.includes(property)) {
          item.tags[property] = value
        } else {
          item[property] = value
        }
      }

      return item
    })
    .map(wrapTo180)

  return deserialized
}

const wrapTo180 = stream => {
  if (typeof stream.annotations?.latitude !== 'undefined') {
    let lat = Number(stream.annotations.latitude)

    if (lat > 180) {
      lat = lat - 360
    }
    stream.annotations.latitude = lat.toString()
  }

  if (typeof stream.annotations?.longitude !== 'undefined') {
    let long = Number(stream.annotations.longitude)

    if (long > 180) {
      long = long - 360
    }

    stream.annotations.longitude = long.toString()
  }

  return stream
}

export const deserializeGeoStreamsList = ({ listData = [] }) => {
  const data = deserializeList({ listData })
  return data
    .filter(
      o =>
        typeof o.annotations.latitude !== 'undefined' &&
        typeof o.annotations.longitude !== 'undefined'
    )
    .map(wrapTo180)
}

export const deserializeMetadataUsage = ({ metadataUsage }) => {
  const deserialized = {}

  for (const annotation of lodashGet(metadataUsage, 'annotations', [])) {
    deserialized[`annotations.${annotation.key}`] = annotation.count
  }

  return deserialized
}

export const serializeAvailableColumns = ({ addAnnotations, streamsData }) => {
  const columnList = {
    topLevel: {}
  }

  const countAnnotations = data => {
    columnList.annotations = {}
    Object.keys(data).map(key => {
      const fullKeyName = `annotations.${key}`
      return columnList.annotations[fullKeyName] !== undefined
        ? (columnList.annotations[fullKeyName] += 1)
        : (columnList.annotations[fullKeyName] = 1)
    })
  }

  const countTopLevel = stream => {
    Object.keys(stream).map(rootKey =>
      columnList.topLevel[rootKey] !== undefined
        ? (columnList.topLevel[rootKey] += 1)
        : (columnList.topLevel[rootKey] = 1)
    )
  }

  const countTags = data => {
    Object.keys(data).map(key => {
      const fullKeyName = `tags.${key}`
      return columnList.topLevel[fullKeyName] !== undefined
        ? (columnList.topLevel[fullKeyName] += 1)
        : (columnList.topLevel[fullKeyName] = 1)
    })
  }

  for (const originalStream of streamsData) {
    const {
      __typename,
      annotations = [],
      tags = [],
      ...stream
    } = originalStream
    countTopLevel(stream)
    countTags(tags)
    if (addAnnotations) {
      countAnnotations(annotations)
    }
  }

  return columnList
}

export const serializeColumn = ({ name }) => {
  const getFieldName = name => {
    const split = name.split('.')
    let fieldName
    if (split.length > 1) {
      fieldName = split.slice(1).join('.')
    } else {
      fieldName = split[0]
    }

    return fieldName
  }

  const fieldName = getFieldName(name)

  return {
    Header: fieldName,
    id: name,
    _pathToAttr: name
  }
}

export const serializeQuery = ({
  columns,
  filters = {},
  page = 0,
  pageSize = 10,
  sort = [[], []]
}) => {
  // build base queries
  const columnsToSelect = [
    'uuid',
    'collection',
    'name',
    'unit',
    'ingress',
    'distiller',
    'annotations::json as annotations',
    'property_version'
  ]
  let countQuery = sql.select('streams', 'COUNT(uuid)')
  let queryObj = sql.select('streams', columnsToSelect)

  // maybe add WHERE clause
  if (isWhereQuery({ columns, filters })) {
    const queryWhere = buildQueryWhere({ columns, filters })

    countQuery = countQuery.where(queryWhere, 'ILIKE')
    queryObj = queryObj.where(queryWhere, 'ILIKE')
  }

  // add ORDER BY and LIMIT clauses
  queryObj = queryObj
    .orderby(buildQuerySort(sort, columns))
    .limit(pageSize, resultOffset(pageSize, page))

  return {
    countQuery: countQuery,
    listQuery: queryObj
  }
}

const isWhereQuery = ({ columns, filters }) =>
  Object.entries(filters).find(filter => {
    const property = filter[0]
    const searchTerm = filter[1]
    return columns.includes(property) && !!searchTerm
  })

const buildQueryWhere = ({ columns, filters }) => {
  filters = lodashOmitBy(filters, (val, key) => {
    return lodashIsNil(val) || !columns.includes(key)
  })

  const queryWhere = {}

  for (let [property, searchTerm] of Object.entries(filters)) {
    let searchColumn
    if (property.startsWith('tags.')) {
      searchColumn = property.split('.')[1]
    } else if (property.startsWith('annotations.')) {
      const annotation = property.split('.')[1]
      searchColumn = `annotations->'${annotation}'`
    } else if (property === 'uuid') {
      searchColumn = 'uuid::text'
    } else {
      searchColumn = property
    }

    searchTerm = searchTerm.replace(new RegExp('%', 'g'), '/%')
    searchTerm = searchTerm
      .trim()
      .replace(/  +/g, ' ')
      .replace(new RegExp(' ', 'g'), '%')
    queryWhere[searchColumn] = '%' + searchTerm + '%'
  }

  return queryWhere
}

const buildQuerySort = (sort, visibleColumns) => {
  let querySort = ''
  let sortColumn = ''

  if (sort[0].length > 0) {
    const requestedSortColumn = sort[0][0]
    if (visibleColumns.includes(requestedSortColumn)) {
      sortColumn = requestedSortColumn

      if (sortColumn.startsWith('tags.')) {
        sortColumn = sortColumn.split('.')[1]
      } else if (sortColumn.startsWith('annotations.')) {
        const annotation = sortColumn.split('.')[1]
        sortColumn = `annotations->'${annotation}'`
      }

      const sortOrder = sort[1][0]
      querySort = sortColumn + ' ' + sortOrder
    }
  }

  if (!sortColumn) {
    querySort = ['collection ASC', 'name ASC']
  }

  return querySort
}

const resultOffset = (pageSize, page) => {
  return pageSize * page
}
